import React, { useState, useEffect, useRef } from "react";

const DataFilters = ({
  warehouses,
  categories,
  selectedWarehouse,
  selectedCategories,
  onWarehouseChange,
  onCategoryChange,
  navigatedWarehouse,
  showCategory = true,
}) => {
  const [isWarehouseOpen, setWarehouseOpen] = useState(false);
  const [isCategoryOpen, setCategoryOpen] = useState(false);
  const [warehouseSearch, setWarehouseSearch] = useState("");
  const [filteredWarehouses, setFilteredWarehouses] = useState(warehouses);
  const [filteredCategories, setFilteredCategories] = useState([]);

  // Refs to handle clicks outside of the dropdowns
  const warehouseDropdownRef = useRef(null);
  const categoryDropdownRef = useRef(null);

  useEffect(() => {
    const uniqueCategories = [...new Set(categories)];
    setFilteredCategories(uniqueCategories);
  }, [categories]);

  // Handle warehouse selection (single selection)
  const handleWarehouseSelection = (warehouse) => {
    const newSelectedWarehouse =
      selectedWarehouse === warehouse ? null : warehouse;
    onWarehouseChange(newSelectedWarehouse);
    onCategoryChange([]);
    setWarehouseOpen(false);
  };

  // Handle category selection (multiple selection)
  const toggleCategorySelection = (category) => {
    const newSelectedCategories = selectedCategories.includes(category)
      ? selectedCategories.filter((item) => item !== category)
      : [...selectedCategories, category];

    onCategoryChange(newSelectedCategories);
  };

  useEffect(() => {
    const filtered = warehouses.filter((warehouse) =>
      warehouse.toLowerCase().includes(warehouseSearch.toLowerCase())
    );
    setFilteredWarehouses(filtered);
  }, [warehouseSearch, warehouses]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        warehouseDropdownRef.current &&
        !warehouseDropdownRef.current.contains(event.target)
      ) {
        setWarehouseOpen(false);
      }
      if (
        categoryDropdownRef.current &&
        !categoryDropdownRef.current.contains(event.target)
      ) {
        setCategoryOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    setWarehouseSearch("");
    setFilteredWarehouses(warehouses);
    return () => {
      setWarehouseSearch("");
      setFilteredWarehouses(warehouses);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="flex flex-wrap gap-2 items-center p-4 w-full text-xs font-medium leading-none text-gray-900 border-b border-gray-200 max-md:max-w-full font-golos">
      <span className="flex-1 shrink self-stretch my-auto basis-8">
        Filters:
      </span>
      <div className="relative" ref={warehouseDropdownRef}>
        <button
          onClick={() => setWarehouseOpen(!isWarehouseOpen)}
          className="flex gap-2 items-center self-stretch px-4 py-2 my-auto bg-gray-50 rounded-lg border border-gray-300 border-solid"
        >
          <span className="self-stretch my-auto text-ellipsis">
            {selectedWarehouse ? `Site: ${selectedWarehouse}` : "Sites"}
          </span>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/53922b06987d0d7a5a57983c4f8534e8a87e912bd77a3b62c0fbf0bdd2777236"
            alt=""
            className="object-contain shrink-0 self-stretch my-auto aspect-square w-[18px]"
          />
        </button>

        {isWarehouseOpen && (
          <div className="absolute z-10 mt-2 w-48 bg-gray-50 rounded-lg shadow-lg border border-gray-200 max-h-60 overflow-y-auto">
            {/* <div className="p-2 border-b border-gray-200">
              <input
                type="text"
                placeholder="Search warehouses..."
                value={warehouseSearch}
                onChange={(e) => setWarehouseSearch(e.target.value)}
                className="w-full px-3 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
              />
            </div> */}

            {filteredWarehouses.map((warehouse) => (
              <label
                key={warehouse}
                className="flex items-center p-2 hover:bg-gray-100 cursor-pointer"
              >
                <input
                  type="radio"
                  name="warehouse"
                  value={warehouse}
                  checked={selectedWarehouse === warehouse}
                  onChange={() => handleWarehouseSelection(warehouse)}
                  className="mr-2"
                />
                {warehouse}
              </label>
            ))}
            {filteredWarehouses.length === 0 && (
              <p className="p-4 text-gray-500 text-center">
                No matching warehouses
              </p>
            )}
          </div>
        )}
      </div>

      {showCategory && (
        <div className="relative" ref={categoryDropdownRef}>
          <button
            onClick={() => {
              if (selectedWarehouse) {
                setCategoryOpen(!isCategoryOpen);
              }
            }}
            className={`flex gap-2 items-center self-stretch px-4 py-2 my-auto rounded-lg border border-solid
            ${
              selectedWarehouse
                ? "bg-gray-50 border-gray-300 cursor-pointer"
                : "bg-gray-100 border-gray-200 cursor-not-allowed opacity-60"
            }`}
          >
            <span className="self-stretch my-auto text-ellipsis">
              {selectedCategories.length > 0
                ? `scenarios (${selectedCategories.length})`
                : "scenarios"}
            </span>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/dbe26ee3fa79a58c1172d304f66b2ec033154496f4aecd51b2da2f99c4b38dc9"
              alt=""
              className="object-contain shrink-0 self-stretch my-auto aspect-square w-[18px]"
            />
          </button>

          {isCategoryOpen && selectedWarehouse && (
            <div className="absolute z-10 mt-2 w-64 bg-gray-50 rounded-lg shadow-lg border border-gray-200">
              <div className="max-h-60 overflow-y-auto">
                {filteredCategories && filteredCategories.length > 0 ? (
                  filteredCategories.map((category) => (
                    <label
                      key={category}
                      className="flex items-center p-2 hover:bg-gray-100 cursor-pointer"
                    >
                      <input
                        type="checkbox"
                        value={category}
                        checked={selectedCategories.includes(category)}
                        onChange={() => toggleCategorySelection(category)}
                        className="mr-2 p-4"
                      />
                      {category}
                    </label>
                  ))
                ) : (
                  <p className="p-4 text-gray-500 text-center">
                    No use-cases available
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DataFilters;
