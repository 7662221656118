import React, {
  createContext,
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";

export const WebSocketContext = createContext(null);

const WS_STATES = {
  CONNECTING: 0,
  OPEN: 1,
  CLOSING: 2,
  CLOSED: 3,
};

const CONFIG = {
  MAX_RECONNECT_ATTEMPTS: 5,
  INITIAL_RECONNECT_DELAY: 1000,
  MAX_RECONNECT_DELAY: 25000,
  PING_INTERVAL: 60000,
  MESSAGE_QUEUE_SIZE: 100,
  BATCH_MESSAGE_TIMEOUT: 100,
};

export const WebSocketProvider = ({ children, url, onMessageCallback }) => {
  const [isConnected, setIsConnected] = useState(false);
  const [error, setError] = useState(null);
  const [triggerFetch, setTriggerFetch] = useState(false);
  const [fetchDetectionStatus, setFetchDetectionStatus] = useState(false);
  const socketRef = useRef(null);
  const reconnectTimeoutRef = useRef(null);
  const reconnectAttemptsRef = useRef(0);
  const pingIntervalRef = useRef(null);
  const messageQueueRef = useRef([]);
  const batchTimeoutRef = useRef(null);
  const lastPongRef = useRef(Date.now());

  const dispatch = useDispatch();
  const socketParams = useSelector((state) => state.user);

  const getFullUrl = useCallback(
    (projectID) => {
      if (!socketParams?.socketID || !socketParams?.email || !projectID) {
        throw new Error("Missing required socket parameters");
      }

      return `${url}?socket_id=${socketParams.socketID}&email=${socketParams.email}&project_id=${projectID}`;
    },
    [url, socketParams]
  );

  const processMessage = useCallback(
    (event) => {
      try {
        if (event.data === "pong") {
          console.log("WebSocket pong received");
          lastPongRef.current = Date.now();
          return;
        }

        const message = JSON.parse(event.data);
        console.log("WebSocket message received:", message);

        if (message?.type === "DetectionStatus") {
          setFetchDetectionStatus(true);
          console.log("fetchDetection", message?.type);
        } else if (message?.fetch === true) {
          dispatch({ type: "FETCH_DATA", payload: message.data });
          setTriggerFetch(true);
        } else {
          onMessageCallback?.(message);
        }
      } catch (err) {
        console.error("WebSocket message processing error:", err);
        setError("Message processing failed");
      }
    },
    [dispatch, onMessageCallback]
  );

  const connectWebSocket = useCallback(
    (params) => {
      if (reconnectAttemptsRef.current >= CONFIG.MAX_RECONNECT_ATTEMPTS) {
        console.error("Max reconnection attempts reached");
        setError("Connection failed after maximum attempts");
        return;
      }

      try {
        const projectID = params || socketParams?.projectID;
        const fullUrl = getFullUrl(projectID);
        const ws = new WebSocket(fullUrl);
        socketRef.current = ws;

        ws.onopen = () => {
          console.log("WebSocket connected");
          setIsConnected(true);
          setError(null);
          reconnectAttemptsRef.current = 0;

          pingIntervalRef.current = setInterval(() => {
            if (ws.readyState === WS_STATES.OPEN) {
              ws.send("ping");

              if (Date.now() - lastPongRef.current > CONFIG.PING_INTERVAL * 2) {
                console.warn("No pong received - connection may be dead");
                ws.close();
              }
            }
          }, CONFIG.PING_INTERVAL);

          flushMessageQueue();
        };

        ws.onmessage = processMessage;

        ws.onclose = () => {
          setIsConnected(false);
          clearInterval(pingIntervalRef.current);

          if (reconnectAttemptsRef.current < CONFIG.MAX_RECONNECT_ATTEMPTS) {
            const delay = Math.min(
              CONFIG.INITIAL_RECONNECT_DELAY *
                Math.pow(2, reconnectAttemptsRef.current),
              CONFIG.MAX_RECONNECT_DELAY
            );

            reconnectAttemptsRef.current++;
            reconnectTimeoutRef.current = setTimeout(
              () => connectWebSocket(projectID),
              delay
            );
          }
        };

        ws.onerror = (error) => {
          console.error("WebSocket error:", error);
          setError("Connection error occurred");
        };
      } catch (error) {
        console.error("WebSocket connection failed:", error);
        setError("Failed to establish connection");
      }
    },
    [getFullUrl, socketParams, processMessage]
  );

  const flushMessageQueue = useCallback(() => {
    if (!socketRef.current || socketRef.current.readyState !== WS_STATES.OPEN) {
      return;
    }

    while (messageQueueRef.current.length > 0) {
      const message = messageQueueRef.current.shift();
      socketRef.current.send(JSON.stringify(message));
    }
  }, []);

  const sendMessage = useCallback(
    (message) => {
      if (socketRef.current?.readyState === WS_STATES.OPEN) {
        socketRef.current.send(JSON.stringify(message));
      } else {
        messageQueueRef.current.push(message);

        if (messageQueueRef.current.length > CONFIG.MESSAGE_QUEUE_SIZE) {
          messageQueueRef.current.shift();
        }

        if (!batchTimeoutRef.current) {
          batchTimeoutRef.current = setTimeout(() => {
            batchTimeoutRef.current = null;
            flushMessageQueue();
          }, CONFIG.BATCH_MESSAGE_TIMEOUT);
        }
      }
    },
    [flushMessageQueue]
  );

  useEffect(() => {
    return () => {
      clearTimeout(reconnectTimeoutRef.current);
      clearTimeout(batchTimeoutRef.current);
      clearInterval(pingIntervalRef.current);

      if (socketRef.current?.readyState === WS_STATES.OPEN) {
        socketRef.current.close();
      }
    };
  }, []);

  const contextValue = useMemo(
    () => ({
      isConnected,
      sendMessage,
      error,
      connectWebSocket,
      triggerFetch,
      setTriggerFetch,
      fetchDetectionStatus,
      setFetchDetectionStatus,
    }),
    [
      isConnected,
      sendMessage,
      error,
      connectWebSocket,
      triggerFetch,
      fetchDetectionStatus,
    ]
  );

  return (
    <WebSocketContext.Provider value={contextValue}>
      {children}
    </WebSocketContext.Provider>
  );
};
