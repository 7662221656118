import React from 'react';
import { X } from 'lucide-react';
import { Rnd } from 'react-rnd';

const BoundingBox = ({
  index,
  boundaryBox,
  isSelected,
  imageWidth,
  imageHeight,
  onSelect,
  onDelete,
  onDragStart,
  onDragStop,
  onResizeStart,
  onResizeStop,
}) => {
  const getDeleteButtonPosition = () => {
    const isNearRightEdge = boundaryBox.left_img + boundaryBox.boxWidth_img > imageWidth - 24;
    const isNearTopEdge = boundaryBox.top_img < 12;
    
    return `absolute ${isNearTopEdge ? 'bottom' : 'top'}-[-12px] ${isNearRightEdge ? 'left' : 'right'}-[-12px]`;
  };

  return (
    <Rnd
      position={{
        x: boundaryBox.left_img,
        y: boundaryBox.top_img
      }}
      size={{
        width: boundaryBox.boxWidth_img,
        height: boundaryBox.boxheight_img,
      }}
      style={{
        position: 'absolute',
        zIndex: isSelected ? 1000 : 1,
      }}
      bounds="parent"
      minWidth={20}
      minHeight={20}
      onDragStart={onDragStart}
      onResizeStart={onResizeStart}
      onDrag={(e, d) => {
        const maxX = imageWidth - boundaryBox.boxWidth_img;
        const maxY = imageHeight - boundaryBox.boxheight_img;
        const newX = Math.max(0, Math.min(d.x, maxX));
        const newY = Math.max(0, Math.min(d.y, maxY));
        return {
          x: newX,
          y: newY
        };
      }}
      onDragStop={(e, d) => {
        const maxX = imageWidth - boundaryBox.boxWidth_img;
        const maxY = imageHeight - boundaryBox.boxheight_img;
        const newX = Math.max(0, Math.min(d.x, maxX));
        const newY = Math.max(0, Math.min(d.y, maxY));
        onDragStop(index, newX, newY);
      }}
      onResize={(e, direction, ref, delta, position) => {
        const width = Math.min(ref.offsetWidth, imageWidth - position.x);
        const height = Math.min(ref.offsetHeight, imageHeight - position.y);
        ref.style.width = `${width}px`;
        ref.style.height = `${height}px`;
      }}
      onResizeStop={(e, direction, ref, delta, position) => {
        const width = Math.min(ref.offsetWidth, imageWidth - position.x);
        const height = Math.min(ref.offsetHeight, imageHeight - position.y);
        const x = Math.max(0, Math.min(position.x, imageWidth - width));
        const y = Math.max(0, Math.min(position.y, imageHeight - height));
        onResizeStop(index, x, y, width, height);
      }}
      className={`border-2 border-dashed ${
        isSelected ? 'border-red-500 bg-blue-50/20' : 'border-red-500'
      }`}
      onClick={(e) => {
        e.stopPropagation();
        onSelect(index);
      }}
      enableResizing={{
        top: true,
        right: true,
        bottom: true,
        left: true,
        topRight: true,
        bottomRight: true,
        bottomLeft: true,
        topLeft: true,
      }}
      dragAxis="both"
      resizeGrid={[1, 1]}
      dragGrid={[1, 1]}
    >
      {isSelected && (
        <button
          className={`${getDeleteButtonPosition()} p-1 bg-red-500 rounded-full text-xs font-bold border-2 border-white text-white cursor-pointer shadow-md`}
          onClick={(e) => onDelete(e, index)}
          onMouseDown={(e) => e.stopPropagation()}
        >
          <X size={10} />
        </button>
      )}
    </Rnd>
  );
};

export default React.memo(BoundingBox);