import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { useSelector } from "react-redux";
import Sidebar from "../../components/Sidebar";
import { useNavigate } from "react-router-dom";
import { processSitesData } from "./../../utils/chartData";
import alertFunctions from "../../utils/alertUtils";
import {
  getAllAlerts,
  GetSiteEvents,
  getVideos,
} from "../../services/apiServiceDetails";
import { DotLoader } from "react-spinners";
import VideoPlayer from "../../components/VideoPlayer";
import Tabs from "../../components/Tabs";
import { useParams } from "react-router-dom/dist";
import TrendsSection from "../../components/TrendsSection";
import { createRecentAlertData } from "../../utils/recentAlertUtils";
import CategorySection from "../../components/CategorySection";
import DataFilters from "../../components/DataFilters";
import DateFilter from "../../components/DateFilter";
import RecentAlertsPanel from "../../components/RecentAlertsPanel";
import Heatmap from "../../components/HeatMap";
import SafetyDashboard from "../../components/SafetyDashboard";
import BarChartIcon from "@mui/icons-material/BarChart";
import Loader from "../../components/Loader";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import { getIdByName } from "../../utils/constants";
// import { FileText } from "lucide-react";
import { Report } from "@mui/icons-material";

export const GraphVisualize = () => {
  const { projectid } = useParams();
  const [siteChartData, setSiteChartData] = useState([]);
  const [siteList, setSiteList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [recentAlertData, setRecentAlertData] = useState([]);
  const [specificAlertData, setSpecificAlertData] = useState([]);
  const [videoData, setVideoData] = useState([]);
  const [open, setOpen] = useState(false);
  const videoUrl = "https://www.w3schools.com/html/mov_bbb.mp4"; // Sample video URL
  const [selectedTab, setSelectedTab] = useState("Overview");
  const [selectedDateRange, setSelectedDateRange] = useState("Hours");
  const [selectWarehouse, setSelectWarehouse] = useState("");
  const [selectCategories, setSelectCategories] = useState([]);
  const [siteSpecificData, setSiteSpecificData] = useState([]);
  const [navigatedWarehouse, setNavigatedWarehouse] = useState(false);
  const [datecounters, setDatecounters] = useState(1);
  const [siteEventData, setSiteEventData] = useState({});
  const [alertData, setAlertData] = useState({
    Hours: 0,
    Week: 0,
    Month: 0,
    Year: 0,
  });
  const navigate = useNavigate();
  const params = useParams();
  // console.log("params", params);
  const user = useSelector((state) => state.user);
  var StreamOptions = ["All Videos"];

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const navItems = [
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/de3b8b5271d8bc1e88c6056582e69f592845a7ad580a06c9846c637a3015e288?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde",
      text: "Active Sites",
      active: false,
      navigate: () => navigate("/home"),
    },
    {
      icon: <BarChartIcon />,
      text: "Reporting Dashboard",
      active: true,
      navigate: () => navigate("/dashboard"),
    },
    {
      icon: <AutoGraphIcon />,
      text: "Operational Dashboard",
      active: false,
      linkTo: "/six-sigma-chart",
      navigate: () => navigate("/six-sigma-chart"),
    },
    {
      icon: <Report size={24} />,
      text: "Incident Report",
      active: false,
      linkTo: "/reports",
      navigate: () => navigate("/reports"),
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      var processSiteData;
      setLoading(true);
      try {
        let accountID = localStorage.getItem("account_id");
        const data = await getAllAlerts(accountID); // Fetch the alert data
        // const videoDetail = await getVideos(projectid); // Fetch the videos
        //setVideoData(videoDetail);

        // if (videoDetail.length > 0) {
        //   videoDetail.map((video) =>
        //     StreamOptions.push(video.stream.video_name)
        //   );
        // }

        // const data_s = data.sites.forEach((site) => {
        //   console.log("GraphVisualize -> data_s -> site", site);
        //   site?.use_cases?.forEach((useCase) => {
        //     if (useCase?.alerts) {
        //       // Ensure alerts is not null
        //       useCase?.alerts.forEach((alert) => {
        //         if (alert?.forklift_id !== undefined) {
        //           // Check if forklift_id is present
        //           alert.alert_type = "Forklift overspeeding"; // Add alert_type
        //         }
        //       });
        //     }
        //   });
        // });

        const recentAlertData = createRecentAlertData(data);
        setRecentAlertData(recentAlertData);
        processSiteData = processSitesData(data);

        const last24hours = alertFunctions.getAlertsInLast24Hours(data.sites);
        const last1week = alertFunctions.getAlertsInLastWeek(data.sites);
        const last1month = alertFunctions.getAlertsInLastMonth(data.sites);
        const last1year = alertFunctions.getAlertsInLastYear(data.sites);

        const a = {
          Hours: last24hours,
          Week: last1week,
          Month: last1month,
          Year: last1year,
        };
        // console.log("Trends section alert data", a);
        setAlertData(a);

        setSpecificAlertData(data);
        setSiteChartData(processSiteData.transformedData);
        setSiteList(processSiteData.siteList);
        // console.log("processSiteData.siteList", processSiteData.siteList);
        const storedData = sessionStorage.getItem("warehouseData");

        if (storedData) {
          const { warehouse } = JSON.parse(storedData);
          setNavigatedWarehouse(true);
          setSelectWarehouse(warehouse);
          sessionStorage.removeItem("warehouseData");
        } else {
          setSelectWarehouse(
            processSiteData.siteList[processSiteData.siteList.length - 1]
          );
          setNavigatedWarehouse(true);
        }
      } catch (error) {
        console.error("Error fetching or processing data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  function getUseCaseNamesForSite(transformedData, siteName) {
    // Find the site that matches the given siteName
    const siteData = transformedData.find(
      (site) => site.site_name === siteName
    );

    // If site is found, extract and return useCaseNames
    if (siteData) {
      return siteData.useCases?.map((useCase) => useCase.name);
    } else {
      // Return an empty array or handle the case where the site doesn't exist
      return [];
    }
  }

  const fetchEvents = async (selectedDateRange) => {
    try {
      if (process.env.NODE_ENV === "development") {
        const res = await GetSiteEvents(221, selectedDateRange);
        setSiteEventData(res.sites[0]);
        // console.log("!!!!!!!!!!!!!!!!!!!!!!! sites", res.sites[0]);
        return res;
      }
      const siteID = getIdByName(selectWarehouse);
      if (siteID) {
        const res = await GetSiteEvents(siteID, selectedDateRange);
        // console.log("!!!!!!!!!!!!!!!!!!!!!!! sites", res.sites[0]);
        setSiteEventData(res.sites[0]);
        return res;
      }
    } catch (error) {
      console.error("Error fetching or processing data:", error);
    }
  };

  useEffect(() => {
    // console.log("Selected Date range in GraphVisulaise", s/electedDateRange);
    fetchEvents(selectedDateRange);
  }, [selectWarehouse, selectedDateRange]);

  // useEffect(() => {
  //   const storedData = sessionStorage.getItem("warehouseData");

  //   if (storedData) {
  //     const { warehouse } = JSON.parse(storedData);
  //     setNavigatedWarehouse(true);
  //     setSelectWarehouse(warehouse);
  //     sessionStorage.removeItem("warehouseData");
  //     console.log("inside fetch data");
  //   }
  // }, [navigate]);

  useEffect(() => {
    if (selectedDateRange === "Hours") {
      setDatecounters(24);
    } else {
      setDatecounters(1);
    }
  }, [selectedDateRange]);

  function getSiteData(transformedData, siteName) {
    // Find the site object with the specified siteId
    const siteData = transformedData.find(
      (site) => site.site_name === siteName
    );

    // Check if the siteData exists
    if (siteData) {
      return siteData; // Return the data for the specific site
    } else {
      return null; // Return null if the site is not found
    }
  }

  useEffect(
    () => {
      const useCasesForSite = getUseCaseNamesForSite(
        siteChartData,
        selectWarehouse
      );
      // console.log("useCasesForSite", useCasesForSite);
      setCategoryList(useCasesForSite);

      const specificSiteData = getSiteData(siteChartData, selectWarehouse);
      setSiteSpecificData(specificSiteData);

      // console.log("zz", navigatedWarehouse, specificSiteData);

      if (useCasesForSite && useCasesForSite.length > 0 && specificSiteData) {
        // console.log("useCasesForSite", useCasesForSite);
        setSelectCategories(useCasesForSite);
      } else {
        setSelectCategories([]);
      }
    },
    [siteChartData, selectWarehouse, siteSpecificData]
    // siteSpecificData
  );

  const handleBackClick = () => {
    navigate("/home"); // Navigate to the dashboard page
  };

  return (
    <div className="h-screen w-screen bg-gray-100">
      <div className="flex h-full">
        <Sidebar
          navItems={navItems}
          userName={user.name}
          usermail={user.email}
          currentPage="Dashboard"
          selectData={localStorage.getItem("projectname")}
          handleBackClick={handleBackClick}
        />
        {loading ? (
          <Loader
            heading={"Loading Reporting Dashboard"}
            subheading={"Please wait while we load the reporting dashboard."}
          />
        ) : (
          <React.Fragment>
            <main
              className="flex flex-col h-[calc(100vh-2rem)] overflow-y-auto bg-white ml-5 mr-4 my-4 w-full rounded-xl"
              data-testid="main-container"
            >
              <div className="flex flex-col grow justify-center py-4 pr-4 h-full max-md:max-w-full">
                <div className="flex overflow-hidden flex-col justify-center w-full max-md:max-w-full">
                  <div className="flex flex-col px-8 pt-8 w-full bg-white rounded-b-xl max-md:px-5 max-md:max-w-full h-full">
                    <h1
                      className="flex-1 shrink self-stretch my-auto text-3xl font-semibold tracking-tighter leading-tight text-gray-900 basis-10 max-md:max-w-full font-suse"
                      data-testid="dashboard-title"
                    >
                      Reporting Dashboard for {datecounters} {selectedDateRange}{" "}
                      - {selectWarehouse}
                    </h1>
                    <div className="flex flex-wrap justify-between items-center w-full h-full text-sm font-medium leading-none max-md:max-w-full">
                      <div className="flex flex-wrap gap-1 items-center self-stretch p-1 my-auto text-gray-500 bg-gray-50 rounded-lg border border-gray-200 border-solid min-w-[240px] max-md:max-w-full">
                        <Tabs
                          selectedTab={selectedTab}
                          setSelectedTab={setSelectedTab}
                        />
                      </div>
                      <div className="flex gap-2 items-center self-stretch px-4 py-2.5 my-auto bg-white rounded-lg min-h-[40px] text-slate-600 w-[200px]">
                        <DateFilter setSelectedOption={setSelectedDateRange} />
                      </div>

                      <div className="flex flex-wrap gap-4 mt-8 w-full h-[calc(100vh-230px)] max-md:max-w-full justify-center">
                        {selectedTab !== "Heatmap" ? (
                          <>
                            <div className="flex flex-col flex-1 shrink bg-white h-[calc(100vh-230px)] rounded-lg border border-gray-200 border-solid basis-0 min-w-[240px] max-md:max-w-full">
                              <DataFilters
                                warehouses={siteList}
                                categories={categoryList}
                                selectedWarehouse={selectWarehouse}
                                selectedCategories={selectCategories}
                                onWarehouseChange={setSelectWarehouse}
                                onCategoryChange={setSelectCategories}
                                navigatedWarehouse={navigatedWarehouse}
                              />
                              {selectCategories.length > 0 ? (
                                <>
                                  <div className="flex-1 overflow-y-auto">
                                    {selectCategories.map((category, index) => (
                                      <CategorySection
                                        key={index}
                                        category={category}
                                        selectWarehouse={selectWarehouse}
                                        siteSpecificData={siteSpecificData}
                                        selectedDateRange={selectedDateRange}
                                        specificAlertData={specificAlertData}
                                        LineChartData={siteEventData}
                                        fetchEvents={fetchEvents}
                                      />
                                    ))}
                                  </div>
                                </>
                              ) : (
                                <div className="flex flex-wrap gap-2 text-gray-500  items-center justify-center mt-2 w-full h-full max-md:max-w-full overflow-">
                                  No data available
                                </div>
                              )}
                              {/* <SafetyDashboard /> */}
                            </div>
                            <TrendsSection
                              alertData={alertData}
                              selectedDateRange={selectedDateRange}
                              selectWarehouse={selectWarehouse}
                              selectCategories={selectCategories}
                              recentAlertData={recentAlertData}
                            />
                          </>
                        ) : (
                          <div className="w-full min-h-[calc(100vh-2rem)]">
                            <Heatmap specificAlert={specificAlertData} />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </React.Fragment>
        )}
      </div>

      <VideoPlayer videoUrl={videoUrl} open={open} onClose={handleClose} />
    </div>
  );
};
