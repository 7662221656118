import React from "react";
import { useNavigate } from "react-router-dom/dist";

const Logo = ({ isCollapsed }) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate("/home")}
      className="flex gap-2 cursor-pointer items-center self-start text-3xl font-semibold tracking-tighter leading-none text-center text-[#052D61] whitespace-nowrap"
    >
      <img
        loading="lazy"
        src={isCollapsed ? `/LogoMarkBlue.png` : `/LogoBluewithmark.png`}
        alt="Company logo"
        className="object-contain shrink-0 self-stretch my-auto w-[200px] h-[100px]"
        data-testid="logo"
      />
      {/* <div className="self-stretch my-auto">Logo</div> */}
    </div>
  );
};

export default Logo;
