import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setSiteNames } from "../../redux/actions/siteActions";
import Sidebar from "../../components/Sidebar";
import { CreateSite } from "../../components/Modal/CreateSite";
import { getAllAlerts, getAllSites } from "../../services/apiServiceDetails";
import MainContent from "../../components/MainContent";
import alertFunctions from "../../utils/alertUtils";
import BarChartIcon from "@mui/icons-material/BarChart";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import { FileText } from "lucide-react";
import { Report } from "@mui/icons-material";

export const Dashboard = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [allsites, setallsites] = useState([]);
  const [siteDetails, setSiteDetails] = useState([]);
  const [alertInTimeFrame, setAlertInTimeFrame] = useState([]);
  const [siteAndCategories, setSiteAndCategories] = useState([]);
  const [userName, setusername] = useState();
  const [usermail, setusermail] = useState();
  const environment = useSelector((state) => state.env);
  const sites = useSelector((state) => state.siteDetails);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  function getSiteData(data) {
    const siteData = data.sites?.map((site) => {
      return {
        id: site.site_id,
        siteName: site.site_name,
        location: site.location,
        useCases:
          site.use_cases != null
            ? site.use_cases.map((useCase) => {
                return {
                  useCaseName: useCase.name,
                  alertCount: useCase.alerts ? useCase.alerts.length : 0,
                };
              })
            : [],
      };
    });

    // Check if the siteData exists
    if (siteData) {
      return siteData; // Return the data for the specific site
    } else {
      return null; // Return null if the site is not found
    }
  }

  const getSiteDetails = async () => {
    const data = await getAllAlerts();
    const results = getSiteData(data);
    console.log("results", results);
    const last24Hours =
      data?.sites?.length > 0
        ? alertFunctions.getAlertsInLast24Hours(data.sites)
        : 0;
    console.log("last24Hours", JSON.stringify(last24Hours, null, 2));
    setAlertInTimeFrame(last24Hours);
    setSiteDetails(results);
  };
  const getsites = async () => {
    let accountID = localStorage.getItem("account_id");
    const data = await getAllSites(accountID);
    console.log("data", data);
    dispatch(setSiteNames(data));
    localStorage.setItem("sites", JSON.stringify(data));
    setallsites(data);
  };

  //  function getUpdatedSites(sites,useCasesData) {
  //   const updatedSites = sites.map(site => {
  //     const useCaseForSite = useCasesData.find(useCase => useCase.siteName == site.name);

  //     return {
  //       ...site,
  //       useCases: useCaseForSite ? useCaseForSite.useCases : [] // Add useCases if available, otherwise empty array
  //     };
  //   });

  //   if (updatedSites) {
  //     return updatedSites; // Return the data for the specific site
  //   } else {
  //     return null; // Return null if the site is not found
  //   }
  //  }

  //  function getAlertCounts(data) {
  //   const result = [];

  //   data.sites.forEach((site) => {
  //     const siteInfo = {
  //       site_name: site.site_name,
  //       use_cases: []
  //     };

  //     site.use_cases.forEach((use_case) => {
  //       const alertCount = use_case.alerts.length;
  //       siteInfo.use_cases.push({
  //         use_case_name: use_case.name,
  //         alert_count: alertCount
  //       });
  //     });

  //     result.push(siteInfo);
  //   });

  //   return result;
  // }

  useEffect(() => {
    if (sites.length > 0) {
      setallsites(sites);
    } else {
      getsites();
      // getSiteDetails();
    }
  }, []);

  // useEffect( ()=> {
  //   const updated = getUpdatedSites(siteDetails);
  //   //setSiteAndCategories(updated);
  //   console.log("updated",updated);
  // },[allsites,siteDetails])

  const handleSubmit = () => {
    getsites();
    // getSiteDetails();
    closeModal();
  };

  const navigate = useNavigate();

  const navItems = [
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/de3b8b5271d8bc1e88c6056582e69f592845a7ad580a06c9846c637a3015e288?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde",
      text: "Active Sites",
      active: true,
      linkTo: "/home",
      navigate: () => navigate("/home"),
    },
    {
      icon: <BarChartIcon />,
      text: "Reporting Dashboard",
      active: false,
      linkTo: "/visualization",
      navigate: () => navigate("/dashboard"),
    },
    {
      icon: <AutoGraphIcon />,
      text: "Operational Dashboard",
      active: false,
      linkTo: "/six-sigma-chart",
      navigate: () => navigate("/six-sigma-chart"),
    },
    {
      icon: <Report size={24} />,
      text: "Incident Report",
      active: false,
      linkTo: "/reports",
      navigate: () => navigate("/reports"),
    },
  ];

  const handleCardClick = (id) => {
    navigate(`/site-details/${id}/projects`);
  };

  return (
    <div
      data-testid="dashboard-page"
      className="h-screen w-screen bg-gray-100 "
    >
      <div className="flex custom-scrollbar">
        <Sidebar
          navItems={navItems}
          userName={user.name}
          usermail={user.email}
          currentPage="Sites"
        />
        <MainContent
          currentPage="Sites"
          data={allsites}
          alertInTimeFrame={alertInTimeFrame}
          title={`Health Dashboard - Current Active Sites`}
          openModal={openModal}
          handleCardClick={handleCardClick}
          buttonText={`Add New Site`}
        />
        {isModalOpen ? (
          <CreateSite
            isOpen={isModalOpen}
            onClose={closeModal}
            onSubmit={handleSubmit}
          />
        ) : null}
      </div>
    </div>
  );
};
