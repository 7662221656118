import React, { useState, useEffect } from 'react';

function RecentAlertsPanel({ recentAlertData, selectedDateRange, selectWarehouse, selectCategories }) {
  const [recentAlert, setRecentAlert] = useState([])
  // [
  // { alertType: "", alertDate: "" }]);
  const alerts = [
    {
      id: '46P34K',
      type: 'No Helmet',
      description: 'Nunc pharetra odio in neque lacus ultrices eleifend non urna. Sed tellus dignissim id ultricies. Ornare arcu quisque.',
      date: '30 July, 24',
      hasActions: true
    },
    {
      id: '46P34K',
      type: 'Improper Lifting',
      description: 'Nunc pharetra odio in neque lacus ultrices eleifend non urna. Sed tellus dignissim',
      date: '17 June, 24',
      hasActions: false
    },
    {
      id: '46P34K',
      type: 'Safety Vest',
      description: 'Nunc pharetra odio in neque lacus',
      date: '02 June, 24',
      hasActions: false
    },
    {
      id: '46P34K',
      type: 'No Helmet',
      description: 'Nunc pharetra odio in neque lacus ultrices eleifend non urna. Sed tellus dignissim id ultricies. Ornare arcu quisque.',
      date: '01 June, 24',
      hasActions: false
    }
  ];

  const filterAlerts = (data, siteName, useCaseNames) => {
    // console.log("siteId", siteName, "useCaseNames", useCaseNames);
    return data
      // .filter(site => site.site_id == siteId);
      // return data
      .filter(site => site.site_name == siteName && useCaseNames.some(useCase => site.use_case_name.includes(useCase))) // Check for matching site ID and use cases
    // .map(site => ({
    //     site_id: site.site_id,
    //     site_name: site.site_name,
    //     use_case_name: site.use_case_name,
    //     alerts: site.alerts // This will return alerts, even if empty
    // }));
  };

  useEffect(() => {
    var recent_Alerts;
    if (Array.isArray(recentAlertData[selectedDateRange])) {
      recent_Alerts = filterAlerts(recentAlertData[selectedDateRange], selectWarehouse, selectCategories);

      if (recent_Alerts && recent_Alerts.length > 0) {
        // Process the alert data to get recent alerts
        const processedAlerts = recent_Alerts
          .filter(item => item.alerts !== null) // Filter out entries with null alerts
          .flatMap(item =>
            item.alerts.map(alert => ({
              type: alert.alert_type,
              date: new Date(alert.created_at).toISOString(), // Convert date to ISO string or format as needed
            }))
          )
          .sort((a, b) => new Date(b.date) - new Date(a.date)); // Sort by date descending

        // console.log("processedAlerts",processedAlerts);
        // Update the state
        setRecentAlert(processedAlerts);
      } else {
        // If alertData is empty, set recentAlert to an empty array
        setRecentAlert([]);
      }
    }

    // console.log("recentAlerts",JSON.stringify(recent_Alerts,null,2));

  }, [recentAlertData, selectedDateRange, selectWarehouse, selectCategories])

  return (
    <aside className="flex overflow-hidden relative flex-col px-1 py-4 rounded-lg border border-gray-200 border-solid min-w-[240px] w-[248px] ">
      <h2 className="gap-2  px-3 pb-1 w-full text-sm font-semibold leading-none text-gray-900">
        Recent Alerts
      </h2>
      <div className="flex-grow overflow-y-auto max-h/2">
        {recentAlert?.length === 0 ? (
          <div className="text-center text-gray-500 p-4">No data available</div>
        ) : (
          recentAlert.map((alert, index) => {
            var formattedDate = new Date(alert.date).toDateString();
            {/* console.log("alert date", new Date(alert.date).toDateString); */ }
            return (
              <div key={index} className={`flex z-0 flex-col px-3 py-4 w-full text-xs`}>
                <div className="flex flex-col w-full">
                  <h3 className="font-medium leading-none text-gray-900">{alert.type}</h3>
                </div>
                <div className="flex justify-between items-center mt-2 w-full">
                  <div className="flex flex-1 shrink gap-2 items-center self-stretch my-auto text-xs leading-none text-gray-500 basis-0">
                    <span>{formattedDate}</span>
                  </div>
                  {alert.hasActions && (
                    <div className="flex gap-2 items-center self-stretch my-auto">
                      <img loading="lazy" src="http://b.io/ext_24-" className="object-contain shrink-0 self-stretch my-auto w-4 aspect-square" alt="" />
                      <img loading="lazy" src="http://b.io/ext_25-" className="object-contain shrink-0 self-stretch my-auto w-4 aspect-square" alt="" />
                    </div>
                  )}
                </div>
              </div>
            )
          })
        )}
      </div>

    </aside>
  );
}

export default RecentAlertsPanel;