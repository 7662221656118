import axios from 'axios';
import { MOCK_REPORTS } from '../mockData/mockReports';

const BASE_URL = process.env.REACT_APP_BASE_URL || 'qa.lumeneo.ai/services/report';
const isDevelopment = process.env.NODE_ENV === 'development';

const reportSummary = [
  {
      "incidentId": "PPE_107_9421f_70_11_1732546168",
      "incidentType": "Safety Helmet",
      "occurrenceTime": "November 25, 2024 at 20:19:28",
      "occurrenceLocation": "Dallas",
      "status": "draft"
  },
  {
      "incidentId": "PPE_107_9421f_70_11_1732546148",
      "incidentType": "Safety Helmet",
      "occurrenceTime": "November 25, 2024 at 20:19:08",
      "occurrenceLocation": "Dallas",
      "status": "draft"
  },
  {
      "incidentId": "PPE_107_c66d6_70_1_1732546123",
      "incidentType": "Safety Helmet",
      "occurrenceTime": "November 25, 2024 at 20:18:44",
      "occurrenceLocation": "Dallas",
      "status": "draft"
  },
  {
      "incidentId": "PPE_107_c66d6_70_7_1732546131",
      "incidentType": "Safety Helmet",
      "occurrenceTime": "November 25, 2024 at 20:18:51",
      "occurrenceLocation": "Dallas",
      "status": "draft"
  }
]

// API functions with mock data for development
export const getReportsSummary = async () => {
  if (isDevelopment) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(reportSummary);
      }, 1500);
    });
  }
  const response = await axios.get(`${BASE_URL}/report/reportSummary`);
  return response.data;
};

export const getReportDetails = async (incidentId) => {
  if (isDevelopment) {
    return new Promise((resolve) => {
      setTimeout(() => {
        const report = MOCK_REPORTS.find(r => r.incidentId === incidentId);
        resolve(report || { message: 'Report not found' });
      }, 500);
    });
  }
  try {
    const response = await axios.get(`${BASE_URL}/report/api/report/${incidentId}`);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const createReport = async (reportData) => {
  if (isDevelopment) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ ...reportData, id: `IR-${Date.now()}` });
      }, 500);
    });
  }
  const response = await axios.post(`${BASE_URL}/report/api/report`, reportData);
  return response.data;
};

export const updateReport = async (incidentId, reportData) => {
  if (isDevelopment) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ ...reportData, id: incidentId });
      }, 500);
    });
  }
  const response = await axios.put(`${BASE_URL}/report/api/report/${incidentId}`, reportData);
  return response.data;
};

export const deleteReport = async (incidentId) => {
  if (isDevelopment) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ success: true });
      }, 500);
    });
  }
  const response = await axios.delete(`${BASE_URL}/report/api/report/${incidentId}`);
  return response.data;
};

// Mock RCA questions for different incident types
const mockRCAQuestions = {
  'PPE Violation': [
    'What PPE was missing or improperly used?',
    'Why was the proper PPE not used?',
    'What are the potential consequences of this violation?',
    'How can we prevent similar violations in the future?'
  ],
  'Safety Incident': [
    'What happened during the incident?',
    'What were the immediate causes?',
    'What were the root causes?',
    'What preventive measures can be implemented?'
  ],
  'Near Miss': [
    'What potential incident was avoided?',
    'What conditions led to this near miss?',
    'What immediate actions were taken?',
    'What long-term preventive measures are needed?'
  ],
  'default': [
    'What happened?',
    'Why did it happen?',
    'What were the contributing factors?',
    'How can it be prevented in the future?'
  ]
};

export const getRCAQuestions = async (incidentType) => {
  if (isDevelopment) {
    return new Promise((resolve) => {
      setTimeout(() => {
        const questions = mockRCAQuestions[incidentType] || mockRCAQuestions.default;
        resolve(questions);
      }, 500);
    });
  }
  const response = await axios.get(`${BASE_URL}/report/api/rca-questions/${incidentType}`);
  return response.data;
};

const mockPlannedActions = {
  "plannedActions": [
    {
      "_id": "674eeaee01678647c932aad3",
      "nodeID": "PPE_HELMET_A201",
      "plannedAction": "Develop Supplier Contingency Plans",
      "plannedActionDescription": "Identify multiple reliable suppliers and negotiate agreements for rapid restocking during demand surges."
    },
    {
      "_id": "674eeaee01678647c932aad5",
      "nodeID": "PPE_HELMET_A301",
      "plannedAction": "Prioritize Distribution Based on Need",
      "plannedActionDescription": "Allocate available helmets to the highest-risk areas or tasks until the supply is replenished."
    }
  ]
};

export const getPlannedActions = async (nodeIDs) => {
  if (process.env.NODE_ENV === 'development') {
    // Simulate API delay
    await new Promise(resolve => setTimeout(resolve, 500));
    return mockPlannedActions;
  }

  try {
    // const response = await fetch('qa.lumeneo.ai/services/report/api/getPlannedActions', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify({ nodeIDs }),
    // });

    const response = await axios.post(`${BASE_URL}/report/api/getPlannedActions`, { nodeIDs });

    // if (!response.ok) {
    //   throw new Error('Failed to fetch planned actions');
    // }

    return response.data;
  } catch (error) {
    console.error('Error fetching planned actions:', error);
    throw error;
  }
};
