import React, { useEffect, useRef, useState } from 'react';
import Hls from 'hls.js';
import { Play, Square, X as CloseIcon } from 'lucide-react';
import { startHLSStream, stopStreams } from '../../services/apiServiceDetails';

let serverUrl = process.env.REACT_APP_BASE_URL || 'http://web.lumeneo.ai/services';

const RTSPPlayer = ({ rtspUrl, streamId, setError, isLoading, setIsLoading }) => {
  const videoRef = useRef(null);
  const hlsRef = useRef(null);
  const retryCountRef = useRef(0);
  const MAX_RETRIES = 5;

  // const stopPlayback = async () => {
  //   if (hlsRef.current) {
  //     hlsRef.current.destroy();
  //     hlsRef.current = null;
  //   }
  //   if (videoRef.current) {
  //     videoRef.current.src = '';
  //   }
  
  //   try {
  //     const response = await stopStreams(streamId);
  
  //     if (!response.ok) {
  //       throw new Error(`Failed to stop stream: ${response.status}`);
  //     }
  
  //     setIsPlaying(false);
  //     showStatus('Stream stopped');
  //   } catch (error) {
  //     showStatus(`Error: ${error instanceof Error ? error.message : 'Unknown error'}`, true);
  //   }
  // };  
  
  const initializeHLS = async (streamId, hlsUrl) => {
    console.log("streamId", streamId);
    hlsUrl = hlsUrl.replace(/^"|"$/g, "");
    const streamUrl = `${serverUrl}/streams/stream${hlsUrl}`;
    console.log("streamUrl", streamUrl, videoRef.current);
    if (!videoRef.current) return;

    if (Hls.isSupported()) {
      if (hlsRef.current) {
        hlsRef.current.destroy();
      }

      const hls = new Hls({
        debug: false,
        enableWorker: true,
        lowLatencyMode: true,
      });

      hlsRef.current = hls;
      hls.loadSource(streamUrl);
      hls.attachMedia(videoRef.current);

      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        videoRef.current?.play()
          .catch(e => console.log("Autoplay prevented:", e));
        retryCountRef.current = 0;
        setIsLoading(false);
      });

      hls.on(Hls.Events.ERROR, (_event, data) => {
        if (data.fatal) {
          if (retryCountRef.current < MAX_RETRIES) {
            retryCountRef.current++;
            setTimeout(() => initializeHLS(streamId, hlsUrl), 2000);
          } else {
            setError('Failed to load stream after multiple attempts');
            setIsLoading(false);
          }
        }
      });
    } else if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
      videoRef.current.src = streamUrl;
    } else {
      setError('HLS playback is not supported in your browser');
    }
  };

  const startHLS = async () => {
    try {
      setIsLoading(true);
  
      const streamData = {
        id: streamId,
        video_url: encodeURIComponent(rtspUrl),
      };

      const response = await startHLSStream(JSON.stringify(streamData)); 
      console.log("response", response); 
      const { hlsUrl, status } = response.data;
      console.log("hlsUrl", hlsUrl, status);
      if (status !== 'success') {
        throw new Error(`Failed to start stream: ${status}`);
      }

      console.log("hlsUrl", hlsUrl, streamId);
      
      setTimeout(() => initializeHLS(streamId, hlsUrl), 3000);
      setIsLoading(false);
    } catch (error) {
      setError(`Error: ${error instanceof Error ? error.message : 'Unknown error'}`);
      setIsLoading(false);
    }
  };  

  useEffect(() => {
    startHLS();
    return () => {
      if (hlsRef.current) {
        hlsRef.current.destroy();
      }
    };
  }, [rtspUrl]);

  return (
    <video
      ref={videoRef}
      className="absolute top-0 left-0 w-full h-full object-contain inset-0"
      playsInline
      controls
      autoPlay
    />
  );
};

export default RTSPPlayer;
