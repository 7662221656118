import React from "react";
import { AlertCircle, CheckCircle, X, Loader, Info } from "lucide-react";
import { STATUS_TYPES } from "../utils/constants";

// // {/* Progress: Goal Detection */}
// <ModalTrainingStatus
//   type={STATUS_TYPES.PROGRESS.GOAL_DETECTION}
//   message={MODAL_MESSAGES[STATUS_TYPES.PROGRESS.GOAL_DETECTION]}
// />

// // {/* Progress: Model Training */}
// <ModalTrainingStatus
//   type={STATUS_TYPES.PROGRESS.MODEL_TRAINING}
//   message={MODAL_MESSAGES[STATUS_TYPES.PROGRESS.MODEL_TRAINING]}
// />

// // {/* Success: Training Complete */}
// <ModalTrainingStatus
//   type={STATUS_TYPES.SUCCESS.TRAINING_COMPLETE}
//   message={MODAL_MESSAGES[STATUS_TYPES.SUCCESS.TRAINING_COMPLETE]}
//   buttonText={BUTTON_TEXT.TEST_MODEL}
//   onButtonClick={() => console.log("Test Model clicked")}
// />

// // {/* Success: Detection Complete */}
// <ModalTrainingStatus
//   type={STATUS_TYPES.INFO.DETECTION_COMPLETE}
//   message={MODAL_MESSAGES[STATUS_TYPES.INFO.DETECTION_COMPLETE]}
//   buttonText={BUTTON_TEXT.REMOVE_INCORRECT_LABELS}
//   onButtonClick={() => console.log("Remove Incorrect Labels clicked")}
// />

// // {/* Error: General Error */}
// <ModalTrainingStatus
//   type={STATUS_TYPES.ERROR.GENERAL_ERROR}
//   message={MODAL_MESSAGES[STATUS_TYPES.ERROR.GENERAL_ERROR]}
//   onClose={() => console.log("Error message closed")}
// />

const ModalTrainingStatus = ({
  type,
  message,
  buttonText,
  onButtonClick,
  onClose,
}) => {
  const iconClass = "w-5 h-5";

  const icons = {
    [STATUS_TYPES.PROGRESS.GOAL_DETECTION]: <Info className={iconClass} />,
    [STATUS_TYPES.PROGRESS.MODEL_TRAINING]: <Info className={iconClass} />,
    [STATUS_TYPES.SUCCESS.TRAINING_COMPLETE]: (
      <CheckCircle className={iconClass} />
    ),
    [STATUS_TYPES.INFO.DETECTION_COMPLETE]: (
      <CheckCircle className={iconClass} />
    ),
    [STATUS_TYPES.ERROR]: <AlertCircle className={iconClass} />,
  };

  const bgColors = {
    [STATUS_TYPES.PROGRESS.GOAL_DETECTION]:
      "bg-yellow-100 border border-yellow-200",
    [STATUS_TYPES.PROGRESS.MODEL_TRAINING]:
      "bg-yellow-100 border border-yellow-200",
    [STATUS_TYPES.SUCCESS.TRAINING_COMPLETE]:
      "bg-green-100 border border-green-200",
    [STATUS_TYPES.INFO.DETECTION_COMPLETE]:
      "bg-blue-100 border border-green-200",
    [STATUS_TYPES.ERROR]: "bg-red-100 border border-red-400",
  };

  const buttonStyles = {
    [STATUS_TYPES.SUCCESS.TRAINING_COMPLETE]:
      "px-4 py-3 bg-green-600 text-white text-sm font-medium rounded-md hover:bg-green-700 transition-colors",
    [STATUS_TYPES.INFO.DETECTION_COMPLETE]:
      "px-4 py-3 bg-[#052D61] text-white text-sm font-medium rounded-md hover:bg-blue-700 transition-colors",
  };

  return (
    <div
      data-testid={`modal-training-status-${type}`}
      className={`h-16 py-2 px-3 rounded-md flex items-center justify-between ${bgColors[type]}`}
    >
      <div
        className="flex items-center space-x-3 flex-grow"
        data-testid={`status-icon-container-${type}`}
      >
        <div className="flex-shrink-0" data-testid={`status-icon-${type}`}>
          {icons[type]}
        </div>
        <span
          className="text-sm flex-grow"
          data-testid={`status-message-${type}`}
        >
          {message}
        </span>
      </div>

      <div className="flex items-center space-x-2">
        {type?.startsWith("progress") && (
          <Loader
            className={`${iconClass} text-yellow-500 animate-spin`}
            data-testid={`status-loader-${type}`}
          />
        )}

        {buttonText && (
          <button
            onClick={onButtonClick}
            className={buttonStyles[type]}
            data-testid={`status-button-${type}`}
          >
            {buttonText}
          </button>
        )}

        {type?.startsWith("error") && onClose && (
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-600"
            data-testid={`status-close-button-${type}`}
          >
            <X className={iconClass} />
          </button>
        )}
      </div>
    </div>
  );
};

export default ModalTrainingStatus;
