export const SET_TRAINING_STATUS = "SET_TRAINING_STATUS";
export const RESET_TRAINING_STATUS = "RESET_TRAINING_STATUS";
export const SET_MODAL_STATUS = "SET_MODAL_STATUS";

export const setTrainingStatus = (
  projectId,
  status,
  message,
  buttonText,
  onButtonClick,
  modalStatus = "camera-stream"
) => {
  console.log(
    "params: ",
    status,
    message,
    buttonText,
    onButtonClick,
    projectId
  );
  return {
    type: SET_TRAINING_STATUS,
    payload: {
      projectId,
      status,
      message,
      buttonText,
      onButtonClick,
      modalStatus,
    },
  };
};

export const setModalStatus = (projectId, status) => {
  console.log("status", status);
  //const status = "wrong-label-detection"
  return {
    type: SET_MODAL_STATUS,

    payload: { projectId, status },
  };
};

export const resetTrainingStatus = () => {
  return {
    type: RESET_TRAINING_STATUS,
  };
};
