import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
  useContext,
} from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  getAllROIImages,
  getRequestStatus,
  startDetection,
  getStatusProject,
} from "../services/apiServiceDetails";

import ModalTrainingStatus from "./ModelTrainingStatus";
import ModalLayout from "./TrainModals/ModalLayout";
import {
  setModalStatus,
  setTrainingStatus,
} from "./../redux/actions/trainingStatusActions";
import StreamSelectionComponent from "./StreamSelectModal/StreamSelectionComponent";
import useCustomToast from "../hooks/useToast";
import { WebSocketContext } from "../context/WebSocketContext";
import { BUTTON_TEXT, MODAL_MESSAGES, STATUS_TYPES } from "../utils/constants";

const ProcessBar = ({ projectid, hideBar, setHideBar }) => {
  const [loading, setLoading] = useState(true);
  const showToast = useCustomToast();

  const pollingInterval = 5000; // Polling every 5 seconds
  const [open, setOpen] = useState(false);
  const pollingIntervalRef = useRef(null);
  const [streamOpen, setStreamOpen] = useState(false);
  const dispatch = useDispatch();
  const imageCache = useRef({});

  const [toFetch, setTofetch] = useState(false);

  const [requestID, setRequestID] = useState(null);
  const [hasDeleteAnnotation, setDeleteAnnotation] = useState(false);
  const [retrainModal, setRetrainModal] = useState(false);
  const [images, setImages] = useState([]);
  const [imageAOIDeleted, setImageAOIDeleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [totalImages, setTotalImages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [statusmessage, setStatusmessage] = useState(null);
  const [statusType, setStatusType] = useState(null);
  const [pageSize] = useState(6);

  const [hasData, setHasData] = useState(false); // Button appears only when data is fetched
  const trainingStatus = useSelector((state) => state.trainingStatus);
  const filteredTrainingStatus = trainingStatus[projectid] || {};
  console.log("trainingStatus", trainingStatus);
  console.log("filteredTrainingStatus", filteredTrainingStatus);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleTestModel = () => setStreamOpen(true);
  const handleCloseSelectStream = () => setStreamOpen(false);

  const { connectWebSocket, triggerFetch, setTriggerFetch } =
    useContext(WebSocketContext);

  // const fetchRequestStatus = async () => {
  //   try {
  //     const response = await getRequestStatus(requestID);
  //     // Stop polling after receiving data
  //     return response.status; // Indicate that data was received
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //     // Indicate that data was not received
  //   }
  // };
  useEffect(() => {
    getstatusOfProject();
    return setTofetch(false);
  }, []);

  // Save to localStorage whenever the Redux state (myArray) changes
  useEffect(() => {
    if (Object.keys(trainingStatus).length > 0) {
      localStorage.setItem(
        "processStatusArray",
        JSON.stringify(trainingStatus)
      );
    }
  }, [trainingStatus]);

  const fetchImages = useCallback(
    async (page, fetch = false) => {
      setIsLoading(true);
      try {
        console.log("fetchImage");
        if (!fetch) {
          return;
        }
        const response = await getAllROIImages(page, pageSize, projectid);

        const { results, totalCount, total_pages, message } = response;
        const newTotalImages = totalCount;

        if (message == "No records found for the specified project ID") {
          dispatch(
            setTrainingStatus(
              projectid,
              STATUS_TYPES.PROGRESS.GOAL_DETECTION,
              MODAL_MESSAGES[STATUS_TYPES.PROGRESS.GOAL_DETECTION],
              null,
              null
            )
          );
        } else if (results) {
          setImages(results);
          console.log("Results in fetch Images", results);
          setTotalImages(newTotalImages);
          setHasData(true); // Set true when data is fetched
          dispatch(
            setTrainingStatus(
              projectid,
              STATUS_TYPES.INFO.DETECTION_COMPLETE,
              MODAL_MESSAGES[STATUS_TYPES.INFO.DETECTION_COMPLETE],
              BUTTON_TEXT.REMOVE_INCORRECT_LABELS,
              handleRetrainModalOpen,
              "wrong-label-detection"
            )
          );
          // Cache image URLs for performance
          results.forEach((image) => {
            imageCache.current[image.frames[0].frame_id] = image.frames[0].url;
          });
        }
        //}
      } catch (error) {
        console.error("Error fetching images:", error);
      } finally {
        setIsLoading(false);
      }
    },
    [pageSize]
  );

  const handleRetrainModalOpen = () => {
    dispatch(setModalStatus(projectid, "wrong-label-detection"));
    console.log("inside ");
    setRetrainModal(true);
    // stopPolling();
    fetchImages(currentPage, true);
  };

  const getstatusOfProject = async () => {
    const res = await getStatusProject(projectid);
    console.log("Project status", res);
    if (res.annotation_req_id && res.annotation_status === "In Progress") {
      dispatch(
        setTrainingStatus(
          projectid,
          STATUS_TYPES.PROGRESS.GOAL_DETECTION,
          MODAL_MESSAGES[STATUS_TYPES.PROGRESS.GOAL_DETECTION],
          null,
          null
        )
      );
      setHideBar(false);
    } else if (
      res.annotation_req_id &&
      res.annotation_status === "Completed" &&
      res.train_model_req_id === ""
    ) {
      setTriggerFetch(true);
      dispatch(
        setTrainingStatus(
          projectid,
          STATUS_TYPES.INFO.DETECTION_COMPLETE,
          MODAL_MESSAGES[STATUS_TYPES.INFO.DETECTION_COMPLETE],
          BUTTON_TEXT.REMOVE_INCORRECT_LABELS,
          () => {
            handleRetrainModalOpen();
          },
          "wrong-label-detection"
        )
      );
    } else if (
      res.train_model_req_id &&
      res.training_status === "In Progress"
    ) {
      dispatch(
        setTrainingStatus(
          projectid,
          STATUS_TYPES.PROGRESS.MODEL_TRAINING,
          MODAL_MESSAGES[STATUS_TYPES.PROGRESS.MODEL_TRAINING],
          null,
          null,
          "training"
        )
      );
    } else if (res.train_model_req_id && res.training_status === "Completed") {
      dispatch(
        setTrainingStatus(
          projectid,
          STATUS_TYPES.SUCCESS.TRAINING_COMPLETE,
          MODAL_MESSAGES[STATUS_TYPES.SUCCESS.TRAINING_COMPLETE],
          BUTTON_TEXT.TEST_MODEL,
          () => console.log("Start Detection clicked")
        )
      );
    }
  };

  const startPolling = useCallback(() => {
    if (pollingIntervalRef.current) {
      clearInterval(pollingIntervalRef.current);
    }
    pollingIntervalRef.current = setInterval(() => {
      fetchImages(currentPage); // Fetch the latest images for the current page
    }, pollingInterval);
  }, [fetchImages, currentPage, pollingInterval]);

  // Stop polling
  const stopPolling = useCallback(() => {
    if (pollingIntervalRef.current) {
      clearInterval(pollingIntervalRef.current);
      pollingIntervalRef.current = null;
    }
  }, []);

  useEffect(() => {
    if (triggerFetch) {
      console.log("triggerfetch called me useeffect called me");
      setTofetch(true);
      fetchImages(currentPage, true);
      setTriggerFetch(false);
    }
  }, [triggerFetch, fetchImages, currentPage, setTriggerFetch]);

  useEffect(() => {
    console.log("imageAOIDeleted", imageAOIDeleted);
    if (triggerFetch) {
      if (imageAOIDeleted || currentPage !== undefined) {
        // Only call fetchImages if the current page changes or imageAOIDeleted is true
        console.log("roideleted useeffect called me");
        fetchImages(currentPage, true);

        // Reset the imageAOIDeleted flag after fetching
        if (imageAOIDeleted) {
          setImageAOIDeleted(false);
        }
      }
    }
  }, [currentPage, imageAOIDeleted, fetchImages]);

  const totalPages = useMemo(
    () => Math.ceil(totalImages / pageSize),
    [totalImages, pageSize]
  );

  const handlePageChange = useCallback(
    (newPage) => {
      if (newPage > 0 && newPage <= totalPages) {
        setTriggerFetch(true);
        setCurrentPage(newPage);
      }
    },
    [totalPages]
  );

  const startdetection = async () => {
    try {
      const resp = await startDetection(projectid);
      showToast("Successful", "success");
      dispatch(setTrainingStatus(projectid, null, null, null, null, null));
      // handleTestModel();
    } catch (error) {
      console.error("Error starting detection:", error);
      showToast("An error occurred while starting detection.", "error");
    }
  };

  function removeProcessStatusById(id) {
    const numericId = parseInt(id, 10);
    // Get the array from localStorage
    console.log(
      "Removing process status",
      localStorage.getItem("processStatusArray")
    );
    const processStatusArray =
      JSON.parse(localStorage.getItem("processStatusArray")) || {};

    console.log("Removing process status", processStatusArray);

    // Filter the array to remove the element with the matching ID
    if (processStatusArray[id]) {
      delete processStatusArray[id];
      // Update the localStorage with the updated object
      localStorage.setItem(
        "processStatusArray",
        JSON.stringify(processStatusArray)
      );
      setHideBar(true);
      console.log(`Removed status with id: ${id}`);
    } else {
      console.log(`No status found with id: ${id}`);
    }

    // console.log("Removing process status", updatedArray);

    // Update the array in localStorage
    // localStorage.setItem("processStatusArray", JSON.stringify(updatedArray));

    console.log(`Item with id ${id} has been removed.`);
  }

  useEffect(() => {
    setHideBar(false);
    console.log("triggerFetch", triggerFetch);
  }, [triggerFetch]);

  return (
    <div className={`py-5 ${hideBar ? "hidden" : ""}`}>
      {filteredTrainingStatus.status ===
        STATUS_TYPES.PROGRESS.GOAL_DETECTION && (
        <ModalTrainingStatus
          type={STATUS_TYPES.PROGRESS.GOAL_DETECTION}
          message={MODAL_MESSAGES[STATUS_TYPES.PROGRESS.GOAL_DETECTION]}
          buttonText={null}
          onButtonClick={null}
          onClose={() => console.log("Modal closed")}
        />
      )}

      {filteredTrainingStatus.status === STATUS_TYPES.INFO.DETECTION_COMPLETE ||
      filteredTrainingStatus.status === STATUS_TYPES.PROGRESS.MODEL_TRAINING ||
      filteredTrainingStatus.status ===
        STATUS_TYPES.SUCCESS.TRAINING_COMPLETE ? (
        <ModalTrainingStatus
          type={filteredTrainingStatus.status}
          message={filteredTrainingStatus.message}
          buttonText={
            filteredTrainingStatus.status ===
            STATUS_TYPES.INFO.DETECTION_COMPLETE
              ? BUTTON_TEXT.REMOVE_INCORRECT_LABELS
              : filteredTrainingStatus.status ===
                STATUS_TYPES.SUCCESS.TRAINING_COMPLETE
              ? "Finish!"
              : null
          }
          onButtonClick={
            filteredTrainingStatus.status ===
            STATUS_TYPES.INFO.DETECTION_COMPLETE
              ? handleRetrainModalOpen
              : filteredTrainingStatus.status ===
                STATUS_TYPES.SUCCESS.TRAINING_COMPLETE
              ? () => {
                  removeProcessStatusById(projectid);
                }
              : null
          }
          onClose={() => console.log("Modal closed")}
        />
      ) : null}

      {hasData ? (
        <ModalLayout
          isOpen={retrainModal}
          onClose={() => setRetrainModal(false)}
          onSelect={() => console.log("Retrain Model")}
          images={images}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          imageAOIDeleted={imageAOIDeleted}
          setImageAOIDeleted={setImageAOIDeleted}
          setDeleteAnnotation={setDeleteAnnotation}
          setRequestID={setRequestID}
          setHasData={setHasData}
          setImages={setImages}
          onDeleteImage={(img) => {
            console.log("img", img);
            setImages(img);
          }}
        />
      ) : null}

      {hasData ? (
        <StreamSelectionComponent
          onOpen={streamOpen}
          handleClose={handleCloseSelectStream}
        />
      ) : null}
    </div>
  );
};

export default ProcessBar;
