import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import { useSelector } from "react-redux";
import { AddVideo } from "../../components/Modal/AddVideo";
import { CreateSite } from "../../components/Modal/CreateSite";
import {
  getallstreams,
  getProject,
  getProjects,
  getProjectStreams,
  UpdateROI,
  createMultipleRois,
  deleteAssociations,
  getStatusProject,
  getLatestStatusOfDectetion,
} from "../../services/apiServiceDetails";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import MainContent from "../../components/MainContent";
import { CreateProject } from "../../components/Modal/CreateProject";
import AppLogicsModal from "../../components/AppLogicsModal";
import { InterestAreaModal } from "../../components/InterestAreaModel";
import { generateThumbnail } from "../../utils/generateThumbnail";
import ModalLayout from "../../components/TrainModals/ModalLayout";
import {
  setModalStatus,
  setTrainingStatus,
} from "../../redux/actions/trainingStatusActions";
import { WebSocketContext } from "../../context/WebSocketContext";
import { StreamModal } from "../../components/AllStreamsModal";
import {
  useCaseToLogics,
  useCaseToLogicsF,
  getFromLocalStorage,
  BUTTON_TEXT,
  MODAL_MESSAGES,
  STATUS_TYPES,
} from "../../utils/constants";
import { Assignment } from "@mui/icons-material";
import useCustomToast from "../../hooks/useToast";

const environment = process.env.NODE_ENV;

export const ProjectDetails = () => {
  const { project, siteid, projectid } = useParams();

  // console.log(project, siteid, projectid);

  const navItems = [
    {
      icon: <Assignment />,
      text: "Use Case Details",
      active:
        window.location.pathname ===
        `/site-details/${siteid}/${project}/${projectid}`,
      navigate: () =>
        navigate(`/site-details/${siteid}/${project}/${projectid}`),
    },
    // {
    //   icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/389f597f3dbe7367231aff370cd6b6a526ff3bd25933c7a8ab653571d65eb103?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde",
    //   text: "Model Dashboard",
    //   active: window.location.pathname.includes(`/model-dashboard`),
    //   navigate: () =>
    //     navigate(`/site/${siteid}/${project}/${projectid}/model-dashboard`),
    // },

    // {
    //   icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/5606b06eae36d23ab1603f3e1c8b04d8b7d174fe2a7cceaee2ecec9fa22a9b09?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde",
    //   text: "Detections",
    //   active: window.location.pathname.includes(
    //     `/site-details/${siteid}/${project}/${projectid}/detections`
    //   ),
    //   navigate: () =>
    //     navigate(`/site-details/${siteid}/${project}/${projectid}/detections`),
    // },
    // {
    //   icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/ae7e6fac086adfa5edba6aaec57b23e81441178bb24bf679c3aae6b13cc45c7c?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde",
    //   text: "Business Workflow",
    //   active:
    //     window.location.pathname ===
    //     `/site-details/${siteid}/${project}/business-workflow`,
    //   navigate: () =>
    //     projectid ? navigate(`/site-details/${siteid}/${project}/${projectid}`) : null,
    // },
  ];

  let projectDetails = {
    project: {
      id: 2,
      name: "ProjectX",
      site_id: 2,
      usecase_id: "8f2a4be3-7f1d-4e85-9b4e-f2fd980c7d6a",
      description: "Sample description",
      created_at: "2024-08-28 10:30:00",
      updated_at: "2024-08-28 10:30:00",
      disabled: false,
    },
    usecase: {
      UsecaseID: "8f2a4be3-7f1d-4e85-9b4e-f2fd980c7d6a",
      usecase_type: "xyz",
    },
    flows: [
      {
        FlowID: "a9d1c2b7-d3e9-4d1f-9f0a-7f8c9c6f7a1b",
        FlowType: "x",
      },
      {
        FlowID: "b0d8a3c6-e2ab-4f6d-9a8c-2f0f6b7d4b5c",
        FlowType: "y",
      },
      {
        FlowID: "c5e3b4f7-d4fb-4e6c-a1a9-7c6d3f8b2a3d",
        FlowType: "z",
      },
    ],
  };

  const videoData = [
    {
      video_name: "Big Buck Bunny",
      video_url:
        "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
      roi: ["0,0,0,0", "0,0,0,0"],
    },
    {
      video_name: "The first Blender Open Movie from 2006",
      video_url:
        "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4",
      roi: ["0,0,0,0", "0,0,0,0"],
    },
    {
      video_name: "For Bigger Blazes",
      video_url:
        "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4",
    },
    {
      video_name: "For Bigger Escape",
      video_url:
        "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4",
    },
    {
      video_name: "Big Buck Bunny",
      video_url:
        "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
      roi: true,
    },
    {
      video_name: "For Bigger Blazes",
      video_url:
        "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4",
    },
    {
      video_name: "For Bigger Escape",
      video_url:
        "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4",
      roi: true,
    },
    {
      video_name: "The first Blender Open Movie from 2006",
      video_url:
        "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4",
    },
  ];

  const [isModalOpen, setModalOpen] = useState(false);
  const [logicsModalOpen, setLogicsModalOpen] = useState(false);
  const [interestAreaModalOpen, setInterestAreaModalOpen] = useState(false);
  const user = useSelector((state) => state.user);
  const siteNames = useSelector((state) => state.siteDetails);
  console.log(siteNames);
  const [projectz, setproject] = useState(null);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  const [points, setPoints] = useState([]);
  const [imageSrc, setImageSrc] = useState(null);
  const [assosID, setAssosID] = useState(null);
  const [projectName, setprojectname] = useState("Project");
  const [usecaseName, setusecasename] = useState("Detection");
  const [flowsLenght, setflowsLength] = useState(0);
  const [name, setName] = useState("");
  const [retrainModel, setRetrainModel] = useState(false);
  const [openStreamModal, setOpenStreamModal] = useState(false);
  const [assosUpdated, setAssosupdated] = useState(false);
  const [associatedStream, setAssociatedStream] = useState();
  const [detectionStatus, setDetectionStatus] = useState();
  const [detectionLoading, setDetectionLoading] = useState(false);
  const [detectionStopinging, setDetectionStoping] = useState(false);
  const [maxDetection, setMaxDetection] = useState(5);
  const [activeDetection, setActiveDetection] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [activeStatus, setActiveStatus] = useState("Inactive");
  const [hideBar, setHideBar] = useState(false);
  const [showRoiDetails, setShowRoiDetails] = useState(false);
  const [roiDimensions, setRoiDimensions] = useState([]);
  const [checkboxStates, setCheckboxStates] = useState();
  // const [isUploading, setIsUploading] = useState(false);
  // const [progress, setProgress] = useState(0);
  const [detectionStopping, setDetectionStopping] = useState(false);

  const [logics, setLogics] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [allstreams, setallstreams] = useState([]);

  const { connectWebSocket, fetchDetectionStatus, setFetchDetectionStatus } =
    useContext(WebSocketContext);
  const showToast = useCustomToast();

  const getAllStreams = async () => {
    // const resp = await getProjects(siteid);
    // setallstreams(resp);
    // // console.log("All projects fetched", resp);
  };
  // console.log("siteNames", siteNames);

  const getproject = async () => {
    const resp = await getProject(projectid);
    setproject(resp);
    setMaxDetection(resp?.Detection_limits?.max_detection_allowed);
    setActiveStatus(resp?.detection_status);
    // setMaxDetection(0);

    setActiveDetection(resp?.Detection_limits?.current_detections_utilization);
    // console.log(resp);
  };

  const getprojectstreams = async () => {
    const resp = await getProjectStreams(projectid);
    if (resp) {
      setAssosupdated(true);
      setAssociatedStream(resp[0].stream.id);
    }
    console.log(resp);
    setAssosID(resp[0].association_id);
    setallstreams(resp);
  };

  const getLatestStatus = async () => {
    const resp = await getLatestStatusOfDectetion(projectid);
    console.log("detctionresp", resp);
    if (
      resp?.message &&
      resp?.message === "No active status found for this project."
    ) {
      setDetectionStatus(false);
    } else if (resp?.status === 1 && !resp?.is_active) {
      setDetectionLoading(true);
      setDetectionStatus(false);
    } else if (resp?.is_active && resp?.status !== 6) {
      setDetectionLoading(false);
      setDetectionStopping(false);
      if (!detectionStopping) {
        setDetectionStatus(true);
      }
    } else if (resp?.status === 6) {
      setDetectionStatus(false);
      setDetectionStopping(false);
      setDetectionLoading(false);
      // showToast("Detections Completed", "success");
    } else {
      setDetectionStatus(false);
      setDetectionStopping(false);
    }
  };

  useEffect(() => {
    console.log("fetchDetectionStatus", fetchDetectionStatus);

    if (fetchDetectionStatus) {
      console.log("fetchDetectionStatus-true", fetchDetectionStatus);
      getLatestStatus();
      getproject();
      setFetchDetectionStatus(false);
    } else {
      getprojectstreams();
      getproject();
      getLatestStatus();
    }
  }, [fetchDetectionStatus]);

  useEffect(() => {
    const siteName = siteNames.names.find((item) => item.id == siteid)?.name;
    setName(siteName);
  }, []);

  useEffect(() => {
    if (allstreams) {
      setCheckboxStates(() => {
        const length = allstreams?.[0]?.required_roi?.length || 0; // Safely get the length
        return length > 0 ? Array(length).fill(false) : [false]; // If length > 0, fill the array; otherwise, use [false]
      });
    }
  }, [allstreams]);

  useEffect(() => {
    if (projectz) {
      setprojectname(projectz.project.name);
      localStorage.setItem("projectname", projectz.project.name);
      localStorage.setItem("playback_url", projectz.project.playback_url);
      setusecasename(projectz.usecase.usecase_type);
      setflowsLength(projectz.flows.length);
      console.log("Projectz", projectz);
    }
  }, [projectz]);

  useEffect(() => {
    if (projectid) {
      dispatch({ type: "SET_PROJECT_ID", payload: projectid });
      connectWebSocket(projectid);
    }
  }, [projectid]);

  const updateroi = async () => {
    const res = await createMultipleRois(assosID, points, roiDimensions);
    console.log("ROI updated", res);
    getprojectstreams();
  };

  const handlePointsSave = () => {
    console.log("Points saved", points);
    setShowRoiDetails(false);
    setInterestAreaModalOpen(false);
    updateroi();
  };

  const handleCardClick = (id) => {
    ///site-details/:name/:project/visualisation
    // console.log("Card clicked", id);
    // navigate(`/site-details/${siteid}/${project}/${id}`);
  };

  const handleSubmit = (e) => {
    // e.preventDefault();
    getAllStreams();
    // Handle form submission logic here
    // console.log("Form submitted");
    closeModal();
  };

  const handleLogicsClicked = () => {
    // console.log("Logics clicked");
    setLogicsModalOpen(true);
  };

  const delAssos = async () => {
    if (assosID) {
      const res = await deleteAssociations(assosID);
    }
  };

  const handleAssociatonSubmit = () => {
    if (assosUpdated) {
      delAssos();
    }
    console.log("i am called");
    getprojectstreams();
    CloseStreamMOdal();
  };

  const CloseStreamMOdal = () => {
    setOpenStreamModal(false);
  };

  const handleInterestAreaClicked = (imageurl) => {
    // console.log(image);
    // generateThumbnail(image.video_url).then((thumbnail) => {
    //   setImageSrc(thumbnail);
    // });
    setImageSrc(imageurl);
    if (imageurl) {
      setInterestAreaModalOpen(true);
    }
  };

  const handleBackClick = () => {
    navigate("/home"); // Navigate to the dashboard page
  };

  console.log(
    "detectionStatus",
    detectionStatus,
    detectionLoading,
    detectionStopping
  );

  return (
    <div className=" w-screen bg-gray-100 ">
      <div className="flex flex-row h-full relative">
        <Sidebar
          navItems={navItems}
          userName={user.name}
          usermail={user.email}
          currentPage="Project"
          siteName={project}
          handleBackClick={handleBackClick}
          selectDataOptions={siteNames}
        />
        <MainContent
          currentPage="Project-Details"
          data={environment === "development" ? videoData : allstreams}
          title={`Connected Devices - ${
            name ? name : getFromLocalStorage(siteid)
          }, ${usecaseName}`}
          subHeading={projectName}
          projectDetails={projectz?.project}
          maxDetection={maxDetection}
          activeDetection={activeDetection}
          openModal={openStreamModal}
          emptyscreenbuttonaction={() => setOpenStreamModal(true)}
          fetchDetectionStatus={fetchDetectionStatus}
          handleCardClick={handleInterestAreaClicked}
          getLatestStatus={getLatestStatus}
          logicsClicked={handleLogicsClicked}
          detectionLoading={detectionLoading}
          detectionStopping={detectionStopping}
          setDetectionStopping={setDetectionStopping}
          buttonText={detectionStatus ? `Stop Detections` : `Start Detection`}
          emptyScreenbuttontext={"Connect Device"}
          flowslength={flowsLenght}
          detectionStatus={detectionStatus}
          detection_status={activeStatus}
          hideBar={hideBar}
          setHideBar={setHideBar}
          setDetectionStatus={setDetectionStatus}
          useCase={useCaseToLogicsF(usecaseName)}
          //   contentType={videos}
          links={[
            { name: "Sites", path: "/home" },
            {
              name: `${name ? name : getFromLocalStorage(siteid)}`,
              path: `/site-details/${siteid}/projects`,
            },
            { name: "Scenario", path: `/site-details/${siteid}/projects` },
            { name: `${projectName}` },
          ]}
          retrainModel={{
            openModal: () => {
              console.log("Open clicked");
              dispatch(setModalStatus(projectid, "camera-stream"));
              setRetrainModel(true);
            },
            closeModal: () => setRetrainModel(false),
            onSelect: () => console.log("Retrain Model"),
          }}
          opeStreamMOdal={{
            openStreamModal: () => {
              setOpenStreamModal(true);
            },
            closeStreamModal: () => setOpenStreamModal(false),
          }}
          assosUpdated={assosUpdated}
        />
        {/* <CreateProject
          isOpen={isModalOpen}
          onClose={closeModal}
          onSubmit={handleSubmit}
        /> */}
        <AppLogicsModal
          isOpen={logicsModalOpen}
          Flows={projectz ? projectz.flows : [""]}
          onClose={() => setLogicsModalOpen(false)}
          useCase={useCaseToLogicsF(usecaseName)}
          detection_status={activeStatus}
        />
        {allstreams && (
          <InterestAreaModal
            isOpen={interestAreaModalOpen}
            onClose={() => {
              setInterestAreaModalOpen(false);
              setPoints([]);
              setImageSrc(null);
            }}
            onSave={handlePointsSave}
            usecaseType={projectz?.usecase?.usecase_type}
            flowType={projectz?.flows}
            points={points}
            imageSrc={imageSrc}
            setPoints={setPoints}
            assosID={assosID}
            required_roi={allstreams}
            showRoiDetails={showRoiDetails}
            setShowRoiDetails={setShowRoiDetails}
            roiDimensions={roiDimensions}
            checkboxStates={checkboxStates}
            setCheckboxStates={setCheckboxStates}
            setRoiDimensions={setRoiDimensions}
            onClick={() => {
              setShowRoiDetails(true);
            }}
            onBack={() => {
              setShowRoiDetails(false);
            }}
          />
        )}

        {retrainModel && (
          <ModalLayout
            isOpen={retrainModel}
            onClose={() => setRetrainModel(false)}
            onSelect={() => console.log("Retrain Model")}
            setHideBar={setHideBar}
          />
        )}
        {/* {openStreamModal && ( */}
        <StreamModal
          siteid={siteid}
          projectid={projectid}
          onSubmit={handleAssociatonSubmit}
          isOpen={openStreamModal}
          onClose={CloseStreamMOdal}
          associatedStream={associatedStream}
          setAssociateedStreams={setAssociatedStream}
          progress={progress}
          setProgress={setProgress}
          isUploading={isUploading}
          setIsUploading={setIsUploading}
        />
        {/* )} */}
      </div>
    </div>
  );
};
