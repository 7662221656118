import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../redux/actions/userActions";
import InputFields from "../../components/InputFields";
import { useNavigate } from "react-router-dom";
import { loginApi } from "../../services/apiServiceDetails";
import { emailToSocketID } from "../../utils/constants";
import { encrypt } from "../../utils/encryption";
import useSession from "../../hooks/useSession";

export const Login = () => {
  const dispatch = useDispatch();
  const user = useSession();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showpass, setshowpass] = useState(false);

  const navigate = useNavigate();
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  useEffect(() => {
    if (user.user) {
      navigate("/home");
    }
  }, [user]);

  const validatePassword = (password) => {
    const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
    return passwordPattern.test(password);
  };

  const setSessionAndUser = (session, email, name, accountID) => {
    return new Promise((resolve) => {
      localStorage.setItem("session", encrypt(JSON.stringify(session)));
      dispatch(setUser(email, name, emailToSocketID(email)));
      resolve();
    });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setEmailError("");
    setPasswordError("");
    setError("");

    // Validate email and password

    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
      return;
    }

    // if (!validatePassword(password)) {
    //   setPasswordError("Password not correct");

    //   return;
    // }

    try {
      const data = await loginApi(email, password);
      const session = {
        email: data.email,
        name: data.name,
        expiry: new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString(),
        token: data.token,
      };
      await setSessionAndUser(session, data.email, data.name, data.account_id);
      localStorage.setItem("account_id", data.account_id);
      // console.log(
      //   "session, localstorage",
      //   session,
      //   localStorage.getItem("session")
      // );
      navigate("/home");
    } catch (err) {
      setError(err.message || "An error occurred during login.");
    }
  };

  return (
    <main
      data-testid="login-page"
      className="h-screen w-screen bg-hero-pattern bg-cover bg-center flex justify-between"
    >
      <section className="flex absolute left-32 top-2/4 z-0 flex-col -translate-y-2/4 min-w-[240px] translate-x-[0%] w-[487px] max-md:max-w-full">
        <div className="flex flex-col w-full max-md:max-w-full">
          <h1
            data-testid="login-title"
            className="text-6xl font-semibold text-white max-md:max-w-full max-md:text-4xl"
          >
            Put Lumeneo AI to work for your Industrial Applications
          </h1>
          {/* <p className="mt-2 text-base leading-6  text-gray-400 max-md:max-w-full">
            <br />
            <span className="text-yellow-600">
              Computer Vision
              <br /> Apps
            </span>{" "}
            has never been
            <br /> easier
          </p> */}
        </div>
      </section>
      <section className="flex absolute top-2/4 z-0 flex-col justify-center items-center p-12 bg-white rounded-xl -translate-y-2/4 min-w-[240px] right-[121px] translate-x-[0%] w-[471px] max-md:px-5 max-md:max-w-full">
        <div className="flex flex-col max-w-full w-[375px]">
          <div className="flex flex-col w-full">
            <h2
              data-testid="login-header"
              className="text-3xl font-semibold leading-tight text-gray-900"
            >
              Log in to your account
            </h2>
            <p
              data-testid="login-subheader"
              className="mt-2 text-sm leading-none text-slate-600"
            >
              Please login to continue forward
            </p>
          </div>

          <form
            onSubmit={handleLogin}
            className="flex flex-col mt-12 w-full max-md:mt-10"
            data-testid="login-form"
          >
            <InputFields
              label="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              testid="email-input"
            />
            {emailError && (
              <p className="text-red-500 mb-4" data-testid="email-error">
                {emailError}
              </p>
            )}
            <InputFields
              label="Password"
              type={showpass ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              showpass={showpass}
              setshowpass={setshowpass}
              testid="password-input"
            />
            {passwordError && (
              <p className="text-red-500 mb-4" data-testid="password-error">
                {passwordError}
              </p>
            )}
            <div
              className="flex gap-10 justify-between items-center mt-6 w-full text-sm leading-none"
              data-testid="login-options"
            >
              <label className="flex gap-2 items-center self-stretch my-auto font-medium text-slate-800">
                <input
                  type="checkbox"
                  className="flex shrink-0 self-stretch my-auto bg-white rounded border border-gray-300 border-solid h-[18px] w-[18px]"
                  data-testid="remember-me-checkbox"
                />
                <span className="self-stretch my-auto">Remember me</span>
              </label>
              <a
                href="#"
                className="overflow-hidden gap-2 self-stretch py-2 my-auto font-semibold tracking-normal text-center text-[#052D61] rounded-lg"
                data-testid="forgot-password-link"
              >
                Forgot Password?
              </a>
            </div>
            {error && (
              <p className="text-red-500 mb-4" data-testid="login-error">
                {error}
              </p>
            )}
            <button
              type="submit"
              className="flex flex-col mt-6 w-full text-sm font-semibold tracking-normal leading-none text-center text-white whitespace-nowrap"
              data-testid="login-button"
            >
              <span className="overflow-hidden gap-2 self-stretch px-4 py-3.5 w-full bg-[#052D61] rounded-xl min-h-[48px]">
                Login
              </span>
            </button>
          </form>
        </div>
      </section>
    </main>
  );
};
