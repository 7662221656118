import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CustomToast = ({ message, type }) => (
  <div data-testid={`toast-${type}`}>{message}</div>
);

const useCustomToast = () => {
  const showToast = (message, type) => {
    toast(<CustomToast message={message} type={type} />, {
      type: type,
    });
  };

  return showToast;
};

export default useCustomToast;
