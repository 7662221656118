import React, { useState, useEffect, useRef } from "react";
import { ffmpegRTSPService } from "../services/ffmpeg-service";

function FFmpeg() {
  const [rtspUrls, setRtspUrls] = useState([
    "rtsp://ubuntu:lumeneo123@3.86.8.239:8554/forkliftStream",
  ]);
  const [thumbnails, setThumbnails] = useState([]);
  const [selectedStreamIndex, setSelectedStreamIndex] = useState(null);
  const [videoUrl, setVideoUrl] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const videoRef = useRef(null);
  const mediaSourceRef = useRef(null);

  // Generate thumbnails for all RTSP streams
  useEffect(() => {
    const generateThumbnails = async () => {
      try {
        setIsProcessing(true);
        setErrorMessage("");

        const thumbnailPromises = rtspUrls.map(async (url, index) => {
          const thumbnailBlob = await ffmpegRTSPService.generateThumbnail(url);
          return URL.createObjectURL(thumbnailBlob);
        });

        const generatedThumbnails = await Promise.all(thumbnailPromises);
        console.log("Generated thumbnails:", generatedThumbnails);
        setThumbnails(generatedThumbnails);
      } catch (error) {
        setErrorMessage("Error generating thumbnails. Please try again.");
        console.error(error);
      } finally {
        setIsProcessing(false);
      }
    };

    generateThumbnails();
  }, [rtspUrls]);

  // Function to handle RTSP stream processing
  const processStream = async (index) => {
    try {
      setSelectedStreamIndex(index);
      setIsProcessing(true);
      setErrorMessage("");

      // Start WebSocket connection to receive video chunks
      const socket = new WebSocket("ws://localhost:8080");
      socket.onopen = () => {
        console.log("WebSocket connected");
        socket.send(
          JSON.stringify({ action: "start-stream", rtspUrl: rtspUrls[index] })
        );
      };

      // Create MediaSource object to handle streaming chunks
      const mediaSource = new MediaSource();
      mediaSourceRef.current = mediaSource;
      const video = videoRef.current;
      video.src = URL.createObjectURL(mediaSource);

      mediaSource.addEventListener("sourceopen", () => {
        const sourceBuffer = mediaSource.addSourceBuffer(
          'video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
        );
        console.log("MediaSource opened");

        socket.onmessage = (event) => {
          const data = event.data;
          console.log("Received chunk:", data);

          // Append incoming chunks to the SourceBuffer
          if (sourceBuffer && !sourceBuffer.updating) {
            sourceBuffer.appendBuffer(data);
          }
        };
      });

      socket.onerror = (error) => {
        console.error("WebSocket error:", error);
      };

      socket.onclose = () => {
        console.log("WebSocket closed");
      };
    } catch (error) {
      setErrorMessage("Error processing RTSP stream. Please try again.");
      console.error(error);
    } finally {
      setIsProcessing(false);
    }
  };

  // Cleanup when the component unmounts
  useEffect(() => {
    return () => {
      if (videoUrl) {
        ffmpegRTSPService.revokeVideoUrl(videoUrl);
      }
    };
  }, [videoUrl]);

  return (
    <div className="p-8 bg-gray-100">
      <h1 className="text-2xl font-bold mb-8">RTSP Stream Processor</h1>

      <div className="grid grid-cols-3 gap-4">
        {thumbnails.map((thumbnailUrl, index) => (
          <div key={index} className="bg-white p-4 rounded-lg shadow">
            <img
              src={thumbnailUrl}
              alt={`Thumbnail for stream ${index + 1}`}
              className="w-full h-auto rounded-lg mb-4"
            />
            <button
              onClick={() => processStream(index)}
              className="w-full bg-[#052D61] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              View Stream {index + 1}
            </button>
          </div>
        ))}
      </div>

      {selectedStreamIndex !== null && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-75">
          <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-4xl">
            <h2 className="text-xl font-bold mb-4">
              RTSP Stream {selectedStreamIndex + 1}
            </h2>
            <video ref={videoRef} controls width="100%" />
            <button
              onClick={() => setSelectedStreamIndex(null)}
              className="mt-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}

      {errorMessage && <p className="mt-4 text-red-500">{errorMessage}</p>}
    </div>
  );
}

export default FFmpeg;
