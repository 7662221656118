// export default Sidebar;
import React, { useState } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import Logo from "./Logo"; // Assuming Logo component is imported
import UserProfile from "./UserProfile"; // Assuming UserProfile component is imported

const Sidebar = ({
  navItems,
  userName,
  usermail,
  currentPage,
  selectData,
  handleBackClick,
  selectDataOptions,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleSidebar = (e) => {
    e.stopPropagation();
    setIsCollapsed((prev) => !prev);
  };

  const renderIcon = (icon) => {
    if (typeof icon === "string") {
      return <img src={icon} alt="icon" className="w-5 h-5 shrink-0" />;
    } else if (React.isValidElement(icon)) {
      return icon;
    }
    return null;
  };

  const logout = () => {
    localStorage.removeItem("session");
    window.location.reload();
  };

  return (
    <div className="relative">
      {/* Collapse Toggle Button */}
      <button
        onClick={toggleSidebar}
        className={`absolute -right-3 top-6 z-20 bg-white border border-gray-200 rounded-full p-1.5 hover:bg-gray-50 transition-all duration-300 ${
          isCollapsed ? "rotate-180" : ""
        }`}
      >
        <svg
          className="w-4 h-4 text-gray-600"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M15 19l-7-7 7-7"
          />
        </svg>
      </button>

      <aside
        data-testid="sidebar"
        className={`h-screen bg-white border-r border-gray-200 transition-all duration-300 ease-out ${
          isCollapsed ? "w-20" : "w-72"
        }`}
      >
        <nav className="flex flex-col h-full py-4">
          {/* Logo Section */}
          <div
            className={`px-3 mb-8 ${isCollapsed ? "flex justify-center" : ""}`}
          >
            <Logo
              data-testid="sidebar-logo"
              isCollapsed={isCollapsed}
              className={`h-8 `}
            />
          </div>

          {/* Navigation Items */}
          <div className="flex-1 overflow-y-auto overflow-x-hidden px-3 font-golos">
            <div className="space-y-2">
              {navItems.map((item, index) => (
                <div key={index} className="relative group">
                  <button
                    onClick={item.navigate}
                    className={`w-full flex items-center rounded-lg transition-colors ${
                      isCollapsed ? "justify-center p-3" : "gap-3 px-4 py-3"
                    } ${
                      item.active
                        ? "bg-indigo-50 text-[#052D61] font-semibold"
                        : "text-slate-600 hover:bg-gray-50"
                    }`}
                  >
                    {/* <img
                      src={item.icon}
                      alt={`${item.text} icon`}
                      className="w-5 h-5 shrink-0"
                    /> */}
                    {renderIcon(item.icon)}
                    {!isCollapsed && (
                      <span className="transition-opacity duration-300">
                        {item.text}
                      </span>
                    )}
                  </button>
                </div>
              ))}
            </div>
          </div>

          {/* User Profile and Logout */}
          <div className="mt-auto pt-4 border-t border-gray-200 px-3 space-y-3 font-golos">
            {/* User Profile */}
            <div
              className={`flex ${
                isCollapsed ? "justify-center" : "items-center gap-3"
              }`}
            >
              <div className="w-8 h-8 rounded-full bg-indigo-50 border border-blue-200 flex items-center justify-center text-[#052D61] font-medium shrink-0">
                {userName?.charAt(0)}
              </div>
              {!isCollapsed && (
                <div className="flex flex-col min-w-0">
                  <span className="text-sm font-medium text-[#052D61] truncate">
                    {userName}
                  </span>
                  <span className="text-xs text-slate-600 truncate">
                    {usermail}
                  </span>
                </div>
              )}
            </div>

            {/* Logout Button */}
            <button
              onClick={logout}
              className={`w-full flex items-center rounded-lg transition-colors hover:bg-red-50 text-red-600 ${
                isCollapsed ? "justify-center p-3" : "gap-3 px-4 py-3"
              }`}
            >
              <svg
                className="w-5 h-5 shrink-0"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                />
              </svg>
              {!isCollapsed && <span className="font-medium">Logout</span>}
            </button>
          </div>
        </nav>
      </aside>
    </div>
  );
};

export default Sidebar;
