import React, { useEffect, useMemo, useState, useCallback } from 'react';
import {
    ResponsiveContainer,
    ComposedChart,
    Bar,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ReferenceLine,
    Cell,
    Text,
} from 'recharts';
import { getAlertsBySiteId } from '../services/apiServiceDetails';
import { processSiteData } from '../utils/constants';

const CONTROL_LIMITS = {
    "Hours": { four: 3, five: 1, six: 0 },
    "Week": { four: 21, five: 7, six: 0 },
    "Month": { four: 84, five: 28, six: 0 },
    "Year": { four: 84 * 12, five: 28 * 12, six: 0 }
};

const map = {
    "24hrs": "Hours",
    "1week": "Week",
    "1month": "Month",
    "1year": "Year",
};

const MOVING_AVERAGE_WINDOWS = {
    "Hours": 3,
    "Week": 3,
    "Month": 7,
    "Year": 1,
};

const limits = {
    hours: { min: 0, max: 10 },
    week: { min: 0, max: 50 },
    month: { min: 0, max: 100 },
    year: { min: 0, max: 1000 },
}

const TIME_CONSTANTS = {
    HOUR_IN_MS: 60 * 60 * 1000,
    DAY_IN_MS: 24 * 60 * 60 * 1000,
};

const TIME_RANGES = {
    Hours: {
        current: 23 * TIME_CONSTANTS.HOUR_IN_MS,
        previous: 48 * TIME_CONSTANTS.HOUR_IN_MS,
    },
    Week: {
        current: 6 * TIME_CONSTANTS.DAY_IN_MS,
        previous: 14 * TIME_CONSTANTS.DAY_IN_MS,
    },
    Month: {
        current: 29 * TIME_CONSTANTS.DAY_IN_MS,
        previous: 60 * TIME_CONSTANTS.DAY_IN_MS,
    },
    Year: {
        current: 364 * TIME_CONSTANTS.DAY_IN_MS,
        previous: 730 * TIME_CONSTANTS.DAY_IN_MS,
    },
};

const calculateMovingAverage = (data, windowSize) => {
    const values = data.map(item => item.violations);
    const result = [];

    for (let i = 0; i < values.length; i++) {
        if (i < windowSize - 1) {
            result.push(null); // Not enough data points for this window size
            continue;
        }

        let sum = 0;
        for (let j = i - (windowSize - 1); j <= i; j++) {
            sum += values[j];
        }
        const avg = sum / windowSize;
        result.push(parseFloat(avg.toFixed(2)));
    }

    return data.map((item, index) => ({
        ...item,
        movingAvg: result[index],
    }));
};

const getWindowInfo = (timeFrame, index, totalPoints) => {
    const windowSize = MOVING_AVERAGE_WINDOWS[timeFrame];
    switch (timeFrame) {
        case "Hours":
            return `${windowSize} hour average`;
        case "Week":
            return `${windowSize} day average`;
        case "Month":
            return `${windowSize} day average`;
        case "Year":
            return `${windowSize} month average`;
        default:
            return `${windowSize} point average`;
    }
};

const getBarColor = (violations, timeFrame) => {
    const limits = CONTROL_LIMITS[map[timeFrame]];
    if (violations > limits.four) return '#FF0000';
    if (violations > limits.five) return '#FFA500';
    if (violations > limits.six) return '#FFFF00';
    return '#4477AA';
};


const ForkliftViolationChart = ({ siteid, timeFrame }) => {
    const limits = CONTROL_LIMITS[map[timeFrame]];
    const [windowSize, setWindowSize] = useState(3);
    const [chartData, setChartData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Memoize the alert filtering function
    const filterRelevantAlerts = useCallback((data) => {
        const targetUseCases = new Set(['Forklift Safety & Compliance, Downtime & Maintenance', 'Safety & Compliance (PPE)', 'Safety & Compliance (Intrusion)']);
        return data.find(alert => targetUseCases.has(alert.name))?.alerts || [];
    }, []);

    // Memoize the violation counting function
    const countViolations = useCallback((alerts, startTime, endTime) => {
        return alerts.reduce((count, alert) => {
            const alertTime = new Date(alert.created_at).getTime();
            return (alertTime >= startTime && alertTime < endTime) ? count + 1 : count;
        }, 0);
    }, []);

    // Process data for each time frame
    const processTimeFrameData = useCallback((alerts, timeFrame, now) => {
        const currentTime = now.getTime();

        const timeFrameConfigs = {
            Hours: {
                length: 24,
                getInterval: (i) => ({
                    start: new Date(currentTime - (i * TIME_CONSTANTS.HOUR_IN_MS)),
                    end: new Date(currentTime - ((i - 1) * TIME_CONSTANTS.HOUR_IN_MS)),
                    format: (date) => `${date.getHours()}:00`
                })
            },
            Week: {
                length: 7,
                getInterval: (i) => {
                    const start = new Date(currentTime - (i * TIME_CONSTANTS.DAY_IN_MS));
                    start.setHours(0, 0, 0, 0);
                    const end = new Date(start.getTime() + TIME_CONSTANTS.DAY_IN_MS);
                    return {
                        start,
                        end,
                        format: () => ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][start.getDay()]
                    };
                }
            },
            Month: {
                length: 30,
                getInterval: (i) => {
                    const start = new Date(currentTime - (i * TIME_CONSTANTS.DAY_IN_MS));
                    start.setHours(0, 0, 0, 0);
                    const end = new Date(start.getTime() + TIME_CONSTANTS.DAY_IN_MS);
                    return {
                        start,
                        end,
                        format: () => `Day ${start.getDate()}`
                    };
                }
            },
            Year: {
                length: 12,
                getInterval: (i) => {
                    const date = new Date(now);
                    date.setMonth(date.getMonth() - i);
                    const start = new Date(date.getFullYear(), date.getMonth(), 1);
                    const end = new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59, 999);
                    return {
                        start,
                        end,
                        format: () => ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][start.getMonth()]
                    };
                }
            }
        };

        const config = timeFrameConfigs[map[timeFrame]];
        return Array.from({ length: config.length }, (_, i) => {
            const { start, end, format } = config.getInterval(i);
            const violations = countViolations(alerts, start.getTime(), end.getTime());
            const timeKey = timeFrame === '24hrs' ? 'time' : timeFrame === '1year' ? 'month' : 'day';

            return {
                [timeKey]: format(start),
                violations,
                windowInfo: `${start.toLocaleString()} - ${end.toLocaleString()}`
            };
        }).reverse();
    }, [countViolations]);

    const fetchData = useCallback(async () => {
        try {
            setLoading(true);
            setError(null);
            const data = await getAlertsBySiteId(siteid, timeFrame);

            // Filter relevant alerts
            const relevantAlerts = filterRelevantAlerts(data.sites[0].use_cases);
            if (relevantAlerts.length === 0) {
                throw new Error(`No relevant alerts found for the selected site`);
            }

            // Process data based on timeFrame
            const now = new Date();
            const processedData = processTimeFrameData(relevantAlerts, timeFrame, now);

            // Calculate moving average
            const processedDataWithAvg = calculateMovingAverage(processedData, windowSize);
            setChartData(processedDataWithAvg);
        } catch (error) {
            console.error('Error fetching alerts:', error);
            setError('Failed to load data. Please try again.');
        } finally {
            setLoading(false);
        }
    }, [siteid, timeFrame, windowSize, filterRelevantAlerts, processTimeFrameData]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    if (loading) {
        return (
            <div className="w-full h-[500px] p-2 bg-white rounded-lg shadow-sm">
                <div className="animate-pulse space-y-4">
                    <div className="flex justify-between items-center mb-4">
                        <div className="h-6 bg-gray-200 rounded w-1/4"></div>
                        <div className="h-8 bg-gray-200 rounded w-1/3"></div>
                    </div>
                    <div className="h-[400px] bg-gray-100 rounded-lg">
                        <div className="h-full w-full flex items-center justify-center">
                            <div className="flex flex-col items-center space-y-2">
                                <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
                                <p className="text-sm text-gray-500">Loading chart data...</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center space-x-4">
                        {[1, 2, 3].map((i) => (
                            <div key={i} className="h-4 bg-gray-200 rounded w-20"></div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="w-full h-[500px] bg-white rounded-lg shadow-sm p-6 border border-gray-200">
                <div className="h-full flex flex-col items-center justify-center text-center">
                    <div className="mb-4">
                        <svg className="w-16 h-16 text-gray-400 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </div>
                    <h3 className="text-xl font-semibold text-gray-700 mb-2">
                        Control Chart Data Unavailable
                    </h3>
                    <p className="text-gray-500 mb-4 max-w-md">
                        {error === 'No forklift data available'
                            ? `No control chart data is currently available for this site and time period.`
                            : error}
                    </p>
                    <div className="text-sm text-gray-400">
                        Try selecting a different time period.
                    </div>
                </div>
            </div>
        );
    }

    const handleWindowSizeChange = (e) => {
        setWindowSize(Number(e.target.value));
    };

    const formatXAxisTick = (tick) => {
        if (timeFrame === "24hrs") {
            const hour24 = parseInt(tick.split(":")[0], 10);
            const hour12 = hour24 % 12 || 12;
            const period = hour24 >= 12 ? " PM" : " AM";
            return `${hour12}${period}`;
        }
        if (timeFrame === "1month") return tick.split(" ")[1];
        return tick;
    };

    const getXAxisKey = () => {
        switch (timeFrame) {
            case "24hrs": return "time";
            case "1week": return "day";
            case "1month": return "day";
            default: return "month";
        }
    };

    // Custom Legend Renderer
    const renderCustomLegend = (props) => {
        const { payload } = props;

        return (
            <ul className="flex justify-center space-x-4 mt-4">
                {payload.map((entry, index) => (
                    entry.value !== "Violations" && (
                        <li key={`legend-item-${index}`} className="flex items-center">
                            <span
                                className="w-4 h-4 mr-2 inline-block"
                                style={{ backgroundColor: entry.color }}
                            />
                            {entry.value}
                        </li>
                    )
                ))}

                <li className="flex items-center">
                    <span className="w-4 h-4 mr-2 inline-block" style={{ backgroundColor: "#FF0000" }} />
                    4σ Limit ({limits.four} violations)
                </li>
                <li className="flex items-center">
                    <span className="w-4 h-4 mr-2 inline-block" style={{ backgroundColor: "#FFA500" }} />
                    5σ Limit ({limits.five} violations)
                </li>
                <li className="flex items-center">
                    <span className="w-4 h-4 mr-2 inline-block" style={{ backgroundColor: "#FFFF00" }} />
                    6σ Limit ({limits.six} violations)
                </li>
            </ul>
        );
    };

    return (
        <div className="w-full h-[500px] p-2">
            <div className="flex justify-between items-center mb-2">
                <h2 className="text-xl font-semibold mb-2">Control Chart</h2>
                <div className="text-sm text-gray-600 mb-2">
                    <label className="text-sm mr-2">Select Moving Average Window:</label>
                    <select
                        value={windowSize}
                        onChange={handleWindowSizeChange}
                        className="border border-gray-300 rounded px-2 py-1 mb-2"
                    >
                        {timeFrame === "24hrs" && [1, 2, 3, 4, 6].map(size => (
                            <option key={size} value={size}>{`${size} Hours`}</option>
                        ))}
                        {timeFrame === "1week" && [3, 4].map(size => (
                            <option key={size} value={size}>{`${size} Days`}</option>
                        ))}
                        {timeFrame === "1month" && [3, 5, 7, 10].map(size => (
                            <option key={size} value={size}>{`${size} Days`}</option>
                        ))}
                        {timeFrame === "1year" && [1, 2, 3, 6, 12].map(size => (
                            <option key={size} value={size}>{`${size} Months`}</option>
                        ))}
                    </select>
                </div>
            </div>
            <ResponsiveContainer width="100%" height="100%">
                <ComposedChart
                    data={chartData}
                    margin={{ top: 10, right: 100, left: 10, bottom: 40 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey={getXAxisKey()}
                        interval={0}
                        angle={-45}
                        textAnchor="end"
                        height={80}
                        tick={{ fontSize: 12 }}
                        tickFormatter={formatXAxisTick}
                        label={{
                            value: timeFrame,
                            position: 'insideBottom',
                            offset: -10
                        }}
                    />
                    <YAxis
                        label={{
                            value: 'Violations',
                            angle: -90,
                            position: 'insideCenter',
                            dx: -20
                        }}
                    />
                    <Tooltip
                        content={({ active, payload }) => {
                            if (active && payload && payload.length) {
                                return (
                                    <div className="bg-white p-2 border rounded shadow">
                                        <p className="text-sm">{`Violations: ${payload[0].value}`}</p>
                                        {payload[1]?.value && <p className="text-sm">{`Moving Average: ${payload[1]?.value}`}</p>}
                                        {payload[1]?.value && (
                                            <p className="text-sm text-gray-600">{payload[0]?.payload?.windowInfo}</p>
                                        )}
                                    </div>
                                );
                            }
                            return null;
                        }}
                    />
                    <Bar dataKey="violations" barSize={30} name="Violations">
                        {chartData.map((entry, index) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={getBarColor(entry.violations, timeFrame)}
                                fillOpacity={0.8}
                            />
                        ))}
                    </Bar>
                    <ReferenceLine y={limits?.four} stroke="#FF0000" strokeDasharray="5 5" />
                    <ReferenceLine y={limits?.five} stroke="#FFA500" strokeDasharray="5 5" />
                    <ReferenceLine y={limits?.six} stroke="#FFFF00" strokeDasharray="5 5" />
                    <Line
                        type="monotone"
                        dataKey="movingAvg"
                        stroke="#FF8800"
                        strokeWidth={2}
                        dot={false}
                        name="Moving Average"
                    />
                    <Legend content={renderCustomLegend} />
                </ComposedChart>
            </ResponsiveContainer>
        </div>
    );
};

export default ForkliftViolationChart;