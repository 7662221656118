import React, { useMemo, useState, useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import PropTypes from "prop-types";
import { CustomTooltip, formatTime } from "./utils";

const AnalyticsCard = ({
  data,
  timeframe = "day",
  isLoading = false,
  onClick = () => {},
  isLarge = false,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const isForklift =
    data.name === "Forklift Safety, Compliance, Downtime, and Maintenance";

  const config = useMemo(() => {
    if (isForklift) {
      return {
        title: "Average Forklift Load/Unload Time",
        lines: [
          { key: "loading_time", name: "Loading Time", color: "#295F98" },
          { key: "unloading_time", name: "Unloading Time", color: "#60a5fa" },
        ],
        formatValue: formatTime,
      };
    }
    return {
      title: "Number of Packages",
      lines: [
        { key: "picked_boxes", name: "Picked", color: "#8884d8" },
        { key: "packed_boxes", name: "Packed", color: "#82ca9d" },
        { key: "shipped_boxes", name: "Shipped", color: "#ffc658" },
      ],
      formatValue: (val) => Math.round(val),
    };
  }, [isForklift]);

  const calculateStats = () => {
    if (!data?.Events?.length) {
      return {
        metrics: {},
        change: 0,
        isPositive: false,
      };
    }

    const events = data.Events;
    const metrics = {};
    let total = 0;

    config.lines.forEach((line) => {
      const value = events.reduce(
        (sum, event) => sum + (parseFloat(event[line.key]) || 0),
        0
      );
      metrics[line.key] = value;
      total += value;
    });

    const previousTotal = total * 1.1;
    const changePercent = (
      ((total - previousTotal) / previousTotal) *
      100
    ).toFixed(1);

    return {
      metrics,
      change: Math.abs(changePercent),
      isPositive: changePercent > 0,
    };
  };

  const { metrics, change, isPositive } = calculateStats();

  const axisConfig = useMemo(() => {
    if (timeframe === "Hours") {
      return {
        xAxisTitle: "Hour (00-23)",
        yAxisTitle: "Total Minutes (0-60)",
        yTicks: [0, 15, 30, 45, 60],
        xTicks: Array.from({ length: 24 }, (_, i) => i),
      };
    } else if (timeframe === "Week") {
      return {
        xAxisTitle: "Days of the Week",
        yAxisTitle: "Total Hours (0-23)",
        xTicks: [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
        ],
        yTicks: [0, 5, 10, 15, 20, 23],
      };
    } else if (timeframe === "Month") {
      const daysInMonth = new Date().getDate();
      return {
        xAxisTitle: "Days of the Month",
        yAxisTitle: "Total Hours (0-23)",
        xTicks: Array.from({ length: daysInMonth }, (_, i) => i + 1),
        yTicks: [0, 5, 10, 15, 20, 23],
      };
    } else if (timeframe === "Year") {
      return {
        xAxisTitle: "Months",
        yAxisTitle: "Total Hours (0-730)",
        xTicks: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        yTicks: [0, 100, 300, 500, 730],
      };
    }
    return {};
  }, [timeframe]);

  const getXAxisValue = (createdAt) => {
    let date = new Date(`${createdAt}Z`);
    const local = date.toLocaleString();
    const localDate = new Date(local);
    console.log("createdAt", createdAt, "localDate", localDate);

    if (timeframe === "Hours") {
      const hour = localDate.getHours();
      const period = hour >= 12 ? "PM" : "AM";
      const formattedHour = hour % 12 || 12;
      return `${formattedHour} ${period}`;
    } else if (timeframe === "Week") {
      const daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      return daysOfWeek[localDate.getDay()];
    } else if (timeframe === "Month") {
      const day = localDate.getDate();
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      return `${day} ${months[localDate.getMonth()]}`;
    } else if (timeframe === "Year") {
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      return months[localDate.getMonth()];
    }
    return null;
  };

  const formattedData = (data?.Events || []).map((event) => ({
    ...event,
    xAxisValue: getXAxisValue(event.created_at),
  }));

  // const CustomLegend = () => (
  //     <div className="flex justify-center gap-4 text-xs">
  //         {config.lines.map(line => (
  //             <div key={line.key} className="flex items-center">
  //                 <div className="w-3 h-3 rounded-full mr-1" style={{ backgroundColor: line.color }}></div>
  //                 <span>{line.name}</span>
  //             </div>
  //         ))}
  //     </div>
  // );

  return (
    <div
      className={`flex flex-col px-2 py-2 rounded-lg border-2 border-gray-200 ${
        isLarge
          ? "w-full h-full"
          : "basis-[300px] min-w-[300px] max-w-[300px] h-[220px] cursor-pointer"
      }  hover:border-gray-300 transition-colors duration-200`}
      onClick={!isLarge ? () => onClick(data, config.title) : undefined}
    >
      <div className="flex justify-between items-start p-2 h-[80px] mb-5">
        <div className="flex flex-col">
          <div className="text-md font-semibold text-gray-500">
            {config.title}
          </div>
          <div className="flex flex-col gap-1 mt-2 mb-2 text-xs text-gray-900">
            {config.lines.map((line) => (
              <div key={line.key} className="flex items-center">
                <div
                  className="w-2 h-2 rounded-full mr-1"
                  style={{ backgroundColor: line.color }}
                ></div>
                <span>
                  {line.name}: {config.formatValue(metrics[line.key])}
                  {isForklift ? " hours" : ""}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>

      {isLoading ? (
        <div className="w-full flex justify-center items-center">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
        </div>
      ) : (
        <div className={`${isLarge ? "h-[600px] p-3" : "h-[120px]"}`}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={formattedData || []}
              margin={{ top: 5, right: 5, left: 5, bottom: 15 }}
            >
              {isLarge ? (
                <>
                  <XAxis
                    dataKey="xAxisValue"
                    // ticks={axisConfig.xTicks}
                    tick={{ angle: -45, textAnchor: "end", fontSize: 12 }}
                    label={{
                      value: axisConfig.xAxisTitle,
                      position: "insideBottom",
                      offset: -5,
                      dy: 10,
                    }}
                    fontSize={12}
                  />
                  <YAxis
                    // ticks={axisConfig.yTicks}
                    label={{
                      value: isForklift
                        ? axisConfig.yAxisTitle
                        : "Number of Boxes",
                      angle: -90,
                      position: "insideLeft",
                    }}
                    fontSize={12}
                  />
                </>
              ) : (
                <>
                  <XAxis
                    tick={{ angle: -45, textAnchor: "end", fontSize: 12 }}
                    dataKey="created_at"
                    hide
                  />
                  <YAxis hide />
                </>
              )}
              <Tooltip
                content={(props) => (
                  <CustomTooltip
                    {...props}
                    isForklift={isForklift}
                    timeframe={timeframe}
                  />
                )}
              />
              {config.lines.map((line) => (
                <Line
                  key={line.key}
                  type="monotone"
                  dataKey={line.key}
                  stroke={line.color}
                  strokeWidth={2}
                  dot={true}
                  name={line.name}
                />
              ))}
            </LineChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
};

AnalyticsCard.propTypes = {
  data: PropTypes.shape({
    Events: PropTypes.arrayOf(PropTypes.object),
    name: PropTypes.string.isRequired,
  }).isRequired,
  timeframe: PropTypes.string,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  isLarge: PropTypes.bool,
};

const LineChartsModal = ({ data, timeframe, isModal = false, onClick }) => {
  console.log("!!!!!!!!!!!!!!!!!!!!!!! ", timeframe, data);
  return (
    <div className="flex flex-wrap gap-4">
      <AnalyticsCard
        data={data}
        timeframe={timeframe}
        isLarge={isModal}
        onClick={onClick}
      />
    </div>
  );
};

LineChartsModal.propTypes = {
  data: PropTypes.shape({
    Events: PropTypes.arrayOf(PropTypes.object),
    name: PropTypes.string.isRequired,
  }).isRequired,
  timeframe: PropTypes.string,
};

export default LineChartsModal;
