import React, { useRef, useState } from "react";
import { Modal, Box, IconButton, Slider } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import Forward30Icon from "@mui/icons-material/Forward30";
import Replay30Icon from "@mui/icons-material/Replay30";
import CloseIcon from "@mui/icons-material/Close"; // Import Close icon

// Styles for the modal
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

const VideoPlayer = ({ videoUrl, open, onClose }) => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(1);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const togglePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleVolumeChange = (event, newValue) => {
    setVolume(newValue);
    videoRef.current.volume = newValue;
  };

  const handleProgressChange = (event) => {
    const time = (event.target.value / 100) * videoRef.current.duration;
    videoRef.current.currentTime = time;
  };

  const toggleFullscreen = () => {
    if (isFullscreen) {
      document.exitFullscreen();
    } else {
      videoRef.current.parentElement.requestFullscreen();
    }
    setIsFullscreen(!isFullscreen);
  };

  const forwardVideo = () => {
    videoRef.current.currentTime += 30; // Forward 30 seconds
  };

  const rewindVideo = () => {
    videoRef.current.currentTime -= 30; // Rewind 30 seconds
  };

  const currentTime = videoRef.current ? videoRef.current.currentTime : 0;
  const duration = videoRef.current ? videoRef.current.duration : 0;

  return (
    <Modal open={open} onClose={onClose} data-testid="video-player-modal">
      <Box sx={style} data-testid="video-player-box">
        <IconButton
          onClick={onClose}
          sx={{ position: "absolute", top: 10, right: 10 }}
          data-testid="close-button"
        >
          <CloseIcon />
        </IconButton>
        <video
          ref={videoRef}
          src={videoUrl}
          controls={false}
          style={{ width: "100%", borderRadius: "8px" }}
          onEnded={() => setIsPlaying(false)}
          data-testid="video-element"
        />

        <Box display="flex" alignItems="center" mt={2}>
          <IconButton onClick={rewindVideo} data-testid="rewind-button">
            <Replay30Icon />
          </IconButton>
          <IconButton onClick={togglePlayPause} data-testid="play-pause-button">
            {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
          </IconButton>
          <IconButton onClick={forwardVideo} data-testid="forward-button">
            <Forward30Icon />
          </IconButton>

          <Slider
            value={volume * 100}
            onChange={handleVolumeChange}
            aria-label="Volume"
            sx={{ width: 200, mx: 2 }}
            data-testid="volume-slider"
          />

          <IconButton
            onClick={toggleFullscreen}
            data-testid="fullscreen-button"
          >
            {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
          </IconButton>
        </Box>

        <Slider
          defaultValue={0}
          onChange={handleProgressChange}
          onMouseUp={() => {
            videoRef.current.play();
            setIsPlaying(true);
          }}
          value={(currentTime / duration) * 100 || 0}
          sx={{ mt: 1 }}
          data-testid="progress-slider"
        />
      </Box>
    </Modal>
  );
};

export default VideoPlayer;
