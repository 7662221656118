import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

const severityLabels = ["Low", "Medium", "High", "Critical"];
const frequencyLabels = [
  "Critical",
  "Very High",
  "High",
  "Medium",
  "Low",
  "Very Low",
];
const apiData = [
  { name: "Forklift Speed Violation", frequency: 100 },
  { name: "Forklift Proximity to People", frequency: 200 },
  { name: "Forklift Not Stopping At Designated Areas", frequency: 250 },
  { name: "No Mask", frequency: 230 },
  { name: "No Boots", frequency: 240 },
  { name: "No Gloves", frequency: 260 },
  { name: "No Helmet", frequency: 240 },
  { name: "No Vest", frequency: 120 },
  { name: "No Goggles", frequency: 150 },
];

// const severityLevels = {
//   "No Helmet": 4,
//   "Forklift Speed Violation": 4,
//   "Forklift Proximity Violation": 3,
//   "Forklift Stoppage Violation": 3,
//   "No Boots": 2,
//   "No Goggles": 2,
//   "No Gloves": 1,
//   "No Mask": 1,
//   "No Vest": 1,
// };

const severityLevels = {
  "Safety Vests": 2,
  "Safety Helmet": 3,
  "Safety Goggles": 2,
  "Safety Gloves": 2,
  "Safety Boots": 2,
  "Forklift Overspeeding": 4,
  "Forklift Proximity To People": 4,
  "Forklift Stoppage Violation": 3,
  "Forklift Utilization Low": 2,
  "Person Intrusion In Restricted Area": 2,
};

const SeverityChart = ({ data, timeframe, loading, selectWarehouse }) => {
  const [hoveredCell, setHoveredCell] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState("bottom");

  if (loading) {
    return (
      <div className="w-full h-[500px] p-2 bg-white rounded-lg shadow-sm">
        <div className="animate-pulse space-y-4">
          <div className="flex justify-between items-center mb-4">
            <div className="h-6 bg-gray-200 rounded w-1/4"></div>
            <div className="h-8 bg-gray-200 rounded w-1/3"></div>
          </div>
          <div className="h-[400px] bg-gray-100 rounded-lg">
            <div className="h-full w-full flex items-center justify-center">
              <div className="flex flex-col items-center space-y-2">
                <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
                <p className="text-sm text-gray-500">Loading chart data...</p>
              </div>
            </div>
          </div>
          <div className="flex justify-center space-x-4">
            {[1, 2, 3].map((i) => (
              <div key={i} className="h-4 bg-gray-200 rounded w-20"></div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  if (data.length === 0) {
    return (
      <div className="w-full h-[500px] bg-white rounded-lg shadow-sm p-6 border border-gray-200">
        <div className="h-full flex flex-col items-center justify-center text-center">
          <div className="mb-4">
            <svg className="w-16 h-16 text-gray-400 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </div>
          <h3 className="text-xl font-semibold text-gray-700 mb-2">
            Severity Chart Data Unavailable
          </h3>
          <p className="text-gray-500 mb-4 max-w-md">
            No severity chart data is currently available for this site and time period.
          </p>
          <div className="text-sm text-gray-400">
            Try selecting a different time period.
          </div>
        </div>
      </div>
    );
  }

  const frequencies = data.map((item) => item.frequency);
  const minFreq = Math.min(...frequencies);
  const maxFreq = Math.max(...frequencies);
  const bucketSize = (maxFreq - minFreq) / 6;

  const categorizedData = data
    .filter(item => severityLevels.hasOwnProperty(item.name))
    .map((item) => {
      const severity = severityLevels[item.name];
      const frequencyCategory = Math.min(
        Math.floor((item.frequency - minFreq) / bucketSize),
        5
      );
      return { ...item, severity, frequencyCategory };
    });

  // Initialize grid with empty arrays
  const gridData = Array(6)
    .fill()
    .map(() => Array(4)
      .fill()
      .map(() => []));

  categorizedData.forEach((item) => {
    if (item.severity >= 1 && item.severity <= 4) {
      const row = 5 - item.frequencyCategory;
      const col = item.severity - 1;
      if (row >= 0 && row < 6 && col >= 0 && col < 4) {
        gridData[row][col] = [...gridData[row][col], item];
      }
    }
  });

  const getCellColor = (rowIndex, colIndex) => {
    const risk = (6 - rowIndex) * (colIndex + 1);
    if (risk >= 15) return "bg-red-500";
    if (risk >= 10) return "bg-orange-500";
    if (risk >= 5) return "bg-yellow-300";
    return "bg-green-500";
  };

  return (
    <div className="p-2 flex flex-col">
      <h2 className="text-xl font-semibold mb-3">Risk Analysis Heatmap</h2>
      <div className="flex flex-col md:flex-row max-w-full">
        {/* Y-axis Label */}
        <div className="flex flex-row items-center justify-center pr-2">
          <div className="transform -rotate-90 font-medium text-sm">
            Frequency
          </div>
          <div className="flex flex-col mt-3 items-end justify-between">
            {frequencyLabels.map((label, index) => {
              const reverseIndex = frequencyLabels.length - index - 1;
              return (
                <div
                  key={index}
                  className="h-16 flex items-end justify-end font-medium text-xs"
                >
                  <span className="pr-2">{label}</span>
                  <span className="text-gray-500">
                    ({Math.round(minFreq + reverseIndex * bucketSize)} -{" "}
                    {Math.round(minFreq + (reverseIndex + 1) * bucketSize)})
                  </span>
                </div>
              );
            })}
          </div>
        </div>

        <div className="flex-1">
          <div className="flex justify-center mb-2">
            <div className="font-medium text-sm mr-4">Severity</div>
          </div>

          <div className="flex justify-between mb-2 pl-2">
            {severityLabels.map((label, index) => (
              <div
                key={index}
                className="flex-1 text-center font-medium text-xs"
              >
                {label}
              </div>
            ))}
          </div>

          {/* Heatmap Grid */}
          <div className="grid grid-cols-4 gap-1">
            {gridData.map((row, rowIndex) =>
              row.map((cell, colIndex) => (
                <motion.div
                  key={`${rowIndex}-${colIndex}`}
                  className={`${getCellColor(
                    rowIndex,
                    colIndex
                  )} flex p-2 min-h-[60px] rounded-lg shadow-sm items-center justify-center transition-colors duration-200`}
                  onMouseEnter={(e) => {
                    const cellRect = e.target.getBoundingClientRect();
                    setTooltipPosition(
                      window.innerHeight - cellRect.bottom < 100
                        ? "top"
                        : "bottom"
                    );
                    setHoveredCell({ rowIndex, colIndex });
                  }}
                  onMouseLeave={() => setHoveredCell(null)}
                  whileHover={{ scale: 1.05 }}
                  transition={{ duration: 0.3 }}
                >
                  <div className="text-center text-xs">
                    {cell.length > 0
                      ? cell.map((item) => item.name).join(", ")
                      : "-"}
                  </div>

                  <AnimatePresence>
                    {hoveredCell &&
                      hoveredCell.rowIndex === rowIndex &&
                      hoveredCell.colIndex === colIndex &&
                      cell.length > 0 && (
                        <motion.div
                          initial={{ opacity: 0, y: -10 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -10 }}
                          className="absolute p-2 bg-gray-800 text-white rounded shadow-lg text-xs z-50"
                          style={{
                            top: tooltipPosition === "bottom" ? "100%" : "auto",
                            bottom: tooltipPosition === "top" ? "100%" : "auto",
                            maxHeight: "200px",
                            overflowY: "auto",
                          }}
                        >
                          <ul>
                            {cell.map((item, idx) => (
                              <li key={idx}>
                                <strong>{item.name}</strong>: <br /> Frequency{" "}
                                {item.frequency}
                              </li>
                            ))}
                          </ul>
                        </motion.div>
                      )}
                  </AnimatePresence>
                </motion.div>
              )))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SeverityChart;
