export const SET_USER = "SET_USER";
export const SET_PROJECT_ID = "SET_PROJECT_ID";

export const setUser = (email, name, socketID) => {
  // console.log("email",email,name);
  return {
    type: SET_USER,
    payload: { email, name, socketID },
  };
};
