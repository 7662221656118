import { FFmpeg } from '@ffmpeg/ffmpeg';
import { fetchFile } from '@ffmpeg/util';

class FFmpegService {
    constructor() {
        this.ffmpeg = new FFmpeg();
        this.isLoaded = false;
    }

    async load() {
        if (!this.isLoaded) {
            await this.ffmpeg.load({
                log: true,
                coreURL: 'https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd/ffmpeg-core.js'
            });

            this.ffmpeg.on('log', ({ message }) => {
                console.log('FFmpeg Log:', message);
            });

            this.ffmpeg.on('progress', ({ progress, time }) => {
                console.log('Processing time:', time);
                console.log('Processing progress:', Math.round(progress * 100), '%');
            });

            this.isLoaded = true;
        }
    }

    async processVideo(presignedUrl, quality = 'medium') {
        try {
            await this.load();
            const videoData = await fetchFile(presignedUrl);
            await this.ffmpeg.writeFile('input.mp4', videoData);

            const presets = {
                fast: [
                    '-i', 'input.mp4',
                    '-c:v', 'libx264',
                    '-preset', 'ultrafast',
                    '-tune', 'fastdecode',
                    '-c:a', 'aac',
                    '-b:a', '128k',
                    '-movflags', '+faststart',
                    '-f', 'mp4',
                    'output_video.mp4'
                ],
                medium: [
                    '-i', 'input.mp4',
                    '-c:v', 'libx264',
                    '-preset', 'veryfast',
                    '-crf', '23',
                    '-maxrate', '2M',
                    '-bufsize', '2M',
                    '-c:a', 'aac',
                    '-b:a', '128k',
                    '-movflags', '+faststart',
                    '-f', 'mp4',
                    'output_video.mp4'
                ],
                lowLatency: [
                    '-i', 'input.mp4',
                    '-c:v', 'libx264',
                    '-preset', 'ultrafast',
                    '-tune', 'zerolatency',
                    '-crf', '27',
                    '-maxrate', '1M',
                    '-bufsize', '1M',
                    '-c:a', 'aac',
                    '-b:a', '96k',
                    '-movflags', '+faststart',
                    '-f', 'mp4',
                    'output_video.mp4'
                ],
                streamOptimized: [
                    '-i', 'input.mp4',
                    '-c:v', 'libx264',
                    '-preset', 'superfast',
                    '-profile:v', 'baseline',
                    '-level', '3.0',
                    '-crf', '25',
                    '-maxrate', '1.5M',
                    '-bufsize', '1.5M',
                    '-c:a', 'aac',
                    '-b:a', '128k',
                    '-movflags', '+faststart',
                    '-f', 'mp4',
                    'output_video.mp4'
                ]
            };

            await this.ffmpeg.exec(presets[quality]);

            const processedData = await this.ffmpeg.readFile('output_video.mp4');

            await this.ffmpeg.deleteFile('input.mp4');
            await this.ffmpeg.deleteFile('output_video.mp4');

            return new Blob([processedData.buffer], { type: 'video/mp4' });
        } catch (error) {
            console.error('Error processing video:', error);
            throw new Error('Failed to process video');
        }
    }

    async getVideoMetadata(videoData) {
        await this.ffmpeg.writeFile('temp.mp4', videoData);
        await this.ffmpeg.exec([
            '-i', 'temp.mp4',
            '-f', 'null',
            '-'
        ]);
        await this.ffmpeg.deleteFile('temp.mp4');
    }

    async terminate() {
        if (this.isLoaded) {
            await this.ffmpeg.terminate();
            this.isLoaded = false;
        }
    }

    createVideoUrl(blob) {
        return URL.createObjectURL(blob);
    }

    revokeVideoUrl(url) {
        URL.revokeObjectURL(url);
    }
}

export const ffmpegService = new FFmpegService();