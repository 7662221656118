import React from 'react';

const RadioGroup = ({ name, value, onChange, disabled }) => {
  return (
    <div className="flex gap-4">
      <label className="flex items-center">
        <input
          type="radio"
          name={name}
          value="Yes"
          checked={value === 'Yes'}
          onChange={onChange}
          disabled={disabled}
          className="mr-2"
        />
        Yes
      </label>
      <label className="flex items-center">
        <input
          type="radio"
          name={name}
          value="No"
          checked={value === 'No'}
          onChange={onChange}
          disabled={disabled}
          className="mr-2"
        />
        No
      </label>
    </div>
  );
};

export default RadioGroup;