import axios from "axios";
import axiosInstance from "./axiosinstance";
import {
  vt,
  projectStreams,
  roiImages,
  sites,
  projects,
  streams,
  loginData,
  videos,
  warehouseAlerts,
  LineChartData,
  LineChartDataWeek,
  LineChartDataMonth,
  LineChartDataYear,
  LineChartDataEmployee,
  singleWarehouse,
} from "../mockData/mockData";
import { MockHeatmapdata } from "../utils/HeatmapData";

const API_URL = "http://web.lumeneo.ai"; // Replace with your API base URL
const isDevelopment = process.env.NODE_ENV === "development";
// console.log("isDevelopment", isDevelopment);
// Function to handle login
export const loginApi = async (email, password) => {
  try {
    if (isDevelopment) {
      let data = {
        email: email,
        ...loginData,
      };
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(data);
        }, 500);
      });
    } else {
      console.log("Loggin");
      const response = await axiosInstance.post(`/auth/auth/login`, {
        email,
        password,
      });
      // console.log(response);
      return response.data.data;
    }
  } catch (error) {
    console.error("Axios error:", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const createsiteApi = async (
  name,
  location,
  description,
  latitude,
  longitude,
  account_id
) => {
  try {
    const temp_accountid = 1;
    const response = await axiosInstance.post(`/site/sites`, {
      name,
      location,
      description,
      latitude,
      longitude,
      account_id: temp_accountid,
    });
    return { success: true, data: response.data };
  } catch (error) {
    if (error.response) {
      if (
        error.response.data.error &&
        error.response.data.error.includes("already exists")
      ) {
        return {
          success: false,
          duplicate: true,
          message: `Site name '${name}' already exists.`,
        };
      }
      return {
        success: false,
        message: error.response.data.error || "An error occurred",
      };
    } else {
      return { success: false, message: "Network Error" };
    }
  }
};

export const UpdateSite = async (
  id,
  name,
  location,
  description,
  latitude,
  longitude
) => {
  // console.log(name);
  try {
    const response = await axiosInstance.put(`/site/api/UpdateSites/${id}`, {
      name,
      location,
      description,
      latitude,
      longitude,
    });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const getAllSites = async (accountID) => {
  if (isDevelopment) {
    // Return mock data in development
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(sites);
      }, 500); // Simulate network delay
    });
  } else {
    try {
      // const response = await axios.get(`http://localhost:8081/sites`);
      const response = await axiosInstance.get(
        `/site/api/sites/dashboard-account/${accountID}`
      );
      // console.log(response.data);
      return response.data.sites;
    } catch (error) {
      console.error("Axios error:", error);
      if (error.response) {
        console.error("Server responded with:", error.response);
        throw error.response.data;
      } else if (error.request) {
        console.error("Request made, but no response received", error.request);
        throw new Error("No response received from server");
      } else {
        console.error("Error setting up request:", error.message);
        throw new Error("Network Error");
      }
    }
  }
};

export const createStream = async (formData) => {
  // console.log(formData);
  try {
    const response = await axiosInstance.post(
      "/streams/stream/create",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    if (error.response) {
      if (
        error.response.data.error &&
        error.response.data.error.includes("already exists")
      ) {
        return {
          success: false,
          duplicate: true,
          message: `Site name already exists.`,
        };
      }
      return {
        success: false,
        message: error.response.data.error || "An error occurred",
      };
    } else {
      return { success: false, message: "Network Error" };
    }
  }
};

export const startHLSStream = async (streamData) => {
  try {
    const response = await axiosInstance.post(
      "/streams/stream/startHLS",
      streamData
    );
    return response;
  } catch (error) {
    console.error("Axios error:", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

const base64ToBlob = (base64, contentType = "image/jpeg") => {
  const byteCharacters = atob(base64.split(",")[1]);
  const byteNumbers = new Array(byteCharacters.length).map((_, i) =>
    byteCharacters.charCodeAt(i)
  );
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: contentType });
};

export const uploadThumbnail = async (stream_id, thumbnail) => {
  try {
    const formData = new FormData();
    const thumbnailBlob = base64ToBlob(thumbnail, "image/jpeg");
    formData.append("stream_id", stream_id);
    formData.append("thumbnail", thumbnailBlob);
    const response = await axiosInstance.post(
      "/streams/stream/uploadThumbnail",
      formData
    );
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const getThumbnail = async (stream_id) => {
  try {
    console.log("stream_id", stream_id);
    if(isDevelopment){
      let even = Math.floor(Math.random() * 2);
      console.log("even", even);
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            thumbnailUrl: even%2 == 0 ? "https://picsum.photos/200/300" : "/ppe_frame_0.jpg",
          });
        }, 1200);
      });
    }
    const response = await axiosInstance.get(
      `/streams/stream/getThumbnail/${stream_id}`
    );
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const getThumbnailURLs = async (stream_ids = []) => {
  try {
    if(isDevelopment){
      console.log("stream_ids", stream_ids);
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({
              "streams": [
                {
                  "id": 178,
                  "name": "video1.mp4",
                  "url": "http://picsum.photos/200/300"
                },
                {
                  "id": 148,
                  "name": "video2.mp4",
                  "url": "http://picsum.photos/200/300"
                },
                {
                  "id": 118,
                  "name": "video4.mp4",
                  "url": "http://picsum.photos/200/300"
                },
                {
                  "id": 188,
                  "name": "video3.mp4",
                  "url": "/ppe_frame_0.jpg"
                }
              ]            
          });
        }, 1000);
      });
    }
    const response = await axiosInstance.post(
      `/streams/stream/getThumbnailURLs/`,
      {
        stream_ids,
      }
    );
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const stopStreams = async (id) => {
  try {
    const response = await axiosInstance.get(
      `/streams/stream/stopStream/${id}`
    );
    // console.log(response.data);
    return response.data;
  } catch (error) {}
};

export const getallstreams = async (id) => {
  if (isDevelopment) {
    // Return mock data in development
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(streams);
      }, 500); // Simulate network delay
    });
  } else {
    try {
      const response = await axiosInstance.get(`/streams/streams/${id}`);
      // console.log(response.data);
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : new Error("Network Error");
    }
  }
};

export const getAllAlerts = async (accountId) => {
  if (isDevelopment) {
    // Return mock data in development
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(warehouseAlerts);
      }, 500); // Simulate network delay
    });
  } else {
    try {
      // const response = await axiosInstance.get(
      //   `/dashboard/alerts/${projectID}/${duration}`
      // );
      const response = await axiosInstance.get(
        `/dashboard/alerts/api/allAlertsbyAccount/${accountId}/1year`
      );

      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : new Error("Network Error");
    }
  }
};

export const getAlertsBySiteId = async (siteId, timeFrame) => {
  if (isDevelopment) {
    // Return mock data in development
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(singleWarehouse);
      }, 3500); // Simulate network delay
    });
  } else {
    try {
      const response = await axiosInstance.get(
        `/dashboard/alerts/api/allAlerts/${siteId}/${timeFrame}`
      );

      return response.data; 
    } catch (error) {
      throw error.response ? error.response.data : new Error("Network Error");
    }
  }
};

export const fetchPresignedUrl = async (videoUrl) => {
  try {
    console.log("videoUrl", videoUrl);
    const response = await axiosInstance.post(
      `/dashboard/alerts/api/generatePresignedURL`,
      {
        s3_url: videoUrl,
      }
    );
    return response.data.presigned_url;
  } catch (error) {
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const createProject = async (formData, siteid) => {
  siteid = parseInt(siteid);
  try {
    const response = await axiosInstance.post(`/project/projects`, {
      name: formData.projectName,
      site_id: siteid,
      description: "no description",
      usecase_type: formData.useCase,
      flows_type: formData.logics,
    });
    // console.log(response);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const getProject = async (id) => {
  try {
    const response = await axiosInstance.get(`/project/project/${id}`);
    // console.log(response);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const getProjects = async (id) => {
  if (isDevelopment) {
    // Return mock data in development
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(projects);
      }, 500); // Simulate network delay
    });
  } else {
    try {
      const response = await axiosInstance.get(
        `/project/api/projects/projects_card/${id}`
      );
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : new Error("Network Error");
    }
  }
};

export const createProjectStreams = async (id, streams) => {
  try {
    const response = await axiosInstance.post(`/project/addstream/${id}`, {
      streamsID: [streams],
    });
    // console.log(response);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const UpdateProject = async (formData, projectid) => {
  console.log(formData, projectid);
  try {
    const response = await axiosInstance.put(`/project/projects/${projectid}`, {
      name: formData.projectName,
      flows: formData.logics,
      streams: formData.streams,
    });
    // console.log(response);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const getProjectStreams = async (id) => {
  if (isDevelopment) {
    // Return mock data in development
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(projectStreams);
      }, 500); // Simulate network delay
    });
  } else {
    try {
      const response = await axiosInstance.get(
        `/project/fetchassociation/${id}`
      );
      // console.log(response);
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : new Error("Network Error");
    }
  }
};

export const startDetection = async (id) => {
  try {
    const response = await axiosInstance.post(
      `/project/api/projects/${id}/start-detection`,
      {}
    );
    // console.log(response);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const UpdateROI = async (id, points) => {
  try {
    const response = await axiosInstance.post(`/project/api/updateROI/${id}`, {
      roi: points,
    });
    // console.log(response);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const getVideos = async (projectId) => {
  if (isDevelopment) {
    // Return mock data in development
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(videos);
      }, 500); // Simulate network delay
    });
  } else {
    try {
      const response = await axiosInstance.get(
        `/project/api/fetchassociation/${projectId}`
      );
      // console.log(response);
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : new Error("Network Error");
    }
  }
};

// ProjectID int     `json:"project_id"`
// 	StreamURLs []string `json:"stream_urls"`
// 	Prompt    string  `json:"prompt"`

export const startDetectionSimulation = async (
  projectId,
  streamUrls,
  prompt
) => {
  if (isDevelopment) {
    // Return mock data in development
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ message: "Annotation request sent successfully" });
      }, 500); // Simulate network delay
    });
  } else {
    try {
      const response = await axiosInstance.post(
        `/annotations/api/${projectId}`,
        {
          prompt: prompt,
          streamsID: streamUrls,
          // project_id: projectId,
          // stream_urls: streamUrls,
          // prompt: prompt,
        }
      );
      // console.log(response);
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : new Error("Network Error");
    }
  }
};

export const getAllROIImages = async (page, pageSize, projectId) => {
  if (isDevelopment) {
    // Return mock data in development
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(roiImages);
      }, 500); // Simulate network delay
    });
  } else {
    try {
      // annotations/api/12345/objects?limit=10&page=1
      const response = await axiosInstance.get(
        `/annotations/api/${projectId}/objects?limit=${pageSize}&page=${page}`
      );
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : new Error("Network Error");
    }
  }
};

export const deleteROIImages = async (projectId, frameIds) => {
  try {
    const response = await axiosInstance.delete(
      `/annotations/api/${projectId}/frames`,
      {
        data: {
          frame_id: frameIds,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const trainROIImages = async (projectId) => {
  try {
    const response = await axiosInstance.post(
      `/annotations/api/${projectId}/start_training`
    );
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const getRequestStatus = async (id) => {
  try {
    const response = await axiosInstance.get(
      `/annotations/api/get_status/${id}`
    );
    // console.log(response);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const UpdateROIImages = async (projectId, stream) => {
  if (isDevelopment) {
    // Return mock data in development
    return 0;
  } else {
    try {
      console.log("Device", stream, projectId);
      const response = await axiosInstance.put(
        `/annotations/api/${projectId}/updateframe/${stream.frames[0].frame_id}`,
        stream.frames[0].objects
      );
      console.log(response);
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : new Error("Network Error");
    }
  }
};

export const testStreamModal = async (projectId, streamId) => {
  try {
    const response = await axiosInstance.post(
      `/annotations/api/${projectId}/start_testing/${streamId}`
    );
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const createMultipleRois = async (AssosId, rois, properties) => {
  console.log("roi+prop", rois, properties);
  try {
    const response = await axiosInstance.post(
      `/project/api/addROI/${AssosId}`,
      {
        rois,
        properties,
      }
    );
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const fetchMultipleRois = async (AssosId) => {
  try {
    const response = await axiosInstance.get(
      `/project/api/FetchROI/${AssosId}`
    );
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const fetchUsecase = async () => {
  try {
    const response = await axiosInstance.get(`workflows/api/getusecases`);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const fetchApplicationflows = async (id) => {
  try {
    const response = await axiosInstance.get(
      `/workflows/api/getflows/usecase/${id}`
    );
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const getHeatmapData = async () => {
  if (isDevelopment) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(MockHeatmapdata);
      }, 500);
    });
  } else {
    try {
      const response = await axiosInstance.get(
        `/dashboard/alerts/api/allAlertsbyAccount/1/1year`
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : new Error("Network Error");
    }
  }
};

export const deleteAssociations = async (id) => {
  try {
    const response = await axiosInstance.delete(
      `/project/api/deleteStream/${id}`
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const getStatusProject = async (id) => {
  try {
    const response = await axiosInstance.get(
      `annotations/api/project/${id}/status`
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const sendMail = async (mailData) => {
  try {
    const response = await axiosInstance.post(
      `notifications/notifications/send-mail`,
      mailData
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

const datemap = {
  Hours: "24hrs",
  Week: "1week",
  Month: "1month",
  Year: "1year",
};

export const GetSiteEvents = async (id, daterange) => {
  let timeframe = datemap[daterange] ? datemap[daterange] : daterange;
  console.log("timeframe", timeframe, daterange);
  if (isDevelopment) {
    // console.log("!!!!!!!!!!!!!!!!!!!!!!! daterange ", daterange);
    let data = {};
    if (daterange == "Hours") {
      // console.log("Hourssss");
      data = LineChartData;
    } else if (daterange == "Week") {
      data = LineChartDataWeek;
    } else if (daterange == "Month") {
      data = LineChartDataMonth;
    } else {
      data = LineChartDataYear;
    }
    return new Promise((resolve) => {
      setTimeout(() => {
        // console.log("!!!!!!!!!!!!!!!!!!!!!!! linechartdata ", data);
        resolve(LineChartData);
      }, 500);
    });
  } else {
    try {
      let timeframe = datemap[daterange] ? datemap[daterange] : daterange;
      const response = await axiosInstance.get(
        `dashboard/events/api/allEvents/${id}/${timeframe}`
      );
      // console.log(response.data);
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : new Error("Network Error");
    }
  }
};

export const StopProjectDetections = async (id) => {
  try {
    const response = await axiosInstance.post(
      `project/api/projects/${id}/stop-detection`,
      {}
    );
    console.log("stop detection", response);
    return response.data;
  } catch (error) {
    console.log("stop detection error", error);

    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const getLatestStatusOfDectetion = async (id) => {
  if (isDevelopment) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ status: "Running" });
      }, 500);
    });
  }

  try {
    const response = await axiosInstance.get(`project/api/latest-status/${id}`);
    // console.log(response.data);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const GetAlertTypeCount = async (siteId, duration) => {
  // For development, return mock data
  if(isDevelopment) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve([
          {
              "name": "Person Intrusion In Restricted Area",
              "frequency": 24
          },
          {
              "name": "Safety Gloves",
              "frequency": 2
          },
          {
              "name": "Safety Vests",
              "frequency": 32
          },
          {
              "name": "Safety Helmet",
              "frequency": 9
          },
          {
              "name": "Safety Boots",
              "frequency": 10
          }
      ]);
      }, 1000);
    });
  }  
  try {
    const response = await axiosInstance.get(`/dashboard/alerts/api/alertTypeCount/${siteId}/${duration}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching alert type count:', error);
    throw error;
  }
  
};
