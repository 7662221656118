import React, { useState } from "react";
import { createsiteApi } from "../../../services/apiServiceDetails";
import { useNavigate } from "react-router-dom";
import Button from "../../Button";
import useCustomToast from "../../../hooks/useToast";
import AddressInput from "../../AddressInputLoader";
import axios from "axios";

export const CreateSite = ({ isOpen, onClose, onSubmit }) => {
  //if (!isOpen) return null;
  const [siteName, setSiteName] = useState("");
  const [location, setLocation] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const showToast = useCustomToast();

  const handleClose = () => {
    setSiteName("");
    setLocation("");
    setDescription("");
    onClose();
  };
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      const googleApiKey = "AIzaSyCiHTXiHSC2O-LnDLZOypQ29DcKqUsx6O8";
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json`,
        {
          params: {
            address: location,
            key: googleApiKey,
          },
        }
      );
      if (response.data.results.length > 0) {
        const { lat, lng } = response.data.results[0].geometry.location;
        const latitude = lat.toString();
        const longitude = lng.toString();
        const accountID = parseInt(localStorage.getItem("account_id"));

        const data = await createsiteApi(
          siteName,
          location,
          description,
          latitude,
          longitude,
          accountID
        );
        console.log(data);
        if (data.success) {
          showToast("Site created successfully!", "success");
          localStorage.getItem("sites");
          let sites = JSON.parse(localStorage.getItem("sites"));
          sites.push(data.data);
          localStorage.setItem("sites", JSON.stringify(sites));
          navigate(`/site-details/${data.data.id}/projects`);
        } else if (data.duplicate) {
          showToast("Site already exists", "error");
        } else {
          showToast(data.message, "error");
        }
        onSubmit();
      }
    } catch (error) {
      console.log(error);
      showToast("Failed to Add New Site", "error");
    } finally {
      setLoading(false);
    }
  };
  if (!isOpen) return null;
  // console.log(isOpen)
  return (
    <div
      id="create-site-modal"
      className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden"
    >
      <div
        className="absolute inset-0 bg-black opacity-50 backdrop-blur-sm"
        onClick={onClose}
        data-testid="modal-overlay"
      ></div>

      <div className="relative w-full max-w-md px-4 py-10">
        <div
          className="bg-white rounded-2xl shadow-xl p-8"
          data-testid="create-site-form"
        >
          <div className="flex items-start justify-between border-b border-gray-200 pb-6">
            <div className="flex flex-col items-start">
              <h3 className="text-3xl font-semibold tracking-tighter text-gray-900">
                Add New Site
              </h3>
              <p className="text-sm text-gray-600 mt-2">
                Please enter the following details
              </p>
            </div>
            <button
              onClick={handleClose}
              className="text-gray-400 hover:text-gray-900 p-2 rounded-full transition-colors"
              aria-label="Close"
              data-testid="close-button"
            >
              <svg
                className="w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          <form onSubmit={handleSubmit} className="mt-5 space-y-6">
            <div>
              <label
                htmlFor="siteName"
                className="block text-sm font-medium text-gray-700"
              >
                Site Name <span className="text-red-600">*</span>
              </label>
              <input
                type="text"
                id="siteName"
                value={siteName}
                onChange={(e) => setSiteName(e.target.value)}
                placeholder="Enter site name"
                className="mt-1 w-full px-4 py-3 border rounded-lg border-gray-300 focus:ring-blue-500 focus:border-blue-500 transition-shadow focus:shadow-lg"
                required
                data-testid="input-site-name"
              />
            </div>

            <div>
              <label
                htmlFor="location"
                className="block text-sm font-medium text-gray-700"
              >
                Location <span className="text-red-600">*</span>
              </label>
              <div className="relative">
                <input
                  type="text"
                  id="location"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  placeholder="Search location"
                  className="mt-1 w-full px-4 py-3 border rounded-lg border-gray-300 focus:ring-blue-500 focus:border-blue-500 transition-shadow focus:shadow-lg"
                  required
                  data-testid="input-location"
                />
                <svg
                  className="absolute right-4 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </div>
            </div>
            {/* <div>
              <AddressInput setLocation={setLocation} />
            </div> */}

            <div>
              <label
                htmlFor="description"
                className="block text-sm font-medium text-gray-700"
              >
                Description
              </label>
              <textarea
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Type here..."
                className="mt-1 w-full px-4 py-3 border rounded-lg border-gray-300 focus:ring-blue-500 focus:border-blue-500 transition-shadow focus:shadow-lg min-h-[10rem]"
                data-testid="input-description"
              />
            </div>
            <Button
              text="Add Site"
              testId="submit-button"
              onClick={handleSubmit}
            />
          </form>
        </div>
      </div>
    </div>
  );
};
