// axiosInstance.js

import axios from "axios";
import { decrypt } from "../utils/encryption";

// Create an instance of Axios
const axiosInstance = axios.create({
  // baseURL: "http://web.lumeneo.ai/services",
  baseURL: process.env.REACT_APP_BASE_URL,
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Retrieve the token from localStorage or any other storage
    // const token = sessionStorage.getItem("accessToken");
    let session = localStorage.getItem("session");
    if (!session) {
      return config;
    }
    const token = JSON.parse(decrypt(session));
    if (token) {
      config.headers.Authorization = `${token.token}`;
    }

    return config;
  },
  (error) => {
    // Handle the error
    return Promise.reject(error);
  }
);

export default axiosInstance;
