import { combineReducers } from "redux";
import userReducer from "../reducers/userReducer";
import siteReducer from "../reducers/siteReducer";
import trainingStatusReducer from "../reducers/trainingStatusReducer";
import envReducer from "../reducers/envReducer";
// import reportsReducer from '../reducers/reportsSlice';

const rootReducer = combineReducers({
  user: userReducer,
  siteDetails: siteReducer,
  trainingStatus: trainingStatusReducer,
  env: envReducer,
  // reports: reportsReducer,
});

export default rootReducer;
