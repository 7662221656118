import React, { useState, useEffect, useMemo } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { format, formatDate, isDate, set } from "date-fns";
import Button from "../../components/Button";
import ReportForm from "../../components/Modal/ReportIncident/ReportForm";
import PreviewReport from "../../components/Modal/ReportIncident/Forms/PreviewReport";
import VideoPlayerModal from "../../components/Modal/VideoPlayerModal";
import TableLoader from "../../components/TableLoader";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useCustomToast from "../../hooks/useToast";
import {
  getReportsSummary,
  deleteReport,
  getReportDetails,
} from "../../services/reportService";
import {
  Eye,
  Pencil,
  Trash2,
  BarChart,
  Activity,
  FileText,
  Loader2,
} from "lucide-react";
import BarChartIcon from "@mui/icons-material/BarChart";
import Select from "react-select";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import Sidebar from "../../components/Sidebar";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import {
  fetchPresignedUrl,
  getAlertsBySiteId,
  getAllSites,
} from "../../services/apiServiceDetails";
import { setSiteNames } from "../../redux/actions/siteActions";
import { s } from "framer-motion/m";
import { formaOccurrenceTime, processSiteData } from "../../utils/constants";
import { ffmpegService } from "../../services/ffmpeg";
import { Report } from "@mui/icons-material";

const STATUS_COLORS = {
  completed: "bg-green-100 text-green-800",
  draft: "bg-yellow-100 text-yellow-800",
  inProgress: "bg-blue-100 text-blue-800",
};

const SEVERITY_COLORS = {
  low: "bg-blue-50 text-blue-600",
  medium: "bg-yellow-50 text-yellow-600",
  high: "bg-red-50 text-red-600",
};

const ReportsDashboard = () => {
  const navigate = useNavigate();
  const showToast = useCustomToast();
  const user = useSelector((state) => state.user);
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedReport, setSelectedReport] = useState(null);
  const [showNewReportForm, setShowNewReportForm] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterStatus, setFilterStatus] = useState("all");
  const [selectedTab, setSelectedTab] = useState("All Reports");
  const [selectedDateRange, setSelectedDateRange] = useState("Week");
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedSeverity, setSelectedSeverity] = useState(null);
  const [selectedSite, setSelectedSite] = useState(null);
  const [selectedUseCase, setSelectedUseCase] = useState(null);
  const [selectedTimeframe, setSelectedTimeframe] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [alerts, setAlerts] = useState([]);
  const [showAlerts, setShowAlerts] = useState(false);
  const [selectedAlerts, setSelectedAlerts] = useState([]);
  const [useCases, setUseCases] = useState([]);
  const [selectedIncidentType, setSelectedIncidentType] = useState(null);
  const [alertMap, setAlertMap] = useState({});
  const [alertDataMap, setAlertDataMap] = useState({});
  const [selectedAlertType, setSelectedAlertType] = useState(null);
  const [filteredAlerts, setFilteredAlerts] = useState([]);
  const sites = useSelector((state) => state.siteDetails).names;
  const [incidentTypes, setIncidentTypes] = useState([]);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [currentVideoUrl, setCurrentVideoUrl] = useState("");
  const [allSites, setallsites] = useState(sites);
  const dispatch = useDispatch();

  const statusOptions = [
    { value: "all", label: "All Status" },
    { value: "completed", label: "Completed" },
    { value: "draft", label: "Draft" },
  ];

  const severityOptions = [
    { value: "all", label: "All Severity" },
    { value: "high", label: "High" },
    { value: "medium", label: "Medium" },
    { value: "low", label: "Low" },
  ];

  const [loadingSites, setLoadingSites] = useState(false);
  const [loadingTimeframes, setLoadingTimeframes] = useState(false);
  const [loadingUseCases, setLoadingUseCases] = useState(false);
  const [loadingAlertTypes, setLoadingAlertTypes] = useState(false);

  useEffect(() => {
    fetchReports();
  }, []);

  useEffect(() => {
    const fetchSites = async () => {
      try {
        setLoadingSites(true);
        const accountID = localStorage.getItem("account_id");
        if (!accountID) {
          console.error("No account ID found");
          return;
        }

        const data = await getAllSites(accountID);
        if (data && data.length > 0) {
          dispatch(setSiteNames(data));
          localStorage.setItem("sites", JSON.stringify(data));
          setallsites(data);
        } else {
          console.warn("No sites found for the account");
        }
      } catch (error) {
        console.error("Error fetching sites:", error);
      } finally {
        setLoadingSites(false);
      }
    };

    if (!sites || sites.length === 0) {
      const storedSites = JSON.parse(localStorage.getItem("sites"));
      if (storedSites && storedSites.length > 0) {
        dispatch(setSiteNames(storedSites));
        setallsites(storedSites);
      } else {
        fetchSites();
      }
    } else {
      setallsites(sites);
    }
  }, [sites, dispatch]);

  const timeframeOptions = [
    { value: "24hrs", label: "24 Hours" },
    { value: "1week", label: "This Week" },
    { value: "1month", label: "This Month" },
    { value: "1year", label: "This Year" },
  ];

  const customSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: "#052D61",
      boxShadow: state.isFocused ? "0 0 0 1px #052D61" : "none",
      "&:hover": {
        borderColor: "#052D61",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#052D61"
        : state.isFocused
        ? "#dbeafe"
        : "white",
      color: state.isSelected ? "white" : "#374151",
      "&:hover": {
        backgroundColor: state.isSelected ? "#052D61" : "#dbeafe",
      },
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#dbeafe",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "#052D61",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "#052D61",
      "&:hover": {
        backgroundColor: "#052D61",
        color: "white",
      },
    }),
  };

  const formattedSiteOptions = useMemo(() => {
    return sites.map((site) => ({
      value: site.id,
      label: site.name,
    }));
  }, [sites]);

  const handleSiteAndTimeframeSelect = async (siteId, timeframe) => {
    try {
      setLoadingTimeframes(true);
      const response = await getAlertsBySiteId(siteId, timeframe);
      const {
        useCases: newUseCases,
        newAlertMap,
        newAlertDataMap,
      } = await processSiteData(response);
      setUseCases(newUseCases);
      setAlertMap(newAlertMap);
      setAlertDataMap(newAlertDataMap);
      setSelectedUseCase(null);
      setSelectedAlertType(null);
      setFilteredAlerts([]);
    } catch (error) {
      console.error("Error fetching alerts:", error);
      showToast("Failed to fetch alerts", "error");
    } finally {
      setLoadingTimeframes(false);
    }
  };

  useEffect(() => {
    if (selectedUseCase) {
      const alertTypes = alertMap[selectedUseCase.value] || [];
      setLoadingUseCases(true);
      setIncidentTypes(alertTypes);
      setSelectedAlertType(null);
      setFilteredAlerts([]);
      setLoadingUseCases(false);
    }
  }, [selectedUseCase, alertMap]);

  useEffect(() => {
    if (selectedAlertType) {
      const alerts = alertDataMap[selectedAlertType.value] || [];
      setLoadingAlertTypes(true);
      setFilteredAlerts(alerts);
      setLoadingAlertTypes(false);
    }
  }, [selectedAlertType, alertDataMap]);

  useEffect(() => {
    fetchReports();
  }, []);

  const fetchReports = async () => {
    try {
      const data = await getReportsSummary();
      if (data) {
        // console.log(data);
        const transformedData = data.map((report) => ({
          ...report,
          id: report.incidentId,
          title: report.incidentType,
        }));
        setReports(transformedData);
      } else {
        setReports([]);
      }
    } catch (error) {
      console.error("Error fetching reports:", error);
      showToast("Failed to fetch reports", "error");
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteReport = async (incidentId) => {
    if (window.confirm("Are you sure you want to delete this report?")) {
      try {
        await deleteReport(incidentId);
        showToast("Report deleted successfully", "success");
        fetchReports();
      } catch (error) {
        console.error("Error deleting report:", error);
        showToast("Failed to delete report", "error");
      }
    }
  };

  const handleCreateNewReport = () => {
    setShowFilters(true);
  };

  const handleApplyFilters = async () => {
    if (selectedSite && selectedTimeframe) {
      setLoading(true);
      try {
        await getAlertsBySiteId(selectedSite.value, selectedTimeframe.value);
        setShowAlerts(true);
      } catch (error) {
        showToast("Failed to fetch alerts", "error");
      } finally {
        setLoading(false);
      }
    } else {
      showToast("Please select site and timeframe", "error");
    }
  };

  const handleAlertSelect = (alert) => {
    if (selectedAlerts.includes(alert)) {
      setSelectedAlerts([]);
      setSelectedReport(null);
    } else {
      setSelectedAlerts([alert]);
      console.log(alert);
      setSelectedReport({
        incidentId: alert.alert_id,
        incidentType: alert.alert_type,
        occurrenceTime: alert.created_at,
        occurrenceLocation: selectedSite?.label,
        description: alert.description,
        incidentClip: alert.violation_clip_URL,
      });
    }
  };

  const handleCreateReport = () => {
    if (selectedAlerts.length > 0) {
      setShowNewReportForm(true);
      setShowAlerts(false);
    } else {
      showToast("Please select at least one alert", "error");
    }
  };

  const handlePreviewReport = async (report) => {
    const reportDetails = await getReportDetails(report.incidentId);
    setSelectedReport(reportDetails);
    setShowPreview(true);
  };

  const handleEditReport = async (incidentId) => {
    try {
      const reportDetails = await getReportDetails(incidentId);
      if (reportDetails) {
        setSelectedReport(reportDetails);
        setShowNewReportForm(true);
      } else {
        showToast("Report not found", "error");
      }
    } catch (error) {
      console.error("Error fetching report details:", error);
      showToast("Failed to fetch report details", "error");
    }
  };

  const handleBackClick = () => {
    navigate("/home");
  };

  const navItems = [
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/de3b8b5271d8bc1e88c6056582e69f592845a7ad580a06c9846c637a3015e288?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde",
      text: "Active Sites",
      active: false,
      navigate: () => navigate("/home"),
    },
    {
      icon: <BarChartIcon />,
      text: "Reporting Dashboard",
      active: false,
      navigate: () => navigate("/dashboard"),
    },
    {
      icon: <AutoGraphIcon />,
      text: "Operational Dashboard",
      active: false,
      linkTo: "/six-sigma-chart",
      navigate: () => navigate("/six-sigma-chart"),
    },
    {
      icon: <Report size={24} />,
      text: "Incident Report",
      active: true,
      linkTo: "/reports",
      navigate: () => navigate("/reports"),
    },
  ];

  const resetFilters = () => {
    setSelectedStatus(null);
    setSelectedSeverity(null);
    setSelectedTab("All Reports");
    setSelectedSite(null);
    setSelectedUseCase(null);
    setSelectedTimeframe(null);
    setSelectedAlerts([]);
    setSearchQuery("");
    setShowFilters(false);
    setShowAlerts(false);
    setFilteredAlerts([]);
    setSelectedIncidentType(null);
    setSelectedAlertType(null);
    setSelectedReport(null);
    setShowPreview(false);
    setShowNewReportForm(false);
  };

  const filteredReports = useMemo(() => {
    if (
      !searchQuery &&
      (!selectedStatus || selectedStatus.value === "all") &&
      (!selectedSeverity || selectedSeverity.value === "all") &&
      selectedTab === "All Reports"
    ) {
      return reports;
    }

    return reports.filter((report) => {
      const matchesSearch =
        !searchQuery ||
        report?.title?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        report?.id?.toLowerCase().includes(searchQuery.toLowerCase());
      const matchesStatus =
        !selectedStatus ||
        selectedStatus.value === "all" ||
        report.status === selectedStatus.value;
      const matchesSeverity =
        !selectedSeverity ||
        selectedSeverity.value === "all" ||
        report.severity === selectedSeverity.value;
      const matchesTab =
        selectedTab === "All Reports" ||
        report.status === selectedTab.toLowerCase().replace(" ", "");
      return matchesSearch && matchesStatus && matchesSeverity && matchesTab;
    });
  }, [reports, searchQuery, selectedStatus, selectedSeverity, selectedTab]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setSelectedUseCase(null);
    setUseCases([]);
    setAlerts([]);
    setSelectedIncidentType(null);
  }, [selectedSite, selectedTimeframe]);

  const handleViewClip = async (e, clipUrl) => {
    e.stopPropagation();
    const formattedUrl = await fetchPresignedUrl(clipUrl);
    setCurrentVideoUrl(formattedUrl);
    setShowVideoModal(true);
  };

  return (
    <div className="h-screen w-screen bg-gray-100 overflow-hidden">
      <div className="flex h-full">
        <Sidebar
          navItems={navItems}
          userName={user.name}
          usermail={user.email}
          currentPage="Incident Report"
          selectData={localStorage.getItem("projectname")}
          handleBackClick={handleBackClick}
        />
        <main className="flex-1 p-2 sm:p-4 md:p-6 overflow-auto">
          <div className="bg-white rounded-xl shadow-sm min-h-[calc(100vh-3rem)] flex flex-col">
            <div className="px-3 sm:px-4 md:px-6 py-4 border-b border-gray-200">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 sm:gap-0">
                <h1
                  className="text-2xl sm:text-3xl font-semibold tracking-tighter leading-tight text-gray-900"
                  data-testid="dashboard-title"
                >
                  Incident Report
                </h1>
                <div>
                  <Button
                    onClick={handleCreateNewReport}
                    text="Create New Report"
                    variant="primary"
                  />
                </div>
              </div>
            </div>

            <div className="flex-1 p-3 sm:p-4 md:p-6 flex flex-col">
              <div className="flex flex-col md:flex-row gap-3 md:gap-4 mb-6 justify-end">
                <input
                  type="text"
                  placeholder="Search reports..."
                  className="w-full md:w-1/5 px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <Select
                  options={statusOptions}
                  styles={customSelectStyles}
                  className="w-full md:w-1/5"
                  placeholder="Filter by status"
                  value={selectedStatus}
                  onChange={setSelectedStatus}
                />
                {/* <Select
                  options={severityOptions}
                  styles={customSelectStyles}
                  className="w-full md:w-1/5"
                  placeholder="Filter by severity"
                  value={selectedSeverity}
                  onChange={setSelectedSeverity}
                /> */}
              </div>

              <div className="flex flex-col h-full">
                <div className="flex-1 overflow-hidden">
                  <div className="h-full flex flex-col bg-white rounded-lg shadow-sm">
                    <div className="h-[calc(100vh-300px)] sm:h-[calc(100vh-300px)] md:h-[calc(100vh-270px)] w-full">
                      <DataGrid
                        rows={filteredReports}
                        columns={[
                          {
                            field: "id",
                            headerName: "ID",
                            flex: 1,
                            headerClassName: "font-semibold",
                          },
                          {
                            field: "title",
                            headerName: "Title",
                            flex: 1,
                            headerClassName: "font-semibold",
                          },
                          {
                            field: "status",
                            headerName: "Status",
                            flex: 1,
                            headerClassName: "font-semibold",
                            renderCell: (params) => (
                              <span
                                className={`capitalize ${
                                  STATUS_COLORS[params.value]
                                } px-2 py-1 rounded-full`}
                              >
                                {params.value}
                              </span>
                            ),
                          },
                          // {
                          //   field: 'createdAt',
                          //   headerName: 'Report Creation Time',
                          //   flex: 1,
                          //   headerClassName: 'font-semibold',
                          //   renderCell: (params) => (
                          //     <span className="capitalize">
                          //       { isDate(params.value) && format(params.value, 'MMM dd, yyyy HH:mm')}
                          //     </span>
                          //   )
                          // },
                          {
                            field: "occurrenceTime",
                            headerName: "Incident Occurrence Time",
                            flex: 1,
                            headerClassName: "font-semibold",
                            renderCell: (params) => (
                              <span className="capitalize">{formaOccurrenceTime(params.value)}</span>
                            ),
                          },
                          {
                            field: "occurrenceLocation",
                            headerName: "Location",
                            flex: 1,
                            headerClassName: "font-semibold",
                          },
                          {
                            field: "actions",
                            headerName: "Actions",
                            flex: 1,
                            headerClassName: "font-semibold",
                            type: "actions",
                            getActions: (params) => [
                              <GridActionsCellItem
                                icon={<Eye className="h-4 w-4" />}
                                label="Preview"
                                onClick={() => handlePreviewReport(params.row)}
                                sx={{ color: "primary.main" }}
                              />,
                              <GridActionsCellItem
                                icon={<Pencil className="h-4 w-4" />}
                                label="Edit"
                                onClick={() => handleEditReport(params.row.id)}
                                sx={{ color: "success.main" }}
                              />,
                              <GridActionsCellItem
                                icon={<Trash2 className="h-4 w-4" />}
                                label="Delete"
                                onClick={() =>
                                  handleDeleteReport(params.row.id)
                                }
                                sx={{ color: "error.main" }}
                              />,
                            ],
                          },
                        ]}
                        getRowId={(row) => row.id}
                        initialState={{
                          pagination: {
                            paginationModel: { pageSize: 10, page: 0 },
                          },
                          pinnedColumns: {
                            left: ["desk"],
                          },
                        }}
                        pageSizeOptions={[5, 10, 25]}
                        disableSelectionOnClick
                        loading={loading}
                        components={{
                          LoadingOverlay: TableLoader,
                          NoRowsOverlay: () => (
                            <div className="flex items-center justify-center h-full">
                              <span className="text-gray-500">
                                No reports to show
                              </span>
                            </div>
                          ),
                        }}
                        slotProps={{
                          loadingOverlay: {
                            variant: "skeleton",
                            noRowsVariant: "skeleton",
                          },
                        }}
                        sx={{
                          border: "none",
                          "& .MuiDataGrid-cell": {
                            borderBottom: "1px solid #f3f4f6",
                          },
                          "& .MuiDataGrid-columnHeaders": {
                            backgroundColor: "#f9fafb",
                            borderBottom: "2px solid #e5e7eb",
                          },
                          "& .MuiDataGrid-row:hover": {
                            backgroundColor: "#f9fafb",
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>

      <AnimatePresence>
        {showFilters && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
          >
            <div className="bg-white rounded-xl shadow-xl w-[90vw] max-w-[1200px] max-h-[90vh] overflow-hidden">
              <div className="px-6 py-4 border-b border-gray-200 bg-gray-50">
                <div className="flex justify-between items-center">
                  <h2 className="text-2xl font-semibold text-gray-900">
                    Create Incident Report
                  </h2>
                  <button
                    onClick={() => {
                      resetFilters();
                    }}
                    className="text-gray-500 hover:text-gray-700 transition-colors"
                  >
                    <svg
                      className="w-6 h-6"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>

              <div className="flex h-[calc(90vh-4rem)]">
                {/* Left Panel - Filters */}
                <div className="w-1/3 border-r border-gray-200 p-6 space-y-6 overflow-y-auto">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Site
                    </label>
                    <Select
                      options={formattedSiteOptions}
                      value={selectedSite}
                      onChange={(selected) => {
                        setSelectedSite(selected);
                        setSelectedTimeframe(null);
                        setSelectedUseCase(null);
                        setSelectedAlertType(null);
                        setUseCases([]);
                        setIncidentTypes([]);
                        setFilteredAlerts([]);
                      }}
                      placeholder="Select Site"
                      styles={customSelectStyles}
                      className="w-full"
                      isLoading={loadingSites}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Timeframe
                    </label>
                    <Select
                      options={timeframeOptions}
                      value={selectedTimeframe}
                      onChange={(selected) => {
                        setSelectedTimeframe(selected);
                        if (selectedSite) {
                          handleSiteAndTimeframeSelect(
                            selectedSite.value,
                            selected.value
                          );
                        }
                      }}
                      placeholder="Select Timeframe"
                      styles={customSelectStyles}
                      className="w-full"
                      isDisabled={!selectedSite}
                      isLoading={loadingTimeframes}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Use Case
                    </label>
                    <Select
                      options={useCases}
                      value={selectedUseCase}
                      onChange={setSelectedUseCase}
                      placeholder="Select Use Case"
                      styles={customSelectStyles}
                      className="w-full"
                      isDisabled={!selectedTimeframe || !selectedSite}
                      isLoading={loadingUseCases}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Alert Type
                    </label>
                    <Select
                      options={incidentTypes}
                      value={selectedAlertType}
                      onChange={setSelectedAlertType}
                      placeholder="Select Alert Type"
                      styles={customSelectStyles}
                      className="w-full"
                      isDisabled={!selectedUseCase}
                      isLoading={loadingAlertTypes}
                    />
                  </div>
                </div>

                {/* Right Panel - Alerts */}
                <div className="w-2/3 p-6 flex flex-col">
                  <div className="mb-4">
                    <h3 className="text-lg font-medium text-gray-900">
                      Alerts{" "}
                      {filteredAlerts.length > 0 &&
                        `(${filteredAlerts.length})`}
                    </h3>
                    <p className="text-sm text-gray-500 mt-1">
                      Select alert to create incident report
                    </p>
                  </div>

                  <div
                    className="flex-1 overflow-y-auto"
                    style={{ maxHeight: "60vh" }}
                  >
                    {loading ? (
                      <div className="flex items-center justify-center h-full">
                        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
                      </div>
                    ) : filteredAlerts.length === 0 ? (
                      <div className="flex flex-col items-center justify-center h-full text-gray-500">
                        <svg
                          className="w-12 h-12 mb-4"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M12 12h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                        <p>No alerts found</p>
                      </div>
                    ) : (
                      <div className="grid grid-cols-1 gap-3 mt-1 mx-1">
                        {filteredAlerts.map((alert) => (
                          <div
                            key={`${alert.alert_id}-${alert.created_at}`}
                            className={`p-3 rounded-lg border cursor-pointer transition-all hover:shadow-md ${
                              selectedAlerts.includes(alert)
                                ? "border-primary bg-blue-50 ring-1 ring-primary"
                                : "border-gray-200 hover:border-primary"
                            }`}
                            onClick={() => handleAlertSelect(alert)}
                          >
                            <div className="flex items-start justify-between">
                              <div className="flex-1">
                                <div className="flex items-center justify-between gap-2 mb-1">
                                  <h4 className="font-medium text-gray-900 text-sm">
                                    {alert.alert_type}
                                  </h4>
                                  {alert.violation_clip_URL && (
                                    <button
                                      onClick={(e) =>
                                        handleViewClip(
                                          e,
                                          alert.violation_clip_URL
                                        )
                                      }
                                      className="inline-flex items-center px-2 py-1 text-xs font-medium text-primary bg-blue-100 rounded-full hover:bg-blue-200 transition-colors"
                                    >
                                      <svg
                                        className="w-3 h-3 mr-1"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth={2}
                                          d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
                                        />
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth={2}
                                          d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                        />
                                      </svg>
                                      View Clip
                                    </button>
                                  )}
                                </div>
                                <p className="text-sm text-gray-600 mb-1">
                                  {alert.description}
                                </p>
                                <div className="flex items-center gap-3 text-xs text-gray-500">
                                  <span className="flex items-center gap-1">
                                    <svg
                                      className="w-3 h-3"
                                      fill="none"
                                      stroke="currentColor"
                                      viewBox="0 0 24 24"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                                      />
                                    </svg>
                                    {isDate(alert.created_at) &&
                                      format(
                                        new Date(alert.created_at),
                                        "MMM dd, yyyy HH:mm"
                                      )}
                                  </span>
                                  {/* {alert.person_id && (
                                    <span className="flex items-center gap-1">
                                      <svg className="w-3 h-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                                      </svg>
                                      ID: {alert.person_id}
                                    </span>
                                  )} */}
                                </div>
                              </div>
                              {selectedAlerts.includes(alert) && (
                                <div className="text-primary">
                                  <svg
                                    className="w-5 h-5"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                </div>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>

                  {/* Footer Actions */}
                  <div className="mt-4 flex justify-between items-center border-t pt-4">
                    <div className="text-sm text-gray-600">
                      {/* {selectedAlerts.length} alert{selectedAlerts.length !== 1 ? 's' : ''} selected */}
                    </div>
                    <div className="flex gap-3">
                      <Button
                        onClick={() => setShowFilters(false)}
                        text="Cancel"
                        variant="outline"
                      />
                      <Button
                        onClick={handleCreateReport}
                        text="Create Report"
                        disabled={selectedAlerts.length === 0}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <VideoPlayerModal
        isOpen={showVideoModal}
        onClose={() => setShowVideoModal(false)}
        videoUrl={currentVideoUrl}
      />
      {console.log(selectedReport)}
      {showNewReportForm && (
        <ReportForm
          isOpen={showNewReportForm}
          onClose={() => {
            setShowNewReportForm(false);
            setSelectedReport(null);
            resetFilters();
          }}
          // initialData={
          //   {
          //     ...selectedReport,
          //     incidentId: selectedReport?.alertId || selectedReport?.incidentId,
          //     incidentType: selectedReport?.alertType || selectedReport?.incidentType,
          //     occurrenceTime: selectedReport?.createdAt || selectedReport?.occurrenceTime,
          //     occurrenceLocation: selectedSite?.label || selectedReport?.location,
          //     description: selectedReport?.description || selectedReport?.description,
          //     incidentClip: selectedReport?.violation_video_url || selectedReport?.incidentClip,

          //   }
          // }
          initialData={selectedReport}
          heading={
            selectedReport
              ? `Edit Report ${selectedReport?.incidentId}`
              : `Incident Report Generation for ${selectedReport?.alertId}, ${selectedReport?.alertType}, ${selectedSite?.label}`
          }
          siteid={selectedSite?.value}
        />
      )}

      {showPreview && selectedReport && (
        <PreviewReport
          isOpen={showPreview}
          onClose={() => {
            setShowPreview(false);
            setSelectedReport(null);
          }}
          formData={selectedReport}
        />
      )}
    </div>
  );
};

export default ReportsDashboard;
