import { createStore, applyMiddleware } from "redux";
import rootReducer from "./rootReducer";
import localStorageMiddleware from "../../utils/localStorageMIddleware";

const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  //   applyMiddleware(localStorageMiddleware)
);

export default store;
