import React, { useState, useEffect } from 'react';
import { getIncidentRootNode, getNodeNeighbors } from '../api/rcaData';
import { motion, AnimatePresence } from 'framer-motion';
import { Loader2, AlertCircle, ChevronRight } from 'lucide-react';
import Select from 'react-select';

const LoadingSpinner = () => (
  <motion.div 
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    className="flex items-center justify-center w-full h-[200px] bg-white/50 rounded-lg"
  >
    <div className="flex items-center space-x-3">
      <Loader2 className="w-6 h-6 text-blue-500 animate-spin" />
      <span className="text-gray-600 font-medium">Loading next question...</span>
    </div>
  </motion.div>
);

const ErrorMessage = ({ message }) => (
  <motion.div 
    initial={{ opacity: 0, y: -10 }}
    animate={{ opacity: 1, y: 0 }}
    className="flex items-center p-4 mb-4 bg-red-50 border-l-4 border-red-500 rounded-r"
  >
    <AlertCircle className="w-5 h-5 text-red-500 mr-3" />
    <span className="text-red-700">{message}</span>
  </motion.div>
);

const QuestionCard = ({ question, onSelect, selectedAnswer, isActive, isLoading }) => {
  const options = question.children?.map(answer => ({
    value: answer.id,
    label: answer.content
  }));

  const value = selectedAnswer ? {
    value: selectedAnswer.id,
    label: selectedAnswer.content
  } : null;

  const customStyles = {
    control: (base, state) => ({
      ...base,
      minHeight: '42px',
      borderRadius: '0.375rem',
      borderColor: state.isFocused ? '#2563eb' : '#d1d5db',
      boxShadow: state.isFocused ? '0 0 0 1px #2563eb' : 'none',
      '&:hover': {
        borderColor: state.isFocused ? '#2563eb' : '#9ca3af'
      }
    }),
    menu: (base) => ({
      ...base,
      zIndex: 9999
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? '#2563eb' : state.isFocused ? '#f3f4f6' : 'white',
      color: state.isSelected ? 'white' : '#111827',
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      '&:active': {
        backgroundColor: '#2563eb',
        color: 'white'
      }
    }),
    input: (base) => ({
      ...base,
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      color: '#111827'
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      color: '#6b7280'
    }),
    singleValue: (base) => ({
      ...base,
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      color: '#111827'
    })
  };

  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="w-full md:w-[calc(50%-0.75rem)] relative"
    >
      <div className={`p-3 bg-white rounded-lg shadow-sm mb-4 ${isActive ? 'border-2 border-blue-500' : ''}`}>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          {question.content}
        </label>
        <Select
          value={value}
          onChange={(selected) => onSelect(question.id, selected.value)}
          options={options}
          isDisabled={isLoading}
          className="w-full"
          classNamePrefix="rca-select"
          placeholder="Select an answer..."
          styles={customStyles}
        />
      </div>
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-white/50 rounded-lg backdrop-blur-sm">
          <LoadingSpinner />
        </div>
      )}
    </motion.div>
  );
};

const RCASection = ({ incidentType, onChange, initialState }) => {
  const [rcaQuestionSets, setRcaQuestionSets] = useState(initialState?.questionSets || []);
  const [rcaAnswers, setRcaAnswers] = useState(initialState?.answers || {});
  const [rcaHistory, setRcaHistory] = useState(initialState?.history || []);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [initializing, setInitializing] = useState(true);

  // Initialize RCA when incident type changes and no initial state
  useEffect(() => {
    const initializeRCA = async () => {
      if (!incidentType) {
        console.log('No incident type provided');
        setInitializing(false);
        return;
      }

      if (initialState?.questionSets?.length > 0) {
        console.log('Using initial state:', initialState);
        setInitializing(false);
        return;
      }

      setInitializing(true);
      setError(null);
      
      try {
        // Get the root node for this incident type
        const rootNode = await getIncidentRootNode(incidentType);
        console.log('Got root node:', rootNode);

        if (!rootNode || !rootNode.id) {
          console.error('Invalid root node returned');
          return;
        }

        // Get the actual RCA questions
        const questions = await getNodeNeighbors(rootNode.id);
        console.log('Got RCA questions:', questions);

        if (!questions || !questions.length) {
          console.error('No questions returned');
          return;
        }

        // Initialize with only the first question
        const newQuestionSets = [{
          questionId: questions[0].id,
          questions: [questions[0]]
        }];

        console.log('Setting initial question set:', newQuestionSets);

        // Add root cause to history
        const newHistory = [{
          id: rootNode.id,
          content: rootNode.content,
          type: rootNode.type
        }];

        setRcaQuestionSets(newQuestionSets);
        setRcaAnswers({});
        setRcaHistory(newHistory);
        
        // Notify parent component
        notifyParent(newHistory, newQuestionSets, {});
      } catch (error) {
        console.error('Error initializing RCA:', error);
        setError('No questions available');
      } finally {
        setInitializing(false);
      }
    };

    initializeRCA();
  }, [incidentType, initialState]);

  const notifyParent = (history, questionSets, answers) => {
    onChange(
      history?.map(item => item.content),
      {
        history,
        questionSets,
        answers
      }
    );
  };

  const handleRCASelection = async (questionId, answerId) => {
    const questionSetIndex = rcaQuestionSets.findIndex(set => set.questionId === questionId);
    if (questionSetIndex === -1) return;

    setLoading(true);
    setError(null);

    try {
      console.log('Handling RCA selection for questionId:', questionId, 'answerId:', answerId);
      
      const question = rcaQuestionSets[questionSetIndex].questions[0];
      const selectedAnswer = question.children.find(answer => answer.id === answerId);

      if (!selectedAnswer) {
        console.error('Answer not found:', answerId);
        return;
      }

      console.log('Selected answer:', selectedAnswer);

      // Get next questions for this answer
      const neighbors = await getNodeNeighbors(answerId);
      console.log('Got neighbors for answer:', neighbors);
      
      // Update answers state
      const newAnswers = {
        ...rcaAnswers,
        [questionId]: {
          id: answerId,
          content: selectedAnswer.content
        }
      };
      setRcaAnswers(newAnswers);

      // Update history
      const newHistory = [
        ...rcaHistory.slice(0, questionSetIndex + 1),
        selectedAnswer
      ];
      setRcaHistory(newHistory);

      // Update question sets - show only the next question if available
      if (neighbors && neighbors.length > 0) {
        const newQuestionSets = [
          ...rcaQuestionSets.slice(0, questionSetIndex + 1),
          {
            questionId: neighbors[0].id,
            questions: [neighbors[0]]
          }
        ];
        setRcaQuestionSets(newQuestionSets);
      }
      
      // Notify parent with all state updates
      notifyParent(newHistory, rcaQuestionSets, newAnswers);
    } catch (error) {
      console.error('Error processing RCA selection:', error);
      setError('Failed to process selection');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="space-y-6">
      {error && <ErrorMessage message={error} />}

      {rcaHistory.length > 1 && (
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="p-3 bg-gray-50 rounded-lg"
        >
          <h4 className="text-sm font-semibold text-gray-600 mb-2">Root Cause Analysis</h4>
          <div className="flex flex-wrap gap-2">
            {rcaHistory.map((item, index) => (
              <div key={item.id} className="flex items-center">
                <span className="px-3 py-1 bg-white rounded-full text-sm text-gray-600 border border-gray-200">
                  {item.content}
                </span>
                {index < rcaHistory.length - 1 && (
                  <ChevronRight className="mx-2 w-4 h-4 text-gray-400" />
                )}
              </div>
            ))}
          </div>
        </motion.div>
      )}

      <div className="flex flex-wrap md:flex-row gap-6">
        {!initializing && rcaQuestionSets.map((questionSet, index) => (
          <QuestionCard
            key={questionSet.questionId}
            question={questionSet.questions[0]}
            onSelect={handleRCASelection}
            selectedAnswer={rcaAnswers[questionSet.questionId]}
            isActive={index === rcaQuestionSets.length - 1}
            isLoading={loading && index === rcaQuestionSets.length - 1}
          />
        ))}
        {initializing && (
          <div className="w-full md:w-[calc(50%-0.75rem)]">
            <LoadingSpinner />
          </div>
        )}
      </div>

      
    </div>
  );
};

export default RCASection;
