import React from "react";

const Button = ({ text, onClick, loading, testId, variant = "primary", disabled = false }) => {
  // Define styles for each variant
  const variantStyles = {
    primary:
      "text-white bg-primary hover:bg-primary-hover focus:ring-primary-focus",
    secondary:
      "text-gray-700 bg-gray-200 hover:bg-gray-300 focus:ring-gray-400",
    danger:
      "text-white bg-red-600 hover:bg-red-700 focus:ring-red-300",
    outline:
      "text-primary bg-white border border-primary hover:bg-blue-100 focus:ring-primary-focus",
    disabled: "bg-gray-300 text-gray-600 cursor-not-allowed",
  };

  const baseStyles =
    "w-full px-5 py-3 text-sm font-semibold rounded-lg transition-transform transform focus:outline-none focus:ring-4 hover:scale-105";

  const finalStyles = `${baseStyles} ${
    loading || disabled ? variantStyles.disabled : variantStyles[variant] || ""
  }`;

  return (
    <div>
      <button
        type="button"
        className={`${finalStyles} ${loading ? "opacity-50 cursor-not-allowed" : ""}`}
        data-testid={testId}
        onClick={!loading && !disabled ? onClick : null}
        disabled={loading || disabled}
      >
        {loading ? (
          <span className="flex items-center justify-center">
            <svg
              className="animate-spin h-5 w-5 mr-2 text-current"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
              ></path>
            </svg>
            Loading...
          </span>
        ) : (
          text
        )}
      </button>
    </div>
  );
};

export default Button;
