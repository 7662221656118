import React, { useState, useCallback, useEffect } from 'react';
import Modal from './Modal';
import FormInput from './FormInput';
import Stepper from './Stepper';
import EditableTable from './Table/EditableTable';
import Button from '../../Button';
import { convertToLocalTimeAndShift, formatDate } from '../../../utils/constants';
import useCustomToast from '../../../hooks/useToast';
import { incidentLevels } from './api/dropdownData';
import CascadingDropdown from './Forms/CascadingDropdown';
import PreviewReport from './Forms/PreviewReport';
import RCASection from './Forms/RCASection';
import { getRCAQuestions, getPlannedActions, createReport, getReportDetails } from '../../../services/reportService';
import { format, addMonths, set } from 'date-fns';

const initialTableData = [
  { 
    plannedAction: 'Frequent checks of helmet expiration date',
    description: 'Bi-monthly checks to verify the expiration date of all the helmets in use should be conducted and the materials management system should be updated with the appropriate expiration dates.',
    assignedTo: 'John Smith',
    deadline: '12-15-2024',
    status: 'In Progress',
    verificationBy: 'Safety Manager',
  },
  {
    plannedAction: 'Frequent checking on the materials management system to order new helmets',
    description: 'Bi-monthly checks to identify expired helmets should be run on the materials management system so that new replacement helmets can be ordered in a timely fashion.',
    assignedTo: 'Sarah Johnson',
    deadline: '03-02-2025',
    status: 'Complete',
    verificationBy: 'Training Lead',
  },
];

const initialFormState = {
  incidentId: '',
  incidentType: '',
  occurrenceTime: '',
  occurrenceLocation: '',
  description: '',
  incidentClip: '',
  personnelName: '',
  personnelId: '',
  shift: '',
  jobTitle: '',
  supervisorName: '',
  supervisorId: '',
  incidentStopped: '',
  anyInjuries: '',
  incidentStoppedHow: '',
  injuryDescription: '',
  incidentSeverity: '',
  potentialConsequences: '',
  rootCauseAnalysis: [],
  actionItems: [],
  rcaQuestionSets: [],
  rcaAnswers: {},
  rcaHistory: [],
  status: 'draft'
};

let disabledFields = [
  'incidentId',
  'incidentType',
  'occurrenceTime',
  'occurrenceLocation',
  'description',
  'incidentClip',
];

const IncidentReportForm = ({ isOpen, onClose, heading, alert, initialData, siteid }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState(() => ({
    ...initialFormState,
    ...initialData,
    incidentId: initialData?.incidentId || '',
    incidentType: initialData?.incidentType || '',
    occurrenceTime: formatDate(initialData?.occurrenceTime) || '',
    occurrenceLocation: initialData?.occurrenceLocation || '',
    description: initialData?.description || '',
    incidentClip: initialData?.incidentClip || '',
    personnelName: initialData?.personnelName || '',
    personnelId: initialData?.personnelId || '',
    shift: initialData?.shift || '',
    jobTitle: initialData?.jobTitle || '',
    supervisorName: initialData?.supervisorName || '',
    supervisorId: initialData?.supervisorId || '',
    incidentStopped: initialData?.incidentStopped || '',
    anyInjuries: initialData?.anyInjuries || '',
    incidentStoppedHow: initialData?.incidentStoppedHow || '',
    injuryDescription: initialData?.injuryDescription || '',
    incidentSeverity: initialData?.incidentSeverity || '',
    potentialConsequences: initialData?.potentialConsequences || '',
    actionItems: initialData?.actionItems || [],
  }));
  const [tableData, setTableData] = useState(initialData?.actionItems);
  const [showPreview, setShowPreview] = useState(false);
  const showToast = useCustomToast();

  // Reset form when modal is closed
  useEffect(() => {
    if (!isOpen) {
      setCurrentStep(0);
      setShowPreview(false);
    }
  }, [isOpen]);

  const checkIfFormDataExists = async (alert) => {
    console.log(alert, formData);
    const reportDetails = await getReportDetails(formData.incidentId);
    // console.log(reportDetails);
    if (reportDetails.message === 'Report not found') {
      return null;
    }
    return reportDetails;
  };

  // Update form when initialData changes
  useEffect(() => {
    if (initialData) {
      console.log('initialData changed:', initialData);
      setFormData(initialData);
      // setTableData(initialData.actionItems?.length > 0 ? initialData.actionItems : initialTableData);
    }
  }, [initialData]);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    console.log('Name:', name, 'Value:', value);
    console.log('formData:', formData);
    
    setFormData(prev => ({
      ...prev,
      [name]: value || ''
    }));
  }, []);

  const handleRCAChange = useCallback((analysis, rcaState) => {
    console.log('RCA Analysis:', analysis, 'RCA State:', rcaState, formData);
    setFormData(prev => ({
      ...prev,
      rootCauseAnalysis: analysis,
      rcaQuestionSets: rcaState.questionSets,
      rcaAnswers: rcaState.answers,
      rcaHistory: rcaState.history
    }));
  }, []);

  useEffect(() => {
    console.log('formData updated:', formData);
  }, [formData]);

  const handleIncidentTypeChange = useCallback(async (e) => {
    const { name, value } = e.target;
    console.log('Name:', name, 'Value:', value, formData);
    setFormData((prev) => ({ ...prev, [name]: value }));

    // Only fetch RCA questions for new reports or if questions don't exist
    if (!initialData?.rcaQuestions) {
      try {
        const questions = await getRCAQuestions(value);
        setFormData((prev) => ({
          ...prev,
          rcaQuestions: questions.map((q) => ({ question: q, answer: "" })),
        }));
      } catch (error) {
        console.error("Error initializing RCA:", error);
        // Use default questions if API fails
        setFormData((prev) => ({
          ...prev,
        }));
      }
      console.log('Fetched RCA questions:', formData);
    }
  }, [initialData]);

  const onCloseModal = useCallback(() => {
    console.log('Closing modal');
    setCurrentStep(0);
    setFormData(initialData || initialFormState);
    setTableData(initialData?.actionItems?.length > 0 ? initialData.actionItems : initialTableData);
    onClose();
  }, [onClose, initialData]);

  const handleSubmit = useCallback((e) => {
    console.log('Final form data:', formData);
    e.preventDefault();
    handleFinalSubmit();
    setShowPreview(true);
  }, [formData, tableData]);

  const handleFinalSubmit = useCallback(async () => {
    // Check if all required fields are filled
    console.log('Final form data:', formData);
    const requiredFields = [
      'incidentId',
      'incidentType',
      'occurrenceTime',
      'occurrenceLocation',
      'description',
      'personnelName',
      'personnelId',
      'shift',
      'jobTitle',
      'supervisorName',
      'supervisorId',
      'incidentStopped',
      'anyInjuries',
      'incidentSeverity'
    ];

    const isComplete = requiredFields.every(field => formData[field] && formData[field].toString().trim() !== '');
    const finalStatus = isComplete ? 'completed' : 'draft';

    const finalFormData = {
      ...formData,
      status: finalStatus,
      actionItems: tableData,
      siteId: siteid
    };

    console.log('Submitting form data:', finalFormData);
    try{
      const data = await createReport(finalFormData);
      showToast(`Report ${finalStatus === 'completed' ? 'submitted' : 'saved as draft'} successfully`, 'success');
    }catch (error) {
      console.error('Error submitting form data:', error);
      showToast('Failed to submit report', 'error');
    }
    // const data = createReport(finalFormData);
    // onCloseModal();
  }, [formData, tableData, showToast]);

  const handleNext = useCallback(async () => {
    console.log('Current step:', currentStep, formData);
    if (currentStep === 1 && formData.actionItems.length === 0 && tableData.length === 0) {
      try {
        const nodeIDs = formData.rcaAnswers ? 
          Object.values(formData.rcaAnswers)
            .filter(answer => answer.id)
            .map(answer => answer.id) : [];
        
        // console.log('Node IDs:', nodeIDs);

        if (nodeIDs.length > 0) {
          const data = await getPlannedActions(nodeIDs);

          if(data.length === 0){
            // setTableData(initialTableData);
            return;
          }
          
          const newActionItems = data.plannedActions.map(action => ({
            plannedAction: action.plannedAction,
            description: action.plannedActionDescription,
            assignedTo: '',
            deadline: format(addMonths(new Date(), 1), 'yyyy-MM-dd'),
            status: 'Pending',
            verificationBy: '',
            nodeID: action.nodeID
          }));

          setFormData(prev => ({
            ...prev,
            actionItems: newActionItems
          }));
          if(newActionItems.length > 0){
            setTableData(newActionItems);
          }
        }else{
          setFormData(prev => ({
            ...prev,
            actionItems: []
          }));
          setTableData([]);
        }
        console.log('Planned actions:', "Form Data:", formData);
      } catch (error) {
        console.error('Error fetching planned actions:', error);
      }
    }
    setCurrentStep(currentStep + 1);
  }, [currentStep, formData]);

  const handlePrevious = useCallback(() => {
    setCurrentStep(prev => prev - 1);
  }, []);

  const handleAddRow = useCallback(() => {
    setTableData(prev => {
      if (!Array.isArray(prev)) {
        let data = [];
        data.push({
          plannedAction: '',
          description: '',
          assignedTo: '',
          deadline: format(addMonths(new Date(), 1), 'yyyy-MM-dd'),
          status: 'Pending',
          verificationBy: '',
          nodeID: null
        });
        return data;
        
      }
      return [...prev, {
        plannedAction: '',
        description: '',
        assignedTo: '',
        deadline: format(addMonths(new Date(), 1), 'yyyy-MM-dd'),
        status: 'Pending',
        verificationBy: '',
        nodeID: null
      }];
    });
  }, []);

  const handleTableChange = useCallback((index, field, value) => {
    setTableData(prev => {
      const newTableRows = [...prev];
      newTableRows[index][field] = value;
      return newTableRows;
    });
  }, []);

  const handleDeleteRow = useCallback((index) => {
    setTableData(prev => prev.filter((_, i) => i !== index));
  }, []);

  const preFillData = useCallback(async () => {
      let shift = convertToLocalTimeAndShift(initialData.occurrenceTime)?.shift;
      console.log('Shift:', shift);

      setFormData({
        ...formData,
        shift: shift,
      });
      console.log('Pre-filled form data:', formData);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let formData = await checkIfFormDataExists(initialData?.incidentId);
        if (formData) {
          console.log('Fetched form data:', formData);
          setFormData(formData);
          setTableData(formData.actionItems);
        } else {
          await preFillData();
        }
      } catch (error) {
        console.error("Error fetching form data:", error);
        await preFillData();
      }
    };
    if(initialData?.incidentId){
      fetchData();
    }
    // fetchData();
  }, []);

  const steps = [
    {
      title: 'Incident Details',
      groups: [
        [
          { id: 1, label: 'Incident ID', type: 'text', name: 'incidentId' },
          { id: 2, label: 'Incident Type', type: 'text', name: 'incidentType' }
        ],
        [
          { id: 3, label: 'Occurrence Time', type: 'text', name: 'occurrenceTime' },
          { id: 4, label: 'Occurrence Location', type: 'text', name: 'occurrenceLocation' }
        ],
        [
          { id: 5, label: 'Description', type: 'textarea', name: 'description' },
          { id: 6, label: 'Incident Clip', type: 'text', name: 'incidentClip' }
        ],
        [
          { id: 7, label: 'Name Of Personnel Involved', type: 'text', name: 'personnelName' },
          { id: 8, label: 'Employee ID Of Personnel Involved', type: 'text', name: 'personnelId' }
        ],
        [
          { id: 9, label: 'Shift', type: 'select', name: 'shift', options: ['Morning', 'Afternoon', 'Evening' ,'Night'] },
          { id: 10, label: 'Job Title', type: 'text', name: 'jobTitle' }
        ],
        [
          { id: 11, label: 'Supervisor\'s Name', type: 'text', name: 'supervisorName' },
          { id: 12, label: 'Supervisor\'s Employee ID', type: 'text', name: 'supervisorId' }
        ],
        [
          { id: 13, label: 'Was the incident stopped immediately', type: 'radio', name: 'incidentStopped' },
          { id: 15, label: 'Were there any injuries', type: 'radio', name: 'anyInjuries' }
        ],
        [
          { id: 14, label: 'How was the incident stopped', type: 'textarea', name: 'incidentStoppedHow', showConditional: (data) => data.incidentStopped === 'Yes' },
          { id: 16, label: 'Describe the injuries and medical attention provided', type: 'textarea', name: 'injuryDescription', showConditional: (data) => data.anyInjuries === 'Yes' }
        ],
      ]
    },
    {
      title: 'Root Cause Analysis',
      groups: [
        [
          { id: 17, label: 'Severity Of The Incident', type: 'select', name: 'incidentSeverity', options: ['Low', 'Medium', 'High', 'Critical'] },
          { id: 18, label: 'Potential Consequences If Not Addressed', type: 'select', name: 'potentialConsequences', options: ['Minor Inconvenience', 'Operational Delay', 'Financial Loss', 'Fatal Accident'] }
        ],
        [
          { id: 23, label: 'Why Did The Incident Occur', type: 'textarea', name: 'description', className: 'col-span-2' }
        ],
      ]
    },  
    {
      title: 'Corrective Actions',
      groups: []
    }
  ];

  return (
    <>
      <Modal isOpen={isOpen} onClose={onCloseModal} heading={heading}>
        <div className="flex flex-col h-full">
          <div className="sticky top-0 bg-white z-10 p-4 border-b border-gray-200 shadow-md">
            <Stepper
              steps={steps}
              currentStep={currentStep}
              onStepClick={setCurrentStep}
              heading={heading}
              onClose={onCloseModal}
            />
          </div>

          <form onSubmit={handleSubmit} className="flex-grow space-y-6 p-6 overflow-y-auto">
            {currentStep === 2 && (
              <div className="flex flex-col space-y-4">
                <div className="flex justify-end">
                  <div>
                    <Button text='Add Row' onClick={handleAddRow} variant='primary' />
                  </div>
                </div>
                <EditableTable
                  rows={tableData}
                  onChange={handleTableChange}
                  onDeleteRow={handleDeleteRow}
                />
              </div>
            )}
            {steps[currentStep].groups.map((group, groupIndex) => (
              <div key={groupIndex} className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                {group.map((field) => {
                  if (field.showConditional && !field.showConditional(formData)) {
                    return null;
                  }
                  return (
                    <div key={field.id} className={field.className}>
                      <FormInput
                        {...field}
                        value={formData[field.name]}
                        onChange={field.name === 'incidentType' ? handleIncidentTypeChange : handleChange}
                        isReadOnly={disabledFields.length > 0 && disabledFields.includes(field.name)}
                      />
                    </div>
                  );
                })}
              </div>
            ))}
            {currentStep === 1 && (
              <div className="mt-2">
                <RCASection 
                  incidentType={formData.incidentType}
                  onChange={handleRCAChange}
                  initialState={{
                    questionSets: formData.rcaQuestionSets,
                    answers: formData.rcaAnswers,
                    history: formData.rcaHistory
                  }}
                />
              </div>
            )}
          </form>

          <div className="sticky bottom-0 bg-white z-10 px-6 py-4 border-t border-gray-200 shadow-md flex justify-between">
            <div>
              <Button
                text="Previous"
                onClick={handlePrevious}
                disabled={currentStep === 0}
                variant={currentStep === 0 ? "disabled" : "outline"}
              />
            </div>
            <div className="flex gap-3">
              <Button text="Save" onClick={() => handleFinalSubmit()} variant="outline" />
              {currentStep < steps.length - 1 ? (
                <Button text="Next" onClick={handleNext} variant="primary" />
              ) : (
                <Button text="Preview" onClick={handleSubmit} variant="primary" />
              )}
            </div>
          </div>
        </div>
      </Modal>

      <PreviewReport 
        isOpen={showPreview}
        onClose={() => setShowPreview(false)}
        formData={{ ...formData, actionItems: tableData }}
        handleFinalSubmit={handleFinalSubmit}
      />
    </>
  );
};

export default React.memo(IncidentReportForm);