import React from "react";
import { Link } from "react-router-dom";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";

const Breadcrumb = ({ links }) => {
  console.log("links", links);
  return (
    <div
      className="flex gap-4 items-center px-6 py-4 w-full text-sm font-medium leading-none text-gray-400 rounded-xl border-b max-md:px-5 max-md:max-w-full font-suse"
      data-testid="breadcrumb-container"
    >
      <div
        className="flex gap-1 items-center self-stretch my-auto min-w-[240px]"
        data-testid="breadcrumb-items"
      >
        {/* <Link to="/" className="flex items-center">
          <HomeOutlinedIcon
            style={{ fontSize: '1.25rem', color: 'inherit' }}
            className="shrink-0 self-stretch my-auto w-5 aspect-square"
          />
        </Link> */}
        {links.map((link, index) => (
          <React.Fragment key={index}>
            {index === links.length - 1 ? (
              <span
                className="self-stretch my-auto"
                data-testid={`breadcrumb-item-${index}`}
              >
                {link.name}
              </span>
            ) : (
              <React.Fragment>
                <Link
                  to={link.path ? link.path : void 0}
                  className={`${
                    link.path ? "" : "cursor-none"
                  } self-stretch my-auto`}
                  data-testid={`breadcrumb-link-${index}`}
                >
                  {link.name}
                </Link>
                <ChevronRightOutlinedIcon
                  style={{ fontSize: "1.25rem", color: "inherit" }}
                  className="shrink-0 self-stretch my-auto w-5 aspect-square"
                  data-testid={`breadcrumb-chevron-${index}`}
                />
              </React.Fragment>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default Breadcrumb;
