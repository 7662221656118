import { useRef, useState, useCallback } from 'react';

export const useBoxDrawing = (imageRef, dimensions, onBoxDrawn) => {
  const [drawingBox, setDrawingBox] = useState(null);
  const drawingRef = useRef({ isDrawing: false, startX: 0, startY: 0 });

  const getMousePosition = useCallback((e) => {
    if (!imageRef.current) return { x: 0, y: 0 };
    const rect = imageRef.current.getBoundingClientRect();
    return {
      x: Math.max(0, Math.min(e.clientX - rect.left, dimensions.width)),
      y: Math.max(0, Math.min(e.clientY - rect.top, dimensions.height))
    };
  }, [dimensions]);

  const handleMouseDown = useCallback((e) => {
    if (e.button !== 0) return;
    const { x, y } = getMousePosition(e);
    drawingRef.current = { isDrawing: true, startX: x, startY: y };
    setDrawingBox({ x, y, width: 0, height: 0 });
  }, [getMousePosition]);

  const handleMouseMove = useCallback((e) => {
    if (!drawingRef.current.isDrawing) return;
    const { x, y } = getMousePosition(e);
    const width = x - drawingRef.current.startX;
    const height = y - drawingRef.current.startY;

    setDrawingBox({
      x: width < 0 ? x : drawingRef.current.startX,
      y: height < 0 ? y : drawingRef.current.startY,
      width: Math.abs(width),
      height: Math.abs(height),
    });
  }, [getMousePosition]);

  const handleMouseUp = useCallback(() => {
    if (!drawingRef.current.isDrawing) return;
    
    const box = drawingBox;
    if (box && box.width > 5 && box.height > 5) {
      onBoxDrawn(box);
    }
    
    drawingRef.current.isDrawing = false;
    setDrawingBox(null);
  }, [drawingBox, onBoxDrawn]);

  return {
    drawingBox,
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
  };
};