import React, { useState } from "react";
import Button from "../Button";
import { useSelector } from "react-redux";
import { sendMail } from "../../services/apiServiceDetails";
import useCustomToast from "../../hooks/useToast";

const SendEmailModal = ({
  onClose,
  alertID,
  alertType,
  alertCreated,
  alertVideoURL,
  wareHouse,
}) => {
  const [receiverEmail, setReceiverEmail] = useState();
  const user = useSelector((state) => state.user);
  const showToast = useCustomToast();

  const [subject, setSubject] = useState(
    `Immediate Attention Required: ${alertType} – Incident ID ${alertID} at ${wareHouse}`
  );
  const [mailBody, setMailBody] = useState(`Hello team,

This is an important notification regarding a recent ${alertType} at ${wareHouse} that requires prompt attention. Please review the incident details below and address any necessary actions at your earliest convenience.

Incident Details:
-Site: ${wareHouse}
-Incident Type: ${alertType}
-Incident ID: ${alertID}
-Severity Level: High
-Timestamp: ${alertCreated}
-Video Link: ${alertVideoURL ? alertVideoURL : "Video Link Not Available"}

If you have questions or require further assistance, please reach out to solutions@lumeneo.ai.

Thank you for your immediate attention to this matter.

Best regards,
${user.name}
`);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const res = await sendMail({
      sender_email: "piyyush1219@gmail.com",
      subject: subject,
      recipient_email: receiverEmail,
      user_name: user.name,
      mail_body: mailBody,
    });

    console.log(res);
    onClose();
    if (res) {
      showToast(
        `Incident Notification sent successfully to ${receiverEmail}`,
        "success"
      );
    }
  };
  return (
    <div
      id="create-site-modal"
      className="fixed top-[50%] left-[50%] bg-gray-900 bg-opacity-50 w-screen h-screen translate-x-[-50%] translate-y-[-50%] z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden"
    >
      <div
        className="absolute inset-0"
        onClick={onClose}
        data-testid="modal-overlay"
      ></div>

      <div className="relative w-[80vw] px-4 py-10 border-gray-300">
        <div
          className="bg-white rounded-2xl shadow-xl p-8"
          data-testid="create-site-form"
        >
          <div className="flex items-start justify-between border-b border-gray-200 pb-6">
            <div className="flex flex-col items-start">
              <h3 className="text-3xl tracking-tighter font-semibold  text-gray-900">
                Incident Occurrence Notification
              </h3>
              <p className="text-sm text-gray-600 mt-2">
                Please enter the following details
              </p>
            </div>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-900 p-2 rounded-full transition-colors"
              aria-label="Close"
              data-testid="close-button"
            >
              <svg
                className="w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          <form onSubmit={handleSubmit} className="mt-5 w-full space-y-4">
            <div className="w-full flex flex-row justify-center items-center gap-x-2">
              <label
                htmlFor="siteName"
                className="block w-[100px] text-left text-sm font-medium text-gray-700"
              >
                To <span className="text-red-600">*</span>
              </label>
              <input
                type="text"
                id="receiveremail"
                value={receiverEmail}
                onChange={(e) => setReceiverEmail(e.target.value)}
                placeholder="Enter Emails"
                className="mt-1 w-full px-4 py-3 border rounded-lg border-gray-300 focus:ring-blue-500 focus:border-blue-500 transition-shadow focus:shadow-lg"
                required
                data-testid="input-site-name"
              />
            </div>

            <div className="w-full flex flex-row justify-center items-center gap-x-2">
              <label
                htmlFor="subject"
                className="block w-[100px] text-left text-sm font-medium text-gray-700"
              >
                Subject:<span className="text-red-600">*</span>
              </label>
              <input
                type="text"
                id="subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                placeholder="subject"
                className="mt-1 w-full px-4 py-3 border rounded-lg border-gray-300 focus:ring-blue-500 focus:border-blue-500 transition-shadow focus:shadow-lg"
                required
                data-testid="input-location"
              />
            </div>
            <div>
              <label
                htmlFor="description"
                className="block text-sm font-medium text-gray-700"
              >
                Message
              </label>
              <textarea
                id="description"
                value={mailBody}
                onChange={(e) => setMailBody(e.target.value)}
                placeholder="Type here..."
                className="mt-1 w-full px-4 py-3 border rounded-lg border-gray-300 focus:ring-blue-500 focus:border-blue-500 transition-shadow focus:shadow-lg min-h-[20rem]"
                data-testid="input-description"
              />
            </div>
            <Button text="Send" testId="submit-button" onClick={handleSubmit} />
          </form>
        </div>
      </div>
    </div>
  );
};

export default SendEmailModal;
