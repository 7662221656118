import React, { useEffect, useRef, useState } from "react";

const IVSPlayer = ({ playbackUrl, handleClose }) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [info, setInfo] = useState(null);

  useEffect(() => {
    let isMounted = true;
    const script = document.createElement("script");
    script.src =
      "https://player.live-video.net/1.33.0/amazon-ivs-player.min.js";
    script.async = true;
    document.body.appendChild(script);

    const ensurePlaying = () => {
      if (playerRef.current && playerRef.current.getState() !== "Playing") {
        playerRef.current.play();
      }
    };

    script.onload = () => {
      if (!isMounted) return;

      const { IVSPlayer } = window;
      if (!IVSPlayer.isPlayerSupported) {
        setError("The current browser does not support the IVS player.");
        setIsLoading(false);
        return;
      }

      try {
        const player = IVSPlayer.create();
        playerRef.current = player;
        player.attachHTMLVideoElement(videoRef.current);

        player.load(playbackUrl);
        player.play();

        player.addEventListener(IVSPlayer.PlayerState.PLAYING, () => {
          if (isMounted) {
            setIsLoading(false);
            setError(null);
          }
        });

        player.addEventListener(IVSPlayer.PlayerState.ENDED, () => {
          if (isMounted) {
            setInfo("Device detections have ended");
          }
        });

        player.addEventListener(IVSPlayer.PlayerEventType.ERROR, (err) => {
          if (isMounted) {
            setInfo(
              `Detection video is loading. This might take a few seconds....`
            );
            setIsLoading(false);
          }
        });

        const interval = setInterval(() => ensurePlaying(), 1000);

        return () => clearInterval(interval);
      } catch (err) {
        if (isMounted) {
          setError(`Failed to initialize player: ${err.message}`);
          setIsLoading(false);
        }
      }
    };

    script.onerror = () => {
      if (isMounted) {
        setError("Failed to load IVS player script");
        setIsLoading(false);
      }
    };

    // Cleanup function
    return () => {
      isMounted = false;
      if (playerRef.current) {
        try {
          playerRef.current.delete();
        } catch (err) {
          console.error("Error cleaning up IVS player:", err);
        }
      }
      if (script.parentNode) {
        script.parentNode.removeChild(script);
      }
    };
  }, [playbackUrl]);

  return (
    <div className="relative pt-[56.25%] bg-gray-900 rounded-t-2xl overflow-hidden">
      <video
        ref={videoRef}
        className="absolute inset-0 w-full h-full object-contain pointer-events-none"
        playsInline
        autoPlay
      />

      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-black/50 backdrop-blur-sm">
          <div className="flex flex-col items-center gap-4">
            <div className="w-16 h-16 border-4 border-blue-500 border-t-transparent rounded-full animate-spin" />
            <p className="text-white font-medium">Loading stream...</p>
          </div>
        </div>
      )}

      {info && (
        <div className="absolute inset-0 flex items-center justify-center bg-black/50 backdrop-blur-sm">
          <div className="bg-[#052D61] text-white px-8 py-4 rounded-xl shadow-lg text-lg font-medium">
            {info}
          </div>
        </div>
      )}

      {error && (
        <div className="absolute inset-0 flex items-center justify-center bg-black/50 backdrop-blur-sm">
          <div className="bg-red-500 text-white px-8 py-4 rounded-xl shadow-lg text-lg font-medium">
            {error}
          </div>
        </div>
      )}
    </div>
  );
};

export default IVSPlayer;
