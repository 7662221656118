import React, { useEffect, useRef, useState } from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ClipLoader from "react-spinners/ClipLoader";
import SimpleBarChart from "./SimpleBarChart";
import { alertConfig } from "../utils/alertUtils";
import { formatTime } from "../utils/constants";

function StatCard({
  title,
  value,
  change,
  isPositive,
  heading = "Violations",
  isHour,
  filteredData,
  onClick,
  category,
  selectedDateRange,
  isNumber,
}) {
  const cardRef = useRef(null);
  const [chartData, setChartData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showTooltip, setShowTooltip] = useState(false);
  const [datecounters, setDatecounters] = useState("Last 1");

  useEffect(() => {
    setIsLoading(true);
    const filterData = () => {
      setTimeout(() => {
        const data = filterForAlertType(filteredData, title);
        // console.log("chart data in stat card ", data, filteredData, title, selectedDateRange);
        setChartData(data);
        setIsLoading(false);
      }, 1000);
    };

    filterData();
  }, [filteredData, title]);

  useEffect(() => {
    if (selectedDateRange === "Hours") {
      setDatecounters("Last 24");
    }
  }, [selectedDateRange]);

  const filterForAlertType = (data, alertType) => {
    return data.map((entry) => ({
      group: entry.group,
      value: entry.values.find((val) => val.name === alertType)?.value || 0,
    }));
  };

  if (value === 0 || !value) {
    return (
      <div
        ref={cardRef}
        className="flex flex-col px-2 py-2 rounded-lg border-2 border-gray-200 basis-[300px] min-w-[300px] max-w-[300px] h-[220px] cursor-pointer font-golos"
      >
        <div className="p-5 pb-0">
          <div className="text-md font-semibold text-gray-500 font-suse">
            {title}
          </div>
        </div>

        <div className="flex-1 flex items-center justify-center">
          <span className="text-sm text-gray-500 font-medium">
            {category.toLowerCase().includes("safety") ||
            category.toLowerCase().includes("compliance") ||
            category.toLowerCase().includes("forklift")
              ? "No safety incidents to report. Keep up the great work!"
              : "No data to display"}
          </span>
        </div>
      </div>
    );
  }

  return (
    <div
      ref={cardRef}
      className="flex flex-col px-2 py-2 rounded-lg border-2 border-gray-200 basis-[300px] min-w-[300px] max-w-[300px] h-[220px] cursor-pointer font-golos"
      onClick={() =>
        onClick(title, `${heading}: ${isHour ? formatTime(value) : value}`)
      }
    >
      <div className="flex flex-col p-2 h-[150px]">
        <div className="flex justify-between items-center">
          <div className="text-md font-semibold text-gray-500 font-suse">
            {title}
          </div>
          <div
            className={`relative flex items-center text-xs font-bold ${
              alertConfig[title]?.getArrow(change).color === "red"
                ? "text-red-600"
                : "text-emerald-600"
            }`}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          >
            {alertConfig[title]?.getArrow(change).arrow === "up" ? (
              <ArrowUpwardIcon
                className={`${
                  alertConfig[title]?.getArrow(change).color === "red"
                    ? "text-red-600"
                    : "text-emerald-600"
                }`}
              />
            ) : (
              <ArrowDownwardIcon
                className={`${
                  alertConfig[title]?.getArrow(change).color === "red"
                    ? "text-red-600"
                    : "text-emerald-600"
                }`}
              />
            )}
            <span>{Math.abs(Math.ceil(change))}%</span>

            {showTooltip && (
              <div className="absolute bottom-full right-[-200%] mb-1 p-2 w-[200px] text-xs font-light text-black bg-gray-100 border-gray-300 border-[1px] rounded">
                Change in {isHour ? "Time" : isNumber ? "Total" : "Violations"}{" "}
                for {datecounters} {selectedDateRange}
              </div>
            )}
          </div>
        </div>
        <div className="relative flex items-center mt-2 text-xs text-gray-900 whitespace-nowrap">
          <div>
            {heading}: {isHour ? formatTime(value) : value}
          </div>
        </div>
      </div>

      {isLoading ? (
        <div className="w-full flex justify-center items-center">
          <ClipLoader color="#052D61" loading={isLoading} size={50} />
        </div>
      ) : (
        <SimpleBarChart
          chartData={chartData}
          chartWidth={290}
          isHour={isHour}
          isNumber={isNumber}
        />
      )}
    </div>
  );
}

export default StatCard;
