import { useState, useEffect, useCallback } from 'react';

export const useImageDimensions = (imageRef) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const updateDimensions = useCallback(() => {
    if (imageRef.current) {
      const { offsetWidth, offsetHeight } = imageRef.current;
      setDimensions({ width: offsetWidth, height: offsetHeight });
    }
  }, []);

  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, [updateDimensions]);

  return [dimensions, updateDimensions];
};