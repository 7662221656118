import { Check } from "lucide-react";
import React, { useState, useEffect } from "react";
import { generateThumbnail } from "../../utils/generateThumbnail";

const CameraStreamSelector = ({
  isOpen,
  onNext,
  streams,
  selectedStream,
  setSelectedStream,
  onClose,
}) => {
  const [thumbnails, setThumbnails] = useState({});
  const [loading, setLoading] = useState(true);

  const cstreams = [
    {
      id: 5,
      stream: {
        id: 4,
        video_name: "kafkaVid",
        video_type: "",
        video_url:
          "http://uploaded-videos-bucket.s3.dualstack.eu-north-1.amazonaws.com/streams/kafkaVid?X-Amz-Algorithm=AWS4-HMAC-SHA256\u0026X-Amz-Credential=AKIARSU7LAX6TGVBVQHJ%2F20240904%2Feu-north-1%2Fs3%2Faws4_request\u0026X-Amz-Date=20240904T064221Z\u0026X-Amz-Expires=604800\u0026X-Amz-SignedHeaders=host\u0026X-Amz-Signature=540ff2279dee2f6c9653b8d5fc816cfd9184a07a84f8d8146da9f7f56db558b9",
        created_at: "2024-09-04 06:42:21",
        updated_at: "2024-09-04 06:42:21",
        site_id: 1,
      },
      roi: [
        {
          x: "0.90",
          y: "0.11",
        },
        {
          x: "0.91",
          y: "0.87",
        },
        {
          x: "0.15",
          y: "0.92",
        },
        {
          x: "0.13",
          y: "0.11",
        },
        {
          x: "0.90",
          y: "0.14",
        },
      ],
    },
  ];

  useEffect(() => {
    const fetchThumbnails = async () => {
      const thumbnailsMap = {};
      await Promise.all(
        streams.map(async (stream) => {
          const thumbnail = await generateThumbnail(stream?.stream?.video_url);
          thumbnailsMap[stream?.stream?.id] = thumbnail;
        })
      );
      setThumbnails(thumbnailsMap);
      setLoading(false);
    };

    fetchThumbnails();
  }, [streams]);

  const handleSelect = (stream) => {
    if (selectedStream.includes(stream.id)) {
      setSelectedStream(selectedStream.filter((id) => id !== stream.id));
    } else {
      setSelectedStream([...selectedStream, stream.id]);
    }
  };

  const handleNext = () => {
    onNext();
  };

  return (
    <div
      className="relative bg-white rounded-t-lg shadow-xl w-full h-[100%] overflow-auto"
      data-testid="camera-stream-selector"
    >
      <div className="flex justify-between items-center px-6 py-4">
        <h1
          className="flex-1 shrink self-stretch my-auto text-3xl font-semibold tracking-tighter leading-tight text-gray-900 basis-10 max-md:max-w-full"
          data-testid="camera-stream-title"
        >
          Select Camera Device
        </h1>
      </div>
      <button
        onClick={onClose}
        className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
        data-testid="close-modal-button"
      >
        <span className="block w-6 h-6 relative" aria-label="Close">
          <span className="block absolute w-full h-0.5 bg-current transform rotate-45 top-1/2"></span>
          <span className="block absolute w-full h-0.5 bg-current transform -rotate-45 top-1/2"></span>
        </span>
      </button>

      <div className="flex items-center justify-between px-6 py-4">
        <div
          className="flex items-center space-x-2 text-sm font-medium text-[#052D61]"
          data-testid="stream-navigation"
        >
          <span className="font-semibold">Select Camera Device</span>
          <span>{">"}</span>
          <span className="text-gray-500">Specify Detection Target</span>
          {/* <span>{">"}</span>
          <span className="text-gray-500">Fix Incorrect Detections</span> */}
        </div>

        <button
          onClick={handleNext}
          className={`flex overflow-hidden gap-2 justify-center items-center self-stretch px-5 py-4 my-auto text-sm font-semibold leading-none text-center text-gray-50 ${
            selectedStream.length
              ? "bg-[#052D61] border-blue-600"
              : "bg-gray-200 border-white"
          }  rounded-xl`}
          data-testid="next-button"
          disabled={selectedStream.length > 0 ? false : true}
        >
          <span className="self-stretch my-auto">
            Next: Specify Detection Target
          </span>
        </button>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 px-6 py-6">
        {streams.map((stream) => (
          <div
            key={stream?.stream?.id}
            className={`relative rounded-lg overflow-hidden shadow-md cursor-pointer transform transition-transform duration-200 ease-in-out hover:scale-105 border-2 ${
              selectedStream?.includes(stream?.stream?.id)
                ? "border-blue-600"
                : "border-transparent"
            }`}
            onClick={() => handleSelect(stream.stream)}
            data-testid={`stream-item-${stream?.stream?.id}`}
          >
            {loading || !thumbnails[stream?.stream?.id] ? (
              <div
                className="w-full h-60 bg-gray-300 animate-pulse"
                data-testid={`thumbnail-loading-${stream?.stream?.id}`}
              ></div>
            ) : (
              <img
                src={thumbnails[stream?.stream?.id]}
                alt={stream?.stream?.video_name}
                className="w-full h-60 object-cover"
                data-testid={`thumbnail-${stream?.stream?.id}`}
              />
            )}

            <div
              className={`absolute top-2 left-2 w-6 h-6 rounded-full border-2 flex items-center justify-center ${
                selectedStream?.includes(stream?.stream?.id)
                  ? "bg-[#052D61] border-blue-600"
                  : "bg-gray-200 border-white"
              }`}
              data-testid={`selection-icon-${stream?.stream?.id}`}
            >
              {selectedStream?.includes(stream?.stream?.id) && (
                <Check size={16} className="text-white" />
              )}
            </div>

            <div className="absolute bottom-0 left-0 right-0 p-2 bg-gradient-to-t from-black to-transparent">
              <h3 className="font-bold text-white">
                {stream?.stream?.video_name}
              </h3>
              <p className="text-sm text-gray-300 truncate">
                {stream?.stream?.url}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CameraStreamSelector;
