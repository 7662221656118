// export const processSitesData = (apiData) => {
//   // Extract unique alert names from apiData
//   // const alertTypes = [...new Set(apiData.sites.flatMap(site => 
//   //     site.use_cases?.flatMap(useCase => useCase.alerts?.map(alert => alert.alert_type))
//   // ))];

import { TIME_RANGES } from "./constants";


//   const alertTypes = [...new Set(apiData.sites.flatMap(site => 
//     site.use_cases?.flatMap(useCase => {
//         if (useCase.alerts != null) {
//             return useCase.alerts
//                 .map(alert => alert.alert_type) // Extract alert_type
//                 .filter(alertType => alertType !== undefined); // Filter out undefined values
//         } else {

//             return []; // Return empty array if alerts is null
//         }
//     })
// ))];

//   const initializeValues = () => alertTypes.map(alert => ({ name: alert, value: 0 }));

//   const getGroupedData = (alerts, groupingType, alertCount) => {
//       const grouped = {};
//       const currentTime = new Date(); 

//       // Initialize the grouped structure based on the grouping type
//       if (groupingType === "Hours") {
//           for (let hour = 0; hour < 24; hour++) {
//               grouped[`${String(hour).padStart(2, '0')}:00`] = initializeValues();
//           }
//       } else if (groupingType === "Week") {
//           const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
//           daysOfWeek.forEach(day => {
//               grouped[day] = initializeValues();
//           });
//       } else if (groupingType === "Month") {
//           for (let day = 1; day <= 30; day++) { // Assuming 30 days for simplification
//               grouped[`${day}`] = initializeValues();
//           }
//       } else if (groupingType === "Year") {
//           const monthsOfYear = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
//           monthsOfYear.forEach(month => {
//               grouped[month] = initializeValues();
//           });
//       }

//       alerts?.forEach(alert => {
//           const date = new Date(alert.created_at);
//           let groupKey;

//           const diffHours = (currentTime - date) / (1000 * 60 * 60); // Difference in hours
//           const diffDays = diffHours / 24; // Difference in days
//           const diffMonths = diffDays / 30; // Approximate difference in months


//           if (groupingType === "Hours" && diffHours > 24) {
//             return; // Skip alerts older than 24 hours for "Hours"
//           } else if (groupingType === "Week" && diffDays > 7) {
//             return; // Skip alerts older than 7 days for "Week"
//           } else if (groupingType === "Month" && diffDays > 30) {
//             return; // Skip alerts older than 30 days for "Month"
//           } else if (groupingType === "Year" && diffMonths > 12) {
//             return; // Skip alerts older than 1 year for "Year"
//           }

//           if (groupingType === "Hours") {
//               groupKey = `${String(date.getUTCHours()).padStart(2, '0')}:00`;
//           } else if (groupingType === "Week") {
//               const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
//               groupKey = daysOfWeek[date.getUTCDay()];
//           } else if (groupingType === "Month") {
//               groupKey = `${date.getUTCDate()}`;
//           } else if (groupingType === "Year") {
//               const monthsOfYear = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
//               groupKey = monthsOfYear[date.getUTCMonth()];
//           }

//           const alertType = alert.alert_type;

//           // Update the value for the existing alert type in the respective group
//           const existingAlert = grouped[groupKey].find(v => v.name === alertType);
//           if (existingAlert) {
//               existingAlert.value += 1;
//           }

//           // Update the alertCount for this alert type
//           alertCount[alertType] = (alertCount[alertType] || 0) + 1;
//       });

//       return Object.entries(grouped).map(([group, values]) => ({
//           group,
//           values
//          // values: values.filter(v => v.value > 0)
//       }));
//   };

//   const transformedData = apiData.sites.map(site => ({
//       site_name: site.site_name,
//       site_id: site.site_id,
//       useCases: site.use_cases?.map(useCase => {
//           // Initialize alertCount for each scenario
//           const alertCount = {};

//           const alerts = {
//               Hours: getGroupedData(useCase.alerts, "Hours", alertCount),
//               Week: getGroupedData(useCase.alerts, "Week", alertCount),
//               Month: getGroupedData(useCase.alerts, "Month", alertCount),
//               Year: getGroupedData(useCase.alerts, "Year", alertCount)
//           };

//           return {
//               name: useCase.name,
//               id: useCase.id,
//               alerts,
//               alertCount // Include alertCount in the return object
//           };
//       })
//   }));

//   const siteList = apiData.sites.map(site => site.site_name);
//   const useCaseList = apiData.sites.flatMap(site => 
//     site.use_cases && site.use_cases.length > 0 
//       ? site.use_cases.map(useCase => useCase.name) 
//       : []
//   );
//   // const useCaseList = apiData.sites.flatMap(site => 
//   //     site.use_cases?.map(useCase => useCase.name)
//   // );

//   return {
//     transformedData,
//     siteList,
//     useCaseList
//   }
// };

export const processSitesData = (apiData) => {
  const getUniqueAlertTypes = (useCase) => {
    return [...new Set(
      useCase.alerts?.map(alert => alert.alert_type).filter(alertType => alertType !== undefined) || []
    )];
  };

  const initializeValues = (alertTypes) =>
    alertTypes.map(alert => ({ name: alert, value: 0 }));

  const getGroupedData = (alerts, groupingType, alertCount, alertTypes) => {
    let grouped = new Map();
    const now = new Date();
    
    // Calculate valid time ranges using local timezone
    const validTimeRange = {
      Hours: new Date(now.getTime() - TIME_RANGES.Hours.current),
      Week: new Date(now.getTime() - TIME_RANGES.Week.current),
      Month: new Date(now.getTime() - TIME_RANGES.Month.current),
      Year: new Date(now.getTime() - TIME_RANGES.Year.current)
    };

    // Get current time components in local timezone
    const currentHour = now.getHours();
    const currentDay = now.getDay();
    const currentDate = now.getDate();
    const currentMonth = now.getMonth();
  
    // Initialize grouped structure with ordered keys
    if (groupingType === "Hours") {
      // Start from (currentHour + 1) and go through all 24 hours
      const hours = Array.from({ length: 24 }, (_, i) => (currentHour + 1 + i) % 24);
      hours.forEach(hour => {
        grouped.set(`${String(hour).padStart(2, '0')}:00`, initializeValues(alertTypes));
      });
    } else if (groupingType === "Week") {
      const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      // Start from the next day
      const orderedDays = [
        ...daysOfWeek.slice(currentDay + 1),
        ...daysOfWeek.slice(0, currentDay + 1)
      ];
      orderedDays.forEach(day => {
        grouped.set(day, initializeValues(alertTypes));
      });
    } else if (groupingType === "Month") {
      const rangeStart = new Date(validTimeRange.Month);
      const rangeEnd = now;

      // const daysInCurrentMonth = new Date(rangeEnd.getFullYear(), rangeEnd.getMonth() + 1, 0).getDate();
      // const daysInPreviousMonth = new Date(rangeStart.getFullYear(), rangeStart.getMonth() + 1, 0).getDate();

      const days = [];
      let currentDate = new Date(rangeStart);
      while (currentDate <= rangeEnd) {
        days.push(currentDate.getDate());
        currentDate.setDate(currentDate.getDate() + 1);
      }

      days.forEach(day => {
        const date = new Date(rangeStart.getFullYear(), rangeStart.getMonth(), day);
        grouped.set(String(day), initializeValues(alertTypes));
      });

      grouped = new Map([...grouped]);
    } else if (groupingType === "Year") {
      const monthsOfYear = ["January", "February", "March", "April", "May", "June", 
                           "July", "August", "September", "October", "November", "December"];
      // Start from next month
      const orderedMonths = [
        ...monthsOfYear.slice(currentMonth + 1),
        ...monthsOfYear.slice(0, currentMonth + 1)
      ];
      orderedMonths.forEach(month => {
        grouped.set(month, initializeValues(alertTypes));
      });
    }

    alerts?.forEach(alert => {
      // Convert alert timestamp to local timezone
      const date = new Date(alert.created_at);

      if (date <= validTimeRange[groupingType]) {
        return;
      }

      let groupKey;
      if (groupingType === "Hours") {
        groupKey = `${String(date.getHours()).padStart(2, '0')}:00`;
      } else if (groupingType === "Week") {
        const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        groupKey = daysOfWeek[date.getDay()];
      } else if (groupingType === "Month") {
        groupKey = String(date.getDate());
      } else if (groupingType === "Year") {
        const monthsOfYear = ["January", "February", "March", "April", "May", "June", 
                             "July", "August", "September", "October", "November", "December"];
        groupKey = monthsOfYear[date.getMonth()];
      }

      const existingValues = grouped.get(groupKey);
      const existingAlert = existingValues?.find(v => v.name === alert.alert_type);
      if (existingAlert) {
        existingAlert.value += 1;
      }

      alertCount[alert.alert_type] = (alertCount[alert.alert_type] || 0) + 1;
    });

    // Convert Map back to array format while preserving order
    return Array.from(grouped.entries()).map(([group, values]) => ({
      group,
      values
    }));
  };

  const transformedData = apiData.sites.map(site => ({
    site_name: site.site_name,
    site_id: site.site_id,
    useCases: site.use_cases?.map(useCase => {
      const alertTypes = getUniqueAlertTypes(useCase);
      const alertCount = {};

      const alerts = {
        Hours: getGroupedData(useCase.alerts, "Hours", alertCount, alertTypes),
        Week: getGroupedData(useCase.alerts, "Week", alertCount, alertTypes),
        Month: getGroupedData(useCase.alerts, "Month", alertCount, alertTypes),
        Year: getGroupedData(useCase.alerts, "Year", alertCount, alertTypes)
      };

      return {
        name: useCase.name,
        id: useCase.id,
        alerts,
        alertCount
      };
    })
  })).filter(site => site.useCases && site.useCases.length > 0);

  const useCaseList = [
    ...new Set(
      apiData.sites.flatMap(site => site.use_cases?.map(({ name }) => name) ?? [])
    )
  ];

  const siteList = apiData.sites.map(site => site.site_name);

  return {
    transformedData,
    siteList,
    useCaseList
  };
};



export const processAlertData = (data) => {
  const twentyFourHours = {};
  const oneWeek = {};
  const oneMonth = {};
  const oneYear = {};
  // const twentyFourHours = {};
  // const twelveHours = {};
  // const fortyEightHours = {};
  const alertCount = {};
  var alert_count = 0;
  // var alertTypes = {}

  // Collect all unique alert types across all data entries
  const allAlertTypes = new Set();

  // Initialize empty groups for 24 hours
  for (let i = 0; i < 24; i++) {
    const hourGroup = `${String(i).padStart(2, "0")}:00`;
    twentyFourHours[hourGroup] = [];
  }

  // Initialize empty groups for 7 days (1 week)
  const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  daysOfWeek.forEach(day => {
    oneWeek[day] = [];
  });

  // Initialize empty groups for 1 month (1 to 31 days)
  for (let i = 1; i <= 31; i++) {
    const dayGroup = `${i}`;
    oneMonth[dayGroup] = [];
  }

  // Initialize empty groups for 12 months (1 year)
  const monthsOfYear = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  monthsOfYear.forEach(month => {
    oneYear[month] = [];
  });


  if (typeof data.alert_count === 'object' && Object.keys(data.alert_count).length === 0) {
    alert_count = 0
  } else {

    alert_count = data.alert_count;
  }

  // Process each entry in the data array
  data.alerts?.forEach(entry => {
    if (entry.all_alerts && Array.isArray(entry.all_alerts)) {
      entry.all_alerts.forEach(alert => {
        allAlertTypes.add(alert.alert_type);

        // Initialize alert count for each alert type
        if (!alertCount[alert.alert_type]) {
          alertCount[alert.alert_type] = 0;
        }

        // Accumulate total alert count
        alertCount[alert.alert_type] += 1;

        const alertDate = new Date(alert.created_at);
        const hour = alertDate.getHours();
        const day = alertDate.getDay(); // Day of the week
        const date = alertDate.getDate(); // Day of the month
        const month = alertDate.getMonth(); // Month of the year

        const hourGroup = `${String(hour).padStart(2, "0")}:00`;
        const dayGroup = daysOfWeek[day];
        const dateGroup = `${date}`;
        const monthGroup = monthsOfYear[month];

        // Helper function to update the group
        const updateGroup = (groupData, group) => {
          const existingAlert = groupData.find(
            (alertItem) => alertItem.name === alert.alert_type
          );

          if (existingAlert) {
            existingAlert.value += 1;
          } else {
            groupData.push({ name: alert.alert_type, value: 1 });
          }
        };

        updateGroup(twentyFourHours[hourGroup], hourGroup);
        updateGroup(oneWeek[dayGroup], dayGroup);
        updateGroup(oneMonth[dateGroup], dateGroup);
        updateGroup(oneYear[monthGroup], monthGroup);
      });
    }
  });


  // Ensure all alert types are present in each group
  const ensureAllAlertTypes = (groupData) => {
    Object.keys(groupData).forEach((group) => {
      allAlertTypes.forEach((alertType) => {
        if (!groupData[group].find((v) => v.name === alertType)) {
          groupData[group].push({ name: alertType, value: 0 });
        }
      });
    });
  };

  // Apply the ensureAllAlertTypes to each duration's data
  ensureAllAlertTypes(twentyFourHours);
  ensureAllAlertTypes(oneWeek);
  ensureAllAlertTypes(oneMonth);
  ensureAllAlertTypes(oneYear);

  const formatData = (data) => {
    return Object.keys(data).map((group) => ({
      group,
      values: data[group],
    }));
  };

  return {
    barChartData: {
      "Hours": formatData(twentyFourHours),
      "Week": formatData(oneWeek),
      "Month": formatData(oneMonth),
      "Year": formatData(oneYear),
    },
    alertCount: alert_count,
    alertTypes: Array.from(allAlertTypes),
  };
};