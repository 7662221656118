import React, { useState, useRef } from "react";
// import PerformanceMetric from "./PerformanceMetric";
// import ActionButton from "./ActionButton";

function PerformanceMetric({ label, value }) {
  return (
    <div className="flex flex-1 shrink gap-3 items-center p-4 bg-white rounded-lg border-2 border-gray-200 border-solid basis-0 min-w-[240px]">
      <div className="flex-1 shrink self-stretch my-auto text-sm leading-none basis-0 text-slate-800">
        {label}
      </div>
      <div className="self-stretch my-auto text-3xl leading-tight text-gray-900">
        {value}
      </div>
    </div>
  );
}

function ActionButton({ children, variant }) {
  const baseClasses =
    "overflow-hidden gap-2 self-stretch p-4 my-auto rounded-xl";
  const variantClasses = {
    primary: "text-gray-50 whitespace-nowrap bg-[#052D61] w-[130px]",
    secondary: "text-[#052D61] bg-white border border-blue-700 border-solid",
  };

  return (
    <button className={`${baseClasses} ${variantClasses[variant]}`}>
      {children}
    </button>
  );
}

// function PerformanceVideo() {
//   return (
//     <video
//       className="object-contain w-full rounded-lg aspect-[1.8] max-md:max-w-full"
//       width="800"
//       height="444"
//       controls
//     >
//       <source src="https://www.w3schools.com/html/mov_bbb.mp4" type="video/mp4" />
//       Your browser does not support the video tag.
//     </video>
//   );
// }

function PerformanceVideo() {
  const [isBuffering, setIsBuffering] = useState(false);
  const videoRef = useRef(null);

  const handleLoadedData = () => {
    setIsBuffering(false);
  };

  const handleWaiting = () => {
    setIsBuffering(true);
  };

  const handlePlaying = () => {
    setIsBuffering(false);
  };

  return (
    <div className="relative w-full aspect-[1.8] max-md:max-w-full">
      <video
        ref={videoRef}
        className="object-contain w-full h-full rounded-lg"
        width="800"
        height="444"
        controls
        onLoadedData={handleLoadedData}
        onWaiting={handleWaiting}
        onPlaying={handlePlaying}
      >
        <source
          src="https://www.w3schools.com/html/mov_bbb.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      {isBuffering && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-200 bg-opacity-50 rounded-lg">
          <div className="w-16 h-16 border-t-4 border-blue-500 border-solid rounded-full animate-spin"></div>
        </div>
      )}
    </div>
  );
}

function ModelPerformanceComponent() {
  const metrics = [
    { label: "Precision", value: "59%" },
    { label: "Recall", value: "41%" },
  ];

  return (
    <section className="flex flex-col p-10 font-semibold bg-gray-50 rounded-2xl max-w-[800px] max-md:px-5">
      <h1 className="w-full text-3xl leading-tight text-gray-900 max-md:max-w-full">
        Model Performance
      </h1>
      <main className="flex flex-col mt-12 w-full whitespace-nowrap max-md:mt-10 max-md:max-w-full">
        <PerformanceVideo />
        <div className="flex flex-wrap gap-3 items-start mt-2.5 w-full h-[50px] max-md:max-w-full">
          {metrics.map((metric, index) => (
            <PerformanceMetric
              key={index}
              label={metric.label}
              value={metric.value}
            />
          ))}
        </div>
      </main>
      <div className="flex gap-2 items-center self-end mt-12 text-sm leading-none text-center max-md:mt-10">
        <ActionButton variant="secondary">Retrain Model</ActionButton>
        <ActionButton variant="primary">Finish</ActionButton>
      </div>
    </section>
  );
}

export default ModelPerformanceComponent;
