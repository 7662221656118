import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import { useSelector } from "react-redux";
import { AddVideo } from "../../components/Modal/AddVideo";
import { CreateSite } from "../../components/Modal/CreateSite";
import { getallstreams } from "../../services/apiServiceDetails";
import { useParams, useNavigate } from "react-router-dom";

import MainContent from "../../components/MainContent";

export const Videos = () => {
  const { siteid, id } = useParams();
  // console.log(siteid, id);
  const navItems = [
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/de3b8b5271d8bc1e88c6056582e69f592845a7ad580a06c9846c637a3015e288?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde",
      text: "Scenarios",
      active: window.location.pathname.includes("/projects"),
      navigate: () => navigate(`/site-details/${siteid}/projects`),
    },
    // {
    //   icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/1273d2ce836ed577aaa9a97d3783aa0f8183a834f317b12a8bb22a73dd01fa74?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde",
    //   text: "Devices",
    //   active: window.location.pathname.includes("/videos"),
    //   navigate: () => navigate(`/site-details/${siteid}/videos`),
    // },
  ];

  const sites = [
    {
      name: "Kolkata Warehouse",
      location: "Kolkata",
      description:
        "Pretium nulla egestas dignissim erat odio. Morbi amet purus volutpat id a purus mauris.",
    },
    {
      name: "Telangana Warehouse",
      location: "Hyderabad",
      description:
        "Pretium nulla egestas dignissim erat odio. Morbi amet purus volutpat id a purus mauris.",
    },
    {
      name: "Karnataka Warehouse",
      location: "Bengaluru",
      description:
        "Pretium nulla egestas dignissim erat odio. Morbi amet purus volutpat id a purus mauris.",
    },
  ];

  const videos = [
    {
      video_name: "Counter",
      video_url: "https://www.coetra/a7f-9j2m3-l0",
      imgsrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/3472d035f2c1530dc9f856737335968c605035d4747f528ac8340ead2bb4e60f?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/3472d035f2c1530dc9f856737335968c605035d4747f528ac8340ead2bb4e60f?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3472d035f2c1530dc9f856737335968c605035d4747f528ac8340ead2bb4e60f?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/3472d035f2c1530dc9f856737335968c605035d4747f528ac8340ead2bb4e60f?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/3472d035f2c1530dc9f856737335968c605035d4747f528ac8340ead2bb4e60f?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3472d035f2c1530dc9f856737335968c605035d4747f528ac8340ead2bb4e60f?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/3472d035f2c1530dc9f856737335968c605035d4747f528ac8340ead2bb4e60f?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/3472d035f2c1530dc9f856737335968c605035d4747f528ac8340ead2bb4e60f?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde",
    },

    {
      video_name: "Aisle",
      video_url: "https://www.coetra/a7f-9j2m3-l0",
      imgsrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/16b1d6d9156fbc13b11b81b02647199f77b0a7b8c5a0281d7d4cffcf46aa91e6?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/16b1d6d9156fbc13b11b81b02647199f77b0a7b8c5a0281d7d4cffcf46aa91e6?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/16b1d6d9156fbc13b11b81b02647199f77b0a7b8c5a0281d7d4cffcf46aa91e6?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/16b1d6d9156fbc13b11b81b02647199f77b0a7b8c5a0281d7d4cffcf46aa91e6?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/16b1d6d9156fbc13b11b81b02647199f77b0a7b8c5a0281d7d4cffcf46aa91e6?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/16b1d6d9156fbc13b11b81b02647199f77b0a7b8c5a0281d7d4cffcf46aa91e6?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/16b1d6d9156fbc13b11b81b02647199f77b0a7b8c5a0281d7d4cffcf46aa91e6?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/16b1d6d9156fbc13b11b81b02647199f77b0a7b8c5a0281d7d4cffcf46aa91e6?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde",
    },

    {
      video_name: "Counter (View 2)",
      video_url: "https://www.coetra/a7f-9j2m3-l0",
      imgsrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/3472d035f2c1530dc9f856737335968c605035d4747f528ac8340ead2bb4e60f?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/3472d035f2c1530dc9f856737335968c605035d4747f528ac8340ead2bb4e60f?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3472d035f2c1530dc9f856737335968c605035d4747f528ac8340ead2bb4e60f?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/3472d035f2c1530dc9f856737335968c605035d4747f528ac8340ead2bb4e60f?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/3472d035f2c1530dc9f856737335968c605035d4747f528ac8340ead2bb4e60f?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3472d035f2c1530dc9f856737335968c605035d4747f528ac8340ead2bb4e60f?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/3472d035f2c1530dc9f856737335968c605035d4747f528ac8340ead2bb4e60f?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/3472d035f2c1530dc9f856737335968c605035d4747f528ac8340ead2bb4e60f?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde",
    },
  ];

  const [isModalOpen, setModalOpen] = useState(false);
  const [name, setName] = useState("");
  const user = useSelector((state) => state.user);
  const siteNames = useSelector((state) => state.siteDetails);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const navigate = useNavigate();
  const [allstreams, setallstreams] = useState([]);

  const getAllStreams = async () => {
    const resp = await getallstreams(siteid);
    setallstreams(resp);
  };
  // console.log("siteNames", siteNames);
  useEffect(() => {
    getAllStreams();
  }, []);

  const handleSubmit = (e) => {
    // e.preventDefault();
    getAllStreams();
    // Handle form submission logic here
    // console.log("Form submitted");
    closeModal();
  };

  useEffect(() => {
    const siteName = siteNames.names.find((item) => item.id == siteid)?.name;
    setName(siteName);
  }, []);

  const handleBackClick = () => {
    navigate("/home"); // Navigate to the dashboard page
  };

  // console.log(siteNames);

  return (
    <div className=" w-screen bg-gray-100 ">
      <div className="flex flex-row h-full">
        <Sidebar
          navItems={navItems}
          userName={user.name}
          usermail={user.email}
          currentPage="Devices"
          siteName={"Devices"}
          handleBackClick={handleBackClick}
          selectDataOptions={siteNames}
        />
        <MainContent
          currentPage="Devices"
          data={allstreams}
          title={`Devices`}
          openModal={openModal}
          buttonText={`Add Device`}
          contentType={videos}
          links={[
            { name: "Dashboard", path: "/home" },
            { name: `${name}` },
            { name: "Devices" },
          ]}
        />
        {isModalOpen ? (
          <AddVideo
            isOpen={isModalOpen}
            onClose={closeModal}
            onSubmit={handleSubmit}
          />
        ) : null}
      </div>
    </div>
  );
};
