import React, { useState, useEffect, useRef } from "react";
import VideoThumbnail from "./thumbnailImage";
import Breadcrumb from "./Breadcrumb";
import HeaderWithButton from "./HeaderWithButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { StreamCard } from "./StreamCard";
import {
  startDetection,
  StopProjectDetections,
  UpdateSite,
} from "../services/apiServiceDetails";
import { useParams } from "react-router-dom";
import ImageGallery from "./ImageGallery";
import { CustomMarker } from "./CustomMarker";
import useCustomToast from "../hooks/useToast";
import OptionsMenu from "./OptionsMenu";
import EmptyDataComponent from "./EmptyDataComponent";
import ProcessBar from "../components/ProcessBar";
import { useNavigate } from "react-router-dom";
import {
  GoogleMap,
  LoadScript,
  Marker,
  useJsApiLoader,
  OverlayView,
} from "@react-google-maps/api";
import axios from "axios";
import Loader from "./Loader";
import DevImageGrid from "./TrainModals/DevImage";
// import { format, toZonedTime } from "date-fns-tz";

const sites = [
  { name: "Alabama Warehouse", location: "Alabama, US", occurance: 15 },
  { name: "Nates Warehouse", location: "Nates, France", occurance: 4 },
  { name: "Ottawa Warehouse", location: "Ottawa, Canada", occurance: 6 },
  { name: "Oran Warehouse", location: "Oran, Algeria", occurance: 5 },
];

const MainContent = ({
  data,
  alertInTimeFrame,
  maxDetection,
  activeDetection,
  openModal,
  currentPage,
  handleCardClick,
  emptyScreenbuttontext,
  getLatestStatus,
  emptyscreenbuttonaction,
  setDetectionStatus,
  fetchDetectionStatus,
  detectionStatus,
  detectionLoading,
  detection_status,
  hideBar,
  setHideBar,
  links,
  title,
  buttonText,
  flowslength,
  handleUpdate,
  projectDetails,
  detectionStopinging,
  setDetectionStoping,
  ...props
}) => {
  const [showOptions, setShowOptions] = useState(null);
  const { project, siteid, projectid } = useParams();
  //const [alertFrame,setAlertFrame] = useState([]);
  const optionsRef = useRef(null);
  const navigate = useNavigate();
  const defaultCenter = {
    lat: 28.613939,
    lng: 77.209023, // Center the map somewhere relevant
  };
  const mapStyles = {
    height: "600px",
    width: "100%",
  };
  const [coordinates, setCoordinates] = useState([]);
  const [center, setCenter] = useState(defaultCenter);
  const [zoom, setZoom] = useState(2.5); // Default zoom level
  const mapRef = useRef(null);
  const showToast = useCustomToast();
  const [isMapLayerLoading, setIsMapLayerLoading] = useState(true);

  const handleEdit = (item) => {
    handleUpdate("edit", item);
  };

  console.log("data dashboard", data);

  const handleMapInteraction = () => {
    // Update the center when the user interacts with the map
    if (mapRef.current) {
      const newCenter = mapRef.current.getCenter();
      setCenter({
        lat: newCenter.lat(),
        lng: newCenter.lng(),
      });
    }
  };

  const convertToIST = (gmtString) => {
    try {
      // Create a Date object directly from the GMT string
      const utcString = gmtString + "Z"; // Append 'Z' to indicate UTC time
      const gmtDate = new Date(utcString);

      console.log("date", gmtDate);

      // Validate the Date
      if (isNaN(gmtDate.getTime())) {
        throw new Error("Invalid date string format");
      }

      // Convert to local time string
      const localTime = gmtDate.toLocaleString(); // Adjusts to the runtime's local timezone
      console.log("date", localTime);

      return localTime;
    } catch (error) {
      console.error("Error converting time:", error.message);
      return "Invalid time";
    }
  };

  const handleButtonClick = (warehouseName) => {
    sessionStorage.setItem(
      "warehouseData",
      JSON.stringify({ warehouse: warehouseName })
    );
    navigate(`/dashboard`);
    // navigate('/dashboard', { state: { warehouse: warehouseName } });
  };

  const handleDelete = (item) => {
    // console.log("Delete", item);
  };

  const handleMoreClick = (index) => {
    console.log("index", index);
    setShowOptions((prev) => (prev === index ? null : index));
  };

  console.log(showOptions);

  const closeOptions = () => {
    setShowOptions(null);
  };

  const handleOptionClick = (action, item) => {
    setShowOptions(null);
    if (action === "edit") {
      handleEdit(item);
    } else if (action === "delete") {
      handleDelete(item);
    } else if (action === "add-interest-areas") {
      // console.log(action);
    }
  };

  const startdetection = async () => {
    try {
      console.log("detect-start", data, data[0]?.rois?.length);
      if (data[0]?.rois?.length > 0) {
        const resp = await startDetection(projectid);
        getLatestStatus();
        showToast("Detection Initation Request Sent", "success");
      } else {
        showToast(
          "Please add Area of interest before starting the detections",
          "error"
        );
      }
    } catch (error) {
      console.error("Error starting detection:", error);
      setDetectionStatus(false);
      showToast("An error occurred while starting detection.", "error");
    }
  };

  const stopdetection = async () => {
    try {
      const resp = await StopProjectDetections(projectid);
      // setDetectionStatus(false);
      setDetectionStoping(true);
      showToast("Detection Stop Request Sent", "success");
    } catch (error) {
      console.error("Error stop detection:", error);
      // setDetectionStatus(false);
      // showToast("An error occurred while stopping detection.", "error");
    }
  };
  const handleClickOutside = (event) => {
    if (optionsRef.current && !optionsRef.current.contains(event.target)) {
      setShowOptions(null);
    }
  };

  // Create a lookup object for alert data
  const alertLookup = alertInTimeFrame?.reduce((acc, result) => {
    acc[result.siteName] = {
      growth: result.growth,
      alertsInTimeFrame: result.alertsInTimeFrame,
    };
    return acc;
  }, {});

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const googleApiKey = "AIzaSyCiHTXiHSC2O-LnDLZOypQ29DcKqUsx6O8"; // Replace with your API key

  // Function to fetch coordinates for each address
  const fetchCoordinates = async () => {
    const newCoordinates = [];
    for (const site of data) {
      // const response = await axios.get(
      //   `https://maps.googleapis.com/maps/api/geocode/json`,
      //   {
      //     params: {
      //       address: site.location,
      //       key: googleApiKey,
      //     },
      //   }
      // );
      // if (response.data.results.length > 0) {
      // const { lat, lng } = response.data.results[0].geometry.location;

      // const latitude = lat.toString();
      // const longitude = lng.toString();

      // const updateresp = await UpdateSite(
      //   site.id,
      //   site.name,
      //   site.location,
      //   site.description,
      //   latitude,
      //   longitude
      // );

      // console.log("updated-site", updateresp);

      // Get alert data for the specific site
      const alertData = alertLookup[site.siteName] || {
        growth: null,
        alertsInTimeFrame: null,
      };

      console.log("incidents", site.count_24hrs, site.count_48hrs);

      const previous24hrs = site.count_48hrs - site.count_24hrs;
      const current24hrs = site.count_24hrs;
      newCoordinates.push({
        lat: site.latitude,
        lng: site.longitude,
        id: site.id,
        name: site.name,
        location: site.location,
        useCases: site.useCases,
        growth: ((current24hrs - previous24hrs) / (previous24hrs || 1)) * 100,
        alertsInTimeFrame: site.count_24hrs,
      });
      // }
      // if (response.data.results.length > 0) {
      //   const { lat, lng } = response.data.results[0].geometry.location;
      //   newCoordinates.push({ lat, lng, id:site.id , name: site.siteName, location:site.location,
      //     useCases: site.useCases
      //   });
      // }
    }
    setCoordinates(newCoordinates);
  };

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: googleApiKey,
  });

  const handleZoomChanged = () => {
    if (mapRef.current) {
      const newZoom = mapRef.current.getZoom();
      setZoom(newZoom);
    }
  };

  useEffect(() => {
    if (currentPage == "Sites" && data.length > 0) fetchCoordinates();
  }, [currentPage, data]);

  // useEffect(() => {
  //   if (coordinates.length > 0 && mapRef.current) {
  //     const lastCoordinate = coordinates[coordinates.length - 1];
  //     mapRef.current.setCenter({
  //       lat: lastCoordinate.lat,
  //       lng: lastCoordinate.lng,
  //     });
  //     setZoom(10);
  //   }
  // }, [coordinates]);

  console.log("coord", coordinates);

  return (
    <main
      className="flex flex-col h-[calc(100vh-2rem)] overflow-hidden overflow-y-auto bg-white ml-5 mr-4 my-4 w-full rounded-xl"
      data-testid="main-container"
    >
      <div className="flex flex-col flex-grow p-4 w-full">
        <div className="flex flex-col flex-grow w-full shadow-sm max-md:max-w-full">
          {links ? <Breadcrumb links={links} data-testid="breadcrumb" /> : null}

          <section
            className="flex flex-col flex-1 p-8 w-full bg-white rounded-xl shadow-sm max-md:px-5 max-md:max-w-full"
            data-testid="section-container"
          >
            {currentPage === "Project-Details" ? (
              <>
                <HeaderWithButton
                  title={title}
                  buttonText={buttonText}
                  detectionLoading={detectionLoading}
                  detectionStopinging={detectionStopinging}
                  maxDetection={maxDetection}
                  activeDetection={activeDetection}
                  detectionStatus={detectionStatus}
                  setDetectionStatus={setDetectionStatus}
                  type={{
                    text: "project-details",
                    retrain: props.retrainModel,
                  }}
                  streammodal={{
                    text: "project-details-stream-modal",
                    openStream: props.opeStreamMOdal,
                  }}
                  onButtonClick={
                    detectionStatus
                      ? detectionStopinging
                        ? null
                        : stopdetection
                      : detectionLoading
                      ? null
                      : startdetection
                  }
                  testid="header-project-details"
                  assosUpdated={props.assosUpdated}
                  currentPage={currentPage}
                />
              </>
            ) : (
              <HeaderWithButton
                title={title}
                buttonText={buttonText}
                onButtonClick={openModal}
                isExist={data ? true : false}
                testid="header-other"
              />
            )}

            {currentPage === "Project-Details" && (
              <div className=" flec flex-col w-full">
                <div
                  className="flex flex-row items-center gap-3 text-sm"
                  data-testid="sub-heading"
                >
                  <span className="text-gray-500">{props.subHeading}</span>
                  <span className="w-1 h-1 bg-gray-300 rounded-full"></span>
                  <span
                    className="text-[#052D61] font-medium cursor-pointer"
                    onClick={() => props.logicsClicked(true)}
                    data-testid="app-logics"
                  >
                    {flowslength} {props.useCase} selected
                  </span>
                  <span className="flex justify-between items-center">
                    <p className="text-sm font-medium text-[#052D61] truncate">
                      Detection Status:
                      <span
                        className={`font-medium ${
                          detection_status === "Active"
                            ? "text-green-500"
                            : "text-red-400"
                        }`}
                      >
                        {" "}
                        {detection_status}{" "}
                      </span>
                    </p>
                  </span>
                </div>

                <ProcessBar
                  projectid={projectid}
                  hideBar={hideBar}
                  setHideBar={setHideBar}
                />
                {/* <TempProcessBar projectid={projectid} /> */}
              </div>
            )}
            {/* </div> */}

            {(data && data.length > 0) || !isMapLayerLoading ? (
              <>
                {currentPage === "Sites" ? (
                  <>
                    <section className="flex overflow-hidden flex-row justify-between mt-8 w-full bg-gray-50 rounded-2xl border-2 border-gray-200 border-solid min-h-[600px] max-md:max-w-full">
                      {isLoaded ? (
                        <GoogleMap
                          mapContainerStyle={mapStyles}
                          zoom={zoom}
                          onDragEnd={handleMapInteraction}
                          center={center}
                          onLoad={(map) => (
                            (mapRef.current = map), setIsMapLayerLoading(false)
                          )}
                          onZoomChanged={handleZoomChanged}
                          options={{
                            streetViewControl: false,
                            mapTypeControl: false,
                          }}
                        >
                          {coordinates?.map((coord, index) => (
                            <OverlayView
                              key={index}
                              position={{ lat: coord.lat, lng: coord.lng }}
                              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                            >
                              <div>
                                <CustomMarker
                                  useCases={coord.useCases}
                                  alertsInTimeFrame={coord.alertsInTimeFrame}
                                  growth={coord.growth}
                                  siteName={coord.name}
                                />
                                <article
                                  className="flex flex-col p-3 max-w-sm w-64 rounded-lg bg-white bg-opacity-70 shadow-[0px_1px_10px_rgba(51,51,51,0.15)]"
                                  style={{
                                    display: zoom >= 3.5 ? "block" : "none",
                                    opacity: zoom >= 3.5 ? "1" : "0",
                                    transition: "opacity 0.3s ease",
                                  }}
                                >
                                  <header className="flex flex-col w-full mb-2">
                                    <h2 className="text-base font-medium leading-none font-golos text-gray-700">
                                      <span className=" capitalize">
                                        {coord.name}
                                      </span>
                                      ,{" "}
                                      <span className=" capitalize">
                                        {coord.location}
                                      </span>
                                    </h2>
                                  </header>
                                  <div className="flex justify-start items-start mt-2.5">
                                    {coord.useCases
                                      ? coord.useCases?.map(
                                          (useCase, index) => (
                                            <div key={index}>
                                              {useCase.useCaseName.includes(
                                                "PPE"
                                              ) && (
                                                <p className="text-xs leading-4 font-golos text-gray-600">
                                                  {/* Safety Incident:{" "}
                                                  {coord.alertsInTimeFrame} */}
                                                  {/* Rise in safety incidents: +40% 
                                                  Drop in efficiency +-20% */}
                                                  {/* Safety Incidents : +40%
                                                  Employee Efficiency: -20%
                                                  Downtime & Maintenance: +10% */}
                                                </p>
                                              )}
                                            </div>
                                          )
                                        )
                                      : null}
                                    <p className="text-xs leading-4 font-golos text-gray-600">
                                      Rise in safety incidents:{" "}
                                      {Math.ceil(coord.growth)}% <br />
                                    </p>
                                    {/* <p className="text-xs leading-4 font-sans text-gray-600">
                                                        Safety Incident: {coord.alertsInTimeFrame}
                                                      </p> */}
                                    {/* Buttons shown regardless of coord.useCases existence */}
                                  </div>
                                  <div className="flex justify-between mt-2">
                                    <button
                                      onClick={() =>
                                        handleButtonClick(coord.name)
                                      }
                                      className="bg-[#052D61]  font-light text-white py-1 px-3 rounded-md hover:bg-blue-700 transition-colors"
                                    >
                                      View Dashboard
                                    </button>
                                    <button
                                      onClick={() => handleCardClick(coord.id)}
                                      className="bg-[#052D61] font-light text-white py-1 px-3 ml-4 rounded-md hover:bg-blue-700 transition-colors"
                                    >
                                      View Site Setup
                                    </button>
                                  </div>
                                </article>
                              </div>
                            </OverlayView>
                          ))}
                        </GoogleMap>
                      ) : (
                        <div>Loading...</div>
                      )}
                    </section>
                  </>
                ) : currentPage === "Scenario" ? (
                  <div className="w-full max-h-full mt-6 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200 font-golos">
                    <div
                      className="flex flex-wrap w-full max-h-full"
                      data-testid="content-grid"
                    >
                      {data
                        .slice()
                        .reverse()
                        .map((project, index) => (
                          <div
                            key={index}
                            className="w-full sm:w-1/2 lg:w-1/3 pb-8 px-4"
                            data-testid={`project-card-wrapper-${index}`}
                          >
                            <div
                              onClick={() => handleCardClick(project.id)}
                              className="flex flex-row p-5 bg-gray-50 gap-3 rounded-xl border-2 border-gray-200 border-solid cursor-pointer hover:bg-gray-100 transition-all h-42"
                              data-testid={`project-card-${index}`}
                            >
                              <div className="flex items-start w-full text-gray-900 relative">
                                <div className="flex flex-col flex-1 min-w-0 justify-between h-full">
                                  <div className="flex justify-between items-start gap-2">
                                    <h2 className=" font-suse text-lg font-semibold leading-tight tracking-wide truncate">
                                      {project.name}
                                    </h2>
                                    <MoreVertIcon
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleMoreClick(index);
                                      }}
                                      className="shrink-0 w-5 h-5 text-gray-400 cursor-pointer hover:text-gray-600"
                                    />
                                  </div>
                                  <p className="text-sm font-medium gap-2 truncate mr-2">
                                    {project.usecase}
                                  </p>
                                  <div className="flex justify-between mt-2 items-center">
                                    <p className="text-sm font-medium truncate mr-2 ">
                                      Detection Status:
                                      <span
                                        className={`font-medium ${
                                          project.detection_status === "Active"
                                            ? "text-green-500"
                                            : "text-red-400"
                                        }`}
                                      >
                                        {" "}
                                        {project.detection_status}{" "}
                                      </span>
                                    </p>
                                  </div>
                                  <p className="text-sm font-medium truncate mt-2 mr-2">
                                    Created At:
                                    <span
                                      className={`font-medium text-gray-500`}
                                    >
                                      {" "}
                                      {convertToIST(project.created_at)}
                                    </span>
                                  </p>
                                </div>
                                {showOptions === index && (
                                  <OptionsMenu
                                    showOptions={showOptions === index}
                                    closeOptions={closeOptions}
                                    options={[
                                      {
                                        label: "Edit",
                                        onClick: () =>
                                          handleOptionClick("edit", project),
                                        testid: `project-card-${index}-edit-button`,
                                      },
                                      {
                                        label: "Delete",
                                        onClick: () =>
                                          handleOptionClick("delete", project),
                                        testid: `project-card-${index}-delete-button`,
                                      },
                                    ]}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                ) : currentPage === "Model Dashboard" ? (
                  <ImageGallery
                    apiEndpointUrl={"https://picsum.photos/v2/list"}
                  />
                ) : currentPage === "Project-Details" ? (
                  <div className="grid grid-cols-3 w-full overflow-y-auto font-golos">
                    {/* <DevImageGrid /> */}
                    {data &&
                      data
                        .slice()
                        .reverse()
                        .map((stream, index) => (
                          <div
                            className="flex-1 min-w-[calc(33.33%-16px)] m-2"
                            key={index}
                          >
                            <StreamCard
                              stream={stream}
                              index={index}
                              project={projectDetails}
                              handleMoreClick={handleMoreClick}
                              handleCardClick={handleCardClick}
                              fetchDetectionStatus={fetchDetectionStatus}
                              showOptions={showOptions}
                              handleOptionClick={handleOptionClick}
                              testid={`stream-card-${index}`}
                              closeOptions={closeOptions}
                            />
                          </div>
                        ))}
                  </div>
                ) : (
                  data && (
                    <div className=" w-full grid grid-cols-3 mx-auto py-5">
                      {data
                        .slice()
                        .reverse()
                        .map((stream, index) => (
                          <div
                            key={index}
                            className="flex flex-row grow shrink whitespace-nowrap rounded-xl border-2 border-gray-200 border-solid shadow-[0px_0px_8px_rgba(0,0,0,0.05)] cursor-pointer hover:shadow-md transition-all"
                            style={{ width: "320px", height: "220px" }}
                            data-testid={`stream-thumbnail-${index}`}
                          >
                            <div className="flex relative flex-col w-full h-full">
                              <VideoThumbnail videoUrl={stream?.video_url} />
                              <div className="flex flex-col pr-4 pb-4 absolute top-0 left-0 pl-4 w-full min-h-[114px]">
                                <div className="flex justify-between items-center w-full relative">
                                  <div className="text-lg font-semibold leading-loose text-gray-50 z-50">
                                    {stream?.video_name}
                                  </div>

                                  <MoreVertIcon
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleMoreClick(index);
                                    }}
                                    style={{
                                      fontSize: "1.25rem",
                                      color: "#98A2B3",
                                    }}
                                    className="shrink-0 mt-1 w-5 aspect-square cursor-pointer"
                                  />
                                  {showOptions === index && (
                                    <OptionsMenu
                                      showOptions={showOptions === index}
                                      closeOptions={closeOptions}
                                      options={[
                                        {
                                          label: "Edit",
                                          onClick: () =>
                                            handleOptionClick("edit", stream),
                                          testid: `stream-thumbnail-${index}-edit-button`,
                                        },
                                        {
                                          label: "Delete",
                                          onClick: () =>
                                            handleOptionClick("delete", stream),
                                          testid: `stream-thumbnail-${index}-delete-button`,
                                        },
                                      ]}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  )
                )}
              </>
            ) : currentPage === "Project-Details" ? (
              <EmptyDataComponent
                currentPage={currentPage}
                title={title}
                buttonText={emptyScreenbuttontext}
                onButtonClick={emptyscreenbuttonaction}
              />
            ) : currentPage === "Sites" ? (
              <Loader
                heading={"Loading Map"}
                subheading={"Please wait while we load the map."}
              />
            ) : (
              <EmptyDataComponent
                currentPage={currentPage}
                title={title}
                buttonText={buttonText}
                onButtonClick={openModal}
              />
            )}
          </section>
        </div>
      </div>
    </main>
  );
};

export default MainContent;
