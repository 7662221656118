import React, { useMemo, useState, useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import PropTypes from "prop-types";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { CustomTooltip, formatTime } from "./utils";
import { alertConfig } from "../../../utils/alertUtils";

const AnalyticsCard = ({
  data,
  timeframe = "day",
  isLoading = false,
  onClick = () => {},
  isLarge = false,
  noData,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [datecounters, setDatecounters] = useState("Last 1");

  // console.log("!!!!!!!!!!!!!!!!!!!!!!! in card", data);

  useEffect(() => {
    if (timeframe === "Hours") {
      setDatecounters("Last 24");
    }
  }, [timeframe]);

  const isForklift =
    data.name === "Forklift Safety, Compliance, Downtime, and Maintenance";

  const config = useMemo(() => {
    if (isForklift) {
      return {
        title: "Average Forklift Load/Unload Time",
        lines: [
          { key: "loading_time", name: "Loading Time", color: "#295F98" },
          { key: "unloading_time", name: "Unloading Time", color: "#60a5fa" },
        ],
        formatValue: formatTime,
      };
    }
    return {
      title: "Number of Packages",
      lines: [
        { key: "picked_boxes", name: "Picked", color: "#8884d8" },
        { key: "packed_boxes", name: "Packed", color: "#82ca9d" },
        { key: "shipped_boxes", name: "Shipped", color: "#ffc658" },
      ],
      formatValue: (val) => Math.round(val),
    };
  }, [isForklift]);

  let percentage_change = [];
  if (isForklift) {
    percentage_change.push(
      data.percentage_change?.loading_time,
      data.percentage_change?.unloading_time
    );
  } else {
    percentage_change.push(
      data.percentage_change?.picked_boxes,
      data.percentage_change?.packed_boxes,
      data.percentage_change?.shipped_boxes
    );
  }

  const cumulativeChange = Math.ceil(
    percentage_change.reduce((acc, val) => acc + val, 0)
  );

  const calculateStats = () => {
    if (!data?.Events?.length) {
      return {
        metrics: {},
        change: 0,
        isPositive: false,
      };
    }

    const events = data.Events;
    const metrics = {};
    let total = 0;

    config.lines.forEach((line) => {
      const value = events.reduce(
        (sum, event) => sum + (parseFloat(event[line.key]) || 0),
        0
      );
      metrics[line.key] = value;
      total += value;
    });

    const previousTotal = total * 1.1;
    const changePercent = (
      ((total - previousTotal) / previousTotal) *
      100
    ).toFixed(1);

    return {
      metrics,
      change: Math.abs(changePercent),
      isPositive: changePercent > 0,
    };
  };

  const { metrics, change, isPositive } = calculateStats();

  const axisConfig = useMemo(() => {
    if (timeframe === "hour") {
      return {
        xAxisTitle: "Hour (00-23)",
        yAxisTitle: "Total Minutes (0-60)",
        yTicks: [0, 15, 30, 45, 60],
        xTicks: Array.from({ length: 24 }, (_, i) => i),
      };
    } else if (timeframe === "week") {
      return {
        xAxisTitle: "Days of the Week",
        yAxisTitle: "Total Hours (0-23)",
        xTicks: [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
        ],
        yTicks: [0, 5, 10, 15, 20, 23],
      };
    } else if (timeframe === "month") {
      const daysInMonth = new Date().getDate();
      return {
        xAxisTitle: "Days of the Month",
        yAxisTitle: "Total Hours (0-23)",
        xTicks: Array.from({ length: daysInMonth }, (_, i) => i + 1),
        yTicks: [0, 5, 10, 15, 20, 23],
      };
    } else if (timeframe === "year") {
      return {
        xAxisTitle: "Months",
        yAxisTitle: "Total Hours (0-730)",
        xTicks: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        yTicks: [0, 100, 300, 500, 730],
      };
    }
    return {};
  }, [timeframe]);

  const CustomLegend = () => (
    <div className="flex justify-center gap-4 text-xs">
      {config.lines.map((line) => (
        <div key={line.key} className="flex items-center">
          <div
            className="w-3 h-3 rounded-full mr-1"
            style={{ backgroundColor: line.color }}
          ></div>
          <span>{line.name}</span>
        </div>
      ))}
    </div>
  );

  return (
    <div
      className={`flex flex-col px-2 py-2 rounded-lg border-2 border-gray-200 ${
        isLarge
          ? "w-full h-full"
          : "basis-[300px] min-w-[300px] max-w-[300px] h-[220px] cursor-pointer"
      }  hover:border-gray-300 transition-colors duration-200`}
      onClick={
        !isLarge && !noData ? () => onClick(data, config.title) : undefined
      }
    >
      {noData ? (
        <div className="relative flex flex-col items-center justify-center h-full">
          {/* Title on the top left */}
          <h1 className="absolute top-0 left-0 p-2 text-md font-semibold text-gray-500">
            {config.title}
          </h1>

          {/* No data message centered */}
          <div className="flex-1 flex items-center justify-center">
            <span className="text-sm text-gray-500 font-medium text-center">
              {config.title.toLowerCase().includes("safety") ||
              config.title.toLowerCase().includes("compliance") ||
              config.title.toLowerCase().includes("forklift")
                ? "No safety incidents to report. Keep up the great work!"
                : "No data to display"}
            </span>
          </div>
        </div>
      ) : (
        <>
          <div className="flex justify-between items-start p-2 h-[80px] mb-5">
            <div className="flex flex-col">
              <div className="text-md font-semibold text-gray-500">
                {config.title}
              </div>

              <div className="flex flex-col gap-1 mt-2 mb-2 text-xs text-gray-900">
                {config.lines.map((line, index) => {
                  const metricValue = metrics[line.key];
                  const change = Math.ceil(percentage_change[index]);
                  const { color, arrow } =
                    alertConfig[config.title]?.getArrow(change);

                  return (
                    <div key={line.key} className="flex items-center">
                      <div
                        className="w-3 h-3 rounded-full mr-1"
                        style={{ backgroundColor: line.color }}
                      ></div>
                      <span>
                        {line.name}: {config.formatValue(metricValue)} (
                        {Math.abs(change)}%)
                      </span>
                      <div className={`flex items-center gap-1 ${color}`}>
                        {arrow === "up" ? (
                          <ArrowUpward
                            className={`h-3 w-3 ${
                              color === "red"
                                ? "text-red-600"
                                : "text-emerald-600"
                            }`}
                          />
                        ) : (
                          <ArrowDownward
                            className={`h-3 w-3 ${
                              color === "red"
                                ? "text-red-600"
                                : "text-emerald-600"
                            }  `}
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div
              className={`relative flex items-center text-xs font-bold ${
                alertConfig[config.title]?.getArrow(cumulativeChange).color ===
                "red"
                  ? "text-red-600"
                  : "text-emerald-600"
              }`}
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
            >
              {alertConfig[config.title]?.getArrow(cumulativeChange).arrow ===
              "up" ? (
                <ArrowUpward className="h-3 w-3" />
              ) : (
                <ArrowDownward className="h-3 w-3" />
              )}
              <span>{Math.ceil(Math.abs(cumulativeChange))}%</span>
              {showTooltip && (
                <div className="absolute bottom-full right-[-200%] mb-1 p-2 w-[200px] text-xs font-light text-black bg-gray-100 border-gray-300 border-[1px] rounded">
                  Change in Total {isForklift ? "Time" : "Volume"} for{" "}
                  {datecounters} {timeframe}
                </div>
              )}
            </div>
          </div>

          {isLoading ? (
            <div className="w-full flex justify-center items-center">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
            </div>
          ) : (
            <div className={`${isLarge ? "h-[600px] p-1" : "h-[130px]"}`}>
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  data={data?.Events || []}
                  margin={{ top: 10, right: 5, left: 5, bottom: 5 }}
                >
                  {isLarge ? (
                    <>
                      <XAxis
                        dataKey="created_at"
                        ticks={axisConfig.xTicks}
                        tick={{ angle: -45, textAnchor: "end", fontSize: 12 }}
                        label={{
                          value: axisConfig.xAxisTitle,
                          position: "insideBottom",
                          offset: -5,
                        }}
                      />
                      <YAxis
                        ticks={axisConfig.yTicks}
                        label={{
                          value: axisConfig.yAxisTitle,
                          angle: -90,
                          position: "insideLeft",
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <XAxis
                        tick={{ angle: -45, textAnchor: "end", fontSize: 12 }}
                        dataKey="created_at"
                        hide
                      />
                      <YAxis hide />
                    </>
                  )}
                  <Tooltip
                    content={(props) => (
                      <CustomTooltip
                        {...props}
                        isForklift={isForklift}
                        timeframe={timeframe}
                      />
                    )}
                  />
                  {config.lines.map((line) => (
                    <Line
                      key={line.key}
                      type="monotone"
                      dataKey={line.key}
                      stroke={line.color}
                      strokeWidth={2}
                      dot={true}
                      name={line.name}
                    />
                  ))}
                </LineChart>
              </ResponsiveContainer>
            </div>
          )}
        </>
      )}
    </div>
  );
};

AnalyticsCard.propTypes = {
  data: PropTypes.shape({
    Events: PropTypes.arrayOf(PropTypes.object),
    name: PropTypes.string.isRequired,
  }).isRequired,
  timeframe: PropTypes.string,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  isLarge: PropTypes.bool,
};

const LineCharts = ({ data, timeframe, isModal = false, onClick }) => {
  // console.log("!!!!!!!!!!!!!!!!!!!!!!! ", timeframe, data);

  const show =
    data.name === "Forklift Safety, Compliance, Downtime, and Maintenance" ||
    data.name === "Inventory Management Efficiency";

  if (!show) {
    return null;
  }

  const hasData = data?.Events?.some(
    (event) =>
      event?.loading_time ||
      event?.unloading_time ||
      event?.picked_boxes ||
      event?.packed_boxes ||
      event?.shipped_boxes
  );
  return (
    <div className="flex flex-wrap gap-4">
      <AnalyticsCard
        data={data}
        timeframe={timeframe}
        isLarge={isModal}
        onClick={onClick}
        noData={!hasData}
      />
    </div>
  );
};

LineCharts.propTypes = {
  data: PropTypes.shape({
    Events: PropTypes.arrayOf(PropTypes.object),
    name: PropTypes.string.isRequired,
  }).isRequired,
  timeframe: PropTypes.string,
};

export default LineCharts;
