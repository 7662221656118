export const rootCauseLabels = {
  level1: "Why did the employee not wear the safety helmet?",
  level2: "What was the immediate cause?",
  level3: "What were the contributing factors?",
  level4: "What is the root cause?"
};

export const incidentLevels = {
  "Safety helmet was not available with the employee": {
    nextQuestion: "Why was the safety helmet not available?",
    options: {
      "Safety helmet was not provided to the employee": {
        nextQuestion: "Why was the safety helmet not provided?",
        options: {
          "Safety helmet was out of stock": {
            nextQuestion: "What led to the safety helmet being out of stock?",
            options: [
              "Ineffective system to track PPE inventory levels",
              "Unexpected demand surge for safety helmets",
              "Supply chain delays",
              "Insufficient procurement planning"
            ]
          },
          "There was no budget for the safety helmet": {
            nextQuestion: "What caused the budget issue?",
            options: [
              "Inadequate safety budget planning",
              "Previous overspending or budget cuts",
              "No dedicated PPE budget",
              "Lack of management awareness to allocate budget for PPE kits"
            ]
          },
          "Oversight during the onboarding process": {
            nextQuestion: "What caused this oversight?",
            options: [
              "Lack of a standardized onboarding checklist",
              "Insufficient training for HR or supervisors regarding employee onboarding",
              "High turnover of employees impacting consistent onboarding"
            ]
          }
        }
      },
      "Safety helmet was confiscated from the employee": {
        nextQuestion: "Why was the safety helmet confiscated?",
        options: {
          "Safety helmet did not meet the required safety standard": {
            nextQuestion: "What made the helmet non-compliant?",
            options: [
              "Purchased from an unverified supplier or did not comply with safety certifications",
              "Helmet's structural integrity degraded due to prolonged use or harsh conditions",
              "Helmet was unsuitable for the work environment",
              "Helmet had passed its expiration date"
            ]
          },
          "Employee misuse or alteration of the helmet": {
            nextQuestion: "How did the employee misuse or alter the helmet?",
            options: [
              "Felt uncomfortable, heavy, or too tight",
              "Altered for aesthetic reasons",
              "Did not understand the importance of keeping it unaltered"
            ]
          },
          "Safety helmet reassigned to another employee": {
            nextQuestion: "Why was the helmet reassigned?",
            options: [
              "Urgent operation required the reassignment",
              "Another employee’s helmet was damaged or lost",
              "Temporary stock shortage necessitated reassignment"
            ]
          }
        }
      },
      "Safety helmet was damaged": {
        nextQuestion: "How did the safety helmet get damaged?",
        options: {
          "Damaged due to wear and tear over time": {
            nextQuestion: "What factors contributed to the wear and tear?",
            options: [
              "Prolonged exposure to harsh environments",
              "Lack of regular inspections and maintenance",
              "Usage beyond the recommended lifespan",
              "Mishandling (e.g., dropping or overloading)"
            ]
          },
          "Improper storage or handling": {
            nextQuestion: "What led to improper storage or handling?",
            options: [
              "Stored in unsafe locations (e.g., under heavy objects)",
              "Lack of designated storage areas",
              "Insufficient training on proper PPE care",
              "Prioritized speed or convenience over proper storage"
            ]
          },
          "Sustained damage during protection from a fall or collision": {
            nextQuestion: "What caused the helmet to be damaged during use?",
            options: [
              "Exposed to hazards such as falling objects",
              "Did not follow safety procedures",
              "Encountered workplace accidents causing helmet strain"
            ]
          }
        }
      },
      "Safety helmet was lost": {
        nextQuestion: "Why was the safety helmet lost?",
        options: {
          "Lack of a designated storage system": {
            nextQuestion: "What contributed to the lack of storage system?",
            options: [
              "No proper layout for providing a storage system",
              "Insufficient space allocated for storage solutions",
              "Financial limitations for investing in storage infrastructure"
            ]
          },
          "Helmet left in a random location and forgotten": {
            nextQuestion: "What led to the helmet being left randomly?",
            options: [
              "Distracted by work tasks",
              "No nearby designated storage area",
              "Removed frequently due to discomfort",
              "High-pressure tasks caused prioritization of work over storage"
            ]
          },
          "Accidentally misplaced during shift handover": {
            nextQuestion: "What caused the helmet to be misplaced during shift handover?",
            options: [
              "Shared without a clear tracking system",
              "No structured procedure during shift changes",
              "Helmets not labeled with unique identifiers"
            ]
          },
          "No system for tracking issued helmets": {
            nextQuestion: "What prevented the tracking system from being implemented?",
            options: [
              "Lack of awareness about tracking systems",
              "Financial constraints prevented investment",
              "No clear policies for assigning and monitoring PPE usage"
            ]
          }
        }
      },
      "Employee was unaware of the requirement": {
        nextQuestion: "Why was the employee unaware of the requirement?",
        options: {
          "Lack of reminders (e.g., signage, verbal instructions)": {
            nextQuestion: "What caused the lack of reminders?",
            options: {
              "Absence of automated reminder systems": {
                nextQuestion: "What led to no automated systems?",
                options: [
                  "No investment in IoT or sensor-based systems",
                  "Limited awareness of technology solutions",
                  "Concerns about complexity and maintenance"
                ]
              },
              "Insufficient strategically placed signs": {
                nextQuestion: "What led to poor signage placement?",
                options: [
                  "Safety plan overlooked placement of visual reminders",
                  "Budget constraints limited signage investment",
                  "No regular audits for assessing signage visibility"
                ]
              },
              "Supervisors do not consistently remind employees": {
                nextQuestion: "Why don't supervisors remind employees?",
                options: [
                  "Overwhelmed with other responsibilities",
                  "Lack of training for enforcing PPE rules",
                  "Assumed employees were already compliant"
                ]
              }
            }
          }
        }
      },
      "Employee intentionally did not wear the safety helmet": {
        nextQuestion: "Why did the employee intentionally not wear the helmet?",
        options: {}
      },
      "Employee forgot to wear the safety helmet": {
        nextQuestion: "What led to the employee forgetting to wear the helmet?",
        options: {
          "Lack of visible reminders": {
            nextQuestion: "What caused the lack of reminders?",
            options: [
              "No pre-shift safety check procedure",
              "Rushed or distracted behavior",
              "Complacency due to familiarity with the job site"
            ]
          }
        }
      }
    }
  }
};
