import React, { useState, useEffect, useRef } from "react";
import { create } from "amazon-ivs-player";

const videos = [
  {
    id: 1,
    title: "Video 1",
    url: "https://www.coetra/a7f-9j...",
    thumbnail: "path_to_thumbnail_1.jpg",
  },
  {
    id: 2,
    title: "Video 2",
    url: "https://www.coetra/a7f-9j...",
    thumbnail: "path_to_thumbnail_2.jpg",
  },
  {
    id: 3,
    title: "Video 3",
    url: "https://www.coetra/a7f-9j...",
    thumbnail: "path_to_thumbnail_3.jpg",
  },
  {
    id: 4,
    title: "Video 4",
    url: "https://www.coetra/a7f-9j...",
    thumbnail: "path_to_thumbnail_4.jpg",
  },
  {
    id: 5,
    title: "Video 1",
    url: "https://www.coetra/a7f-9j...",
    thumbnail: "path_to_thumbnail_1.jpg",
  },
  {
    id: 6,
    title: "Video 2",
    url: "https://www.coetra/a7f-9j...",
    thumbnail: "path_to_thumbnail_2.jpg",
  },
  {
    id: 7,
    title: "Video 3",
    url: "https://www.coetra/a7f-9j...",
    thumbnail: "path_to_thumbnail_3.jpg",
  },
  {
    id: 8,
    title: "Video 4",
    url: "https://www.coetra/a7f-9j...",
    thumbnail: "path_to_thumbnail_4.jpg",
  },
  // Add more videos here if needed
];

const VideoPlayer = ({ playbackUrl, data }) => {
  const [selectedVideo, setSelectedVideo] = useState(videos[0]);
  const videoEl = useRef(null);

  useEffect(() => {
    if (videoEl.current) {
      const player = create({
        wasmWorker:
          "https://cdn.jsdelivr.net/npm/amazon-ivs-player@latest/dist/assets/amazon-ivs-wasmworker.min.js",
        wasmBinary:
          "https://cdn.jsdelivr.net/npm/amazon-ivs-player@latest/dist/assets/amazon-ivs-wasmworker.min.wasm",
      });

      player.attachHTMLVideoElement(videoEl.current);
      player.load(playbackUrl);
      player.play();

      return () => {
        player.pause();
        player.delete();
      };
    }
  }, [playbackUrl]);

  return (
    <div
      className="flex flex-row p-4 w-full rounded-xl border-2 border-gray-200 border-solid h-[500px]"
      data-testid="video-player-container"
    >
      {/* Video List with Scroll */}
      <div
        className="w-1/4 p-4 bg-gray-50 border-r border-gray-200 overflow-y-auto"
        data-testid="video-list"
      >
        {data.map((video) => (
          <div
            key={video.id}
            className={`flex items-center p-2 mb-1 cursor-pointer rounded ${
              selectedVideo.id === video.id
                ? "bg-blue-100"
                : "hover:bg-gray-100"
            }`}
            onClick={() => setSelectedVideo(video)}
            data-testid={`video-item-${video.id}`}
          >
            {/* <img
              src={video.stream.video_name}
              alt={video.stream.video_name}
              className="w-16 h-12 rounded mr-4 object-cover"
            /> */}
            <div>
              <h3
                className={`font-semibold ${
                  selectedVideo.id === video.id
                    ? "text-[#052D61]"
                    : "text-gray-800"
                }`}
                data-testid={`video-title-${video.id}`}
              >
                {video.stream.video_name}
              </h3>
              <p
                className="text-xs text-gray-500 truncate"
                data-testid={`video-url-${video.id}`}
              >
                {video.stream.url}
              </p>
            </div>
          </div>
        ))}
      </div>

      {/* Video Player */}
      <div className="w-3/4 p-4 bg-gray-100 flex flex-col">
        <div
          className="relative bg-black rounded-lg overflow-hidden"
          style={{ height: "calc(100%)" }}
          data-testid="video-player-wrapper"
        >
          <video
            ref={videoEl}
            controls
            style={{ width: "100%", height: "auto" }}
            data-testid="video-element"
          />
        </div>
      </div>
    </div>
  );
};

export default VideoPlayer;

// import React, { useEffect, useRef } from 'react';
// import { create } from 'amazon-ivs-player';

// const IVSPlayer = ({ playbackUrl }) => {
//     const videoEl = useRef(null);

//     useEffect(() => {
//         if (videoEl.current) {
//             const player = create({
//                 wasmWorker: 'https://cdn.jsdelivr.net/npm/amazon-ivs-player@latest/dist/assets/amazon-ivs-wasmworker.min.js',
//                 wasmBinary: 'https://cdn.jsdelivr.net/npm/amazon-ivs-player@latest/dist/assets/amazon-ivs-wasmworker.min.wasm',
//             });

//             player.attachHTMLVideoElement(videoEl.current);
//             player.load(playbackUrl);
//             player.play();

//             return () => {
//                 player.pause();
//                 player.delete();
//             };
//         }
//     }, [playbackUrl]);

//     return (
//         <video
//             ref={videoEl}
//             controls
//             style={{ width: '100%', height: 'auto' }}
//         />
//     );
// };

// export default IVSPlayer;
