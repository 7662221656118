import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { Dialog, DialogContent } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import GroupedChartDetails from "../../pages/Visualize/GroupedChartDetails";
import DateModalFilter from "../DateModalFilter";
import Heatmap from "../HeatMap";
import {
  fetchPresignedUrl,
  getThumbnailURLs,
} from "../../services/apiServiceDetails";
import { Loader2 } from "lucide-react";
import { ffmpegService } from "../../services/ffmpeg";
import { formatDate, getIdByName } from "../../utils/constants";
import SendEmailModal from "./SendEmailModal";
import TimeAnalysisChart from "../TImeSeriesAnalysisChart";
import { title } from "framer-motion/m";
import LineChartsModal from "../Charts/LineCharts/LineChartsModal";
import { TIME_RANGES } from "../../utils/constants";
import { ReportOutlined } from "@mui/icons-material";
import IncidentReportForm from "./ReportIncident/ReportForm";

const isUrlExpired = (url) => {
  const params = new URLSearchParams(url.split("?")[1]);
  const expiresParam = params.get("X-Amz-Expires");
  const dateParam = params.get("X-Amz-Date");

  if (!expiresParam || !dateParam) return true;

  const expirationTime = new Date(
    dateParam.slice(0, 8).replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3")
  );
  expirationTime.setSeconds(
    expirationTime.getSeconds() + parseInt(expiresParam)
  );

  return new Date() > expirationTime;
};

const VideoAlertsPanel = ({
  filteredAlert,
  recentAlert,
  selectedWarehouse,
  selectedDateValue,
  site,
}) => {
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [processedVideoUrl, setProcessedVideoUrl] = useState(null);
  const [progress, setProgress] = useState(0);
  const [isMailModalOpen, setIsmailModalOpen] = useState(false);
  const [alertID, setAlertID] = useState(null);
  const [alertType, setAlertType] = useState(null);
  const [alertCreated, setAlertCreated] = useState(null);
  const [alertVideoURL, setAlertVideoURL] = useState(null);
  const videoRef = useRef(null);
  const [alertDescription, setAlertDescription] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    ffmpegService.ffmpeg.on("progress", ({ progress }) => {
      setProgress(Math.round(progress * 100));
    });

    return () => {
      if (processedVideoUrl) {
        ffmpegService.revokeVideoUrl(processedVideoUrl);
      }
      ffmpegService.terminate();
    };
  }, [processedVideoUrl]);

  const handleVideoClick = async (item) => {
    // let url ="https://violation-clips.s3.eu-north-1.amazonaws.com/2024-09-06_12-47-56.mp4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIARSU7LAX6TGVBVQHJ%2F20241025%2Feu-north-1%2Fs3%2Faws4_request&X-Amz-Date=20241025T041501Z&X-Amz-Expires=172800&X-Amz-SignedHeaders=host&X-Amz-Signature=840cdeea113cdc150b397218edf961ade6bf33b71fdb476fb27a6a6eaf496c38";
    let url = "";
    // console.log("handle video click", item);
    try {
      setIsLoading(true);
      setProgress(0);
      url = await fetchPresignedUrl(item.violation_video_url);

      const processedBlob = await ffmpegService.processVideo(url, "fast");
      const videoUrl = ffmpegService.createVideoUrl(processedBlob);

      setProcessedVideoUrl(videoUrl);
      setSelectedVideo(videoUrl);
    } catch (error) {
      console.error("Error processing video:", error);
      setSelectedVideo(url.replace(/&amp;/g, "&"));
    } finally {
      setIsLoading(false);
    }
  };

  const handleVideoError = (e) => {
    console.error('Video Error:', e);
    if (videoRef.current) {
      console.log('Video Error Details:', {
        error: videoRef.current.error,
        networkState: videoRef.current.networkState,
        readyState: videoRef.current.readyState
      });
      videoRef.current.load();
    }
  };

  const onClose = () => {
    setIsmailModalOpen(false);
  };

  const LoadingState = () => (
    <div className="flex flex-col items-center justify-center w-full h-full bg-gray-100 gap-3 font-golos">
      <Loader2 className="w-8 h-8 text-gray-600 animate-spin" />
      <div className="flex flex-col items-center gap-1">
        <div className="text-sm font-medium text-gray-700">
          Processing video: {progress}%
        </div>
        <div className="text-xs text-gray-500">Please wait...</div>
      </div>
    </div>
  );

  // console.log("recentAlerts", recentAlert);

  return (
    <div className="flex overflow-hidden flex-wrap mt-8 w-full bg-white rounded-lg border overflow-y-auto border-gray-300 border-solid max-md:max-w-full relative h-full">
      {isMailModalOpen && (
        <SendEmailModal
          alertID={alertID}
          alertType={alertType}
          alertVideoURL={alertVideoURL}
          wareHouse={selectedWarehouse}
          alertCreated={alertCreated}
          onClose={onClose}
        />
      )}

      {isModalOpen && alertID && (
        <IncidentReportForm
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          initialData={{
            incidentId: alertID,
            incidentType: alertType,
            occurrenceTime: alertCreated,
            occurrenceLocation: site,
            description: alertDescription,
            incidentClip: alertVideoURL,
            actionItems: [],
          }}
          siteid={getIdByName(selectedWarehouse)}
          heading={`Incident Report Generation for ${alertID}, ${alertType}, ${site}`}
        />
      )}
      <div className="flex flex-col flex-1 shrink p-2 bg-gray-50 basis-0 min-w-[240px] h-full">
        <h3 className="font-semibold text-gray-900 font-suse">Recent Alerts</h3>
        <div className="flex flex-col overflow-y-auto">
          {(recentAlert && recentAlert.length > 0 ? recentAlert : recentAlert)[
            selectedDateValue
          ]?.map((item, ind) => (
            <div
              key={ind}
              className="flex z-0 flex-col px-3 py-4 w-full text-xs"
            >
              <div className="flex flex-col w-full">
                <h3 className="font-medium leading-none text-gray-900">
                  {item.alertTypeId === "" ? item.alertName : item.alertTypeId}
                </h3>
              </div>
              <div className="flex justify-between items-center mt-2 w-full">
                <div className="flex flex-1 shrink gap-2 items-center self-stretch my-auto text-xs leading-none text-gray-500 basis-0">
                  <span>
                    {formatDate(new Date(item.createdAt).toISOString())}
                  </span>
                </div>
                <div className="flex gap-2 items-center self-stretch my-auto">
                  <VisibilityOutlinedIcon
                    className="w-5 h-5 text-gray-600 cursor-pointer"
                    onClick={() => handleVideoClick(item)}
                  />
                  <EventNoteOutlinedIcon
                    onClick={() => {
                      setAlertID(item.alertTypeId);
                      setAlertCreated(new Date(item.createdAt).toISOString());
                      setAlertVideoURL(item.violation_video_url);
                      setAlertType(item.alertName);
                      setIsmailModalOpen(true);
                    }}
                    className="w-5 h-5 text-gray-600 cursor-pointer"
                  />
                  <ReportOutlined
                    onClick={() => {
                      setAlertID(item.alertTypeId);
                      setAlertCreated(new Date(item.createdAt).toISOString());
                      setAlertVideoURL(item.violation_video_url);
                      setAlertType(item.alertName);
                      setAlertDescription(item.description);
                      setIsModalOpen(true);
                    }}
                    className="w-5 h-5 text-gray-600 cursor-pointer"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="flex-1 min-w-[240px] w-[711px]">
        {isLoading ? (
          <LoadingState />
        ) : selectedVideo ? (
          <video
            ref={videoRef}
            className="w-full h-full object-contain"
            controls
            controlsList="nodownload noremoteplayback"
            onError={handleVideoError}
            onLoadedMetadata={(e) => console.log('Video metadata loaded:', e.target.duration)}
            onLoadStart={() => console.log('Video load started')}
            onLoadedData={() => console.log('Video data loaded')}
            playsInline
            autoPlay
            key={selectedVideo}
            crossOrigin="anonymous"
          >
            <source src={selectedVideo} type="video/mp4" />
            <source
              src={selectedVideo.replace(".mp4", ".webm")}
              type="video/webm"
            />
            Your browser does not support the video tag.
          </video>
        ) : (
          <div className="flex items-center justify-center w-full h-full bg-gray-100">
            <div className="text-center text-gray-500">
              <div className="mb-2">
                <VisibilityOutlinedIcon className="w-5 h-5 text-gray-600" />
              </div>
              <div>Click eye icon to view video</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

function StreamsFilter({ setSelectedStream, selectedStream, streams }) {
  const [selectedOption, setLocalSelectedOption] = useState(selectedStream);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setLocalSelectedOption(newValue);
    setSelectedStream(newValue);
  };


  return (
    <div className="flex flex-col gap-1 font-semibold items-center self-stretch ml-5">
      <div className="flex gap-2 items-center self-stretch px-4 py-2.5 my-auto bg-white rounded-lg border border-gray-300 border-solid min-h-[40px] w-[130px]">
        <select
          value={selectedOption}
          onChange={handleChange}
          className="flex-1 shrink justify-between self-stretch my-auto basis-0 text-ellipsis bg-white border-none outline-none ml-0"
        >
          {streams.map((stream, index) => (
            <option key={index} value={stream.id}>
              {stream.name}
            </option>
          ))}
        </select>

      </div>
    </div>
  );
}

const CustomModal = ({
  open,
  alertName,
  handleClose,
  modalData,
  selectedTab,
  setSelectedTab,
  selectedDateRange,
  specificAlert,
  selectedWarehouse,
  fetchEvents,
  utilsfunctions,
  setModalData,
  showLineChart,
  category,
  isNumber,
  cummilativeData,
}) => {
  const [selectedDateValue, setSelectedDateValue] = useState(selectedDateRange);
  const [checked, setChecked] = useState(false);
  const [isToggled, setIsToggled] = useState(false);
  const [recentAlert, setRecentAlert] = useState([]);
  const [filteredAlert, setFilteredAlert] = useState([]);
  const [datecounters, setDatecounters] = useState(1);
  const [customModalEventData, setCustomModalEventData] = useState([]);
  const [streams, setStreams] = useState([]);
  const [streamsData, setStreamsData] = useState([]);
  const [selectedStream, setSelectedStream] = useState("");
  const [chartData, setChartData] = useState(modalData);
  const [heatMapData, setHeatMapData] = useState(specificAlert);
  const [heatMapImage, setHeatMapImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const categorizeAlertsByTimeRange = (alerts) => {
    if (!alerts || alerts.length === 0) return {};

    const now = Date.now(); // Current timestamp in milliseconds

    const categorizedAlerts = {
      Year: [],
      Month: [],
      Week: [],
      Hours: [],
    };

    // Iterate over each alert
    alerts.forEach((alert) => {
      const alertTime = new Date(alert.createdAt).getTime();

      // Check the alert against ranges from largest to smallest
      if (alertTime >= now - TIME_RANGES.Year.current) {
        categorizedAlerts.Year.push(alert);
      }
      if (alertTime >= now - TIME_RANGES.Month.current) {
        categorizedAlerts.Month.push(alert);
      }
      if (alertTime >= now - TIME_RANGES.Week.current) {
        categorizedAlerts.Week.push(alert);
      }
      if (alertTime >= now - TIME_RANGES.Hours.current) {
        categorizedAlerts.Hours.push(alert);
      }
    });

    // console.log("categorizedAlerts", categorizedAlerts);

    return categorizedAlerts;
  };

  const getAlertsByNameSorted = (alertName) => {
    let matchingAlerts = [];
    const filteredAlerts = specificAlert?.filter(
      (alert) => alert.alertName == alertName
    );
    if (!filteredAlerts) return [];
    matchingAlerts = matchingAlerts.concat(
      filteredAlerts?.map((alert) => ({
        alertTypeId: alert.alertTypeId,
        alertName: alert.alertName,
        projectId: alert.projectId,
        description: alert.description,
        createdAt: alert.createdAt,
        violation_video_url: alert.violation_video_url,
        forklift_position: alert.forklift_position,
        streamId: alert.streamId,
      }))
    );
    const uniqueStreamIds = new Set(matchingAlerts.map((alert) => Number(alert.streamId)));
    setStreams(Array.from(uniqueStreamIds));
    setSelectedStream(matchingAlerts[0]?.streamId);
    matchingAlerts?.sort((a, b) => {
      const hasVideoA = a.violation_video_url ? 1 : 0;
      const hasVideoB = b.violation_video_url ? 1 : 0;
      if (hasVideoB - hasVideoA !== 0) return hasVideoB - hasVideoA;
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
    matchingAlerts = categorizeAlertsByTimeRange(matchingAlerts);
    return matchingAlerts;
  };

  useEffect(() => {
    const dateAlerts = getAlertsByNameSorted(alertName);

    setRecentAlert(dateAlerts);
  }, [alertName]);

  useEffect(() => {
    console.log("isLoading", isLoading);
  }, [isLoading])

  useEffect(() => {
    const fetchevents = async () => {
      // console.log("fetchEvents", alertName);
      if (
        alertName === "Forklift Utilization Time" ||
        alertName === "Total Trips" ||
        alertName.includes("Average Forklift Load/Unload Time") ||
        showLineChart ||
        alertName === "Mobile Usage Instances" ||
        alertName === "Time Spent In Work Area"
      ) {
        const resp = await fetchEvents(selectedDateValue);

        // console.log("fetchEvents", resp);

        if (
          alertName.includes("Average Forklift Load/Unload Time") ||
          showLineChart
        ) {
          const data = resp.sites[0].use_cases.find(
            (usecase) => usecase.name === category
          );
          // console.log(
          //   "~~~!!!!!!!!!!!! category, showlinechart, data",
          //   category,
          //   showLineChart,
          //   data
          // );
          setModalData(data);
          return;
        }

        let eventBarchartdata = resp.sites[0].use_cases.find(
          (uc) => uc.name === category
        );

        let res = utilsfunctions(
          eventBarchartdata.Events,
          {},
          selectedDateValue
        );
        // console.log("res", res);
        let filteredD = {};
        filteredD[selectedDateValue] = res.map((entry) => ({
          group: entry.group,
          values: entry.values.filter((value) => value.name === alertName),
        }));
        // .filter((entry) => entry.values.length > 0); // Remove entries with no values
        // console.log("filteredD", filteredD);
        setModalData(filteredD);
      }
    };
    fetchevents();
  }, [selectedDateValue]);

  function capitalizeFirstLetter(val) {
    return String(val).charAt(0).toUpperCase() + String(val).slice(1);
  }

  useEffect(() => {
    async function getAllStreamsData() {
      setIsLoading(true);
      const data = await getThumbnailURLs(streams);
      setStreamsData(data.streams);
      setIsLoading(false);
    }

    if (
      alertName === "Forklift Overspeeding" ||
      alertName === "Forklift Proximity To People" ||
      alertName === "Forklift Speed Violation"
    ) {
      getAllStreamsData();
    }
  }, [streams]);

  useEffect(() => {
    async function processHeatMapData() {
      setIsLoading(true);
      try {
        let filteredData = specificAlert.filter(
          (alert) => alert.streamId === Number(selectedStream)
        );
        console.log("filteredData", streamsData, selectedStream);
        let selectedStreamData = streamsData.find((stream) => stream.id === Number(selectedStream));
        console.log("selectedStreamData", selectedStreamData);
        if (selectedStreamData) {
          setHeatMapImage(selectedStreamData?.url);
        } else {
          setHeatMapImage("");
        }
        setHeatMapData(filteredData);
        setIsLoading(false);
      } catch (error) {
        console.error("Error processing heat map data:", error);
      } finally {
        setIsLoading(false);
      }
    }

    if (
      alertName === "Forklift Overspeeding" ||
      alertName === "Forklift Proximity To People" ||
      alertName === "Forklift Speed Violation"
    ) {
      processHeatMapData();
    }
  }, [selectedStream, streamsData]);

  const getTimeGroup = (alertData) => {
    const alertDate = new Date(alertData);
    const weekday = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const group_Data =
      selectedDateValue == "Week"
        ? weekday[alertDate.getDay()]
        : selectedDateValue == "Month"
          ? alertDate.getDate()
          : selectedDateValue == "Hours"
            ? alertDate.getHours()
            : alertDate.getMonth();
    return group_Data;
  };

  const handleBarClick = ({ group, name, value }) => {
    return;
    const now = Date.now();
    const { current } = TIME_RANGES[name];

    const timeFilteredAlerts = recentAlert.filter((alert) => {
      const timeDiff = now - new Date(alert.createdAt).getTime();
      return timeDiff <= current;
    });

    const alertsWithGroups = timeFilteredAlerts.map((alert) => ({
      ...alert,
      group: getTimeGroup(alert.createdAt),
    }));

    const newAlerts = alertsWithGroups.filter((item) => item.group === group);

    setFilteredAlert(newAlerts);
  };

  const handleCloseModal = () => {
    setChecked(false);
    setSelectedDateValue(selectedDateRange);
    handleClose();
  };

  useEffect(() => {
    setSelectedDateValue(selectedDateRange);
  }, [selectedDateRange]);

  useEffect(() => {
    if (selectedDateValue === "Hours") {
      setDatecounters(24);
    } else {
      setDatecounters(1);
    }
  }, [selectedDateRange, selectedDateValue]);

  return (
    <Dialog
      open={open}
      onClose={handleCloseModal}
      fullWidth
      fullScreen
      maxWidth="xl"
    >
      <DialogContent>
        {modalData && (
          <main
            className="flex flex-col h-full bg-white w-full rounded-xl font-golos"
            data-testid="modal-container"
            id="modal-container"
          >
            <div className="flex flex-col px-10 items-start bg-white rounded-none h-full max-md:px-5 relative">
              <div className="flex items-center gap-4 w-full flex-wrap max-md:max-w-full">
                <div className="flex-1 min-w-[300px] max-md:max-w-full flex flex-col justify-center">
                  <div className="text-3xl font-semibold tracking-tighter leading-tight text-gray-900 font-suse">
                    {alertName} for {datecounters} {selectedDateValue} -{" "}
                    {capitalizeFirstLetter(selectedWarehouse)}
                  </div>
                </div>

                {["Forklift Overspeeding", "Forklift Proximity To People", "Forklift Speed Violation"].includes(alertName) && (
                  <div className="flex items-center gap-2 font-golos text-sm leading-none">
                    <span className={`font-semibold ${!checked ? "text-primary" : "text-gray-500"}`}>
                      Overview
                    </span>
                    <Switch checked={checked} onChange={handleChange} color="primary" />
                    <span className={`font-semibold ${checked ? "text-primary" : "text-gray-500"}`}>
                      HeatMap
                    </span>
                    {checked && (
                      <StreamsFilter
                        setSelectedStream={setSelectedStream}
                        selectedStream={selectedStream}
                        streams={streamsData}
                      />
                    )}
                  </div>
                )}

                <div className="flex-shrink-0">
                  <div className="overflow-hidden gap-2 p-4 text-sm font-semibold leading-none text-center bg-white rounded-xl font-golos">
                    <DateModalFilter setSelectedDate={setSelectedDateValue} selectedDateValue={selectedDateValue} />
                  </div>
                </div>

                <button
                  onClick={handleCloseModal}
                  className="text-gray-600 hover:text-gray-800 ml-auto"
                  data-testid="close-modal-button"
                >
                  <span className="block w-6 h-6 relative" aria-label="Close">
                    <span className="block absolute w-full h-0.5 bg-current transform rotate-45 top-1/2"></span>
                    <span className="block absolute w-full h-0.5 bg-current transform -rotate-45 top-1/2"></span>
                  </span>
                </button>
              </div>

              {checked ? (
                <Heatmap
                  isModal={true}
                  specificAlert={heatMapData}
                  heading={`${alertName} for ${datecounters} ${selectedDateRange} - ${capitalizeFirstLetter(
                    selectedWarehouse
                  )}`}
                  alertName={alertName}
                  selectedDate={selectedDateValue}
                  heatMapImage={heatMapImage}
                  loading={isLoading}
                />
              ) : showLineChart ? (
                <LineChartsModal
                  // key={index}
                  data={modalData}
                  timeframe={selectedDateValue}
                  isModal={true}
                />
              ) : (
                <>
                  <GroupedChartDetails
                    data={modalData}
                    selectedDate={selectedDateValue}
                    height={400} // Set a fixed height for the chart
                    width="100%" // Set width to fill the container
                    handleBarClick={handleBarClick}
                    text={alertName}
                    fullHeight={
                      alertName === "Forklift Utilization Time" ||
                        alertName === "Total Trips" ||
                        alertName === "Employee Mobile Usage" ||
                        alertName === "Time Spent In Work Area"
                        ? true
                        : false
                    }
                    isNumber={isNumber}
                    cummilativeData={cummilativeData}
                  />
                  {alertName === "Forklift Utilization Time" ||
                    alertName === "Total Trips" ||
                    alertName === "Employee Mobile Usage" ||
                    alertName === "Time Spent In Work Area" ? null : (
                    <VideoAlertsPanel
                      filteredAlert={filteredAlert}
                      recentAlert={recentAlert}
                      selectedWarehouse={selectedWarehouse}
                      selectedDateValue={selectedDateValue}
                      site={capitalizeFirstLetter(selectedWarehouse)}
                    />
                  )}
                </>
              )}
            </div>
          </main>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CustomModal;
