import React, { useEffect, useRef, useState } from "react";
import { X } from "lucide-react";
import IVSPlayer from "./IVSPlayer";
import RTSPPlayer from "./RTSPPlayer";

const VideoModal = ({
  open,
  onClose,
  playbackUrl,
  liveVideoUrl,
  isReady,
  streamId,
}) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [info, setInfo] = useState(null);

  const handleVideoError = () => {
    setIsLoading(true);
    // setTimeout(() => videoRef.current?.load(), 1000);
    setTimeout(() => {
      setError("Error loading video");
      setIsLoading(false);
    }, 8000);
  };

  useEffect(() => {
    if (isReady) {
      setIsLoading(false);
      setError(null);
    }
  }, [isReady]);

  const isRTSPStream = playbackUrl?.startsWith("rtsp://");

  const onCloseModal = (streamId, isRTSPStream) => {
    setError(null);
    setIsLoading(true);
    onClose(streamId, isRTSPStream);
  };

  if (!open) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div
        className="absolute inset-0 bg-black/70 backdrop-blur-sm"
        onClick={() => onCloseModal(streamId, isRTSPStream)}
      />

      <div className="relative w-11/12 max-w-7xl bg-gray-900 rounded-2xl shadow-2xl overflow-hidden">
        <button
          onClick={() => onCloseModal(streamId, isRTSPStream)}
          className="absolute top-4 right-4 z-10 p-2 text-gray-400 hover:text-white transition-colors rounded-full hover:bg-white/10"
        >
          <X size={24} />
        </button>

        <div className="relative w-full">
          {!isReady ? (
            <div className="relative pt-[56.25%]">
              {isRTSPStream ? (
                <RTSPPlayer
                  rtspUrl={playbackUrl}
                  streamId={streamId}
                  setError={setError}
                  setIsLoading={setIsLoading}
                />
              ) : (
                <video
                  ref={videoRef}
                  // controls
                  autoPlay
                  preload="metadata"
                  className="absolute inset-0 w-full h-full object-contain rounded-t-2xl"
                  data-testid="modal-video"
                  onError={handleVideoError}
                >
                  <source src={playbackUrl} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              )}
            </div>
          ) : (
            <IVSPlayer playbackUrl={liveVideoUrl} />
          )}
        </div>

        <div className="px-6 py-4 bg-gray-800">
          <div className="flex items-center justify-between">
            <p className="text-white font-medium">
              Status:{" "}
              {/* {isLoading && !isReady
                ? "Loading..."
                : error
                ? "Error"
                : "Streaming"}
              Status:{" "} */}
              {isLoading && !isReady ? "Loading..." : "Streaming"}
            </p>
            <div className="flex items-center gap-2">
              {!error && isReady && (
                <span className="flex items-center gap-2">
                  <span className="w-2 h-2 bg-green-500 rounded-full animate-pulse" />
                  <span className="text-green-500 font-medium">Live</span>
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoModal;
