import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import { format } from "date-fns";
import { formatTime } from "../../utils/constants";

const GroupedChartDetails = ({
  data,
  selectedDate,
  colorScheme,
  height,
  width,
  onTabClick,
  handleBarClick,
  fullHeight,
  text,
  isNumber,
  cummilativeData,
}) => {
  const [selectedLegend, setSelectedLegend] = useState("All");
  const { name, projectid } = useParams();
  const [total, setTotal] = useState(cummilativeData);

  const calculateCummilativeData = () => {
    if (!data || !data[selectedDate]) return 0;
    let totalData = data[selectedDate].flatMap((group) => group.values).reduce((total, value) => total + value.value, 0);
    let heading = cummilativeData.split(":");
    heading = heading[0] + ": " + totalData;
    setTotal(heading);
  };

  const determineTimeUnit = (maxSeconds) => {
    const hours = Math.floor(maxSeconds / 3600);
    const minutes = Math.floor((maxSeconds % 3600) / 60);
    const seconds = Math.floor(maxSeconds % 60);

    if (hours > 0) {
      return "Hours";
    } else if (minutes > 0) {
      return "Minutes";
    } else {
      return "Seconds";
    }
  };

  useEffect(() => {
    calculateCummilativeData();
  }, [data, selectedDate, cummilativeData]);

  const convertTime = (seconds, unit) => {
    switch (unit) {
      case "Seconds":
        return seconds;
      case "Minutes":
        return +(seconds / 60).toFixed(2);
      case "Hours":
        return +(seconds / 3600).toFixed(2);
      default:
        return seconds;
    }
  };

  const findMaxTime = () => {
    if (!data || !data[selectedDate]) return 0;

    return Math.max(
      ...data[selectedDate].flatMap((group) =>
        group.values.map((value) => value.value)
      )
    );
  };

  const maxTime = findMaxTime();
  const timeUnit =
    text.toLowerCase().includes("time") || text.toLowerCase().includes("usage")
      ? determineTimeUnit(maxTime)
      : isNumber
      ? "Total"
      : "Violations";

  // Calculate the Y-axis domain based on the time unit
  const calculateYAxisDomain = () => {
    if (
      !text.toLowerCase().includes("time") &&
      !text.toLowerCase().includes("usage")
    ) {
      return [0, "auto"];
    }

    let convertedMaxValue;
    switch (timeUnit) {
      case "Seconds":
        convertedMaxValue = maxTime;
        break;
      case "Minutes":
        convertedMaxValue = maxTime / 60;
        break;
      case "Hours":
        convertedMaxValue = maxTime / 3600;
        break;
      default:
        convertedMaxValue = maxTime;
    }

    // Round up to the next whole number
    const maxDomain = Math.ceil(convertedMaxValue);
    return [0, maxDomain];
  };

  const yAxisLabel =
    text.toLowerCase().includes("time") || text.toLowerCase().includes("usage")
      ? `Time (${timeUnit.toLowerCase()})`
      : isNumber
      ? "Total"
      : "Violations";

  const transformData = () => {
    if (!data || !data[selectedDate] || data[selectedDate].length === 0)
      return [];

    const today = new Date();
    const viewType = selectedDate;

    return data[selectedDate].map((group) => {
      let formattedGroup;

      switch (viewType) {
        case "Hours":
          const [hour, minute] = group.group.split(":").map(Number);
          const hoursDate = new Date(today);
          hoursDate.setHours(hour, minute, 0, 0);
          formattedGroup = format(hoursDate, "h a").replace(/\s/g, " ");
          break;
        case "Month":
          const dayOfMonth = parseInt(group.group, 10);
          let monthDate;

          if (dayOfMonth > today.getDate()) {
            const prevMonth = today.getMonth() - 1;
            monthDate = new Date(today.getFullYear(), prevMonth, dayOfMonth);
          } else {
            monthDate = new Date(
              today.getFullYear(),
              today.getMonth(),
              dayOfMonth
            );
          }
          formattedGroup = format(monthDate, "d MMM");
          break;
        case "Week":
          formattedGroup = group.group;
          break;
        case "Year":
          const monthName = group.group;
          const monthIndex = getMonthIndex(monthName);
          if (monthIndex === -1) {
            // Handle invalid month name
            formattedGroup = group.group;
          } else {
            const monthDateYear = new Date(today.getFullYear(), monthIndex, 1);
            formattedGroup = format(monthDateYear, "MMM");
          }
          break;
        default:
          formattedGroup = group.group;
      }

      const transformedGroup = {
        group: formattedGroup,
      };

      group.values.forEach((value) => {
        const isTimeData =
          text.toLowerCase().includes("time") ||
          text.toLowerCase().includes("usage");
        transformedGroup[value.name] = isTimeData
          ? convertTime(value.value, timeUnit)
          : value.value;
        transformedGroup[`${value.name}_original`] = value.value;
      });

      return transformedGroup;
    });
  };

  const getMonthIndex = (monthName) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return monthNames.indexOf(monthName);
  };

  const transformedData = transformData();

  const getCategories = () => {
    if (!data || !data[selectedDate] || data[selectedDate].length === 0)
      return [];
    return data[selectedDate][0].values.map((v) => v.name);
  };

  const categories = getCategories();

  const CustomTooltip = ({ active, payload, label }) => {
    if (!active || !payload || !payload.length) return null;

    if (payload[0].payload[payload[0].name] === 0) {
      return null;
    }

    return (
      <div className="bg-white p-3 border border-gray-200 rounded-lg shadow-md">
        {payload.map((entry, index) => {
          const originalValue = entry.payload[`${entry.name}_original`];
          const displayValue =
            text.toLowerCase().includes("time") ||
            text.toLowerCase().includes("usage")
              ? formatTime(originalValue)
              : isNumber
              ? entry.value
              : entry.value.toFixed(2);

          return (
            <p key={index} className="text-sm">
              <span style={{ color: entry.color }}>{entry.name}: </span>
              {displayValue}
            </p>
          );
        })}
      </div>
    );
  };

  const CustomCursor = () => null;

  const formatYAxis = (value) => {
    if (
      text.toLowerCase().includes("time") ||
      text.toLowerCase().includes("usage")
    ) {
      return Math.floor(value);
    }
    return Math.floor(value);
  };

  const onBarClick = (data, index, event) => {
    if (handleBarClick) {
      handleBarClick({
        group: data.group,
        name: event.dataKey,
        value: data[event.dataKey],
      });
    }
  };
  const rotateLabels = selectedDate === "Month" ? -45 : 0;

  return (
    <div
      className={`flex flex-col pr-4 pl-4 pt-2 w-full ${
        fullHeight ? "h-[80%]" : ""
      } rounded-xl border-2 border-gray-200 border-solid font-golos`}
    >
      <div className="flex flex-wrap gap-10 justify-between items-center w-full text-xs leading-none whitespace-nowrap">
        <h3 className="text-lg font-semibold mb-2 font-suse">
          {total}
        </h3>
      </div>
      <div
        className="flex flex-col w-full"
        style={{ height: fullHeight ? "600px" : "300px" }}
      >
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={transformedData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: selectedDate === "Week" ? 30 : 15,
            }}
          >
            <XAxis
              dataKey="group"
              tick={{ angle: -45, textAnchor: "end", fontSize: 12 }}
              interval={0}
            />
            <YAxis
              domain={calculateYAxisDomain()}
              allowDecimals={false}
              tickFormatter={formatYAxis}
              label={{
                value: yAxisLabel,
                angle: -90,
                position: "insideLeft",
                style: { textAnchor: "middle" },
              }}
            />
            <Tooltip content={<CustomTooltip />} cursor={<CustomCursor />} />
            {categories.map((category, index) => (
              <Bar
                key={category}
                dataKey={category}
                fill="#6B7280"
                onClick={onBarClick}
                radius={[4, 4, 0, 0]}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default GroupedChartDetails;
