import React, { useState } from "react";
import ImageGrid from "./ImageGrid"; // Import your ImageGrid component

// Mock data for testing
const mockStreamData = {
  frames: [
    // {
    //   frame_id: "frame1",
    //   name: "Sample Frame 1",
    //   url: "https://placehold.co/600x400", // Placeholder image
    //   objects: [
    //     {
    //       id: "object1",
    //       label: "Object 1",
    //       boundary_box: {
    //         x_min: 0.1,
    //         y_min: 0.2,
    //         x_max: 0.3,
    //         y_max: 0.4,
    //         left_img: 40,
    //         top_img: 60,
    //         boxWidth_img: 80,
    //         boxheight_img: 100,
    //       },
    //     },
    //     {
    //       id: "object2",
    //       label: "Object 2",
    //       boundary_box: {
    //         x_min: 0.5,
    //         y_min: 0.5,
    //         x_max: 0.7,
    //         y_max: 0.7,
    //         left_img: 200,
    //         top_img: 150,
    //         boxWidth_img: 80,
    //         boxheight_img: 80,
    //       },
    //     },
    //   ],
    // },
    
      {
        "url": "https://placehold.co/600x400",
        "frame_id": "6732fe8d53be21ae2f4f5999",
        "objects": [
          {
            "class_id": 0,
            "class_name": "vest",
            "boundary_box": {
              "x_min": 0.316265,
              "y_min": 0.1687,
              "x_max": 0.344175,
              "y_max": 0.29747999999999997
            }
          }
        ],
        "frame_width": 600,
        "frame_height": 400
      }
    
  ],
};

const DevImageGrid = () => {
  const [initialStream, setInitialStream] = useState(mockStreamData);
  const [selectedStream, setSelectedStream] = useState([]);
  const [deleteStreams, setDeleteStreams] = useState([]);
  const [showRnd, setShowRnd] = useState(true);
  const [undisable, setUndisable] = useState(false);

  const handleImageClick = (frameId) => {
    console.log("Image clicked:", frameId);
  };

  const handleSelect = (frame) => {
    console.log("Frame selected:", frame, initialStream);
    // Add selected frame to selectedStream state only if it's not already selected
    if (!selectedStream.includes(frame.frame_id)) {
        setSelectedStream([...selectedStream, frame.frame_id]);
    }else{
        setSelectedStream(selectedStream.filter((id) => id !== frame.frame_id));
        // setSelectedStream((prev) => [...prev, frame]);
    }
  };

  const updateFrame = (updatedStream) => {
    console.log("Frame updated:", updatedStream);
    setInitialStream(updatedStream);
  };

  return (
    <div className="p-10">
      <ImageGrid
        initialStream={initialStream}
        frame_width={600}       // Width of the frame
        frame_height={400}      // Height of the frame
        handleImageClick={handleImageClick}
        selectedStream={selectedStream}
        handleSelect={handleSelect}
        updateFrame={updateFrame}
        deleteStreams={deleteStreams}
        showRnd={showRnd}
        setShowRnd={setShowRnd}
        undisable={undisable}
        setUndisable={setUndisable}
      />
    </div>
  );
};

export default DevImageGrid;
