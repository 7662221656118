import React from "react";
import { StreamCard } from "../StreamCard";
//import StreamCard from "./StreamCard";

const StreamTestComponent = ({
  streams,
  modalPerformance,
  selectedStream,
  setSelectedStream,
}) => {
  const handleSelect = (idx) => {
    console.log("stream selected", idx);
    if (selectedStream == idx) {
      setSelectedStream("");
    } else {
      // If not, select the current stream (single selection)
      setSelectedStream(idx);
    }
  };

  return (
    <main className="flex relative flex-col p-10 bg-gray-50 rounded-2xl max-w-[800px] max-md:px-5">
      <header className="flex z-0 flex-col w-full max-md:max-w-full">
        <h1 className="text-sm font-medium leading-none text-gray-500 max-md:max-w-full">
          Retrain model
        </h1>
        <h2 className="mt-2 text-3xl font-semibold leading-tight text-gray-900">
          Select a Device to Test
        </h2>
      </header>
      <section className="flex z-0 flex-col flex-1 mt-12 w-full max-md:mt-10 max-md:max-w-full">
        <div className="grid grid-cols-2 gap-3 max-h-[390px] overflow-y-auto max-md:grid-cols-1">
          {streams.map((stream, index) => (
            <StreamCard
              key={index}
              stream={stream}
              index={index}
              checkOption={true}
              handleSelect={handleSelect}
              setSelectedStream={setSelectedStream}
              selectedStream={selectedStream}
            />
          ))}
        </div>
      </section>

      <footer className="flex z-0 gap-2 items-center self-end mt-12 text-sm font-semibold leading-none text-center text-white max-md:mt-10">
        {/* <Button onClick={handleClose}>View Model Performance</Button> */}
        <button
          onClick={selectedStream ? modalPerformance : void 0}
          className={`overflow-hidden gap-2 self-stretch p-4 my-auto rounded-xl
          ${
            selectedStream
              ? "bg-[#052D61] border-blue-600"
              : "bg-gray-200 border-white"
          }
         `}
        >
          View Model Performance
        </button>
      </footer>
      {/* <div className="flex absolute right-6 z-0 w-1.5 h-16 bg-gray-200 rounded-full min-h-[64px] top-[200px]" /> */}
    </main>
  );
};

export default StreamTestComponent;
