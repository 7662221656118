import React, { useMemo, useState, useEffect, useCallback } from "react";
import {
  ResponsiveContainer,
  ComposedChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  Cell,
} from "recharts";
import { GetSiteEvents } from "../../../services/apiServiceDetails";

const TIME_CONSTANTS = {
  HOUR_IN_MS: 60 * 60 * 1000,
  DAY_IN_MS: 24 * 60 * 60 * 1000,
};

const formatDate = (dateStr, timeFrame) => {
  const date = new Date(dateStr);
  switch (timeFrame) {
    case "24hrs":
      return date.toLocaleString('en-US', { 
        hour: 'numeric',
        hour12: true
    }).replace(':00', '');
    case "1week":
      return date.toLocaleDateString('en-US', { weekday: 'short' });
    case "1month":
      return date.getDate().toString().padStart(2, '0');
    case "1year":
      return date.toLocaleDateString('en-US', { month: 'short' });
    default:
      return dateStr;
  }
};

const generateAllPossibleTicks = (timeFrame) => {
  const now = new Date();
  const ticks = [];
  
  switch (timeFrame) {
    case "24hrs":
      // Last 24 hours
      for (let i = 23; i >= 0; i--) {
        const date = new Date(now);
        date.setHours(date.getHours() - i);
        date.setMinutes(0, 0, 0);
        ticks.push(formatDate(date, timeFrame));
      }
      break;
    case "1week":
      // Last 7 days
      for (let i = 6; i >= 0; i--) {
        const date = new Date(now);
        date.setDate(date.getDate() - i);
        ticks.push(formatDate(date, timeFrame));
      }
      break;
    case "1month":
      // Days in current month
      const daysInMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
      for (let i = 1; i <= daysInMonth; i++) {
        const date = new Date(now.getFullYear(), now.getMonth(), i);
        ticks.push(formatDate(date, timeFrame));
      }
      break;
    case "1year":
      // Last 12 months
      for (let i = 0; i < 12; i++) {
        const date = new Date(now.getFullYear(), i, 1);
        ticks.push(formatDate(date, timeFrame));
      }
      break;
  }
  return ticks;
};

const CONFIG = {
  trips: {
    limits: { upper: 15, lower: 10 },
    colors: {
      belowLower: "#FF4444",
      withinLimits: "#4477AA",
      aboveUpper: "#FF4444",
    },
    key: 'total_trips',
    label: 'Total Trips'
  },
  loadingUnloading: {
    loading: {
      limits: { upper: 15, lower: 5 },
      colors: {
        belowLower: "#FF4444",
        withinLimits: "#44AAFF",
        aboveUpper: "#FF4444",
      },
      key: 'loading_time',
      label: 'Loading Time'
    },
    unloading: {
      limits: { upper: 15, lower: 5 },
      colors: {
        belowLower: "#FF4444",
        withinLimits: "#4477AA",
        aboveUpper: "#FF4444",
      },
      key: 'unloading_time',
      label: 'Unloading Time'
    }
  },
  occupiedTime: {
    limits: { upper: 20, lower: 5 },
    colors: {
      belowLower: "#FF4444",
      withinLimits: "#4477AA",
      aboveUpper: "#FF4444",
    },
    key: 'occupied_time',
    label: 'Occupied Time'
  }
};

const calculateMovingAverage = (data, windowSize, key) => {
  if (!data || data.length === 0) return [];
  const values = data.map(item => item[key] || 0);
  const result = [];

  for (let i = 0; i < values.length; i++) {
    if (i < windowSize - 1) {
      result.push(null);
      continue;
    }

    let sum = 0;
    for (let j = i - (windowSize - 1); j <= i; j++) {
      sum += values[j];
    }
    const avg = sum / windowSize;
    result.push(parseFloat(avg.toFixed(2)));
  }

  return data.map((item, index) => ({
    ...item,
    [`${key}MA`]: result[index],
  }));
};

const getBarColor = (value, limits, colors) => {
  if (value < limits.lower) return colors.belowLower;
  if (value > limits.upper) return colors.aboveUpper;
  return colors.withinLimits;
};

const getXAxisKey = (timeFrame) => {
  return "formattedDate";
};

const ChartComponent = ({ timeFrame, movingAverageWindow, siteId, config, data, loading, error }) => {
  const allPossibleTicks = useMemo(() => generateAllPossibleTicks(timeFrame), [timeFrame]);
  if (loading) {
    return (
      <div className="w-full h-[500px] bg-white rounded-lg shadow-sm">
        <div className="animate-pulse h-full">
          <div className="h-6 bg-gray-200 rounded w-1/4 mb-4"></div>
          <div className="h-[calc(100%-2rem)] bg-gray-100 rounded-lg">
            <div className="h-full w-full flex items-center justify-center">
              <div className="flex flex-col items-center space-y-2">
                <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
                <p className="text-sm text-gray-500">Loading chart data...</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error || data.length === 0) {
    return (
      <div className="w-full h-[500px] bg-white rounded-lg shadow-sm p-6 border border-gray-200">
        <div className="h-full flex flex-col items-center justify-center text-center">
          <div className="mb-4">
            <svg className="w-16 h-16 text-gray-400 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </div>
          <h3 className="text-xl font-semibold text-gray-700 mb-2">
            {config.label} Data Unavailable
          </h3>
          <p className="text-gray-500 mb-4 max-w-md">
            {error === 'No forklift data available' 
              ? `No ${config.label.toLowerCase()} data is currently available for this site and time period.`
              : error}
          </p>
          <div className="text-sm text-gray-400">
            Try selecting a different time period.
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full h-[500px]">
      <h3 className="text-lg font-semibold mb-4">{config.label} Control Chart</h3>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          data={data}
          margin={{ top: 20, right: 120, left: 20, bottom: 60 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis 
            dataKey={getXAxisKey(timeFrame)}
            interval={0}
            angle={-45}
            textAnchor="end"
            height={80}
            tick={{ fontSize: 12 }}
          />
          <YAxis 
            label={{ 
              value: config.key.includes('time') ? 'Time (minutes)' : 'Number of Trips',
              angle: -90, 
              position: 'insideLeft',
              offset: -5
            }}
          />
          <Tooltip
            content={({ active, payload }) => {
              if (active && payload && payload.length) {
                return (
                  <div className="bg-white p-2 border rounded shadow">
                    <p className="text-sm">{`${config.label}: ${payload[0].value}`}</p>
                    {payload[1]?.value && (
                      <p className="text-sm">{`Moving Average: ${payload[1].value}`}</p>
                    )}
                    <p className="text-xs text-gray-500">
                      {payload[0].payload.created_at ? 
                        new Date(payload[0].payload.created_at).toLocaleString() :
                        'No data'
                      }
                    </p>
                  </div>
                );
              }
              return null;
            }}
          />
          <Legend 
            content={({ payload }) => (
              <div className="flex justify-center items-center space-x-6 mt-2">
                <div className="flex items-center">
                  <div 
                    className="w-4 h-4 mr-2"
                    style={{ backgroundColor: config.colors.withinLimits }}
                  />
                  <span>{config.label}</span>
                </div>
                <div className="flex items-center">
                  <div 
                    className="w-4 h-4 mr-2"
                    style={{ 
                      background: 'linear-gradient(45deg, transparent 0%, transparent 49%, #FF8800 49%, #FF8800 51%, transparent 51%, transparent 100%)',
                      border: '1px solid #FF8800'
                    }}
                  />
                  <span>Moving Average</span>
                </div>
              </div>
            )}
          />
          <Bar 
            dataKey={config.key}
            name={config.label}
            barSize={20}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={getBarColor(entry[config.key], config.limits, config.colors)}
              />
            ))}
          </Bar>
          <ReferenceLine 
            y={config.limits.upper} 
            stroke="#FF4444" 
            strokeDasharray="3 3"
            label={{
              value: 'Upper Limit',
              position: 'right',
              fill: '#FF4444'
            }}
          />
          <ReferenceLine 
            y={config.limits.lower} 
            stroke="#FF4444" 
            strokeDasharray="3 3"
            label={{
              value: 'Lower Limit',
              position: 'right',
              fill: '#FF4444'
            }}
          />
          <Line
            type="monotone"
            dataKey={`${config.key}MA`}
            stroke="#FF8800"
            name="Moving Average"
            dot={false}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

const useChartData = (processedData, siteId, timeFrame, config) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const allPossibleTicks = useMemo(() => generateAllPossibleTicks(timeFrame), [timeFrame]);

  const fetchData = useCallback(async () => {
    if (!siteId) {
      setError("Please select a site");
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      setError(null);

      if (!data?.sites?.[0]) {
        throw new Error(`No data available for the selected site`);
      }
      
      const forkliftData = processedData.sites[0].use_cases.find(
        uc => uc.name === "Forklift Safety, Compliance, Downtime, and Maintenance"
      );

      if (!forkliftData || !forkliftData.Events) {
        throw new Error(`No ${config.label.toLowerCase()} data available for the selected time period`);
      }

      const processedEvents = forkliftData.Events.map(event => {
        const date = new Date(event.created_at);
        return {
          formattedDate: formatDate(event.created_at, timeFrame),
          [config.key]: event[config.key] || 0,
          created_at: event.created_at
        };
      });

      let aggregatedData = processedEvents;
      if (timeFrame === "1week") {
        const dailyData = {};
        processedEvents.forEach(event => {
          const formattedDate = formatDate(event.created_at, timeFrame);
          if (!dailyData[formattedDate]) {
            dailyData[formattedDate] = { ...event, count: 1 };
          } else {
            dailyData[formattedDate][config.key] += event[config.key];
            dailyData[formattedDate].count++;
          }
        });

        aggregatedData = Object.values(dailyData).map(day => ({
          ...day,
          [config.key]: parseFloat((day[config.key] / day.count).toFixed(2))
        }));
      }

      // Create a map of all possible ticks with default values
      const fullDataMap = allPossibleTicks.reduce((acc, tick) => {
        acc[tick] = {
          formattedDate: tick,
          [config.key]: 0,
          created_at: null
        };
        return acc;
      }, {});

      // Update the map with actual values
      aggregatedData.forEach(event => {
        const key = event.formattedDate;
        if (fullDataMap[key]) {
          fullDataMap[key] = event;
        }
      });

      // Convert map back to array and sort to ensure current time is last
      let fullData = Object.values(fullDataMap);
      
      // Find current tick based on timeFrame
      const now = new Date();
      let currentTick;
      switch (timeFrame) {
        case "24hrs":
          currentTick = formatDate(now, timeFrame);
          break;
        case "1week":
          currentTick = formatDate(now, timeFrame);
          break;
        case "1month":
          currentTick = formatDate(now, timeFrame);
          break;
        case "1year":
          currentTick = formatDate(now, timeFrame);
          break;
      }

      // Reorder array to put current tick last
      fullData = [
        ...fullData.filter(item => item.formattedDate !== currentTick),
        ...fullData.filter(item => item.formattedDate === currentTick)
      ];

      setData(fullData);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [siteId, timeFrame, config.key, allPossibleTicks]);

  useEffect(() => {
    fetchData();
    
    // Cleanup function to reset state when component unmounts or dependencies change
    return () => {
      setData([]);
      setError(null);
    };
  }, [fetchData, siteId, timeFrame]); // Add siteId and timeFrame as dependencies

  return { data, loading, error };
};

const TripControlChart = ({ processedData, timeFrame, movingAverageWindow, siteId }) => {
  const { data: rawData, loading, error } = useChartData(processedData,siteId, timeFrame, CONFIG.trips);
  const data = useMemo(() => 
    calculateMovingAverage(rawData, movingAverageWindow, CONFIG.trips.key),
    [rawData, movingAverageWindow]
  );

  return (
    <ChartComponent
      timeFrame={timeFrame}
      movingAverageWindow={movingAverageWindow}
      siteId={siteId}
      config={CONFIG.trips}
      data={data}
      loading={loading}
      error={error}
    />
  );
};

const LoadingUnloadingChart = ({ processedData,timeFrame = "24hrs", movingAverageWindow, siteId }) => {
  const { data: loadingData, loading: loadingLoading, error: loadingError } = 
    useChartData(processedData, siteId, timeFrame, CONFIG.loadingUnloading.loading);
  const { data: unloadingData, loading: unloadingLoading, error: unloadingError } = 
    useChartData(processedData, siteId, timeFrame, CONFIG.loadingUnloading.unloading);

  const loading = loadingLoading || unloadingLoading;
  const error = loadingError || unloadingError;

  const combinedData = useMemo(() => {
    if (!loadingData || !unloadingData) return [];
    return loadingData.map((item, index) => ({
      ...item,
      loading_time: item.loading_time,
      unloading_time: unloadingData[index]?.unloading_time,
      loading_timeMA: calculateMovingAverage(loadingData, movingAverageWindow, CONFIG.loadingUnloading.loading.key)[index]?.loading_timeMA,
      unloading_timeMA: calculateMovingAverage(unloadingData, movingAverageWindow, CONFIG.loadingUnloading.unloading.key)[index]?.unloading_timeMA,
    }));
  }, [loadingData, unloadingData, movingAverageWindow]);

  if (loading) {
    return (
      <div className="w-full h-[500px] bg-white rounded-lg shadow-sm">
        <div className="animate-pulse h-full">
          <div className="h-6 bg-gray-200 rounded w-1/4 mb-4"></div>
          <div className="h-[calc(100%-2rem)] bg-gray-100 rounded-lg">
            <div className="h-full w-full flex items-center justify-center">
              <div className="flex flex-col items-center space-y-2">
                <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
                <p className="text-sm text-gray-500">Loading chart data...</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full h-[500px] bg-white rounded-lg shadow-sm p-6 border border-gray-200">
        <div className="h-full flex flex-col items-center justify-center text-center">
          <div className="mb-4">
            <svg className="w-16 h-16 text-gray-400 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </div>
          <h3 className="text-xl font-semibold text-gray-700 mb-2">
            Loading and Unloading Times Data Unavailable
          </h3>
          <p className="text-gray-500 mb-4 max-w-md">
            {error === 'No forklift data available' 
              ? `No loading and unloading times data is currently available for this site and time period.`
              : error}
          </p>
          <div className="text-sm text-gray-400">
            Try selecting a different time period.
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full h-[500px]">
      <h3 className="text-lg font-semibold mb-4">Loading and Unloading Times Control Chart</h3>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          data={combinedData}
          margin={{ top: 20, right: 120, left: 20, bottom: 60 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis 
            dataKey={getXAxisKey(timeFrame)}
            interval={0}
            angle={-45}
            textAnchor="end"
            height={80}
            tick={{ fontSize: 12 }}
          />
          <YAxis 
            label={{ 
              value: 'Time (minutes)', 
              angle: -90, 
              position: 'insideLeft',
              offset: -5
            }}
          />
          <Tooltip
            content={({ active, payload }) => {
              if (active && payload && payload.length) {
                return (
                  <div className="bg-white p-2 border rounded shadow">
                    {payload[0]?.value && (
                      <p className="text-sm">{`Loading Time: ${payload[0].value}`}</p>
                    )}
                    {payload[1]?.value && (
                      <p className="text-sm">{`Unloading Time: ${payload[1].value}`}</p>
                    )}
                    {payload[2]?.value && (
                      <p className="text-sm">{`Loading MA: ${payload[2].value}`}</p>
                    )}
                    {payload[3]?.value && (
                      <p className="text-sm">{`Unloading MA: ${payload[3].value}`}</p>
                    )}
                    <p className="text-xs text-gray-500">
                      {payload[0].payload.created_at ? 
                        new Date(payload[0].payload.created_at).toLocaleString() :
                        'No data'
                      }
                    </p>
                  </div>
                );
              }
              return null;
            }}
          />
          <Legend 
            content={({ payload }) => (
              <div className="flex justify-center items-center space-x-6 mt-2">
                <div className="flex items-center">
                  <div 
                    className="w-4 h-4 mr-2"
                    style={{ backgroundColor: CONFIG.loadingUnloading.loading.colors.withinLimits }}
                  />
                  <span>Loading Time</span>
                </div>
                <div className="flex items-center">
                  <div 
                    className="w-4 h-4 mr-2"
                    style={{ backgroundColor: CONFIG.loadingUnloading.unloading.colors.withinLimits }}
                  />
                  <span>Unloading Time</span>
                </div>
                <div className="flex items-center">
                  <div 
                    className="w-4 h-4 mr-2"
                    style={{ 
                      background: 'linear-gradient(45deg, transparent 0%, transparent 49%, #FF8800 49%, #FF8800 51%, transparent 51%, transparent 100%)',
                      border: '1px solid #FF8800'
                    }}
                  />
                  <span>Moving Average</span>
                </div>
              </div>
            )}
          />
          <Bar 
            dataKey="loading_time"
            name="Loading Time"
            barSize={20}
          >
            {combinedData.map((entry, index) => (
              <Cell
                key={`cell-loading-${index}`}
                fill={getBarColor(entry.loading_time, CONFIG.loadingUnloading.loading.limits, CONFIG.loadingUnloading.loading.colors)}
              />
            ))}
          </Bar>
          <Bar 
            dataKey="unloading_time"
            name="Unloading Time"
            barSize={20}
          >
            {combinedData.map((entry, index) => (
              <Cell
                key={`cell-unloading-${index}`}
                fill={getBarColor(entry.unloading_time, CONFIG.loadingUnloading.unloading.limits, CONFIG.loadingUnloading.unloading.colors)}
              />
            ))}
          </Bar>
          <ReferenceLine 
            y={CONFIG.loadingUnloading.loading.limits.upper} 
            stroke="#FF4444" 
            strokeDasharray="3 3"
            label={{
              value: 'Upper Limit',
              position: 'right',
              fill: '#FF4444'
            }}
          />
          <ReferenceLine 
            y={CONFIG.loadingUnloading.loading.limits.lower} 
            stroke="#FF4444" 
            strokeDasharray="3 3"
            label={{
              value: 'Lower Limit',
              position: 'right',
              fill: '#FF4444'
            }}
          />
          <Line
            type="monotone"
            dataKey="loading_timeMA"
            stroke="#FF8800"
            name="Loading Moving Average"
            dot={false}
          />
          <Line
            type="monotone"
            dataKey="unloading_timeMA"
            stroke="#FF8800"
            name="Unloading Moving Average"
            dot={false}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

const OccupiedTimeControlChart = ({ processedData, timeFrame, movingAverageWindow, siteId }) => {
  const { data: rawData, loading, error } = useChartData(processedData,siteId, timeFrame, CONFIG.occupiedTime);
  const data = useMemo(() => 
    calculateMovingAverage(rawData, movingAverageWindow, CONFIG.occupiedTime.key),
    [rawData, movingAverageWindow]
  );

  return (
    <ChartComponent
      timeFrame={timeFrame}
      movingAverageWindow={movingAverageWindow}
      siteId={siteId}
      config={CONFIG.occupiedTime}
      data={data}
      loading={loading}
      error={error}
    />
  );
};

const ControlCharts = ({ data, timeFrame = "24hrs", movingAverageWindow = 3, siteId }) => {
  return (
    <div className="space-y-8">
      <TripControlChart 
        processedData={data}
        timeFrame={timeFrame} 
        movingAverageWindow={movingAverageWindow} 
        siteId={siteId}
      />
      <LoadingUnloadingChart 
        processedData={data}
        timeFrame={timeFrame} 
        movingAverageWindow={movingAverageWindow} 
        siteId={siteId}
      />
      <OccupiedTimeControlChart 
        processedData={data}
        timeFrame={timeFrame} 
        movingAverageWindow={movingAverageWindow} 
        siteId={siteId}
      />
    </div>
  );
};

export default ControlCharts;
