var now = new Date();
export function checkMonthlyAlertOccurrences(alerts, specificAlertName) {
   
    const oneMonthAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000); // 1 month ago

    // Filter alerts from the last month
    const recentAlerts = alerts.filter(alert => new Date(alert.createdAt) >= oneMonthAgo);
    // Filter alerts before the last month
    const previousAlerts = alerts.filter(alert => new Date(alert.createdAt) < oneMonthAgo);

    // Count occurrences of the specific alert
    const recentCount = recentAlerts.filter(alert => alert.alertName === specificAlertName).length;
    const previousCount = previousAlerts.filter(alert => alert.alertName === specificAlertName).length;

    return {
        recentCount,
        previousCount,
        hasIncreased: recentCount > previousCount,
        timePeriod: 'Month'
    };
}

export function checkWeeklyAlertOccurrences(alerts, specificAlertName) {
   
    const oneWeekAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000); // 1 month ago

    // Filter alerts from the last month
    const recentAlerts = alerts.filter(alert => new Date(alert.createdAt) >= oneWeekAgo);
    // Filter alerts before the last month
    const previousAlerts = alerts.filter(alert => new Date(alert.createdAt) < oneWeekAgo);

    // Count occurrences of the specific alert
    const recentCount = recentAlerts.filter(alert => alert.alertName === specificAlertName).length;
    const previousCount = previousAlerts.filter(alert => alert.alertName === specificAlertName).length;

    return {
        recentCount,
        previousCount,
        hasIncreased: recentCount > previousCount,
        timePeriod: 'Week'
    };
}

export function checkYearlyAlertOccurrences(alerts, specificAlertName) {
   
    const oneYearAgo = new Date(now.getTime() - 365 * 24 * 60 * 60 * 1000); // 1 month ago

    // Filter alerts from the last month
    const recentAlerts = alerts.filter(alert => new Date(alert.createdAt) >= oneYearAgo);
    // Filter alerts before the last month
    const previousAlerts = alerts.filter(alert => new Date(alert.createdAt) < oneYearAgo);

    // Count occurrences of the specific alert
    const recentCount = recentAlerts.filter(alert => alert.alertName === specificAlertName).length;
    const previousCount = previousAlerts.filter(alert => alert.alertName === specificAlertName).length;

    return {
        recentCount,
        previousCount,
        hasIncreased: recentCount > previousCount,
        timePeriod: 'Year'
    };
}

export function checkHourlyAlertOccurrences(alerts, specificAlertName) {
    //const now = new Date();
    const cutoff = new Date(now.getTime() - 24 * 60 * 60 * 1000); // 24 hours ago

//const cutoff = new Date(30 * 24 * 60 * 60 * 1000)
    // Filter alerts from the last 24 hours
    const recentAlerts = alerts.filter(alert => new Date(alert.createdAt) >= cutoff);
    // Filter alerts before the last 24 hours
    const previousAlerts = alerts.filter(alert => new Date(alert.createdAt) < cutoff);

    // Count occurrences of the specific alert
    const recentCount = recentAlerts.filter(alert => alert.alertName === specificAlertName).length;
    const previousCount = previousAlerts.filter(alert => alert.alertName === specificAlertName).length;

    return {
        recentCount,
        previousCount,
        hasIncreased: recentCount > previousCount,
        timePeriod: 'Hours'
    };
}