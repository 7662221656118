import React, { useState } from 'react';

const ImageModal = ({ imageUrl, onClose }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75">
      <div className="relative max-w-4xl max-h-3/4">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-white text-2xl"
        >
          &times;
        </button>
        <img src={imageUrl} alt="Enlarged" className="max-w-full max-h-full object-contain" />
      </div>
    </div>
  );
};

export default ImageModal;