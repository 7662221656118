import React, { useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";

const TimeAnalysisCard = ({
  data,
  title = "Time Analysis",
  timeframe = "day",
  isLoading = false,
  onChange,
  selectedDateRange,
  onClick,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  console.log("LIneChart-DATA", data);

  const calculateStats = () => {
    if (!(data.length > 0))
      return {
        loadingTime: 0,
        unloadingTime: 0,
        change: 0,
        isPositive: false,
      };

    const alerts = data;
    console.log("Event", alerts);
    const totalLoadingTime = alerts.reduce(
      (sum, alert) => sum + parseFloat(alert.loading_time || 0),
      0
    );
    const totalUnloadingTime = alerts.reduce(
      (sum, alert) => sum + parseFloat(alert.unloading_time || 0),
      0
    );

    const previousTotal = (totalLoadingTime + totalUnloadingTime) * 1.1;
    const currentTotal = totalLoadingTime + totalUnloadingTime;
    const changePercent = (
      ((currentTotal - previousTotal) / previousTotal) *
      100
    ).toFixed(1);

    return {
      loadingTime: Math.round(totalLoadingTime),
      unloadingTime: Math.round(totalUnloadingTime),
      change: Math.abs(changePercent),
      isPositive: changePercent > 0,
    };
  };

  const { loadingTime, unloadingTime, change, isPositive } = calculateStats();

  const formatTime = (minutes) => {
    if (!minutes) return "0:00";
    const hours = Math.floor(minutes / 60);
    const mins = Math.round(minutes % 60);
    return `${hours}:${mins.toString().padStart(2, "0")}`;
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (!active || !payload || !payload.length) return null;

    return (
      <div className="bg-white p-2 shadow-md border border-gray-100 rounded-md">
        <p className="text-xs font-medium text-gray-600 mb-1">{label}</p>
        {payload.map((entry, index) => (
          <p
            key={index}
            className="text-xs font-medium"
            style={{ color: entry.color }}
          >
            {entry.name === "loadingTime" ? "Loading: " : "Unloading: "}
            {Math.round(entry.value)} minutes
          </p>
        ))}
      </div>
    );
  };

  const CustomLegend = () => (
    <div className="flex justify-center gap-4 text-xs">
      <div className="flex items-center">
        <div className="w-3 h-3 rounded-full bg-[#295F98] mr-1"></div>
        <span>Loading</span>
      </div>
      <div className="flex items-center">
        <div className="w-3 h-3 rounded-full bg-[#60a5fa] mr-1"></div>
        <span>Unloading</span>
      </div>
    </div>
  );

  return (
    <div
      className="flex flex-col px-2 py-2 rounded-lg border-2 border-gray-200 basis-[300px] min-w-[300px] max-w-[300px] h-[220px] cursor-pointer hover:border-gray-300 transition-colors duration-200"
      onClick={() => onClick?.(title)}
    >
      <div className="flex justify-between items-start p-2 h-[80px]">
        <div className="flex flex-col">
          <div className="text-md font-semibold text-gray-500">{title}</div>
          <div className="flex flex-col gap-1 mt-2 text-xs text-gray-900">
            <div className="flex items-center">
              <div className="w-2 h-2 rounded-full bg-[#295F98] mr-1"></div>
              <span>Loading: {formatTime(loadingTime)} hours</span>
            </div>
            <div className="flex items-center">
              <div className="w-2 h-2 rounded-full bg-[#60a5fa] mr-1"></div>
              <span>Unloading: {formatTime(unloadingTime)} hours</span>
            </div>
          </div>
        </div>
        <div
          className={`relative flex items-center text-xs font-bold ${
            isPositive ? "text-red-600" : "text-emerald-600"
          }`}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          {isPositive ? (
            <ArrowUpward className="h-4 w-4" />
          ) : (
            <ArrowDownward className="h-4 w-4" />
          )}
          <span>{change}%</span>
          {showTooltip && (
            <div className="absolute bottom-full right-[-200%] mb-1 p-2 w-[200px] text-xs font-light text-black bg-gray-100 border-gray-300 border-[1px] rounded">
              Change in Total Time for {timeframe} {selectedDateRange}
            </div>
          )}
        </div>
      </div>

      {isLoading ? (
        <div className="w-full flex justify-center items-center">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
        </div>
      ) : (
        <div className="h-[120px]">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={data || []}
              margin={{ top: 5, right: 5, left: -20, bottom: 5 }}
            >
              <XAxis
                tick={{ angle: -45, textAnchor: "end", fontSize: 12 }}
                dataKey="created_at"
                hide
              />
              <YAxis hide />
              <Tooltip content={<CustomTooltip />} />
              <Line
                type="monotone"
                dataKey="loading_time"
                stroke="#295F98"
                strokeWidth={2}
                dot={true}
                name="loadingTime"
              />
              <Line
                type="monotone"
                dataKey="unloading_time"
                stroke="#60a5fa"
                strokeWidth={2}
                dot={true}
                name="unloadingTime"
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      )}
      <CustomLegend />
    </div>
  );
};

export default TimeAnalysisCard;
