import React, { useState, useEffect } from "react";
import { Modal, Box } from "@mui/material";
import { useParams } from "react-router-dom";
import StreamTestComponent from "./StreamTestComponent";
import ModelPerformanceComponent from "./ModelPerformanceComponent";
import {
  getProjectStreams,
  testStreamModal,
} from "../../services/apiServiceDetails";

const streamsMockData = [
  {
    id: 1,
    src: "https://picsum.photos/400/300",
    alt: "Device 1"
  },
  {
    id: 2,
    src: "https://picsum.photos/400/300",
    alt: "Device 2"
  },
  {
    id: 3,
    src: "https://picsum.photos/400/300",
    alt: "Device 3"
  },
  {
    id: 4,
    src: "https://picsum.photos/400/300",
    alt: "Device 4",
    //title: "Counter (View 2)",
    // url: "https://www.coetra/a7f-9j2m3-l0"
  },
  {
    id: 5,
    src: "https://picsum.photos/400/300",
    alt: "Device 1"
  },
  {
    id: 6,
    src: "https://picsum.photos/400/300",
    alt: "Device 2"
  },
  {
    id: 7,
    src: "https://picsum.photos/400/300",
    alt: "Device 3"
  },
  {
    id: 8,
    src: "https://picsum.photos/400/300",
    alt: "Device 4",
    //title: "Counter (View 2)",
    // url: "https://www.coetra/a7f-9j2m3-l0"
  }
];


const str = [
  {
    "id": 163,
    "stream": {
      "id": 105,
      "video_name": "testing_parish",
      "video_type": "",
      "video_url": "http://uploaded-videos-bucket.s3.dualstack.eu-north-1.amazonaws.com/streams/testing_parish?X-Amz-Algorithm=AWS4-HMAC-SHA256\u0026X-Amz-Credential=AKIARSU7LAX6TGVBVQHJ%2F20240926%2Feu-north-1%2Fs3%2Faws4_request\u0026X-Amz-Date=20240926T111744Z\u0026X-Amz-Expires=86400\u0026X-Amz-SignedHeaders=host\u0026X-Amz-Signature=ae77a5bfe73e523542ba361b86e018e610cbd292ad98eeec0edb9193905805c1",
      "created_at": "2024-09-26 07:12:38",
      "updated_at": "2024-09-26 07:12:38",
      "site_id": 115
    },
    "roi": [
      {
        "message": "ROI data unavailable"
      }
    ]
  },
  {
    "id": 164,
    "stream": {
      "id": 106,
      "video_name": "testing_parish",
      "video_type": "",
      "video_url": "http://uploaded-videos-bucket.s3.dualstack.eu-north-1.amazonaws.com/streams/testing_parish?X-Amz-Algorithm=AWS4-HMAC-SHA256\u0026X-Amz-Credential=AKIARSU7LAX6TGVBVQHJ%2F20240926%2Feu-north-1%2Fs3%2Faws4_request\u0026X-Amz-Date=20240926T111744Z\u0026X-Amz-Expires=86400\u0026X-Amz-SignedHeaders=host\u0026X-Amz-Signature=ae77a5bfe73e523542ba361b86e018e610cbd292ad98eeec0edb9193905805c1",
      "created_at": "2024-09-26 07:12:38",
      "updated_at": "2024-09-26 07:12:38",
      "site_id": 115
    },
    "roi": [
      {
        "message": "ROI data unavailable"
      }
    ]
  }, {
    "id": 165,
    "stream": {
      "id": 107,
      "video_name": "testing_parish",
      "video_type": "",
      "video_url": "http://uploaded-videos-bucket.s3.dualstack.eu-north-1.amazonaws.com/streams/testing_parish?X-Amz-Algorithm=AWS4-HMAC-SHA256\u0026X-Amz-Credential=AKIARSU7LAX6TGVBVQHJ%2F20240926%2Feu-north-1%2Fs3%2Faws4_request\u0026X-Amz-Date=20240926T111744Z\u0026X-Amz-Expires=86400\u0026X-Amz-SignedHeaders=host\u0026X-Amz-Signature=ae77a5bfe73e523542ba361b86e018e610cbd292ad98eeec0edb9193905805c1",
      "created_at": "2024-09-26 07:12:38",
      "updated_at": "2024-09-26 07:12:38",
      "site_id": 115
    },
    "roi": [
      {
        "message": "ROI data unavailable"
      }
    ]
  }, {
    "id": 166,
    "stream": {
      "id": 108,
      "video_name": "testing_parish",
      "video_type": "",
      "video_url": "http://uploaded-videos-bucket.s3.dualstack.eu-north-1.amazonaws.com/streams/testing_parish?X-Amz-Algorithm=AWS4-HMAC-SHA256\u0026X-Amz-Credential=AKIARSU7LAX6TGVBVQHJ%2F20240926%2Feu-north-1%2Fs3%2Faws4_request\u0026X-Amz-Date=20240926T111744Z\u0026X-Amz-Expires=86400\u0026X-Amz-SignedHeaders=host\u0026X-Amz-Signature=ae77a5bfe73e523542ba361b86e018e610cbd292ad98eeec0edb9193905805c1",
      "created_at": "2024-09-26 07:12:38",
      "updated_at": "2024-09-26 07:12:38",
      "site_id": 115
    },
    "roi": [
      {
        "message": "ROI data unavailable"
      }
    ]
  },
  {
    "id": 168,
    "stream": {
      "id": 108,
      "video_name": "testing_parish",
      "video_type": "",
      "video_url": "http://uploaded-videos-bucket.s3.dualstack.eu-north-1.amazonaws.com/streams/testing_parish?X-Amz-Algorithm=AWS4-HMAC-SHA256\u0026X-Amz-Credential=AKIARSU7LAX6TGVBVQHJ%2F20240926%2Feu-north-1%2Fs3%2Faws4_request\u0026X-Amz-Date=20240926T111744Z\u0026X-Amz-Expires=86400\u0026X-Amz-SignedHeaders=host\u0026X-Amz-Signature=ae77a5bfe73e523542ba361b86e018e610cbd292ad98eeec0edb9193905805c1",
      "created_at": "2024-09-26 07:12:38",
      "updated_at": "2024-09-26 07:12:38",
      "site_id": 115
    },
    "roi": [
      {
        "message": "ROI data unavailable"
      }
    ]
  }
]

const StreamSelectionComponent = ({ onOpen, handleClose }) => {
  const [step, setStep] = useState("test-stream");
  const { project, projectid, siteid } = useParams();
  const [streams, setStreams] = useState([]);
  const [selectedStream, setSelectedStream] = useState('');

  const getAllStreams = async () => {
    //console.log("Getting streams for project", projectid);
    const resp = await getProjectStreams(projectid);
    setStreams(resp);
  };

  const modalPerformance = async () => {
    const res = await testStreamModal(projectid, selectedStream);
    console.log("Response", res);
    setStep("modal-performance");
  };

  useEffect(() => {

    getAllStreams();

  }, [projectid]);

  return (
    <>
      <Modal open={onOpen} onClose={handleClose}>
        <Box
          className="bg-white p-6 rounded-lg shadow-xl"
          sx={{
            width: { xs: "90%", sm: "80%", md: "45%" },
            margin: "auto",
            mt: "10%",
            maxHeight: "80vh",
            overflowY: "auto",
          }}
        >
          {step == "test-stream" && (
            <StreamTestComponent
              streams={streams}
              selectedStream={selectedStream}
              setSelectedStream={setSelectedStream}
              modalPerformance={modalPerformance}
            />
          )}
          {step == "modal-performance" && (
            <ModelPerformanceComponent

            />
          )}
        </Box>
      </Modal>
    </>
  );
}

export default StreamSelectionComponent;