import { SET_SITE_DETAILS } from "../actions/siteActions";

const initialState = {
  names: [],
};

const siteReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SITE_DETAILS:
      // console.log("action",action);
      return {
        ...state,
        names: action.payload.siteNames,
      };
    default:
      return state;
  }
};

export default siteReducer;
