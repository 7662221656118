export const generateThumbnail = (videoUrl, seekTime = 1) => {
  console.log("videoUrl", videoUrl);
  return new Promise((resolve, reject) => {
    const video = document.createElement("video");
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    video.addEventListener("loadeddata", () => {
      video.currentTime = seekTime;
    });

    video.addEventListener("seeked", () => {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

      try {
        const thumbnailDataUrl = canvas.toDataURL("image/png");
        resolve(thumbnailDataUrl);
      } catch (error) {
        reject("Error generating thumbnail: " + error.message);
      }
    });

    video.addEventListener("error", (e) => {
      reject("Error loading video: " + e.message);
    });

    video.src = videoUrl;
    video.crossOrigin = "anonymous";
    video.load();
  });
};
