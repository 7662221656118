export const SET_SITE_DETAILS = "SET_SITE_DETAILS";

export const setSiteNames = (names) => {
  const siteNames = names.map((site) => ({ name: site.name, id: site.id }));
   console.log(siteNames);
  return {
    type: SET_SITE_DETAILS,
    payload: { siteNames },
  };
};
