import { FFmpeg } from '@ffmpeg/ffmpeg';
// import { fetchFile } from '@ffmpeg/util';

class FFmpegRTSPService {
  constructor() {
    this.ffmpeg = new FFmpeg();
    this.isLoaded = false;
  }

  // Load FFmpeg core
  async load() {
    if (!this.isLoaded) {
      await this.ffmpeg.load({
        log: true,
        coreURL: 'https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd/ffmpeg-core.js',
      });

      this.ffmpeg.on('log', ({ message }) => {
        console.log('FFmpeg Log:', message);
      });

      this.ffmpeg.on('progress', ({ progress, time }) => {
        console.log('Processing time:', time);
        console.log('Processing progress:', Math.round(progress * 100), '%');
      });
      console.log('FFmpeg loaded successfully');
      this.isLoaded = true;
    }
  }

  // Process RTSP stream by fetching it and transcoding to MP4
  async processRTSPStream(rtspUrl) {
    try {
        await this.load();

        // Single FFmpeg command to transcode and send the RTSP stream to stdout
        const command = [
            '-i', rtspUrl, // RTSP URL input
            '-c:v', 'libx264', // H.264 video codec
            '-preset', 'veryfast', // Video encoding preset
            '-c:a', 'aac', // AAC audio codec
            '-b:a', '128k', // Audio bitrate
            '-movflags', '+faststart', // Start playback faster
            '-f', 'mp4', // Output format
            'pipe:1' // Output to stdout (pipe)
        ];

        const process = this.ffmpeg.exec(command);
        const outputStream = process.stdout;

        // Send chunks to WebSocket clients
        outputStream.on('data', (chunk) => {
            this.wsServer.clients.forEach(client => {
                if (client.readyState === WebSocket.OPEN) {
                    client.send(chunk);
                }
            });
        });

        // Handle cleanup after streaming
        outputStream.on('end', () => {
            console.log('Streaming finished');
        });

    } catch (error) {
        console.error('Error processing RTSP stream:', error);
        throw new Error('Failed to process RTSP stream');
    }
}


  async generateThumbnail(rtspUrl) {
    try {
      await this.load();
  
      // Generate thumbnail and return it as a Blob
      const thumbnailData = await this.ffmpeg.exec(
        '-i', rtspUrl,
        '-vframes', '1',
        '-an',
        '-s', '320x240',
        '-ss', '0',
        'thumbnail.jpg'
      );
  
      return new Blob([thumbnailData.buffer], { type: 'image/jpeg' });
    } catch (error) {
      console.error('Error generating thumbnail:', error);
      throw error;
    }
  }

  // Terminate the FFmpeg instance
  async terminate() {
    if (this.isLoaded) {
      await this.ffmpeg.terminate(); // Terminate FFmpeg
      this.isLoaded = false; // Mark as not loaded
    }
  }

  // Create a URL from the video Blob to display it
  createVideoUrl(blob) {
    return URL.createObjectURL(blob);
  }

  // Revoke the URL when no longer needed
  revokeVideoUrl(url) {
    URL.revokeObjectURL(url);
  }
}

// Export the RTSP FFmpeg service instance
export const ffmpegRTSPService = new FFmpegRTSPService();
