import React, { useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X } from 'lucide-react';
import { ffmpegService } from '../../services/ffmpeg';

const VideoPlayerModal = ({ isOpen, onClose, videoUrl }) => {
  const videoRef = useRef(null);
  const [progress, setProgress] = React.useState(0);
  const [processedVideoUrl, setProcessedVideoUrl] = React.useState(null);

  async function handleProgress(url) {
    const processedBlob = await ffmpegService.processVideo(url, "streamOptimized");
    const videoUrl = ffmpegService.createVideoUrl(processedBlob);

    setProcessedVideoUrl(videoUrl);
  }

  useEffect(() => {
    ffmpegService.ffmpeg.on("progress", ({ progress }) => {
      setProgress(Math.round(progress * 100));
    });

    return () => {
      if (processedVideoUrl) {
        ffmpegService.revokeVideoUrl(processedVideoUrl);
      }
      ffmpegService.terminate();
    };
  }, [processedVideoUrl]);

  useEffect(() => {
    if (videoUrl) {
      handleProgress(videoUrl);
    }
  }, [videoUrl]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleKeyDown);
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <AnimatePresence>
      <div className="fixed inset-0 z-[100] flex items-center justify-center overflow-hidden">
        {/* Backdrop */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="absolute inset-0 bg-black/70 backdrop-blur-sm"
          onClick={onClose}
        />

        {/* Modal Container */}
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.9 }}
          transition={{ type: "spring", stiffness: 300, damping: 20 }}
          className="relative w-full max-w-5xl mx-4 rounded-xl overflow-hidden shadow-2xl"
        >
          {/* Close Button */}
          <button
            onClick={onClose}
            className="absolute top-4 right-4 z-10 bg-white/20 hover:bg-white/30 text-white rounded-full p-2 transition-colors"
          >
            <X className="w-6 h-6" />
          </button>

          {/* Video Player */}
          <div className="w-full aspect-video bg-black relative">
            {progress < 100 && (
              <div className="absolute inset-0 bg-black/90 flex flex-col items-center justify-center z-10">
                <div className="w-64 bg-gray-200 rounded-full h-2.5 mb-4 dark:bg-gray-700">
                  <div 
                    className="bg-blue-600 h-2.5 rounded-full transition-all duration-300 ease-out"
                    style={{ width: `${progress}%` }}
                  />
                </div>
                <div className="text-white font-medium">
                  Processing Video... {progress}%
                </div>
              </div>
            )}
            <video
              ref={videoRef}
              className="w-full h-full object-contain"
              controls
              controlsList="nodownload noremoteplayback"
              playsInline
              autoPlay
              key={videoUrl}
            >
              <source src={videoUrl} type="video/mp4" />
              <source
                src={videoUrl.replace(".mp4", ".webm")}
                type="video/webm"
              />
              Your browser does not support the video tag.
            </video>
          </div>
        </motion.div>
      </div>
    </AnimatePresence>
  );
};

export default VideoPlayerModal;
