import React, { useMemo } from 'react';
import {
    ResponsiveContainer,
    ComposedChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Bar,
    Line,
    ReferenceLine,
    Cell
} from 'recharts';

const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        const { name, frequency, cumulativePercentage, isVitalFew } = payload[0].payload;

        return (
            <div className="bg-white rounded-lg shadow-lg p-4 border border-gray-200">
                <div className="border-b border-gray-200 pb-2 mb-2">
                    <h3 className="font-semibold text-gray-800">{name}</h3>
                </div>
                <div className="space-y-2">
                    <div className="flex items-center">
                        <span className="text-gray-600 mr-1">Violations: </span>
                        <span className="font-medium text-gray-800">{frequency}</span>
                    </div>
                    <div className="flex items-center">
                        <span className="text-gray-600 mr-1">Cumulative: </span>
                        <span className="font-medium text-gray-800">
                            {cumulativePercentage.toFixed(1)}%
                        </span>
                    </div>
                    {isVitalFew && (
                        <div className="mt-2 pt-2 border-t border-gray-200">
                            <span className="text-red-600 text-sm font-medium">
                                Critical
                            </span>
                        </div>
                    )}
                </div>
            </div>
        );
    }
    return null;
};

const ParetoChart = ({ data, loading, selectWarehouse }) => {
    const processedData = useMemo(() => {
        // Sort data by frequency
        const sortedData = [...data].sort((a, b) => b.frequency - a.frequency);
        const total = sortedData.reduce((acc, item) => acc + item.frequency, 0);
        let cumulativeFrequency = 0;
        return sortedData.map((item) => {
            cumulativeFrequency += item.frequency;
            const cumulativePercentage = (cumulativeFrequency / total) * 100;
            return {
                ...item,
                cumulativePercentage,
                isVitalFew: cumulativePercentage <= 80
            };
        });
    }, [data]);

    const vitalFewIndex = processedData.findIndex((item) => item.cumulativePercentage >= 80);
    { console.log(selectWarehouse) }
    if (loading) {
        return (
            <div className="w-full h-[500px] p-2 bg-white rounded-lg shadow-sm">
                <div className="animate-pulse space-y-4">
                    <div className="flex justify-between items-center mb-4">
                        <div className="h-6 bg-gray-200 rounded w-1/4"></div>
                        <div className="h-8 bg-gray-200 rounded w-1/3"></div>
                    </div>
                    <div className="h-[400px] bg-gray-100 rounded-lg">
                        <div className="h-full w-full flex items-center justify-center">
                            <div className="flex flex-col items-center space-y-2">
                                <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
                                <p className="text-sm text-gray-500"> {selectWarehouse ? `Loading chart data.` : 'Please select a warehouse'}</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center space-x-4">
                        {[1, 2, 3].map((i) => (
                            <div key={i} className="h-4 bg-gray-200 rounded w-20"></div>
                        ))}
                    </div>
                </div>
            </div>
        )
    }

    if (processedData.length === 0) {
        return (
            <div className="w-full h-[500px] bg-white rounded-lg shadow-sm p-6 border border-gray-200">
                <div className="h-full flex flex-col items-center justify-center text-center">
                    <div className="mb-4">
                        <svg className="w-16 h-16 text-gray-400 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </div>
                    <h3 className="text-xl font-semibold text-gray-700 mb-2">
                        Pareto Chart Data Unavailable
                    </h3>
                    <p className="text-gray-500 mb-4 max-w-md">
                        No pareto chart data is currently available for this site and time period.
                    </p>
                    <div className="text-sm text-gray-400">
                        Try selecting a different time period.
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="w-full h-[500px] p-4">
            <div className="mb-6">
                <h2 className="text-xl font-semibold text-gray-800">Pareto Chart</h2>
            </div>
            <ResponsiveContainer width="100%" height="100%">
                <ComposedChart
                    data={processedData}
                    margin={{ top: 10, right: 30, bottom: 120, left: 20 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey="name"
                        interval={0}
                        tick={{ angle: -45, textAnchor: 'end', fontSize: 12 }}
                        height={60}
                    />
                    <YAxis
                        yAxisId="left"
                        orientation="left"
                        label={{
                            value: 'Number of Violations',
                            angle: -90,
                            position: 'insideCenter',
                            offset: -5,
                            dx: -20
                        }}
                    />
                    <YAxis
                        yAxisId="right"
                        orientation="right"
                        domain={[0, 100]}
                        tickCount={6}
                        tickFormatter={(tick) => `${tick}%`}
                        label={{
                            value: 'Cumulative Percentage',
                            angle: -90,
                            position: 'insideCenter',
                            offset: -5,
                            dx: 25
                        }}
                    />
                    <Tooltip content={<CustomTooltip />} />
                    <Bar yAxisId="left" dataKey="frequency" barSize={30}>
                        {processedData.map((entry, index) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={entry.isVitalFew ? '#DC2626' : '#2563EB'}
                                opacity={0.8}
                            />
                        ))}
                    </Bar>
                    <Line
                        yAxisId="right"
                        dataKey="cumulativePercentage"
                        stroke="#6366F1"
                        strokeWidth={2}
                        dot={{ fill: '#6366F1', r: 4 }}
                    />
                    <ReferenceLine
                        yAxisId="right"
                        y={80}
                        stroke="#374151"
                        strokeDasharray="5 5"
                        label={{
                            position: 'right',
                            fill: '#374151',
                            fontSize: 12
                        }}
                    />
                    <ReferenceLine
                        yAxisId="left"
                        x={vitalFewIndex}
                        stroke="#E5E7EB"
                        strokeDasharray="5 5"
                        label={{
                            position: 'left',
                            fill: '#374151',
                            fontSize: 12
                        }}
                    />
                </ComposedChart>
            </ResponsiveContainer>
        </div>
    );
};

export default ParetoChart;