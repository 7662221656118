import React, { useState, useEffect, useRef, useMemo } from "react";
import * as d3 from "d3";
import { motion } from "framer-motion";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
} from "recharts";
import { Activity, Gauge, Clock } from "lucide-react";
import { TIME_RANGES } from "../utils/constants";
// import { checkHourlyAlertOccurrences, checkMonthlyAlertOccurrences, checkWeeklyAlertOccurrences, checkYearlyAlertOccurrences } from "../utils/alertTypeUtils";

const getImageDimensions = (imageUrl) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve({
        width: img.naturalWidth,
        height: img.naturalHeight,
      });
    };
    img.onerror = reject;
    img.src = imageUrl;
  });
};

const aggregateDataByProximity = (positions, radius, originalDimensions) => {
  const clusters = [];

  positions.forEach((pos) => {
    if (!pos) return;

    // Find nearby clusters
    const nearbyCluster = clusters.find((cluster) => {
      const distance = Math.sqrt(
        Math.pow(cluster.x - pos.x, 2) + Math.pow(cluster.y - pos.y, 2)
      );
      return distance < radius;
    });

    if (nearbyCluster) {
      // Add to existing cluster
      nearbyCluster.intensity += 1;
      nearbyCluster.incidents.push(...pos.incidents);
      // Update cluster center
      nearbyCluster.x =
        (nearbyCluster.x * (nearbyCluster.intensity - 1) + pos.x) /
        nearbyCluster.intensity;
      nearbyCluster.y =
        (nearbyCluster.y * (nearbyCluster.intensity - 1) + pos.y) /
        nearbyCluster.intensity;
    } else {
      // Create new cluster
      clusters.push({
        x: pos.x,
        y: pos.y,
        intensity: 1,
        incidents: [...pos.incidents],
      });
    }
  });

  return clusters;
};

const processAlertDataArray = (alerts, dateRange, alertName) => {
  console.log("alerts", alerts);
  let latestIncident = null;
  const speeds = [];
  const proximityValues = [];
  const currentDate = new Date();

  const startDate = new Date(
    currentDate.getTime() - TIME_RANGES[dateRange].current
  );

  console.log("startDate", startDate, alerts, dateRange, alertName);

  const processedData = alerts
    ?.map((alert) => {
      if (alert.alertName !== alertName) {
        return null;
      }

      const alertTimestamp = new Date(alert.createdAt);
      if (alertTimestamp >= startDate) {
        if (
          !alert.forklift_position ||
          alert.forklift_position.x === undefined ||
          alert.forklift_position.y === undefined
        ) {
          return null;
        }

        if (
          alertName === "Forklift Overspeeding" ||
          alertName === "Forklift Speed Violation"
        ) {
          const speedMatch = alert.description.match(/Forklift speed: (\d+)/);
          if (speedMatch) {
            const speed = Math.ceil(parseInt(speedMatch[1], 10)); // Round up to nearest integer
            speeds.push(speed);
          }
        } else if (
          alertName === "Human Proximity Violation" ||
          alertName === "Forklift Proximity To People"
        ) {
          const proximityMatch = alert.description.match(
            /Forklift was at (\d+\.?\d*)/
          );
          if (proximityMatch) {
            const distance = Math.ceil(parseFloat(proximityMatch[1])); // Round up to nearest integer
            proximityValues.push(distance);
          }
        }

        const incident = {
          type: alert.alertName,
          description: alert.description,
          timestamp: alertTimestamp,
          forklift_id: alert.alertTypeId,
        };

        if (!latestIncident || incident.timestamp > latestIncident.timestamp) {
          latestIncident = incident;
        }

        return {
          x: alert.forklift_position.x,
          y: alert.forklift_position.y,
          incidents: [incident],
        };
      }
      return null;
    })
    .filter((position) => position !== null);

  let returnMetrics = {};

  if (
    alertName === "Forklift Speed Violation" ||
    alertName === "Forklift Overspeeding"
  ) {
    const maxiumumSpeed =
      speeds.length > 0 ? Math.max(...speeds) + " mph" : null;
    returnMetrics.maxiumumSpeed = maxiumumSpeed;
  } else if (
    alertName === "Human Proximity Violation" ||
    alertName === "Forklift Proximity To People"
  ) {
    const closestDetection =
      proximityValues.length > 0
        ? Math.min(...proximityValues).toFixed(0) + " cm"
        : null;
    returnMetrics.closestDetection = closestDetection;
  }

  console.log("processedData", processedData, latestIncident, returnMetrics);

  return {
    processedData,
    latestIncident,
    ...returnMetrics,
  };
};

const normalizeIntensity = (data) => {
  const maxIntensity = Math.max(...data.map((d) => d.intensity));
  return data.map((d) => ({
    ...d,
    intensity: maxIntensity > 0 ? d.intensity / maxIntensity : 0,
  }));
};

const calculateResponsiveDimensions = (originalDimensions, isModal = false) => {
  const screenHeight = window.innerHeight;
  const screenWidth = window.innerWidth;

  const containerWidth = screenWidth * 0.66 - 48;

  const aspectRatio = originalDimensions.width / originalDimensions.height;

  if (isModal) {
    const modalWidth = Math.min(screenWidth * 0.8, 1200);
    return {
      width: modalWidth,
      height: modalWidth / aspectRatio,
    };
  }

  const width = Math.min(containerWidth, 900);
  const height = Math.min(width / aspectRatio, screenHeight * 0.5);
  // const height = width / aspectRatio;

  console.log("Responsive dimensions:", { width, height });

  return { width, height };
};

const ForkliftHeatmap = ({
  imageUrl,
  data,
  dimensions,
  originalDimensions,
  threshold = 0.3,
  alertName,
}) => {
  const containerRef = useRef(null);
  const svgRef = useRef(null);
  const tooltipRef = useRef(null);

  useEffect(() => {
    if (
      !data ||
      !svgRef.current ||
      !tooltipRef.current ||
      !containerRef.current
    )
      return;
    console.log("data", data);
    const ORIGINAL_WIDTH = originalDimensions.width || dimensions.width;
    const ORIGINAL_HEIGHT = originalDimensions.height || dimensions.height;

    const clusterRadius = ORIGINAL_WIDTH / 50;
    const aggregatedData = aggregateDataByProximity(data, clusterRadius, {
      width: ORIGINAL_WIDTH,
      height: ORIGINAL_HEIGHT,
    });
    const normalizedData = normalizeIntensity(aggregatedData);

    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();

    const { width, height } = dimensions;

    svg
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", `0 0 ${width} ${height}`)
      .attr("preserveAspectRatio", "xMidYMid meet");

    const xScale = d3
      .scaleLinear()
      .domain([0, ORIGINAL_WIDTH])
      .range([0, width]);

    const yScale = d3
      .scaleLinear()
      .domain([0, ORIGINAL_HEIGHT])
      .range([0, height]);

    const mainGroup = svg.append("g");

    mainGroup
      .append("image")
      .attr("xlink:href", imageUrl)
      .attr("width", width)
      .attr("height", height)
      .attr("preserveAspectRatio", "xMidYMid meet");

    const extractSpeed = (description) => {
      const match = description?.match(/Forklift speed: (\d+)/);
      return match ? parseInt(match[1]) : null;
    };

    const extractDistance = (description) => {
      const match = description?.match(/Forklift was at (\d+\.?\d*)/);
      return match ? parseFloat(match[1]) : null;
    };

    const getClusterMetrics = (incidents, alertName) => {
      let highestSpeed = -Infinity;
      let lowestDistance = Infinity;
      let latestIncident = null;

      incidents.forEach((incident) => {
        if (alertName.toLowerCase().includes("speed")) {
          const speed = extractSpeed(incident.description);
          if (speed !== null && speed > highestSpeed) {
            highestSpeed = speed;
          }
        } else {
          const distance = extractDistance(incident.description);
          if (distance !== null && distance < lowestDistance) {
            lowestDistance = distance;
          }
        }

        if (
          !latestIncident ||
          new Date(incident.timestamp) > new Date(latestIncident.timestamp)
        ) {
          latestIncident = incident;
        }
      });

      return {
        highestSpeed: highestSpeed !== -Infinity ? highestSpeed : "N/A",
        lowestDistance: lowestDistance !== Infinity ? lowestDistance : "N/A",
        latestIncident,
      };
    };

    const findClusters = (points, eps, minPts) => {
      const clusters = [];
      const visited = new Set();

      const getNeighbors = (point) => {
        return points.filter((p) => {
          const distance = Math.sqrt(
            Math.pow(xScale(p.x) - xScale(point.x), 2) +
            Math.pow(yScale(p.y) - yScale(point.y), 2)
          );
          return distance <= eps;
        });
      };

      const expandCluster = (point, neighbors) => {
        const cluster = [point];
        for (let i = 0; i < neighbors.length; i++) {
          const neighbor = neighbors[i];
          const key = `${neighbor.x},${neighbor.y}`;

          if (!visited.has(key)) {
            visited.add(key);
            const newNeighbors = getNeighbors(neighbor);
            if (newNeighbors.length >= minPts) {
              neighbors.push(
                ...newNeighbors.filter(
                  (n) => !visited.has(`${n.x},${n.y}`) && !neighbors.includes(n)
                )
              );
            }
            cluster.push(neighbor);
          }
        }
        return cluster;
      };

      points.forEach((point) => {
        const key = `${point.x},${point.y}`;
        if (visited.has(key)) return;

        visited.add(key);
        const neighbors = getNeighbors(point);

        if (neighbors.length >= minPts) {
          const cluster = expandCluster(point, neighbors);
          if (cluster.length >= minPts) {
            clusters.push(cluster);
          }
        }
      });

      return clusters;
    };

    const boundaryGroup = mainGroup.append("g").attr("class", "boundaries");
    const clusters = findClusters(normalizedData, width * 0.15, 3);

    clusters.forEach((cluster) => {
      if (cluster.length >= 3) {
        const hullPoints = d3.polygonHull(
          cluster.map((d) => [xScale(d.x), yScale(d.y)])
        );

        if (hullPoints) {
          const line = d3.line().curve(d3.curveCatmullRomClosed);

          boundaryGroup
            .append("path")
            .datum(hullPoints)
            .attr("d", line)
            .attr("fill", "none")
            .attr("stroke", "rgba(255, 255, 255, 0.6)")
            .attr("stroke-width", 2)
            .attr("stroke-dasharray", "5,5")
            .style("pointer-events", "none");
        }
      }
    });

    const colorScale = d3
      .scaleSequential()
      .domain([0, 1])
      .interpolator(
        d3.interpolateRgb("rgba(255, 128, 0, 0.5)", "rgba(255, 128, 0, 0.9)")
      );

    const pointGroup = mainGroup.append("g");

    normalizedData.forEach((d, i) => {
      const gradient = svg
        .append("defs")
        .append("radialGradient")
        .attr("id", `point-gradient-${i}`)
        .attr("gradientUnits", "objectBoundingBox");

      gradient
        .append("stop")
        .attr("offset", "0%")
        .attr("stop-color", colorScale(d.intensity))
        .attr("stop-opacity", 0.9);

      gradient
        .append("stop")
        .attr("offset", "100%")
        .attr("stop-color", colorScale(d.intensity))
        .attr("stop-opacity", 0);
    });

    const baseRadius = Math.min(width, height) / 25;

    const circles = pointGroup
      .selectAll("g")
      .data(normalizedData)
      .enter()
      .append("g");

    circles
      .append("circle")
      .attr("class", "hover-circle")
      .attr("cx", (d) => xScale(d.x))
      .attr("cy", (d) => yScale(d.y))
      .attr("r", (d) =>
        Math.min(baseRadius * d.intensity + baseRadius / 2, baseRadius * 1.5)
      )
      .attr("fill", "none")
      .attr("stroke", "none")
      .attr("stroke-width", 2)
      .style("pointer-events", "all");

    circles
      .append("circle")
      .attr("class", "heat-circle")
      .attr("cx", (d) => xScale(d.x))
      .attr("cy", (d) => yScale(d.y))
      .attr("r", (d) =>
        Math.min(baseRadius * d.intensity + baseRadius / 2, baseRadius * 1.5)
      )
      .attr("fill", (d, i) => `url(#point-gradient-${i})`)
      .style("pointer-events", "none");

    circles
      .on("mouseover", (event, d) => {
        const circle = event.currentTarget;

        d3.select(circle)
          .select(".hover-circle")
          .attr("stroke", "white")
          .attr("stroke-opacity", 0.3)
          .transition()
          .duration(200)
          .attr("r", function () {
            const currentRadius = Math.min(
              baseRadius * d.intensity + baseRadius / 2,
              baseRadius * 1.5
            );
            return currentRadius * 1.2;
          });

        const containerRect = containerRef.current.getBoundingClientRect();
        const [mouseX, mouseY] = d3.pointer(event, containerRef.current);

        const tooltip = d3.select(tooltipRef.current);

        const metrics = getClusterMetrics(d.incidents, alertName);
        const date = new Date(
          metrics.latestIncident?.timestamp
        ).toLocaleString();

        let content = `
                <div class="mb-2">Incidents in this area: ${d.incidents.length}</div>
                <div class="text-xs mt-2 border-t pt-2">
            `;

        if (alertName.toLowerCase().includes("speed")) {
          content += `
                    <div class="font-semibold">Highest Speed: ${metrics.highestSpeed} mph</div>
                `;
        } else {
          content += `
                    <div>Lowest Distance: ${metrics.lowestDistance} cm</div>
                `;
        }

        content += `
                </div>
                <div class="text-xs mt-2 border-t pt-2">
                    <div class="font-semibold">Latest Incident:</div>
                    <div>${metrics.latestIncident?.description || "N/A"}</div>
                    <div>Forklift ID: ${metrics.latestIncident?.forklift_id || "N/A"
          }</div>
                    <div>${date || "N/A"}</div>
                </div>
            `;

        let tooltipX = mouseX + 10;
        let tooltipY = mouseY + 10;

        tooltip
          .style("left", `${tooltipX}px`)
          .style("top", `${tooltipY}px`)
          .style("opacity", 1)
          .html(content);

        const tooltipRect = tooltipRef.current.getBoundingClientRect();

        if (tooltipRect.right > containerRect.right) {
          tooltipX = mouseX - tooltipRect.width - 10;
          tooltip.style("left", `${tooltipX}px`);
        }
        if (tooltipRect.bottom > containerRect.bottom) {
          tooltipY = mouseY - tooltipRect.height - 10;
          tooltip.style("top", `${tooltipY}px`);
        }
      })
      .on("mouseout", (event) => {
        const circle = event.currentTarget;

        d3.select(circle)
          .select(".hover-circle")
          .attr("stroke", "none")
          .transition()
          .duration(200)
          .attr("r", function (d) {
            return Math.min(
              baseRadius * d.intensity + baseRadius / 2,
              baseRadius * 1.5
            );
          });

        d3.select(tooltipRef.current).style("opacity", 0);
      });
  }, [imageUrl, data, dimensions, originalDimensions, threshold]);

  return (
    <div
      ref={containerRef}
      className="relative w-full flex justify-center items-center h-fit"
    >
      <svg ref={svgRef} className="w-full h-fit"></svg>
      <div
        ref={tooltipRef}
        className="absolute bg-white rounded-lg shadow-lg border border-gray-100"
        style={{
          padding: "16px",
          opacity: 0,
          pointerEvents: "none",
          maxWidth: "300px",
          fontSize: "14px",
          transform: "translateY(-6px)",
          backdropFilter: "blur(8px)",
          backgroundColor: "rgba(255, 255, 255, 0.98)",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.08)",
          zIndex: 1000,
        }}
      />
    </div>
  );
};

const StatCard = ({ icon: Icon, title, value, subtext, delay = 0 }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5, delay }}
    className="bg-white rounded-lg p-6 shadow-lg hover:shadow-xl transition-all duration-300"
  >
    <div className="flex items-center justify-between">
      <div className="space-y-2">
        <h3 className="text-sm font-medium text-gray-500">{title}</h3>
        <p className="text-2xl font-bold text-gray-900">{value}</p>
        {subtext && <p className="text-xs text-gray-500">{subtext}</p>}
      </div>
      <Icon className="w-8 h-8 text-orange-500" />
    </div>
  </motion.div>
);

const TimeSeriesChart = ({ data, metric, timeFrame }) => {
  const processedData = useMemo(() => {
    if (!data?.length) return [];

    const currentTime = new Date();
    const pastTime = new Date(
      currentTime.getTime() - TIME_RANGES[timeFrame].current
    );

    // Filter the data based on the selected time range
    const filteredData = data.filter((alert) => {
      const alertTime = new Date(alert.createdAt);
      return alertTime >= pastTime && alertTime <= currentTime;
    });

    const valueBuckets = new Map();
    filteredData.forEach((alert) => {
      let value;
      if (metric === "proximity") {
        const match = alert.description.match(/Forklift was at (\d+\.?\d*)/);
        if (match) {
          value = Math.ceil(parseFloat(match[1]));
        }
      } else {
        const match = alert.description.match(/Forklift speed: (\d+)/);
        if (match) {
          value = Math.ceil(parseInt(match[1]));
        }
      }
      if (value !== undefined) {
        valueBuckets.set(value, (valueBuckets.get(value) || 0) + 1);
      }
    });
    return Array.from(valueBuckets.entries())
      .map(([value, count]) => ({
        value: value.toString(),
        violations: count,
        hasData: true,
      }))
      .sort((a, b) => parseInt(a.value) - parseInt(b.value));
  }, [data, metric, timeFrame]);

  const chartTitle =
    metric === "proximity" ? "Proximity Distribution" : "Speed Distribution";
  const xAxisLabel =
    metric === "proximity" ? "Distance (cm)" : "Speed (mph)";

  const getTickInterval = () => {
    const dataLength = processedData.length;
    if (dataLength <= 4) return 0;
    if (dataLength <= 8) return 1;
    if (dataLength <= 12) return 2;
    return Math.floor(dataLength / 6);
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (!active || !payload || !payload.length) return null;

    const data = payload[0].payload;
    return (
      <div className="bg-white p-2 rounded-lg shadow-lg border border-gray-200 text-xs">
        <p className="font-semibold mb-1">{`${xAxisLabel}: ${label}`}</p>
        <p className="mb-1">Violations: {data.violations}</p>
      </div>
    );
  };

  return (
    <div className="bg-white rounded-lg p-4 shadow-lg">
      <h3 className="text-sm font-medium text-gray-500 mb-2">{chartTitle}</h3>
      <div className="h-32">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={processedData}
            barSize={processedData.length <= 4 ? 20 : undefined}
          >
            <CartesianGrid
              strokeDasharray="3 3"
              className="stroke-gray-200"
              vertical={false}
            />
            <XAxis
              dataKey="value"
              className="text-xs"
              tickMargin={5}
              interval={getTickInterval()}
              tick={({ x, y, payload }) => (
                <g transform={`translate(${x},${y})`}>
                  <text
                    x={0}
                    y={0}
                    dy={10}
                    textAnchor="middle"
                    fill="#666"
                    fontSize="10"
                  >
                    {payload.value}
                  </text>
                </g>
              )}
              label={{ value: xAxisLabel, position: "insideBottom", dy: 7 }}
            />
            <YAxis
              dataKey="violations"
              className="text-xs"
              tickMargin={5}
              tick={({ x, y, payload }) => (
                <g transform={`translate(${x},${y})`}>
                  <text x={0} y={0} textAnchor="end" fill="#666" fontSize="10">
                    {payload.value}
                  </text>
                </g>
              )}
              label={{
                value: "Violations",
                angle: -90,
                position: "insideCenter",
                dx: -10,
              }}
            />
            <Tooltip content={<CustomTooltip />} />
            <Bar dataKey="violations" fill="#f97316" opacity={0.8} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

const HeatMap = ({
  isModal = false,
  specificAlert = [],
  heading,
  alertName,
  selectedDate = "Hours",
  heatMapImage,
  loading = true,
}) => {
  const [heatmapData, setHeatmapData] = useState(specificAlert);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [originalDimensions, setOriginalDimensions] = useState(null);
  const [dimensions, setDimensions] = useState(null);
  const [latestIncident, setLatestIncident] = useState([]);
  const [maxSpeed, setMaxSpeed] = useState("8");

  const memoizedData = useMemo(() => {
    console.log("Specific alert data:", specificAlert);
    return specificAlert.length > 0
      ? processAlertDataArray(specificAlert, selectedDate, alertName)
      : null;
  }, [specificAlert, selectedDate]);

  useEffect(() => {
    const initializeHeatmap = async () => {
      try {
        setIsLoading(true);
        console.log("Initializing heatmap...", heatMapImage);
        const imageDims = await getImageDimensions(heatMapImage);
        console.log("Image dimensions:", imageDims);
        setOriginalDimensions(imageDims);

        const responsiveDims = calculateResponsiveDimensions(
          imageDims,
          isModal
        );
        console.log("Responsive dimensions:", responsiveDims);
        setDimensions(responsiveDims);

        if (memoizedData) {
          // console.log("Specific alert data:", selectedDate);
          const {
            processedData,
            latestIncident,
            maxiumumSpeed,
            closestDetection,
          } = memoizedData;
          console.log(
            "Processed data:",
            processedData,
            latestIncident,
            maxiumumSpeed,
            closestDetection
          );
          setLatestIncident(latestIncident);
          setHeatmapData(processedData);
          setMaxSpeed(maxiumumSpeed ? maxiumumSpeed : closestDetection);
        }
      } catch (err) {
        setError("Failed to initialize heatmap. Please try again later.");
        console.error("Error initializing heatmap:", err);
      } finally {
        setIsLoading(false);
      }
    };
    console.log("Memoized data:", heatMapImage);
    if(heatMapImage){
      initializeHeatmap();
    }
  }, [isModal, memoizedData, heatMapImage]);

  useEffect(() => {
    if(loading){
      setIsLoading(true);
    }else{
      setIsLoading(false);
    }
  }, [loading]);

  useEffect(() => {
    const handleResize = () => {
      if (originalDimensions) {
        setDimensions(
          calculateResponsiveDimensions(originalDimensions, isModal)
        );
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isModal, originalDimensions]);

  if (error) return <div>{error}</div>;
  let isNotReady = isLoading || !dimensions || !originalDimensions || !heatMapImage;
  // let isNotReady = true;

  if (!isModal || heatmapData.length <= 0) {
    return (
      <div
        className={`flex flex-row w-full p-6 h-[calc(100vh] rounded-xl border border-gray-300 shadow-lg bg-white`}
      >
        <div className="flex flex-col w-full h-full pr-6">
          <div className="flex flex-col gap-4 w-full h-full text-sm leading-none">
            <div className="text-center h-full text-gray-500 p-6 bg-gray-50 rounded-lg shadow-inner">
              No Heatmap data available
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`flex flex-row w-full p-6 border rounded-xl shadow-lg bg-white font-golos`}
    >
      <div className="grid grid-cols-12 gap-4 w-full">
        <div className="col-span-8 bg-white rounded-lg h-fit shadow-lg p-4">
          {isNotReady ? (
            <>
            <div className="space-y-4">
              <div className="h-8 bg-gray-300 animate-pulse rounded-md"></div>
              <div className="h-6 bg-gray-300 animate-pulse rounded-md w-1/2"></div>
              <div className="h-80 bg-gray-200 animate-pulse rounded-lg"></div>
            </div>
            <div className="col-span-4 space-y-4">
            <div className="space-y-4">
              <div className="h-8 bg-gray-300 animate-pulse rounded-md"></div>
              <div className="h-6 bg-gray-300 animate-pulse rounded-md w-3/4"></div>
              <div className="h-32 bg-gray-200 animate-pulse rounded-lg"></div>
            </div>
          </div>
          </>
          ) : heatmapData.length > 0 ? (
            <>
              {!isModal && (
                <h1 className="flex-1 shrink self-stretch my-auto text-3xl font-semibold tracking-tighter leading-tight text-gray-900 basis-10 max-md:max-w-full">
                  {heading}
                </h1>
              )}
              <h3 className="text-lg font-semibold">Heatmap</h3>
              <ForkliftHeatmap
                imageUrl={heatMapImage}
                data={heatmapData}
                dimensions={dimensions}
                originalDimensions={originalDimensions}
                threshold={0.3}
                containerClass="w-full h-full object-cover"
                style={{ height: "100%", width: "auto" }}
                alertName={alertName}
              />
            </>
          ) : (
            <div className="text-center text-gray-500 p-6 bg-gray-50 rounded-lg shadow-inner">
              No data available
            </div>
          )}
        </div>

        {heading.toLowerCase().includes("proximity") && !isNotReady ? (
          <div className="col-span-4 space-y-4 h-fit">
            <div className="grid grid-cols-1 gap-4">
              <StatCard
                icon={Gauge}
                title="Closest detection of forklift"
                value={`${maxSpeed || "N/A"}`}
                subtext="Proximity limit: 1 m"
              />

              <StatCard
                icon={Activity}
                title="Total Incidents"
                value={heatmapData.length}
                subtext="In current period"
              />

              <StatCard
                icon={Clock}
                title="Latest Incident"
                subtext={`${latestIncident?.description}` || "None"}
                value={
                  latestIncident
                    ? new Date(latestIncident.timestamp).toLocaleString()
                    : "N/A"
                }
              />
              <TimeSeriesChart
                data={specificAlert}
                metric="proximity"
                timeFrame={selectedDate}
              />
            </div>
          </div>
        ) : heading.toLowerCase().includes("speed") && !isNotReady ? (
          <div className="col-span-4 space-y-4 h-fit">
            <div className="grid grid-cols-1 gap-4">
              <StatCard
                icon={Gauge}
                title="Maximum Speed"
                value={`${maxSpeed || "N/A"}`}
                subtext="Speed limit: 20 mph"
              />

              <StatCard
                icon={Activity}
                title="Total Incidents"
                value={heatmapData.length}
                subtext="In current period"
              />

              <StatCard
                icon={Clock}
                title="Latest Incident"
                subtext={`${latestIncident?.description} mph` || "None"}
                value={
                  latestIncident
                    ? new Date(latestIncident.timestamp).toLocaleString()
                    : "N/A"
                }
              />

              <TimeSeriesChart
                data={specificAlert}
                metric="speed"
                timeFrame={selectedDate}
              />
            </div>
          </div>
        ) : null}

        {isNotReady && (
          <>
          <div className="col-span-4 space-y-4 h-fit">
            <div className="space-y-4">
              <div className="h-8 bg-gray-300 animate-pulse rounded-md"></div>
              <div className="h-8 bg-gray-300 animate-pulse rounded-md w-3/4"></div>
              <div className="h-24 bg-gray-200 animate-pulse rounded-lg"></div>
            </div>
            <div className="space-y-4">
              <div className="h-8 bg-gray-300 animate-pulse rounded-md"></div>
              <div className="h-8 bg-gray-300 animate-pulse rounded-md w-3/4"></div>
              <div className="h-24 bg-gray-200 animate-pulse rounded-lg"></div>
            </div>
            <div className="space-y-4">
              <div className="h-8 bg-gray-300 animate-pulse rounded-md"></div>
              <div className="h-8 bg-gray-300 animate-pulse rounded-md w-3/4"></div>
              <div className="h-24 bg-gray-200 animate-pulse rounded-lg"></div>
            </div>
          </div>
          </>
        )}
      </div>
    </div>
  );
};

export default HeatMap;
