import React from 'react';

const DrawingOverlay = ({ drawingBox }) => {
  if (!drawingBox) return null;

  return (
    <div
      style={{
        position: 'absolute',
        left: drawingBox.x,
        top: drawingBox.y,
        width: drawingBox.width,
        height: drawingBox.height,
        border: '2px dashed red',
        backgroundColor: 'rgba(0, 0, 255, 0.2)',
        pointerEvents: 'none',
      }}
    />
  );
};

export default React.memo(DrawingOverlay);