import React, { useRef, useState, useCallback, useEffect } from 'react';
import BoundingBox from './BoundingBox';
import DrawingOverlay from './DrawingOverlay';
import SelectionIndicator from './SelectionIndicator';
import { useImageDimensions } from '../../hooks/useDimensions';
import { useBoxDrawing } from '../../hooks/useBoxDrawing';

const ImageGrid = ({
  initialStream,
  frame_width,
  frame_height,
  selectedStream,
  handleSelect,
  updateFrame,
  setUndisable,
  showRnd,
  setShowRnd,
}) => {
  const imageRef = useRef(null);
  const [dimensions, updateDimensions] = useImageDimensions(imageRef);
  const [streamData, setStreamData] = useState(null);
  const [selectedBox, setSelectedBox] = useState(null);

  useEffect(() => {
    if (streamData) setShowRnd(true);
  }, [streamData, setShowRnd]);

  const handleBoxDrawn = useCallback((box) => {
    const updatedStream = { ...streamData };
    const scaleX = frame_width / dimensions.width;
    const scaleY = frame_height / dimensions.height;

    if (!updatedStream.frames[0].objects) {
      updatedStream.frames[0].objects = [];
    }

    const width = Math.min(box.width, dimensions.width - box.x);
    const height = Math.min(box.height, dimensions.height - box.y);
    const x = Math.max(0, Math.min(box.x, dimensions.width - width));
    const y = Math.max(0, Math.min(box.y, dimensions.height - height));

    const newObject = {
      id: `object${updatedStream.frames[0].objects.length + 1}`,
      label: `New Object ${updatedStream.frames[0].objects.length + 1}`,
      boundary_box: {
        left_img: x,
        top_img: y,
        boxWidth_img: width,
        boxheight_img: height,
        x_min: (x * scaleX) / frame_width,
        y_min: (y * scaleY) / frame_height,
        x_max: ((x + width) * scaleX) / frame_width,
        y_max: ((y + height) * scaleY) / frame_height,
      },
    };

    updatedStream.frames[0].objects.push(newObject);
    setStreamData(updatedStream);
    updateFrame(updatedStream);
    setUndisable(true);
  }, [streamData, dimensions, frame_width, frame_height, updateFrame, setUndisable]);

  const { drawingBox, handleMouseDown, handleMouseMove, handleMouseUp } = useBoxDrawing(
    imageRef,
    dimensions,
    handleBoxDrawn
  );

  const updateBoxPosition = useCallback((index, x, y, width, height) => {
    const updatedStream = { ...streamData };
    const scaleX = frame_width / dimensions.width;
    const scaleY = frame_height / dimensions.height;

    const boxWidth = width || updatedStream.frames[0].objects[index].boundary_box.boxWidth_img;
    const boxHeight = height || updatedStream.frames[0].objects[index].boundary_box.boxheight_img;

    const safeX = Math.max(0, Math.min(x, dimensions.width - boxWidth));
    const safeY = Math.max(0, Math.min(y, dimensions.height - boxHeight));

    const updatedObject = {
      ...updatedStream.frames[0].objects[index],
      boundary_box: {
        ...updatedStream.frames[0].objects[index].boundary_box,
        left_img: safeX,
        top_img: safeY,
        boxWidth_img: boxWidth,
        boxheight_img: boxHeight,
        x_min: (safeX * scaleX) / frame_width,
        y_min: (safeY * scaleY) / frame_height,
        x_max: ((safeX + boxWidth) * scaleX) / frame_width,
        y_max: ((safeY + boxHeight) * scaleY) / frame_height,
      },
    };

    updatedStream.frames[0].objects[index] = updatedObject;
    setStreamData(updatedStream);
    updateFrame(updatedStream);
    setUndisable(true);
  }, [streamData, dimensions, frame_width, frame_height, updateFrame, setUndisable]);

  const deleteBox = useCallback((e, index) => {
    e.preventDefault();
    e.stopPropagation();
    
    const updatedStream = { ...streamData };
    updatedStream.frames[0].objects = updatedStream.frames[0].objects.filter((_, i) => i !== index);
    
    setStreamData(updatedStream);
    updateFrame(updatedStream);
    setUndisable(true);
    setSelectedBox(null);
  }, [streamData, updateFrame, setUndisable]);

  useEffect(() => {
    if (dimensions.width && dimensions.height && initialStream) {
      const updatedStream = { ...initialStream };
      const scaleX = dimensions.width / frame_width;
      const scaleY = dimensions.height / frame_height;

      updatedStream.frames = updatedStream.frames.map((frame) => ({
        ...frame,
        objects: (frame.objects || []).map((object) => {
          if (!object?.boundary_box) return object;

          const { boundary_box } = object;
          const boxWidth = Math.round((boundary_box.x_max - boundary_box.x_min) * frame_width * scaleX);
          const boxHeight = Math.round((boundary_box.y_max - boundary_box.y_min) * frame_height * scaleY);
          const left = Math.round(boundary_box.x_min * frame_width * scaleX);
          const top = Math.round(boundary_box.y_min * frame_height * scaleY);

          return {
            ...object,
            boundary_box: {
              ...boundary_box,
              left_img: left,
              top_img: top,
              boxWidth_img: boxWidth,
              boxheight_img: boxHeight,
            },
          };
        }),
      }));

      setStreamData(updatedStream);
    }
  }, [dimensions, initialStream, frame_width, frame_height]);

  const handleImageClick = useCallback((e) => {
    if (e.target === imageRef.current) {
      setSelectedBox(null);
    }
  }, []);

  return (
    <div
      className={`relative rounded-lg overflow-hidden shadow-md cursor-pointer transform transition-transform duration-200 ease-in-out border-2 ${
        selectedStream.includes(initialStream?.frames[0]?.frame_id)
          ? "border-blue-600"
          : "border-transparent"
      }`}
      onClick={handleImageClick}
    >
      <img
        src={initialStream.frames[0].url}
        alt={initialStream.frames[0].name}
        ref={imageRef}
        className="w-full h-full object-contain"
        onLoad={updateDimensions}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        draggable={false}
      />

      <DrawingOverlay drawingBox={drawingBox} />

      {showRnd && streamData?.frames[0]?.objects?.map((object, index) => (
        <BoundingBox
          key={object.id}
          index={index}
          boundaryBox={object.boundary_box}
          isSelected={selectedBox === index}
          imageWidth={dimensions.width}
          imageHeight={dimensions.height}
          onSelect={setSelectedBox}
          onDelete={deleteBox}
          onDragStart={() => {}}
          onDragStop={updateBoxPosition}
          onResizeStart={() => {}}
          onResizeStop={updateBoxPosition}
        />
      ))}

      <SelectionIndicator
        isSelected={selectedStream.includes(initialStream?.frames[0]?.frame_id)}
        onClick={() => handleSelect(initialStream.frames[0])}
      />

      <div className="absolute bottom-0 left-0 right-0 p-2 bg-gradient-to-t from-black to-transparent">
        <p className="text-sm text-gray-300 truncate">
          {initialStream.frames[0].url}
        </p>
      </div>
    </div>
  );
};

export default ImageGrid;