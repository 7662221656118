import { TIME_RANGES } from "./constants";

function getAlertsInTimeFrame(site, timeFrameMs) {
  const now = Date.now();

  return (
    site.use_cases?.reduce((count, useCase) => {
      if (
        useCase.name.includes("PPE") ||
        useCase.name.includes("Forklift") ||
        useCase.name.includes("Safety")
      ) {
        const alertsInTimeFrame =
          useCase.alerts?.filter((alert) => {
            const alertTime = new Date(alert.created_at);
            const timeDiff = now - alertTime;
            return timeDiff <= timeFrameMs;
          }).length || 0;

        return count + alertsInTimeFrame;
      }
      return count;
    }, 0) ?? 0
  );
}

function getPreviousAlerts(site, periodInMs, lastPeriodInMs) {
  const now = Date.now();

  return (
    site.use_cases?.reduce((count, useCase) => {
      if (
        useCase.name.includes("PPE") ||
        useCase.name.includes("Forklift") ||
        useCase.name.includes("Safety")
      ) {
        const oldAlerts =
          useCase.alerts?.filter((alert) => {
            const alertTime = new Date(alert.created_at);
            const timeDiff = now - alertTime;

            return timeDiff <= lastPeriodInMs;
          }).length || 0;

        return count + oldAlerts;
      }
      return count;
    }, 0) ?? 0
  );
}

// Function to check if alerts have increased for a specific time frame
function checkForIncreasedAlerts(
  sites,
  timeFrameMs,
  lastPeriodInMs,
  periodDescription
) {
  const results = [];
  sites.forEach((site) => {
    const alertsInTimeFrame = getAlertsInTimeFrame(site, timeFrameMs);
    const previousAlerts = getPreviousAlerts(site, timeFrameMs, lastPeriodInMs);

    // Check if either of the arrays is empty
    if (Array.isArray(alertsInTimeFrame) && alertsInTimeFrame.length === 0) {
      console.log(
        `No alerts in the last ${periodDescription} for site: ${site.site_name}`
      );
    }
    if (Array.isArray(previousAlerts) && previousAlerts.length === 0) {
      console.log(
        `No previous alerts for site: ${site.site_name} in the last ${periodDescription}`
      );
    }

    if (alertsInTimeFrame > previousAlerts) {
      // console.log(
      //   `Alerts have increased for site: ${site.site_name} in the last ${periodDescription}. ` +
      //   `Alerts in last ${periodDescription}: ${alertsInTimeFrame}, Previous alerts: ${previousAlerts}`
      // );
      results.push({
        growth: true,
        siteName: site.site_name,
        periodDescription,
        alertsInTimeFrame,
        previousAlerts,
        message:
          `Alerts have increased for site: ${site.site_name} in the last ${periodDescription}. ` +
          `Alerts in last ${periodDescription}: ${alertsInTimeFrame}, Previous alerts: ${previousAlerts}`,
      });
    } else {
      // console.log(
      //   `Alerts have not increased for site: ${site.site_name} in the last ${periodDescription}. ` +
      //   `Alerts in last ${periodDescription}: ${alertsInTimeFrame}, Previous alerts: ${previousAlerts}`
      // );
      results.push({
        growth: false,
        siteName: site.site_name,
        periodDescription,
        alertsInTimeFrame,
        previousAlerts,
        message:
          `Alerts have not increased for site: ${site.site_name} in the last ${periodDescription}. ` +
          `Alerts in last ${periodDescription}: ${alertsInTimeFrame}, Previous alerts: ${previousAlerts}`,
      });
    }
  });
  return results;
}

export const alertConfig = {
  'Forklift Overspeeding': { getArrow: (growth) => ({ arrow: growth >= 0 ? 'up' : 'down', color: growth >= 0 ? 'red' : 'green' }) },
  'Forklift Proximity To People': { getArrow: (growth) => ({ arrow: growth >= 0 ? 'up' : 'down', color: growth >= 0 ? 'red' : 'green' }) },
  'Forklift Speed Violation': { getArrow: (growth) => ({ arrow: growth >= 0 ? 'up' : 'down', color: growth >= 0 ? 'red' : 'green' }) },
  'Employee mobile Usage': { getArrow: (growth) => ({ arrow: growth >= 0 ? 'up' : 'down', color: growth >= 0 ? 'red' : 'green' }) },
  'No Shoes Detected': { getArrow: (growth) => ({ arrow: growth >= 0 ? 'up' : 'down', color: growth >= 0 ? 'red' : 'green' }) },
  'No Helmet Detected': { getArrow: (growth) => ({ arrow: growth >= 0 ? 'up' : 'down', color: growth >= 0 ? 'red' : 'green' }) },
  'No Gloves Detected': { getArrow: (growth) => ({ arrow: growth >= 0 ? 'up' : 'down', color: growth >= 0 ? 'red' : 'green' }) },
  'No Safety Vest Detected': { getArrow: (growth) => ({ arrow: growth >= 0 ? 'up' : 'down', color: growth >= 0 ? 'red' : 'green' }) },
  'Safety Helmet': { getArrow: (growth) => ({ arrow: growth >= 0 ? 'up' : 'down', color: growth >= 0 ? 'red' : 'green' }) },
  'Safety Vests': { getArrow: (growth) => ({ arrow: growth >= 0 ? 'up' : 'down', color: growth >= 0 ? 'red' : 'green' }) },
  'Safety Gloves': { getArrow: (growth) => ({ arrow: growth >= 0 ? 'up' : 'down', color: growth >= 0 ? 'red' : 'green' }) },
  'Safety Shoes': { getArrow: (growth) => ({ arrow: growth >= 0 ? 'up' : 'down', color: growth >= 0 ? 'red' : 'green' }) },
  'Safety Boots': { getArrow: (growth) => ({ arrow: growth >= 0 ? 'up' : 'down', color: growth >= 0 ? 'red' : 'green' }) },
  'Forklift Utilization': { getArrow: (growth) => ({ arrow: growth >= 0 ? 'up' : 'down', color: growth >= 0 ? 'green' : 'red' }) },
  'Forklift Stoppage At Stop Areas': { getArrow: (growth) => ({ arrow: growth >= 0 ? 'up' : 'down', color: growth >= 0 ? 'green' : 'red' }) },
  'Total trips': { getArrow: (growth) => ({ arrow: growth >= 0 ? 'up' : 'down', color: growth >= 0 ? 'green' : 'red' }) },
  'Occupied Time': { getArrow: (growth) => ({ arrow: growth >= 0 ? 'up' : 'down', color: growth >= 0 ? 'green' : 'red' }) },
  'Employee Mobile Usage': { getArrow: (growth) => ({ arrow: growth >= 0 ? 'up' : 'down', color: growth >= 0 ? 'red' : 'green' }) },
  'Time Spent In Work Area': { getArrow: (growth) => ({ arrow: growth >= 0 ? 'up' : 'down', color: growth >= 0 ? 'green' : 'red' }) },
  'on_mobile': { getArrow: (growth) => ({ arrow: growth >= 0 ? 'up' : 'down', color: growth >= 0 ? 'green' : 'red' }) },
  'Average Forklift Load/Unload Time': { getArrow: (growth) => ({ arrow: growth >= 0 ? 'up' : 'down', color: growth >= 0 ? 'green' : 'red' }) },
  'Number of Packages': { getArrow: (growth) => ({ arrow: growth >= 0 ? 'up' : 'down', color: growth >= 0 ? 'green' : 'red' }) },
  'Person Intrusion In Restricted Area': { getArrow: (growth) => ({ arrow: growth >= 0 ? 'up' : 'down', color: growth >= 0 ? 'red' : 'green' }) },
};


const alertFunctions = {
  getAlertsInLast24Hours: (sites) =>
    checkForIncreasedAlerts(
      sites, 
      TIME_RANGES.Hours.current,
      TIME_RANGES.Hours.previous,
      "24 hours"
    ),
  getAlertsInLastWeek: (sites) =>
    checkForIncreasedAlerts(
      sites,
      TIME_RANGES.Week.current,
      TIME_RANGES.Week.previous,
      "week"
    ),
  getAlertsInLastMonth: (sites) =>
    checkForIncreasedAlerts(
      sites,
      TIME_RANGES.Month.current,
      TIME_RANGES.Month.previous,
      "month"
    ),
  getAlertsInLastYear: (sites) =>
    checkForIncreasedAlerts(
      sites,
      TIME_RANGES.Year.current,
      TIME_RANGES.Year.previous,
      "year"
    ),
};

export default alertFunctions;
