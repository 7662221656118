import React from "react";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

function InputFields({
  label,
  type,
  value,
  required,
  onChange,
  showpass,
  setshowpass,
  testid,
}) {
  return (
    <div
      className="flex flex-col mt-6 w-full font-medium whitespace-nowrap min-h-[72px]"
      data-testid={`${testid}-container`}
    >
      <div className="flex flex-col flex-1 w-full">
        <div className="flex gap-2 items-center w-full">
          <label className="flex flex-1 shrink gap-0.5 items-start self-stretch my-auto w-full basis-0 min-w-[240px]">
            <span
              className="text-sm leading-none text-gray-900"
              data-testid={`${testid}-label`}
            >
              {label}
            </span>
            {required && (
              <span
                className="text-xs leading-none text-red-500"
                data-testid={`${testid}-required`}
              >
                *
              </span>
            )}
          </label>
        </div>
        <div
          className="flex overflow-hidden flex-1 gap-1 items-center p-3 mt-1 text-sm leading-none text-gray-900 bg-white rounded-xl border border-gray-300 border-solid size-full"
          data-testid={`${testid}-input-container`}
        >
          <input
            type={type}
            value={value}
            required={required}
            onChange={onChange}
            data-testid={`${testid}-input`}
            className="flex-1 shrink gap-3 self-stretch my-auto w-full min-w-[240px] bg-transparent border-none outline-none px-2"
          />
          {type === "password" && (
            <VisibilityOffIcon
              className="object-contain shrink-0 self-stretch my-auto w-5 aspect-square"
              onClick={() => setshowpass(!showpass)}
              data-testid={`${testid}-visibility-off`}
            />
          )}
          {type === "text" && showpass && (
            <RemoveRedEyeOutlinedIcon
              className="object-contain shrink-0 self-stretch my-auto w-5 aspect-square"
              onClick={() => setshowpass(!showpass)}
              data-testid={`${testid}-remove-red-eye`}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default InputFields;
