import React, { useState, useEffect, useRef } from 'react';
import { Save, Edit, Trash } from 'lucide-react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const TableRow = ({
  row,
  index,
  onChange,
  onDelete,
  errorMessages = {},
  isEditable,
  onEditToggle = () => {},
}) => {
  const [isEditing, setIsEditing] = useState(isEditable);
  const textareaRefs = {
    plannedAction: useRef(null),
    description: useRef(null),
    assignedTo: useRef(null),
    verificationBy: useRef(null),
  };

  const toggleEdit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsEditing((prev) => !prev);
    onEditToggle?.(index);
  };

  const textareaClassName = "w-full border border-gray-300 rounded-md px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:outline-none resize-none overflow-hidden";
  const inputClassName = "w-full border border-gray-300 rounded-md px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:outline-none";
  const spanClassName = "block px-3 py-2 whitespace-pre-wrap break-words";

  // Auto-resize textarea
  const handleTextareaInput = (e) => {
    const textarea = e.target;
    textarea.style.height = 'auto';
    textarea.style.height = textarea.scrollHeight + 'px';
  };

  // Initialize textarea heights
  useEffect(() => {
    if (isEditing) {
      Object.values(textareaRefs).forEach(ref => {
        if (ref.current) {
          ref.current.style.height = 'auto';
          ref.current.style.height = ref.current.scrollHeight + 'px';
        }
      });
    }
  }, [isEditing]);

  // Date formatting utilities
  const formatDateForDisplay = (dateStr) => {
    if (!dateStr) return '';
    const date = new Date(dateStr);
    return date.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
  };

  const formatDateForInput = (dateStr) => {
    if (!dateStr) return null;
    return new Date(dateStr);
  };

  const handleDateChange = (date) => {
    onChange(index, 'deadline', date ? date.toISOString() : '');
  };

  const renderTextarea = (value, onChange, placeholder, ref, field) => (
    <textarea
      ref={ref}
      value={value}
      onChange={(e) => {
        onChange(index, field, e.target.value);
        handleTextareaInput(e);
      }}
      onInput={handleTextareaInput}
      aria-label={`${field} for row ${index + 1}`}
      className={textareaClassName}
      placeholder={placeholder}
      rows={1}
    />
  );

  return (
    <>
      {/* Planned Action Column */}
      <td className="px-4 py-3 max-w-[200px] align-top">
        {isEditing ? (
          renderTextarea(
            row.plannedAction,
            onChange,
            "Enter planned action",
            textareaRefs.plannedAction,
            'plannedAction'
          )
        ) : (
          <span className={spanClassName}>{row.plannedAction}</span>
        )}
        {errorMessages.plannedAction && (
          <p className="text-red-500 text-sm mt-1">{errorMessages.plannedAction}</p>
        )}
      </td>

      {/* Description Column */}
      <td className="px-4 py-3 max-w-[300px] align-top">
        {isEditing ? (
          renderTextarea(
            row.description,
            onChange,
            "Enter description",
            textareaRefs.description,
            'description'
          )
        ) : (
          <span className={spanClassName}>{row.description}</span>
        )}
        {errorMessages.description && (
          <p className="text-red-500 text-sm mt-1">{errorMessages.description}</p>
        )}
      </td>

      {/* Assigned To Column */}
      <td className="px-4 py-3 max-w-[150px] align-top">
        {isEditing ? (
          renderTextarea(
            row.assignedTo,
            onChange,
            "Enter assignee",
            textareaRefs.assignedTo,
            'assignedTo'
          )
        ) : (
          <span className={spanClassName}>{row.assignedTo}</span>
        )}
        {errorMessages.assignedTo && (
          <p className="text-red-500 text-sm mt-1">{errorMessages.assignedTo}</p>
        )}
      </td>

      {/* Deadline Column */}
      <td className="px-4 py-3 w-[150px] align-top">
        {isEditing ? (
          <DatePicker
            selected={formatDateForInput(row.deadline)}
            onChange={handleDateChange}
            dateFormat="MM/dd/yyyy"
            className={inputClassName}
            aria-label={`Deadline for row ${index + 1}`}
          />
        ) : (
          <span className={spanClassName}>{formatDateForDisplay(row.deadline)}</span>
        )}
        {errorMessages.deadline && (
          <p className="text-red-500 text-sm mt-1">{errorMessages.deadline}</p>
        )}
      </td>

      {/* Status Column */}
      <td className="px-4 py-3 w-[170px] align-top">
        {isEditing ? (
          <select
            value={row.status || 'Pending'}
            onChange={(e) => onChange(index, 'status', e.target.value)}
            aria-label={`Status for row ${index + 1}`}
            className={inputClassName}
          >
            <option value="Pending">Pending</option>
            <option value="In Progress">In Progress</option>
            <option value="Complete">Complete</option>
          </select>
        ) : (
          <span className={`inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium
            ${row.status === 'Complete' ? 'bg-green-100 text-green-800' : ''}
            ${row.status === 'In Progress' ? 'bg-blue-100 text-blue-800' : ''}
            ${row.status === 'Pending' ? 'bg-yellow-100 text-yellow-800' : ''}
          `}>
            {row.status || 'Pending'}
          </span>
        )}
        {errorMessages.status && (
          <p className="text-red-500 text-sm mt-1">{errorMessages.status}</p>
        )}
      </td>

      {/* Verification By Column */}
      <td className="px-4 py-3 max-w-[100px] align-top">
        {isEditing ? (
          renderTextarea(
            row.verificationBy,
            onChange,
            "Enter verifier",
            textareaRefs.verificationBy,
            'verificationBy'
          )
        ) : (
          <span className={spanClassName}>{row.verificationBy}</span>
        )}
        {errorMessages.verificationBy && (
          <p className="text-red-500 text-sm mt-1">{errorMessages.verificationBy}</p>
        )}
      </td>

      {/* Actions Column */}
      <td className="px-4 py-3 text-center w-[100px] align-top">
        <div className="flex justify-center space-x-2">
          <button
            onClick={toggleEdit}
            type="button"
            className="p-1 text-blue-600 hover:text-blue-800 transition-colors"
            aria-label={isEditing ? 'Save row' : 'Edit row'}
          >
            {isEditing ? <Save size={18} color='#052D61' /> : <Edit size={18} color='#052D61' />}
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onDelete(index);
            }}
            type="button"
            className="p-1 text-red-600 hover:text-red-800 transition-colors"
            aria-label="Delete row"
          >
            <Trash size={18} />
          </button>
        </div>
      </td>
    </>
  );
};

export default TableRow;