import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import ImageGallery from "../../components/ImageGallery";
import MainContent from "../../components/MainContent";

export const ModelDashboard = () => {
  const { project, siteid, projectid } = useParams();

  // console.log(project, siteid, projectid);

  const navItems = [
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/6742242c9064deb66a33a690108c59f05063597fd9064c3653ef37c2eacb0e67?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde",
      text: "Use Cases Details",
      active:
        window.location.pathname ===
        `/site-details/${siteid}/${project}/${projectid}`,
      navigate: () =>
        navigate(`/site-details/${siteid}/${project}/${projectid}`),
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/389f597f3dbe7367231aff370cd6b6a526ff3bd25933c7a8ab653571d65eb103?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde",
      text: "Model Dashboard",
      active: window.location.pathname.includes(`/model-dashboard`),
      navigate: () =>
        navigate(
          `/site-details/${siteid}/${project}/${projectid}/model-dashboard`
        ),
    },
    // {
    //   icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/5606b06eae36d23ab1603f3e1c8b04d8b7d174fe2a7cceaee2ecec9fa22a9b09?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde",
    //   text: "Data Visualization",
    //   active: window.location.pathname.includes(
    //     `/site-details/${siteid}/${project}/${projectid}/visualisation`
    //   ),
    //   navigate: () =>
    //     navigate(
    //       `/site-details/${siteid}/${project}/${projectid}/visualisation`
    //     ),
    // },
    // {
    //   icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/ae7e6fac086adfa5edba6aaec57b23e81441178bb24bf679c3aae6b13cc45c7c?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde",
    //   text: "Business Workflow",
    //   active:
    //     window.location.pathname ===
    //     `/site-details/${siteid}/${project}/business-workflow`,
    //   navigate: () =>
    //     projectid ? navigate(`/site-details/${siteid}/${project}/${projectid}`) : null,
    // },
  ];

  const user = useSelector((state) => state.user);

  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate("/home");
  };

  return (
    <div className="w-screen bg-gray-100 ">
      <div className="flex flex-row h-full relative">
        <Sidebar
          navItems={navItems}
          userName={user.name}
          usermail={user.email}
          currentPage="Model Dashboard"
          siteName={project}
          handleBackClick={handleBackClick}
          selectDataOptions={[]}
        />
        <MainContent
          currentPage="Model Dashboard"
          data={[]}
          title={`Model Dashboard`}
          buttonText={`Add Model`}
          contentType={[]}
          links={[
            { name: "Dashboard", path: "/home" },
            { name: "Model Dashboard", path: "/model-dashboard" },
          ]}
        />
      </div>
    </div>
  );
};
