import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import axios from "axios";
import { Button } from "@mui/material";
import Loader from "./Loader";

const LoadingIndicator = () => (
  <div
    className="flex justify-center items-center py-4"
    data-testid="loading-indicator"
  >
    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
  </div>
);

const ImageGallery = ({ apiEndpointUrl }) => {
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalImages, setTotalImages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(12);
  const imageCache = useRef({});

  const fetchImages = useCallback(
    async (page) => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${apiEndpointUrl}?page=${page}&limit=${pageSize}`
        );
        const { data, headers } = response;
        const newTotalImages = parseInt(headers["x-total-count"], 10);

        if (page === 1) {
          // For the first page, replace the images
          setImages(data);
        } else {
          // For subsequent pages, append new images
          setImages((prevImages) => [...prevImages, ...data]);
        }

        setTotalImages(newTotalImages);

        // Cache the image URLs
        data.forEach((image) => {
          imageCache.current[image.id] = image.download_url;
        });
      } catch (error) {
        console.error("Error fetching images:", error);
      } finally {
        setIsLoading(false);
      }
    },
    [apiEndpointUrl, pageSize]
  );

  useEffect(() => {
    fetchImages(currentPage, true);
  }, [fetchImages, currentPage]);

  const totalPages = useMemo(
    () => Math.ceil(totalImages / pageSize),
    [totalImages, pageSize]
  );

  const handlePageChange = useCallback(
    (newPage) => {
      if (newPage > 0 && newPage <= totalPages) {
        setCurrentPage(newPage);
      }
    },
    [totalPages]
  );

  const memoizedImageRenderer = useCallback(
    ({ index, key, style }) => {
      const image = images[index];
      return (
        <div
          key={key}
          style={style}
          className="p-2"
          data-testid={`image-container-${index}`}
        >
          {image ? (
            <img
              className="object-cover w-full h-48 rounded-lg shadow-md transition-transform duration-300 transform hover:scale-105"
              loading="lazy"
              src={imageCache.current[image?.id] || "/placeholder.jpg"}
              alt={`Image by ${image?.author}`}
              onError={(e) => (e.target.src = "/placeholder.jpg")}
              data-testid={`image-${index}`}
            />
          ) : (
            <div className="animate-pulse">
              <div
                className="bg-gray-200 rounded-lg w-full h-48"
                data-testid={`image-placeholder-${index}`}
              />
            </div>
          )}
        </div>
      );
    },
    [images]
  );

  return (
    <>
      <div data-testid="image-gallery">
        {images.map((_, index) =>
          memoizedImageRenderer({
            index,
            key: `image-${index}`,
            style: {},
          })
        )}
      </div>

      <div
        className="flex justify-center items-center my-4"
        data-testid="pagination-controls"
      >
        <div className="flex space-x-2">
          <Button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            data-testid="previous-button"
          >
            Previous
          </Button>
          <span>{`Page ${currentPage} of ${totalPages}`}</span>
          <Button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            data-testid="next-button"
          >
            Next
          </Button>
        </div>
      </div>

      {isLoading && <Loader />}
    </>
  );
};

export default ImageGallery;
