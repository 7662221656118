import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AddVideo } from "./Modal/AddVideo";
import { getallstreams, uploadThumbnail } from "../services/apiServiceDetails";
import VideoThumbnail from "./thumbnailImage";
import AddIcon from "@mui/icons-material/Add";
import { Check, Circle } from "lucide-react";
import { createProjectStreams } from "../services/apiServiceDetails";
import { generateThumbnail } from "../utils/generateThumbnail";
import EmptyDataComponent from "./EmptyDataComponent";

export const StreamModal = ({
  siteid,
  projectid,
  isOpen,
  onClose,
  associatedStream,
  setAssociatedStream,
  onSubmit,
  progress,
  setProgress,
  isUploading,
  setIsUploading,
}) => {
  // console.log(siteid, id);
  const videos = [
    {
      video_name: "Counter",
      video_url: "https://www.coetra/a7f-9j2m3-l0",
      imgsrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/3472d035f2c1530dc9f856737335968c605035d4747f528ac8340ead2bb4e60f?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/3472d035f2c1530dc9f856737335968c605035d4747f528ac8340ead2bb4e60f?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3472d035f2c1530dc9f856737335968c605035d4747f528ac8340ead2bb4e60f?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/3472d035f2c1530dc9f856737335968c605035d4747f528ac8340ead2bb4e60f?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/3472d035f2c1530dc9f856737335968c605035d4747f528ac8340ead2bb4e60f?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3472d035f2c1530dc9f856737335968c605035d4747f528ac8340ead2bb4e60f?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/3472d035f2c1530dc9f856737335968c605035d4747f528ac8340ead2bb4e60f?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/3472d035f2c1530dc9f856737335968c605035d4747f528ac8340ead2bb4e60f?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde",
    },

    {
      video_name: "Aisle",
      video_url: "https://www.coetra/a7f-9j2m3-l0",
      imgsrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/16b1d6d9156fbc13b11b81b02647199f77b0a7b8c5a0281d7d4cffcf46aa91e6?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/16b1d6d9156fbc13b11b81b02647199f77b0a7b8c5a0281d7d4cffcf46aa91e6?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/16b1d6d9156fbc13b11b81b02647199f77b0a7b8c5a0281d7d4cffcf46aa91e6?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/16b1d6d9156fbc13b11b81b02647199f77b0a7b8c5a0281d7d4cffcf46aa91e6?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/16b1d6d9156fbc13b11b81b02647199f77b0a7b8c5a0281d7d4cffcf46aa91e6?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/16b1d6d9156fbc13b11b81b02647199f77b0a7b8c5a0281d7d4cffcf46aa91e6?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/16b1d6d9156fbc13b11b81b02647199f77b0a7b8c5a0281d7d4cffcf46aa91e6?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/16b1d6d9156fbc13b11b81b02647199f77b0a7b8c5a0281d7d4cffcf46aa91e6?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde",
    },

    {
      video_name: "Counter (View 2)",
      video_url: "https://www.coetra/a7f-9j2m3-l0",
      imgsrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/3472d035f2c1530dc9f856737335968c605035d4747f528ac8340ead2bb4e60f?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/3472d035f2c1530dc9f856737335968c605035d4747f528ac8340ead2bb4e60f?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3472d035f2c1530dc9f856737335968c605035d4747f528ac8340ead2bb4e60f?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/3472d035f2c1530dc9f856737335968c605035d4747f528ac8340ead2bb4e60f?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/3472d035f2c1530dc9f856737335968c605035d4747f528ac8340ead2bb4e60f?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3472d035f2c1530dc9f856737335968c605035d4747f528ac8340ead2bb4e60f?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/3472d035f2c1530dc9f856737335968c605035d4747f528ac8340ead2bb4e60f?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/3472d035f2c1530dc9f856737335968c605035d4747f528ac8340ead2bb4e60f?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde",
    },
  ];

  const [isModalOpen, setModalOpen] = useState(false);
  const [name, setName] = useState("");
  const [thumbnails, setThumbnails] = useState({});
  const user = useSelector((state) => state.user);
  const siteNames = useSelector((state) => state.siteDetails);
  const [selectedStream, setSelectedstream] = useState();
  const [loading, setLoading] = useState(true);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const [allstreams, setallstreams] = useState([]);

  const getAllStreams = async () => {
    const resp = await getallstreams(siteid);
    if (resp) {
      setallstreams(resp);
    }
  };
  console.log("alll streams", allstreams);
  useEffect(() => {
    getAllStreams();
  }, []);

  const createAssociation = async () => {
    if (associatedStream && associatedStream != selectedStream) {
      const confirmUpdate = window.confirm(
        "Are you sure you want to update the association? All your progress might be lost."
      );

      if (!confirmUpdate) {
        onClose();
        return;
      }
    }
    const res = await createProjectStreams(
      projectid,
      selectedStream.toString()
    );
    onSubmit();
    if (res) {
      window.location.reload();
    }
  };

  const handleSubmit = (e) => {
    getAllStreams();
    closeModal();
  };

  useEffect(() => {
    const siteName = siteNames.names.find((item) => item.id == siteid)?.name;
    setName(siteName);
  }, []);

  useEffect(() => {
    const fetchThumbnails = async () => {
      const thumbnailsMap = {};
      await Promise.all(
        allstreams.map(async (stream) => {
          if (stream?.thumbnail_url) {
            thumbnailsMap[stream?.id] = stream?.thumbnail_url;
            return;
          }
          const thumbnail = await generateThumbnail(stream?.video_url);
          thumbnailsMap[stream?.id] = thumbnail;
          (async () => {
            try {
              await uploadThumbnail(stream?.id, thumbnail);
            } catch (error) {
              console.error(`Failed to upload thumbnail for stream ${stream?.id}:`, error);
            }
          })();
        })
      );
  
      setThumbnails(thumbnailsMap);
      setLoading(false);
    };
  
    fetchThumbnails();
  }, [allstreams]);  

  useEffect(() => {
    if (associatedStream) {
      setSelectedstream(associatedStream);
    }
  }, [associatedStream]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed z-50 bottom-0 left-0 bg-white rounded-t-lg shadow-xl w-full h-full overflow-auto">
      <div className="flex justify-between items-center px-6 py-4">
        <h1
          className="flex-1 shrink self-stretch my-auto text-3xl font-semibold tracking-tighter leading-tight text-gray-900 basis-10 max-md:max-w-full"
          data-testid="camera-stream-title"
        >
          Available Devices
        </h1>
        <button
          onClick={onClose}
          className="text-gray-600 hover:text-gray-800 ml-auto"
          data-testid="close-modal-button"
        >
          <span className="block w-6 h-6 relative" aria-label="Close">
            <span className="block absolute w-full h-0.5 bg-current transform rotate-45 top-1/2"></span>
            <span className="block absolute w-full h-0.5 bg-current transform -rotate-45 top-1/2"></span>
          </span>
        </button>
      </div>
      <div className="flex justify-end ml-auto px-6 gap-y-5">
        <div className="flex flex-row gap-x-5">
          <button
            data-testid="add-association"
            onClick={createAssociation}
            disabled={!selectedStream}
            className={`flex overflow-hidden gap-2 justify-center items-center ${
              selectedStream
                ? "bg-[#052D61] text-white"
                : "bg-gray-400 text-gray-600"
            } self-stretch px-5 py-4 my-auto text-sm font-semibold leading-none text-center text-gray-50 rounded-xl border border-[#052D61] border-solid`}
          >
            <span className="self-stretch my-auto">Connect Device</span>
          </button>
          <button
            data-testid="add-button"
            disabled={isUploading}
            onClick={() => setModalOpen(true)}
            className="flex overflow-hidden gap-2 justify-center items-center self-stretch px-5 py-4 my-auto text-sm font-semibold leading-none text-center text-gray-50 rounded-xl border border-blue-700 border-solid"
          >
            <span className="self-stretch my-auto text-[#052D61]">
              Add New Device
            </span>
          </button>
        </div>
      </div>

      {allstreams && allstreams.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 px-6 mt-10 py-6">
          {isUploading && (
            <div
              className={`relative flex items-center justify-center px-3 rounded-lg overflow-hidden shadow-md cursor-pointer transform transition-transform duration-200 ease-in-out hover:scale-105 border-2 border-gray-500`}
              // data-testid={`stream-item-${stream?.id}`}
            >
              <ProgressBar progress={progress} />
            </div>
          )}
          {allstreams
            .slice()
            .reverse()
            .map((stream) => (
              <div
                key={stream?.id}
                className={`relative rounded-lg overflow-hidden shadow-md cursor-pointer transform transition-transform duration-200 ease-in-out hover:scale-105 border-2 ${
                  selectedStream === stream.id
                    ? "border-blue-600"
                    : "border-transparent"
                }`}
                data-testid={`stream-item-${stream?.id}`}
              >
                {loading || !thumbnails[stream?.id] ? (
                  <div
                    className="w-full h-60 bg-gray-300 animate-pulse"
                    data-testid={`thumbnail-loading-${stream?.id}`}
                  ></div>
                ) : (
                  <img
                    src={thumbnails[stream?.id]}
                    alt={stream?.video_name}
                    className="w-full h-60 object-cover"
                    data-testid={`thumbnail-${stream?.id}`}
                  />
                )}

                <div
                  className={`absolute top-2 left-2 w-6 h-6 rounded-full border-2 flex items-center justify-center ${
                    selectedStream == stream?.id
                      ? "bg-[#052D61] border-[#052D61]"
                      : "bg-gray-200 border-white"
                  }`}
                  onClick={() => setSelectedstream(stream.id)}
                  data-testid={`selection-icon-${stream?.id}`}
                >
                  {selectedStream == stream?.id && (
                    <Check size={16} className="text-white" />
                  )}
                </div>
                <div className="absolute bottom-0 left-0 right-0 p-2 bg-gradient-to-t from-black to-transparent">
                  <h3 className="font-bold text-white">{stream?.video_name}</h3>
                  <p className="text-sm text-gray-300 truncate">
                    ID:{stream?.id}
                  </p>
                </div>
              </div>
            ))}
        </div>
      ) : isUploading ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 px-6 mt-10 py-6">
          <div
            className={`relative flex items-center h-60 justify-center px-3 rounded-lg overflow-hidden shadow-md cursor-pointer transform transition-transform duration-200 ease-in-out hover:scale-105 border-2 border-gray-500`}
            // data-testid={`stream-item-${stream?.id}`}
          >
            <ProgressBar progress={progress} />
          </div>
        </div>
      ) : (
        <EmptyDataComponent
          currentPage={"All Devices Modal"}
          buttonText={"Add New Device"}
          onButtonClick={() => setModalOpen(true)}
          className="w-full h-[80%] flex flex-col justify-center items-center"
        />
      )}

      {isModalOpen ? (
        <AddVideo
          isOpen={isModalOpen}
          onClose={closeModal}
          onSubmit={handleSubmit}
          setIsUploading={setIsUploading}
          isUploading={isUploading}
          progress={progress}
          setProgress={setProgress}
        />
      ) : null}
    </div>
  );
};

const ProgressBar = ({ progress }) => {
  return (
    <div
      style={{
        border: "1px solid #ccc",
        width: "100%",
        height: "20px",
        borderRadius: "5px",
      }}
      className="text-center"
    >
      <div
        className={`h-full bg-gradient-to-r from-green-500 via-green-300 to-green-500 animate-gradient bg-[length:200%_100%]`}
        style={{ width: `${progress}%` }}
      ></div>
      <div className="text-black text">{progress}%</div>
    </div>
  );
};
