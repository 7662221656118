import React, { useEffect, useState } from "react";
import CameraStreamSelector from "./CameraStreamSelector";
import GoalDetection from "./GoalDetection";
import WrongLabelSelector from "./WrongLabelSelector";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getProjectStreams,
  startDetectionSimulation,
  deleteROIImages,
  trainROIImages,
  UpdateROIImages,
} from "../../services/apiServiceDetails";
import {
  setTrainingStatus,
  setModalStatus,
} from "../../redux/actions/trainingStatusActions";
import {
  BUTTON_TEXT,
  MODAL_MESSAGES,
  STATUS_TYPES,
} from "../../utils/constants";

const mockStreams = [
  {
    id: 4,
    video_name: "kafkaVid",
    video_type: "",
    video_url:
      "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
    created_at: "2024-09-04 06:42:21",
    updated_at: "2024-09-04 06:42:21",
    site_id: 1,
  },
  {
    id: 6,
    video_name: "testingVid",
    video_type: "",
    video_url:
      "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
    created_at: "2024-09-05 08:17:14",
    updated_at: "2024-09-05 08:17:14",
    site_id: 1,
  },
  {
    id: 7,
    video_name: "vid",
    video_type: "",
    video_url:
      "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
    created_at: "2024-09-05 10:05:57",
    updated_at: "2024-09-05 10:05:57",
    site_id: 1,
  },
  {
    id: 8,
    video_name: "stream",
    video_type: "",
    video_url:
      "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
    created_at: "2024-09-07 02:07:27",
    updated_at: "2024-09-07 02:07:27",
    site_id: 1,
  },
  {
    id: 13,
    video_name: "kjkj",
    video_type: "",
    video_url:
      "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
    created_at: "2024-09-10 11:09:45",
    updated_at: "2024-09-10 11:09:45",
    site_id: 1,
  },
];

const ModalLayout = ({
  isOpen,
  onClose,
  onSelect,
  images,
  currentPage,
  setCurrentPage,
  setHideBar,
  totalPages,
  onPageChange,
  imageAOIDeleted,
  setImageAOIDeleted,
  setDeleteAnnotation,
  setRequestID,
  setHasData,
  setImages,
  onDeleteImage,
}) => {
  const [step, setStep] = useState("camera-stream");
  const [selectedStreams, setSelectedStreams] = useState([]);
  const [selectedStreamsForTrain, setSelectedStreamsForTrain] = useState([]);
  const [streams, setStreams] = useState([]);
  const { project, projectid, siteid } = useParams();
  const [prompt, setPrompt] = useState([]);
  const environment = process.env.NODE_ENV;
  const [onDelRefresh, setOnDelRefresh] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const trainingStatus = useSelector((state) => state.trainingStatus);
  console.log(trainingStatus);

  useEffect(() => {
    console.log("ts", trainingStatus[projectid]);
  }, [trainingStatus]);

  const getAllStreams = async () => {
    console.log("Getting streams for project", projectid);
    const resp = await getProjectStreams(projectid);
    setStreams(resp);
  };

  useEffect(() => {
    console.log("Environment", environment);
    if (environment === "development") {
      setStreams(mockStreams);
    } else {
      getAllStreams();
    }
  }, [projectid]);

  const handleCameraStreamNext = () => {
    //setSelectedStream(stream);
    dispatch(setModalStatus(projectid, "goal-detection"));
    dispatch(
      setTrainingStatus(projectid, null, null, null, null, "goal-detection")
    );
    setStep("goal-detection");
  };

  const handleCloseModal = () => {
    console.log("Closing Modal");
    setCurrentPage(1);
    setSelectedStreams(null);
    setPrompt("");
    setStep("camera-stream");
    onClose();
  };

  const handleGoalDetectionBack = () => {
    console.log("Going back to camera stream selection");
    dispatch(setModalStatus(projectid, "camera-stream"));
    // setStep("camera-stream");     ,≤f bt
  };
  const handleWronglLabelDetectionBack = () => {
    console.log("Going back to camera stream selection");
    dispatch(setModalStatus(projectid, "camera-stream"));
    // setStep("camera-stream");
  };

  const handleGoalDetectionNext = async () => {
    // let combinedPrompt = prompt.join(",");
    let combinedPrompt = prompt.trim();

    //dispatch(setModalStatus("wrong-label-detection"));
    console.log("Prompt", combinedPrompt);
    console.log("Selected Devices", selectedStreams);
    console.log("Project ID", projectid);
    const res = await startDetectionSimulation(
      projectid,
      selectedStreams,
      combinedPrompt
    );
    // navigate to the visualization page
    console.log("Response", res);

    if (res.message == "Annotation request sent successfully") {
      dispatch(
        setTrainingStatus(
          projectid,
          STATUS_TYPES.PROGRESS.GOAL_DETECTION,
          MODAL_MESSAGES[STATUS_TYPES.PROGRESS.GOAL_DETECTION],
          null,
          null
        )
      );
      setHideBar(false);

      if (
        window.location.pathname ==
        `/site-details/${siteid}/projects/${projectid}`
      ) {
        onClose();
      } else {
        navigate(`/site-details/${siteid}/projects/${projectid}`);
      }
    } else {
      dispatch(
        setTrainingStatus(
          "error",
          `Something went wrong. You can retry by clicking on the 'Retrain Model' button above`,
          null,
          null
        )
      );
      if (
        window.location.pathname ==
        `/site-details/${siteid}/projects/${projectid}`
      ) {
        onClose();
      } else {
        navigate(`/site-details/${siteid}/projects/${projectid}`);
      }
    }
  };

  const handleDelete = async (imgs) => {
    const res = await deleteROIImages(projectid, selectedStreamsForTrain);
    console.log("Response", res);

    setImageAOIDeleted(true);
    // Handle the next step after goal detection
    console.log("delete is called", selectedStreamsForTrain);
    setImages(imgs);
    onDeleteImage(imgs);
    setOnDelRefresh(onDelRefresh + 1);
    // onClose();
  };

  const trainModel = async () => {
    const res = await trainROIImages(projectid);
    if (res) {
      setRequestID(res.request_id);
    }
    console.log("Response", res);
    console.log("train model is called");
    setDeleteAnnotation(true);
    setHasData(true);
    dispatch(
      setTrainingStatus(
        projectid,
        STATUS_TYPES.PROGRESS.MODEL_TRAINING,
        MODAL_MESSAGES[STATUS_TYPES.PROGRESS.MODEL_TRAINING],
        null,
        null,
        "training"
      )
    );
    onClose();
  };

  const updateFrame = async (stream) => {
    const res = await UpdateROIImages(projectid, stream);
    console.log("Response", res);
    //setImageAOIDeleted(true);
    // Handle the next step after goal detection
    console.log("updateFrame is called ", stream);
  };

  return (
    <>
      {isOpen && (
        <>
          <div
            className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-end z-50"
            data-testid="modal-overlay"
          >
            {trainingStatus[projectid].modalStatus === "camera-stream" && (
              <CameraStreamSelector
                isOpen={isOpen}
                onClose={handleCloseModal}
                onNext={handleCameraStreamNext}
                streams={streams}
                selectedStream={selectedStreams}
                setSelectedStream={setSelectedStreams}
              />
            )}
            {trainingStatus[projectid].modalStatus === "goal-detection" && (
              <GoalDetection
                prompt={prompt}
                setPrompt={setPrompt}
                onBack={handleGoalDetectionBack}
                onNext={handleGoalDetectionNext}
                isOpen={isOpen}
                onClose={handleCloseModal}
              />
            )}
            {trainingStatus[projectid].modalStatus ===
              "wrong-label-detection" && (
              <WrongLabelSelector
                onBack={handleWronglLabelDetectionBack}
                onDelete={handleDelete}
                onTrainModel={trainModel}
                isOpen={isOpen}
                onClose={handleCloseModal}
                images={images}
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={onPageChange}
                selectedStream={selectedStreamsForTrain}
                setSelectedStream={setSelectedStreamsForTrain}
                updateFrame={updateFrame}
                onDelRefresh={onDelRefresh}
                setImages={setImages}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default ModalLayout;
