import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import { useSelector } from "react-redux";
import { AddVideo } from "../../components/Modal/AddVideo";
import { CreateSite } from "../../components/Modal/CreateSite";
import {
  getallstreams,
  getProject,
  getProjects,
} from "../../services/apiServiceDetails";
import { useParams, useNavigate } from "react-router-dom";

import MainContent from "../../components/MainContent";
import { CreateProject } from "../../components/Modal/CreateProject";

import { getFromLocalStorage } from "../../utils/constants";
import { Assignment } from "@mui/icons-material";

const environment = process.env.NODE_ENV;

export const Projects = () => {
  const { projectid, project, siteid } = useParams();
  const params = useParams();
  const navItems = [
    {
      icon: <Assignment />,
      text: "Scenarios",
      active: window.location.pathname.includes("/projects"),
      navigate: () => navigate(`/site-details/${siteid}/projects`),
    },
    // {
    //   icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/1273d2ce836ed577aaa9a97d3783aa0f8183a834f317b12a8bb22a73dd01fa74?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde",
    //   text: "Devices",
    //   active: false,
    //   active: window.location.pathname.includes("/videos"),
    //   navigate: () => navigate(`/site-details/${siteid}/videos`),
    // },
  ];

  const [isModalOpen, setModalOpen] = useState(false);
  const user = useSelector((state) => state.user);
  const siteNames = useSelector((state) => state.siteDetails);
  const [streams, setstreams] = useState([]);
  const [disablecreateproject, setdisablecreateproject] = useState(false);
  const [name, setName] = useState("");
  const [editData, seteditData] = useState(null);
  const [action, setaction] = useState("create");
  const [sitename, setsitename] = useState("");
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const navigate = useNavigate();
  const [allstreams, setallstreams] = useState([]);

  const getAllStreams = async () => {
    const resp = await getProjects(siteid);
    setallstreams(resp);
  };
  const getstreamsBySiteId = async () => {
    const resp = await getallstreams(siteid);
    setstreams(resp);
  };
  useEffect(() => {
    getAllStreams();
    getstreamsBySiteId();
  }, []);

  const handleCardClick = (id) => {
    ///site-details/:name/:project/visualisation
    console.log("Params", siteid, project, id);
    navigate(`/site-details/${siteid}/${project}/${id}`);
  };

  const handleSubmit = (e) => {
    // e.preventDefault();
    getAllStreams();
    // Handle form submission logic here
    // console.log("Form submitted");
    closeModal();
  };

  const handleBackClick = () => {
    navigate("/home"); // Navigate to the dashboard page
  };

  const handleUpdate = (action, data) => {
    console.log("inside update function");
    seteditData(data);
    setaction(action);
    openModal();
  };

  const getsiteName = () => {
    let sName = getFromLocalStorage(siteid);
    console.log("sName", sName);
    setsitename(sName);
  };

  useEffect(() => {
    const siteName = siteNames.names.find((item) => item.id == siteid)?.name;
    console.log("SiteName", siteName, siteNames, siteid);
    setName(siteName);
    getsiteName();
  }, []);

  console.log("sName", sitename);

  useEffect(() => {
    if (streams && streams.length > 0) {
      setdisablecreateproject(true);
    }
    console.log("Params", params);
  }, [streams]);

  return (
    <div className=" w-screen bg-gray-100 ">
      <div className="flex flex-row h-full">
        <Sidebar
          navItems={navItems}
          userName={user?.name}
          usermail={user.email}
          currentPage="Project"
          siteName={project}
          handleBackClick={handleBackClick}
          selectDataOptions={siteNames}
        />
        <MainContent
          currentPage="Scenario"
          data={allstreams}
          subHeading={"Mask Detection"}
          title={`Current Scenarios - ${name ? name : sitename}`}
          openModal={openModal}
          handleCardClick={handleCardClick}
          buttonText={`Add Scenario`}
          //contentType={videos}
          handleUpdate={handleUpdate}
          links={[
            { name: "Sites", path: "/home" },
            {
              name: `${name ? name : sitename}`,
              path: `/site-details/${siteid}/projects`,
            },
            { name: "Scenario" },
          ]}
        />
        <CreateProject
          isOpen={isModalOpen}
          onClose={closeModal}
          onSubmit={handleSubmit}
          disablecreateproject={disablecreateproject}
          data={editData}
          seteditData={seteditData}
          action={action}
          setaction={setaction}
        />
      </div>
    </div>
  );
};
