import React, { useState, useCallback, memo } from 'react';
import { format, isValid, parseISO, addMonths } from 'date-fns';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Font, Image } from '@react-pdf/renderer';
import Button from '../../../Button';
import PreviewModal from './PreviewModal';

// Register fonts for a more professional look
Font.register({
  family: 'Helvetica',
  fonts: [
    { src: 'https://fonts.gstatic.com/s/helveticaneue/v70/1Ptsg8zYS_SKggPNyC0IT4ttDfA.ttf' },
    { src: 'https://fonts.gstatic.com/s/helveticaneue/v70/1Ptsg8zYS_SKggPNyC0IT4ttDfB.ttf', fontWeight: 'bold' },
  ]
});

const styles = StyleSheet.create({
  page: {
    padding: 50,
    fontSize: 11,
    fontFamily: 'Helvetica',
    position: 'relative'
  },
  section: {
    marginBottom: 20,
    breakInside: 'avoid'
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
    textAlign: 'center',
    color: '#1a365d'
  },
  subtitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 10,
    color: '#2c5282',
    borderBottom: '1 solid #e2e8f0',
    paddingBottom: 5
  },
  tableOfContents: {
    marginBottom: 30,
    padding: 15,
    backgroundColor: '#f7fafc'
  },
  tocTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 10,
    color: '#2d3748'
  },
  tocItem: {
    marginBottom: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 4
  },
  tocText: {
    flex: 1,
    color: '#4a5568'
  },
  tocDots: {
    borderBottom: '1 dotted #e2e8f0',
    flex: 1,
    marginHorizontal: 10
  },
  tocPage: {
    color: '#718096',
    fontSize: 10
  },
  table: {
    display: 'table',
    width: 'auto',
    marginBottom: 20,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#e2e8f0'
  },
  tableHeader: {
    backgroundColor: '#f7fafc',
    borderBottomWidth: 1,
    borderBottomColor: '#e2e8f0'
  },
  tableHeaderCell: {
    padding: 8,
    fontSize: 11,
    fontWeight: 'bold',
    color: '#2d3748'
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#e2e8f0'
  },
  tableRowEven: {
    backgroundColor: '#ffffff'
  },
  tableRowOdd: {
    backgroundColor: '#f7fafc'
  },
  tableCell: {
    padding: 8,
    fontSize: 10
  },
  tableCellText: {
    color: '#4a5568'
  },
  footer: {
    position: 'absolute',
    bottom: 30,
    left: 40,
    right: 40,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  footerLogo: {
    width: 80,
    height: 20,
    objectFit: 'contain',
  },
  footerText: {
    fontSize: 10,
    color: '#718096',
    fontFamily: 'Helvetica',
  },
  pageNumber: {
    position: 'absolute',
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    fontSize: 10,
    color: '#718096',
    fontFamily: 'Helvetica',
  },
  infoGrid: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 10,
    marginBottom: 20
  },
  infoItem: {
    flex: 1,
    minWidth: '45%',
    padding: 8,
    backgroundColor: '#f7fafc',
    borderRadius: 4
  },
  infoLabel: {
    fontSize: 10,
    color: '#718096',
    marginBottom: 2
  },
  infoValue: {
    fontSize: 11,
    color: '#2d3748'
  },
  statusBadge: {
    padding: '10 10',
    borderRadius: 4,
    fontSize: 10
  },
  statusPending: {
    backgroundColor: '#fef3c7',
    color: '#92400e'
  },
  statusInProgress: {
    backgroundColor: '#dbeafe',
    color: '#1e40af'
  },
  statusComplete: {
    backgroundColor: '#dcfce7',
    color: '#166534'
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
    borderBottom: 1,
    borderBottomColor: '#e2e8f0',
    paddingBottom: 10,
  },
  logoHeader: {
    width: 120,
    height: 30,
    objectFit: 'contain',
  },
  companyInfo: {
    fontSize: 10,
    color: '#64748b',
    textAlign: 'right',
  },
  rcaSection: {
    marginBottom: 15,
    padding: 10,
    backgroundColor: '#f7fafc',
    borderRadius: 4,
  },
  rcaQuestionContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
  },
  rcaQuestion: {
    fontSize: 11,
    color: '#4a5568',
    fontFamily: 'Helvetica-Bold',
    marginBottom: 3,
  },
  rcaAnswer: {
    fontSize: 11,
    color: '#2d3748',
    fontFamily: 'Helvetica',
    paddingLeft: 15,
  },
  rcaQuestionSetTitle: {
    fontSize: 12,
    color: '#2d3748',
    fontFamily: 'Helvetica-Bold',
    marginBottom: 5,
  },
  rcaCauseItem: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
    paddingLeft: 10,
  },
  rcaBullet: {
    fontSize: 11,
    marginRight: 5,
    color: '#4a5568',
  },
});

const areEqual = (prevProps, nextProps) => {
  // Compare formData and pageNumbers to avoid unnecessary re-renders
  return (
    prevProps.pageNumbers.details === nextProps.pageNumbers.details &&
    prevProps.pageNumbers.rca === nextProps.pageNumbers.rca &&
    prevProps.pageNumbers.actions === nextProps.pageNumbers.actions &&
    JSON.stringify(prevProps.formData) === JSON.stringify(nextProps.formData)
  );
};

const PDFDocument = memo(({ formData, pageNumbers, setPageNumbers }) => {
  const updatePageNumber = (section, pageNumber) => {
    setPageNumbers(prev => ({ ...prev, [section]: pageNumber }));
  };

  return (
    <Document>
      {/* Table of Contents */}
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Image style={styles.logoHeader} src={'/LogoBluewithmark.png'} />
        </View>
        <Text style={styles.title}>Incident Report {formData?.incidentId || 'New Report'}</Text>
        <Text style={styles.tocTitle}>Table of Contents</Text>
        <View style={styles.tableOfContents}>
          <View style={styles.tocItem}>
            <Text style={styles.tocText}>1. Incident Details</Text>
            <Text style={styles.tocDots} />
            <Text style={styles.tocPage}>{pageNumbers.details || ''}</Text>
          </View>
          <View style={styles.tocItem}>
            <Text style={styles.tocText}>2. Root Cause Analysis</Text>
            <Text style={styles.tocDots} />
            <Text style={styles.tocPage}>{pageNumbers.rca || ''}</Text>
          </View>
          <View style={styles.tocItem}>
            <Text style={styles.tocText}>3. Corrective Actions</Text>
            <Text style={styles.tocDots} />
            <Text style={styles.tocPage}>{pageNumbers.actions || ''}</Text>
          </View>
        </View>
        <View style={styles.footer}>
          <Text style={styles.footerText}>Generated on {format(new Date(), 'MMM dd, yyyy')}</Text>
          <Image style={styles.footerLogo} src={'/LogoBluewithmark.png'} />
        </View>
        <Text 
          style={styles.pageNumber} 
          render={({ pageNumber }) => `${pageNumber}`} 
          fixed 
        />
      </Page>

      {/* Incident Details */}
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Image style={styles.logoHeader} src={'/LogoBluewithmark.png'} />
        </View>
        {/* <Text style={styles.subtitle}>{formData.incidentId || 'New Report'}</Text> */}
        
        {/* Incident Details */}
        <View style={styles.section}>
          <Text style={styles.subtitle}>1. Incident Details</Text>
          <View style={styles.infoGrid}>
            <View style={styles.infoItem}>
              <Text style={styles.infoLabel}>Incident ID</Text>
              <Text style={styles.infoValue}>{formData?.incidentId || 'N/A'}</Text>
            </View>
            <View style={styles.infoItem}>
              <Text style={styles.infoLabel}>Incident Type</Text>
              <Text style={styles.infoValue}>{formData.incidentType || 'N/A'}</Text>
            </View>
            <View style={styles.infoItem}>
              <Text style={styles.infoLabel}>Occurrence Time</Text>
              <Text style={styles.infoValue}>{formData.occurrenceTime || 'N/A'}</Text>
            </View>
            <View style={styles.infoItem}>
              <Text style={styles.infoLabel}>Location</Text>
              <Text style={styles.infoValue}>{formData.occurrenceLocation || 'N/A'}</Text>
            </View>
            <View style={styles.infoItem}>
              <Text style={styles.infoLabel}>Description</Text>
              <Text style={styles.infoValue}>{formData.description || 'N/A'}</Text>
            </View>
            <View style={styles.infoItem}>
              <Text style={styles.infoLabel}>Incident Clip</Text>
              <Text style={styles.infoValue}>{formData.incidentClip || 'N/A'}</Text>
            </View>
          </View>

          {/* Personnel Information */}
          <View style={[styles.infoGrid, { marginTop: 15 }]}>

            <View style={styles.infoItem}>
              <Text style={styles.infoLabel}>Personnel Name</Text>
              <Text style={styles.infoValue}>{formData.personnelName || 'N/A'}</Text>
            </View>
            <View style={styles.infoItem}>
              <Text style={styles.infoLabel}>Personnel ID</Text>
              <Text style={styles.infoValue}>{formData.personnelId || 'N/A'}</Text>
            </View>
            <View style={styles.infoItem}>
              <Text style={styles.infoLabel}>Shift</Text>
              <Text style={styles.infoValue}>{formData.shift || 'N/A'}</Text>
            </View>
            <View style={styles.infoItem}>
              <Text style={styles.infoLabel}>Job Title</Text>
              <Text style={styles.infoValue}>{formData.jobTitle || 'N/A'}</Text>
            </View>
            <View style={styles.infoItem}>
              <Text style={styles.infoLabel}>Supervisor Name</Text>
              <Text style={styles.infoValue}>{formData.supervisorName || 'N/A'}</Text>
            </View>
            <View style={styles.infoItem}>
              <Text style={styles.infoLabel}>Supervisor ID</Text>
              <Text style={styles.infoValue}>{formData.supervisorId || 'N/A'}</Text>
            </View>
          </View>

          {/* Incident Status */}
          <View style={[styles.infoGrid, { marginTop: 15 }]}>

            <View style={styles.infoItem}>
              <Text style={styles.infoLabel}>Incident Stopped</Text>
              <Text style={styles.infoValue}>{formData.incidentStopped || 'N/A'}</Text>
            </View>
            <View style={styles.infoItem}>
              <Text style={styles.infoLabel}>Any Injuries</Text>
              <Text style={styles.infoValue}>{formData.anyInjuries || 'N/A'}</Text>
            </View>
            <View style={styles.infoItem}>
              <Text style={styles.infoLabel}>How was it stopped</Text>
              <Text style={styles.infoValue}>{formData.incidentStoppedHow || 'N/A'}</Text>
            </View>
            <View style={styles.infoItem}>
              <Text style={styles.infoLabel}>Injury Description</Text>
              <Text style={styles.infoValue}>{formData.injuryDescription || 'N/A'}</Text>
            </View>
          </View>
        </View>

        <View style={styles.footer}>
          <Text style={styles.footerText}>Generated on {format(new Date(), 'MMM dd, yyyy')}</Text>
          <Image style={styles.footerLogo} src={'/LogoBluewithmark.png'} />
        </View>
        <Text 
          style={styles.pageNumber} 
          render={({ pageNumber }) => {
            // Update page number for Incident Details section
            if (pageNumbers.details !== pageNumber) {
              updatePageNumber('details', pageNumber);
            }
            return `${pageNumber}`;
          }} 
          fixed 
        />
      </Page>

      {/* RCA Section */}
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Image style={styles.logoHeader} src={'/LogoBluewithmark.png'} />
        </View>
        <View style={styles.section}>
          <Text style={styles.subtitle}>2. Root Cause Analysis</Text>
          
          {/* Basic RCA Information */}
          <View style={styles.infoGrid}>
            <View style={styles.infoItem}>
              <Text style={styles.infoLabel}>Incident Severity</Text>
              <Text style={styles.infoValue}>{formData.incidentSeverity || 'N/A'}</Text>
            </View>
            <View style={styles.infoItem}>
              <Text style={styles.infoLabel}>Potential Consequences</Text>
              <Text style={styles.infoValue}>{formData.potentialConsequences || 'N/A'}</Text>
            </View>
          </View>

          {/* RCA Questions and Answers */}
          <View style={[styles.rcaQuestionSet, { marginTop: 20 }]}>

            {formData.rcaQuestionSets?.map((questionSet, index) => {
              const question = questionSet.questions[0];
              if (!question) return null;
              
              const answer = formData.rcaAnswers && formData.rcaAnswers[question.id];
              if (!answer) return null;

              return (
                <View key={question.id} style={styles.rcaSection}>
                  <View style={styles.rcaQuestionContainer}>
                    <Text style={styles.rcaQuestion}>{`Q${index + 1}. ${question.content}`}</Text>
                    <Text style={styles.rcaAnswer}>{formData.rcaAnswers[question.id].content || 'N/A'}</Text>
                  </View>
                </View>
              );
            })}
          </View>

          {/* Root Causes List */}
          {formData.rootCauseAnalysis?.length > 0 && (
            <View style={[styles.rcaQuestionSet, { marginTop: 20 }]}>

              <Text style={styles.rcaQuestionSetTitle}>Identified Root Causes</Text>
              <View style={styles.rcaSection}>
                {formData.rootCauseAnalysis.map((cause, index) => (
                  <View key={index} style={styles.rcaCauseItem}>
                    <Text style={styles.rcaBullet}>•</Text>
                    <Text style={styles.rcaAnswer}>{cause}</Text>
                  </View>
                ))}
              </View>
            </View>
          )}
        </View>

        <View style={styles.footer}>
          <Text style={styles.footerText}>Generated on {format(new Date(), 'MMM dd, yyyy')}</Text>
          <Image style={styles.footerLogo} src={'/LogoBluewithmark.png'} />
        </View>
        <Text 
          style={styles.pageNumber} 
          render={({ pageNumber }) => {
            // Update page number for RCA section
            if (pageNumbers.rca !== pageNumber) {
              updatePageNumber('rca', pageNumber);
            }
            return `${pageNumber}`;
          }} 
          fixed 
        />
      </Page>

      {/* Corrective Actions */}
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Image style={styles.logoHeader} src={'/LogoBluewithmark.png'} />
        </View>
        <Text style={styles.subtitle}>3. Corrective Actions</Text>
        {formData.actionItems && formData.actionItems.length > 0 ? (
          <View style={styles.table}>
            <View style={[styles.tableRow, styles.tableHeader]}>
              <View style={[styles.tableHeaderCell, { flex: 1.2 }]}>

                <Text>Action Item</Text>
              </View>
              <View style={[styles.tableHeaderCell, { flex: 1.5 }]}>

                <Text>Description</Text>
              </View>
              <View style={[styles.tableHeaderCell, { flex: 1 }]}>

                <Text>Assigned To</Text>
              </View>
              <View style={[styles.tableHeaderCell, { flex: 1 }]}>

                <Text>Due Date</Text>
              </View>
              <View style={[styles.tableHeaderCell, { flex: 1 }]}>

                <Text>Status</Text>
              </View>
            </View>

            {formData.actionItems.map((item, index) => (
              <View key={index} style={[styles.tableRow, index % 2 === 0 ? styles.tableRowEven : styles.tableRowOdd]}>
                <View style={[styles.tableCell, { flex: 1.2 }]}>

                  <Text style={styles.tableCellText}>{item.plannedAction}</Text>
                </View>
                <View style={[styles.tableCell, { flex: 1.5 }]}>

                  <Text style={styles.tableCellText}>{item.description}</Text>
                </View>
                <View style={[styles.tableCell, { flex: 1 }]}>

                  <Text style={styles.tableCellText}>{item.assignedTo || 'N/A'}</Text>
                </View>
                <View style={[styles.tableCell, { flex: 1 }]}>

                  <Text style={styles.tableCellText}>
                    {item.deadline || 'N/A'}
                  </Text>
                </View>
                <View style={[styles.tableCell, { flex: 1 }]}>

                  <Text>
                    {item.status || 'Pending'}
                  </Text>
                </View>
              </View>
            ))}
          </View>
        ) : (
          <Text style={styles.emptyText}>No action items available</Text>
        )}
        <View style={styles.footer}>
          <Text style={styles.footerText}>Generated on {format(new Date(), 'MMM dd, yyyy')}</Text>
          <Image style={styles.footerLogo} src={'/LogoBluewithmark.png'} />
        </View>
        <Text 
          style={styles.pageNumber} 
          render={({ pageNumber }) => {
            // Update page number for Action Items section
            if (pageNumbers.actions !== pageNumber) {
              updatePageNumber('actions', pageNumber);
            }
            return `${pageNumber}`;
          }} 
          fixed 
        />
      </Page>
    </Document>
  );
}, areEqual);

const PreviewReport = ({ isOpen, onClose, formData, handleFinalSubmit }) => {
  const [pageNumbers, setPageNumbers] = useState({
    details: 0,
    rca: 0,
    actions: 0
  });

  const memoizedSetPageNumbers = useCallback((newPageNumbers) => {
    setPageNumbers(prev => ({ ...prev, ...newPageNumbers }));
  }, []);

  return (
    <PreviewModal isOpen={isOpen} onClose={onClose} title="Preview Report">
      <div className="flex flex-col h-full" style={{ minHeight: '80vh' }}>
        <div style={{ height: 'calc(100vh - 160px)', minHeight: '600px' }}>
          <PDFViewer
            width="100%"
            height="100%"
            style={{ border: 'none' }}
            filename={`Incident_Report_${formData?.incidentId || 'Unknown'}.pdf`}
          >
            <PDFDocument formData={formData} pageNumbers={pageNumbers} setPageNumbers={memoizedSetPageNumbers} />
          </PDFViewer>
        </div>
      </div>
    </PreviewModal>
  );
};

export default PreviewReport;
