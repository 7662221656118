import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { setUser } from "../redux/actions/userActions";
import { emailToSocketID } from "../utils/constants";
import { decrypt } from "../utils/encryption";

const useSession = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(true); // use lowercase for loading

  useEffect(() => {
    const checkSession = async () => {
      if (!user?.email) {
        setLoading(true);
        const session = localStorage.getItem("session");
        if (session) {
          try {
            const decryptedSession = JSON.parse(decrypt(session));
            if (new Date(decryptedSession.expiry) > new Date()) {
              dispatch(
                setUser(
                  decryptedSession.email,
                  decryptedSession.name,
                  emailToSocketID(decryptedSession.email)
                )
              );
            } else {
              localStorage.removeItem("session"); // Clear expired session
            }
          } catch (error) {
            console.error("Error parsing session:", error);
            localStorage.removeItem("session"); // Clear invalid session
          }
        }
        setLoading(false); // Set loading to false after checking session
      } else {
        setLoading(false); // User is already loaded
      }
    };

    checkSession(); // Call the session check
  }, [dispatch, user]);

  return { user, loading }; // Return an object containing user and loading
};

export default useSession;
