const formatTime = (minutes) => {
    if (!minutes) return "0:00";
    const hours = Math.floor(minutes / 60);
    const mins = Math.round(minutes % 60);
    return `${hours}:${mins.toString().padStart(2, "0")}`;
};

function formatDate(input, formatType) {
    const date = new Date(input);

    if (isNaN(date.getTime())) {
        return "Invalid Date";
    }

    function getDateSuffix(day) {
        if (day >= 11 && day <= 13) {
            return day + "th";
        }
        switch (day % 10) {
            case 1: return day + "st";
            case 2: return day + "nd";
            case 3: return day + "rd";
            default: return day + "th";
        }
    }

    const formats = {
        time12Hour: `${date.getHours() % 12 || 12}:${date.getMinutes().toString().padStart(2, '0')} ${date.getHours() >= 12 ? 'PM' : 'AM'}`,  // 1:00 PM
        dayOfWeek: date.toLocaleString('default', { weekday: 'long' }),  
        shortMonth: date.toLocaleString('default', { month: 'short' }),  
        dateOnly: `${getDateSuffix(date.getDate())} ${date.toLocaleString('default', { month: 'short' })}`,                          
    };

    switch (formatType?.toLowerCase()) {
        case 'hours':
            return formats.time12Hour;
        case 'week':
            return formats.dayOfWeek;
        case 'month':
            return formats.dateOnly;
        case 'year':
            return formats.shortMonth;
        default:
            return "Unknown Format Type";
    }
}

const CustomTooltip = ({ active, payload, label, isForklift, timeframe }) => {
    if (!active || !payload || !payload.length) return null;

    // const timeLabel = formatDate(label, timeframe);

    return (
        <div className="bg-white p-2 shadow-lg border border-gray-200 rounded-lg">
            <p className="text-sm text-gray-800">Timeperiod: {label}</p>
            {payload.map((entry, index) => (
                <p
                    key={index}
                    className="text-sm font-semibold text-gray-800"
                    style={{ color: entry.color }}
                >
                    {`${entry.name}: ${isForklift ? formatTime(entry.value) : Math.round(entry.value)}`}
                    {isForklift ? ' hours' : ' boxes'}
                </p>
            ))}
        </div>
    );
};

export {formatTime, CustomTooltip}