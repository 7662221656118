import React, { useRef, useState, useEffect } from "react";
import { fetchMultipleRois } from "../services/apiServiceDetails";
import {
  CheckCircleOutline,
  RadioButtonChecked,
  RadioButtonUnchecked,
} from "@mui/icons-material";

const ImageDrawer = ({
  imageSrc,
  boundingBoxes,
  setBoundingBoxes,
  assosID,
  allstream = 4,
  checkboxStates,
  setCheckboxStates,
  usecaseType,
}) => {
  const canvasRef = useRef(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [image, setImage] = useState(null);
  const [currentPoints, setCurrentPoints] = useState([]);
  const [scaleRatio, setScaleRatio] = useState({ x: 1, y: 1 });
  const [hoveredBox, setHoveredBox] = useState(null);
  const [saveButtonPosition, setSaveButtonPosition] = useState({ x: 0, y: 0 });

  // Initialize with 4 checkboxes
  const [checkboxLabels, setCheckboxLabels] = useState([
    "roi1",
    "roi2",
    "roi3",
    "roi4",
  ]); // Predefined checkbox labels (empty at start)

  // Fetch ROIs and set bounding boxes

  console.log("required_roi", allstream[0].required_roi);
  const getrois = async () => {
    const res = await fetchMultipleRois(assosID);
    if (res && res.rois) {
      const parsedRois = res.rois.map((roi) => {
        return {
          roi_name: roi.roi_name, // Assuming roi_name is part of the fetched data
          roi: JSON.parse(roi.roi), // Parse the JSON string to an object
        };
      });

      setBoundingBoxes(parsedRois);
      let updatedStates = [];
      parsedRois.forEach((_, index) => {
        updatedStates.push(true);
      });

      while (updatedStates?.length < allstream[0].required_roi?.length) {
        updatedStates.push(false);
      }

      setCheckboxStates(updatedStates);
    }
  };

  useEffect(() => {
    const img = new Image();
    img.src = imageSrc;

    img.onload = () => {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");

      // Calculate scaling ratio for the image
      const scaleX = canvas.width / img.width;
      const scaleY = canvas.height / img.height;
      setScaleRatio({ x: scaleX, y: scaleY });

      ctx.drawImage(img, 0, 0, canvas.width, canvas.height); // Draw the image on the canvas
      setImage(img); // Store the image object to avoid reloading it
      setImageLoaded(true); // Set the image loaded state
    };

    img.onerror = () => {
      console.error("Image failed to load");
      setImageLoaded(false); // Set the image loaded state
    };

    getrois();
  }, [imageSrc]);

  // Handle canvas click to create bounding box points
  const handleCanvasClick = (e) => {
    if (!imageLoaded || boundingBoxes?.length >= 4) return;

    const rect = canvasRef.current.getBoundingClientRect();
    const newPoint = {
      x: (e.nativeEvent.offsetX / rect.width) * 620, // Adjusting for canvas width
      y: (e.nativeEvent.offsetY / rect.height) * 400, // Adjusting for canvas height
    };

    setCurrentPoints((prevPoints) => {
      const updatedPoints = [...prevPoints, newPoint];
      if (updatedPoints?.length === 4) {
        // Calculate bottom right point for placing the save button
        const bottomRightPoint = updatedPoints[3];
        setSaveButtonPosition({ x: bottomRightPoint.x, y: bottomRightPoint.y });
      }
      return updatedPoints;
    });
  };

  // Save the ROI and mark the checkbox as checked
  const handleSaveRoi = (points) => {
    const newBox = {
      roi_name:
        allstream?.[0]?.required_roi?.length > boundingBoxes?.length
          ? allstream[0].required_roi[boundingBoxes?.length] // Use predefined label
          : usecaseType === "Safety & Compliance (Intrusion)"
          ? `Restricted Area ${boundingBoxes?.length + 1}`
          : `Work Area ${boundingBoxes?.length + 1}`, // Default label if not available
      roi: points.map((point) => ({
        x: (point.x / 620).toFixed(2), // Normalize and format x
        y: (point.y / 400).toFixed(2), // Normalize and format y
      })),
    };
    setBoundingBoxes((prevBoxes) => [...prevBoxes, newBox]); // Keep only 4 boxes
    setCheckboxStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[boundingBoxes?.length] = true; // Check the corresponding checkbox

      return newStates; // Ensure there are only 4 checkboxes
    });
    setCurrentPoints([]); // Clear current points after saving
  };

  // Draw all bounding boxes including the current one
  const drawAllBoundingBoxes = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    // Clear the canvas and redraw the image
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    if (image) {
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height); // Redraw the image
    }

    // Draw all saved bounding boxes
    if (boundingBoxes?.length > 0) {
      boundingBoxes.forEach((box, index) => {
        drawBoundingBox(ctx, box.roi, box.roi_name, index);
      });
    }

    // Draw the current bounding box if points exist and normalize them
    if (currentPoints?.length > 0) {
      const normalizedPoints = currentPoints.map((point) => ({
        x: (point.x / 620).toFixed(2), // Normalize x to [0, 1]
        y: (point.y / 400).toFixed(2), // Normalize y to [0, 1]
      }));
      drawBoundingBox(ctx, normalizedPoints, null); // Draw normalized current points
    }
  };

  // Draw a single bounding box
  const drawBoundingBox = (ctx, boxPoints, name, index) => {
    ctx.beginPath();
    ctx.moveTo(boxPoints[0].x * 620, boxPoints[0].y * 400);
    boxPoints.forEach((point, i) => {
      if (i > 0) {
        ctx.lineTo(point.x * 620, point.y * 400);
      }
    });
    ctx.closePath();

    ctx.strokeStyle = hoveredBox === index ? "yellow" : "blue"; // Highlight on hover
    ctx.lineWidth = 2;
    ctx.stroke();

    boxPoints.forEach((point) => {
      ctx.beginPath();
      ctx.arc(point.x * 620, point.y * 400, 3, 0, 2 * Math.PI); // White dot for each point
      ctx.fillStyle = "white";
      ctx.fill();
    });

    // Draw the name at the lower left corner of the bounding box if it exists
    if (name) {
      const textWidth = ctx.measureText(name).width;
      const textHeight = 20; // Approximate height for the text
      ctx.fillStyle = "rgba(255, 255, 255, 0.3)"; // Semi-transparent white for glass effect
      ctx.roundRect(
        boxPoints[0].x * 620 - 5,
        boxPoints[0].y * 400 - textHeight - 5,
        textWidth + 10,
        textHeight + 10,
        10
      );
      ctx.fill();

      // Draw the name text in black
      ctx.font = "bold 16px Arial"; // Make the font bold
      ctx.fillStyle = "black"; // Use black color for the name
      ctx.fillText(name, boxPoints[0].x * 620, boxPoints[0].y * 400 - 5); // Position the name above the top left point
    }
  };

  // Handle hover over bounding boxes
  const handleHover = (e) => {
    if (!imageLoaded) return;

    const rect = canvasRef.current.getBoundingClientRect();
    const mouseX = (e.nativeEvent.offsetX / rect.width) * 620;
    const mouseY = (e.nativeEvent.offsetY / rect.height) * 400;

    const hoveredIndex = boundingBoxes.findIndex((box) => {
      return isPointInsideBoundingBox(box.roi, mouseX, mouseY);
    });

    setHoveredBox(hoveredIndex); // Set the hovered box index
  };

  // Check if the mouse point is inside a bounding box
  const isPointInsideBoundingBox = (roi, x, y) => {
    return (
      x >= roi[0].x * 620 &&
      x <= roi[2].x * 620 &&
      y >= roi[0].y * 400 &&
      y <= roi[2].y * 400
    );
  };

  const handleDeleteBox = (index) => {
    setBoundingBoxes((prevBoxes) => {
      return prevBoxes.slice(0, index);
    });

    let updatedStates = checkboxStates;
    if (allstream[0].required_roi.length > 0) {
      updatedStates.fill(false, index);
      setCheckboxStates(updatedStates);
    } else {
      if (index === 0) {
        setCheckboxStates((prevStates) => {
          const updatedStates = prevStates.slice(0, index + 1);
          return updatedStates;
        });
      } else {
        setCheckboxStates((prevStates) => {
          const updatedStates = prevStates.slice(0, index);
          return updatedStates;
        });
      }

      // updatedStates.slice(0, index);
    }
  };

  // Draw bounding boxes whenever boundingBoxes change
  useEffect(() => {
    drawAllBoundingBoxes();
  }, [boundingBoxes, currentPoints, hoveredBox]);

  return (
    <div className="flex flex-row-reverse items-center justify-between w-full">
      <div style={{ flex: 1 }} className=" relative">
        <canvas
          ref={canvasRef}
          width={620}
          height={400}
          onClick={handleCanvasClick}
          onMouseMove={handleHover}
          style={{ border: "1px solid black", position: "relative" }}
        ></canvas>
        {currentPoints?.length >= 4 && (
          <button
            onClick={() => handleSaveRoi(currentPoints)}
            style={{
              position: "absolute",
              left: saveButtonPosition.x,
              top: saveButtonPosition.y,
              background: "green",
              color: "white",
              padding: "5px 10px",
            }}
          >
            Save
          </button>
        )}
        {boundingBoxes.map((box, index) => {
          const topRightX = box.roi[box.roi?.length - 1].x * 620; // Position for delete button
          const topRightY = box.roi[box.roi?.length - 1].y * 400; // Position for delete button
          return (
            <button
              key={index}
              style={{
                position: "absolute",
                left: `${topRightX}px`,
                top: `${topRightY}px`,
                height: 16,
                width: 16,
                backgroundColor: "white",
                borderRadius: "50%",
                fontSize: "8px",
                fontWeight: "bold",
                border: "none",
                color: "black",
                cursor: "pointer",
                zIndex: 10,
              }}
              onClick={() => handleDeleteBox(index)}
            >
              X
            </button>
          );
        })}
      </div>
      <div className=" w-[250px] h-[400px] tracking-tighter">
        <h3 className=" py-5 text-2xl text-gray-600">Interest Areas</h3>
        {checkboxStates.map((state, index) => (
          <div
            key={index}
            className="flex flex-row items-center justify-start gap-3"
            style={{ marginBottom: "10px" }}
          >
            <div className="relative">
              <input
                type="checkbox"
                checked={state}
                className="appearance-none w-6 h-6 rounded-full hidden bg-gray-200 border-2 border-gray-300 checked:bg-green-500 checked:border-green-500 focus:outline-none transition duration-200 cursor-pointer"
              />
              {state ? (
                <div className="bg-green-500 border-green-600 w-4 h-4 rounded-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center">
                  <svg
                    className=" w-4 h-4 text-white"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              ) : (
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-4 h-4 rounded-full bg-gray-500"></div>
              )}
            </div>
            <span className="text-gray-600 capitalize">
              {allstream[0]?.required_roi.length > 0
                ? allstream[0].required_roi[index]
                : boundingBoxes.length > 0
                ? boundingBoxes[index]?.roi_name
                : usecaseType === "Safety & Compliance (Intrusion)"
                ? "Restricted Area 1"
                : "Work Area 1"}
            </span>

            {/* {checkboxStates[index] && (
            <button
              onClick={() => handleDeleteBox(index)}
              className="bg-red-500 px-3 py-2 ml-5 rounded-sm"
            >
              Delete
            </button>
          )} */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageDrawer;
