import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { formatTime } from "../utils/constants";

const convertTo12HourFormat = (time) => {
  // Check if the input is in the expected format
  const timeFormatRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
  if (!timeFormatRegex.test(time)) return time;

  const [hour, minute] = time.split(":").map(Number);
  const ampm = hour >= 12 ? "PM" : "AM";
  const adjustedHour = hour % 12 || 12;
  return `${adjustedHour}:${minute.toString().padStart(2, "0")} ${ampm}`;
};

const CustomTooltip = ({ active, payload, label, isHour, isNumber }) => {
  if (!active || !payload || !payload.length) return null;

  const timeLabel = convertTo12HourFormat(label);

  return (
    <div className="bg-white p-2 shadow-lg border border-gray-200 rounded-lg">
      <p className="text-sm text-gray-600">Timeperiod: {timeLabel}</p>
      <p className="text-sm font-semibold text-gray-800">
        {isHour
          ? `Time: ${formatTime(payload[0].value)}`
          : isNumber
          ? `Total: ${payload[0].value}`
          : `Violation: ${payload[0].value}`}
      </p>
    </div>
  );
};

const SimpleBarChart = ({
  chartData,
  chartWidth,
  chartType = "safety",
  isHour,
  isNumber,
}) => {
  // console.log("chartData in bar chart", chartData);
  // Handle empty data case
  if (!chartData?.length || chartData.every((d) => d.value === 0)) {
    const message =
      chartType === "safety" || chartType === "compliance"
        ? "No safety incidents to report. Keep up the great work!"
        : "No data to display";

    return (
      <div className="w-full h-[50px] flex items-center justify-center">
        <span className="text-sm text-gray-600">{message}</span>
      </div>
    );
  }

  return (
    <div style={{ width: "100%", height: 50 }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={chartData}
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
        >
          <XAxis
            dataKey="group"
            tick={{ angle: -45, textAnchor: "end", fontSize: 12 }}
            hide={true}
          />
          <YAxis hide={true} />
          <Tooltip
            content={<CustomTooltip isHour={isHour} isNumber={isNumber} />}
            cursor={{ fill: "transparent" }}
          />
          <Bar
            dataKey="value"
            fill="#295F98"
            radius={[0, 0, 0, 0]}
            minPointSize={2}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default SimpleBarChart;
