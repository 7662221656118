import React, { useState } from "react";

const GoalDetection = ({ prompt, setPrompt, onBack, onClose, onNext }) => {
  const [currentBehavior, setCurrentBehavior] = useState("");
  const [entities, setEntities] = useState(["Worker", "Helmet"]);

  const handleNext = () => {
    // if (currentBehavior.trim()) {
    //   const updatedPrompt = [...prompt, currentBehavior.trim()];

    //   setPrompt(updatedPrompt);

    //   const newEntitiesString = currentBehavior
    //     .trim()
    //     .split(" ")
    //     .filter((word) => word[0] === word[0].toUpperCase() && word.length > 1)
    //     .filter((entity) => !entities.includes(entity))
    //     .join(", ");

    //   if (newEntitiesString) {
    //     setEntities([...entities, ...newEntitiesString.split(", ")]);
    //   }
    //   setCurrentBehavior("");
    // }
    onNext();
  };

  const handleRemoveBehavior = (index) => {
    setPrompt(prompt.filter((_, i) => i !== index));
  };

  return (
    <div
      className="relative bg-white rounded-t-lg shadow-xl w-full h-[100%] overflow-auto"
      data-testid="goal-detection-container"
    >
      <div
        className="flex justify-between items-center px-6 py-4"
        data-testid="header"
      >
        <h1 className="flex-1 shrink self-stretch my-auto text-3xl font-semibold tracking-tighter leading-tight text-gray-900 basis-10 max-md:max-w-full">
          Specify Detection Target
        </h1>
      </div>
      <button
        onClick={onClose}
        className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
        data-testid="close-modal-button"
      >
        <span className="block w-6 h-6 relative" aria-label="Close">
          <span className="block absolute w-full h-0.5 bg-current transform rotate-45 top-1/2"></span>
          <span className="block absolute w-full h-0.5 bg-current transform -rotate-45 top-1/2"></span>
        </span>
      </button>

      {/* Navigation and Button */}
      <div
        className="flex items-center justify-between px-6 py-4"
        data-testid="navigation-buttons"
      >
        <div className="flex items-center space-x-2 text-sm font-medium text-[#052D61]">
          <span className="text-gray-500">Select Camera Device</span>
          <span>{">"}</span>
          <span className="font-semibold">Specify Detection Target</span>
          {/* <span>{">"}</span> */}
          {/* <span className="text-gray-500">Fix Incorrect Detections</span> */}
        </div>
        <div className="flex gap-2">
          <button
            onClick={onBack}
            className="overflow-hidden gap-2 justify-center items-center self-stretch px-5 py-4 my-auto text-sm font-semibold leading-none text-center bg-gray-200 rounded-xl transition"
            data-testid="back-button"
          >
            Back
          </button>
          <button
            onClick={handleNext}
            className="overflow-hidden gap-2 justify-center items-center self-stretch px-5 py-4 my-auto text-sm font-semibold leading-none text-center text-gray-50 bg-[#052D61] rounded-xl"
            data-testid="next-button"
          >
            Start Target Detection
          </button>
        </div>
      </div>

      <div className="px-6 py-4 max-w-xl w-3/5 ml-0" data-testid="form-section">
        <div className="mb-4">
          <label
            className="block text-sm font-medium text-gray-700 mb-2"
            data-testid="behavior-label"
          >
            What do you want to detect in the stream? *
          </label>
          <div className="flex gap-2">
            <input
              type="text"
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              placeholder="eg: waiter wearing blue apron"
              className="flex-grow p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              data-testid="behavior-input"
            />
            {/* <button
              onClick={handleAddBehavior}
              className="flex items-center justify-center px-5 py-3 text-sm font-semibold text-[#052D61] bg-white border border-blue-300 rounded-lg hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              data-testid="add-behavior-button"
            >
              Add
            </button> */}
          </div>
        </div>

        {/* {prompt.map((behavior, index) => (
          <div
            key={index}
            className="flex gap-2 mb-2"
            data-testid={`behavior-item-${index}`}
          >
            <input
              type="text"
              value={behavior}
              readOnly
              className="flex-grow p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 px-4"
              data-testid={`behavior-text-${index}`}
            />
            <button
              onClick={() => handleRemoveBehavior(index)}
              className="flex items-center justify-center px-5 text-sm font-semibold text-gray-400 hover:text-gray-600 focus:outline-none py-3"
              aria-label="Remove behavior"
              data-testid={`remove-behavior-button-${index}`}
            >
              <span className="block w-6 h-6 relative" aria-label="Close">
                <span className="block absolute w-full h-0.5 bg-current transform rotate-45 top-1/2"></span>
                <span className="block absolute w-full h-0.5 bg-current transform -rotate-45 top-1/2"></span>
              </span>
            </button>
          </div>
        ))} */}

        {entities.length > 0 && (
          <div className="mt-4" data-testid="entities-section">
            <span className="text-sm text-gray-600 mr-2">Entities:</span>
            {entities.map((entity, index) => (
              <span
                key={index}
                className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2"
                data-testid={`entity-${index}`}
              >
                {entity}
              </span>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default GoalDetection;
