import React, { useEffect, useState } from "react";
// import { useCaseToLogics } from "../utils/constants";

const AppLogicsModal = ({
  isOpen,
  Flows,
  onClose,
  useCase,
  detection_status,
}) => {
  if (!isOpen) return null;

  // console.log("AppLogicsModal rendered");

  // console.log(Flows);

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4"
      data-testid="app-logics-modal"
    >
      <div
        className="bg-white rounded-lg w-full max-w-md"
        data-testid="modal-content"
      >
        <div className="p-6">
          <div className="flex justify-between items-center mb-6">
            <h2
              className="text-2xl font-bold text-gray-900"
              data-testid="modal-title"
            >
              Selected {useCase}
            </h2>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500"
              data-testid="close-button"
            >
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                data-testid="close-icon"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          <p className="text-gray-700 mb-4" data-testid="modal-description">
            An alert will be triggered if :
          </p>

          <ul
            className="list-disc pl-5 mb-6 text-gray-700"
            data-testid="flow-list"
          >
            {Flows?.map((flow, index) => (
              <li key={index} data-testid={`flow-item-${index}`}>
                {flow.flowtype}
              </li>
            ))}
          </ul>

          <button
            onClick={onClose}
            className="w-full bg-[#052D61] hover:bg-blue-700 text-white font-bold py-3 px-4 rounded-lg"
            data-testid="done-button"
          >
            Done
          </button>
        </div>
      </div>
    </div>
  );
};

export default AppLogicsModal;
