import React, { useRef, useState, useEffect } from "react";
import { fetchMultipleRois } from "../services/apiServiceDetails";
import {
  CheckCircleOutline,
  RadioButtonChecked,
  RadioButtonUnchecked,
} from "@mui/icons-material";

const DimensionsDrawer = ({
  imageSrc = "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.phl.co.uk%2Fblog%2Funderstanding-different-forklift-types&psig=AOvVaw1bitteimjuQDY2kDxynsgb&ust=1733225162764000&source=images&cd=vfe&opi=89978449&ved=0CBQQjRxqFwoTCPDw1oT9iIoDFQAAAAAdAAAAABAE",
  roiDimensions,
  setRoiDimensions,
  assosID,
  allstream = 4,
}) => {
  const canvasRef = useRef(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [image, setImage] = useState(null);
  const [currentPoints, setCurrentPoints] = useState([]);
  const [scaleRatio, setScaleRatio] = useState({ x: 1, y: 1 });
  const [hoveredBox, setHoveredBox] = useState(null);
  const [saveButtonPosition, setSaveButtonPosition] = useState({ x: 0, y: 0 });
  const [maxSpeed, setMaxSpeed] = useState(0);
  const [dimensions, setDimensions] = useState(0);

  console.log("required_roi", allstream[0].required_roi);
  //   const getrois = async () => {
  //     const res = await fetchMultipleRois(assosID);
  //     if (res && res.rois) {
  //       const parsedRois = res.rois.map((roi) => {
  //         return {
  //           roi_name: roi.roi_name,
  //           roi: JSON.parse(roi.roi),
  //         };
  //       });

  //       setBoundingBoxes(parsedRois);
  //       let updatedStates = [];
  //       parsedRois.forEach((_, index) => {
  //         updatedStates.push(true);
  //       });

  //       while (updatedStates?.length < allstream[0].required_roi?.length) {
  //         updatedStates.push(false);
  //       }

  //       setCheckboxStates(updatedStates);
  //     }
  //   };

  useEffect(() => {
    const img = new Image();
    img.src = imageSrc;

    img.onload = () => {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");

      // Calculate scaling ratio for the image
      const scaleX = canvas.width / img.width;
      const scaleY = canvas.height / img.height;
      setScaleRatio({ x: scaleX, y: scaleY });

      ctx.drawImage(img, 0, 0, canvas.width, canvas.height); // Draw the image on the canvas
      setImage(img); // Store the image object to avoid reloading it
      setImageLoaded(true); // Set the image loaded state
    };

    img.onerror = () => {
      console.error("Image failed to load");
      setImageLoaded(false); // Set the image loaded state
    };

    // getrois();
  }, [imageSrc]);

  // Handle canvas click to create bounding box points
  const handleCanvasClick = (e) => {
    if (!imageLoaded || roiDimensions?.length >= 2) return;

    const rect = canvasRef.current.getBoundingClientRect();
    const newPoint = {
      x: (e.nativeEvent.offsetX / rect.width) * 620, // Adjusting for canvas width
      y: (e.nativeEvent.offsetY / rect.height) * 400, // Adjusting for canvas height
    };

    setCurrentPoints((prevPoints) => {
      const updatedPoints = [...prevPoints, newPoint];
      return updatedPoints;
    });
  };

  // Save the ROI and mark the checkbox as checked
  const handleSaveRoi = (points) => {
    const newBox = {
      dimensions: dimensions,
      max_speed: maxSpeed,
      dim_points: points.map((point) => ({
        x: (point.x / 620).toFixed(2), // Normalize and format x
        y: (point.y / 400).toFixed(2), // Normalize and format y
      })),
    };
    setRoiDimensions((prevBoxes) => [...prevBoxes, newBox]); // Keep only 4 boxes
    setCurrentPoints([]); // Clear current points after saving
    setDimensions(0);
  };

  // Draw all bounding boxes including the current one
  const drawAllBoundingBoxes = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    // Clear the canvas and redraw the image
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    if (image) {
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height); // Redraw the image
    }

    // Draw all saved bounding boxes
    if (roiDimensions?.length > 0) {
      roiDimensions.forEach((box, index) => {
        drawBoundingBox(ctx, box.dim_points, box.dimension, index);
      });
    }

    // Draw the current bounding box if points exist and normalize them
    if (currentPoints?.length > 0) {
      const normalizedPoints = currentPoints.map((point) => ({
        x: (point.x / 620).toFixed(2), // Normalize x to [0, 1]
        y: (point.y / 400).toFixed(2), // Normalize y to [0, 1]
      }));
      drawBoundingBox(ctx, normalizedPoints, null); // Draw normalized current points
    }
  };

  // Draw a single bounding box
  const drawBoundingBox = (ctx, boxPoints, name, index) => {
    ctx.beginPath();
    ctx.moveTo(boxPoints[0].x * 620, boxPoints[0].y * 400);
    boxPoints.forEach((point, i) => {
      if (i > 0) {
        ctx.lineTo(point.x * 620, point.y * 400);
      }
    });
    ctx.closePath();

    ctx.strokeStyle = hoveredBox === index ? "yellow" : "blue"; // Highlight on hover
    ctx.lineWidth = 2;
    ctx.stroke();

    boxPoints.forEach((point) => {
      ctx.beginPath();
      ctx.arc(point.x * 620, point.y * 400, 3, 0, 2 * Math.PI); // White dot for each point
      ctx.fillStyle = "white";
      ctx.fill();
    });

    // Draw the name at the lower left corner of the bounding box if it exists
    if (name) {
      const textWidth = ctx.measureText(name).width;
      const textHeight = 20; // Approximate height for the text
      ctx.fillStyle = "rgba(255, 255, 255, 0.3)"; // Semi-transparent white for glass effect
      ctx.roundRect(
        boxPoints[0].x * 620 - 5,
        boxPoints[0].y * 400 - textHeight - 5,
        textWidth + 10,
        textHeight + 10,
        10
      );
      ctx.fill();

      // Draw the name text in black
      ctx.font = "bold 16px Arial"; // Make the font bold
      ctx.fillStyle = "black"; // Use black color for the name
      ctx.fillText(name, boxPoints[0].x * 620, boxPoints[0].y * 400 - 5); // Position the name above the top left point
    }
  };

  // Handle hover over bounding boxes
  const handleHover = (e) => {
    if (!imageLoaded) return;

    const rect = canvasRef.current.getBoundingClientRect();
    const mouseX = (e.nativeEvent.offsetX / rect.width) * 620;
    const mouseY = (e.nativeEvent.offsetY / rect.height) * 400;

    const hoveredIndex = roiDimensions?.findIndex((box) => {
      return isPointInsideBoundingBox(box.roi, mouseX, mouseY);
    });

    setHoveredBox(hoveredIndex); // Set the hovered box index
  };

  // Check if the mouse point is inside a bounding box
  const isPointInsideBoundingBox = (roi, x, y) => {
    return (
      x >= roi[0].x * 620 &&
      x <= roi[2].x * 620 &&
      y >= roi[0].y * 400 &&
      y <= roi[2].y * 400
    );
  };

  const handleDeleteBox = (index) => {
    setRoiDimensions((prevBoxes) => {
      return prevBoxes.filter((_, i) => i !== index); // Keep all except the box at the given index
    });
  };

  // Draw bounding boxes whenever boundingBoxes change
  useEffect(() => {
    drawAllBoundingBoxes();
  }, [roiDimensions, currentPoints, hoveredBox]);

  console.log("currpoints", currentPoints, roiDimensions);

  return (
    <div className="flex flex-row-reverse items-center justify-between w-full">
      <div style={{ flex: 1 }} className=" relative">
        <canvas
          ref={canvasRef}
          width={620}
          height={400}
          onClick={handleCanvasClick}
          onMouseMove={handleHover}
          style={{ border: "1px solid black", position: "relative" }}
        ></canvas>
        {currentPoints?.length >= 2 && (
          <div>
            <div className="flex flex-row gap-x-2 w-full text-sm font-medium leading-none">
              <input
                data-testid="add-video-name-input"
                type="text"
                id="dimensions-input"
                placeholder="Please Enter Dimnesions of marked points"
                required
                value={dimensions}
                onChange={(e) => setDimensions(e.target.value)}
                className="py-4 mt-1 w-full bg-gray-50 px-3 rounded-xl border border-gray-200"
                aria-label="dimensions"
              />
            </div>

            <button
              onClick={() => handleSaveRoi(currentPoints)}
              style={{
                // position: "absolute",
                // left: saveButtonPosition.x,
                // top: saveButtonPosition.y,
                background: "#2563EB",
                color: "white",
                padding: "5px 10px",
              }}
            >
              Save Measurement
            </button>
          </div>
        )}
        {roiDimensions?.length > 0 &&
          roiDimensions?.map((box, index) => {
            const topRightX =
              box.dim_points[box.dim_points?.length - 1].x * 620; // Position for delete button
            const topRightY =
              box.dim_points[box.dim_points?.length - 1].y * 400; // Position for delete button
            return (
              <button
                key={index}
                style={{
                  position: "absolute",
                  left: `${topRightX}px`,
                  top: `${topRightY}px`,
                  height: 16,
                  width: 16,
                  backgroundColor: "white",
                  borderRadius: "50%",
                  fontSize: "8px",
                  fontWeight: "bold",
                  border: "none",
                  color: "black",
                  cursor: "pointer",
                  zIndex: 10,
                }}
                onClick={() => handleDeleteBox(index)}
              >
                X
              </button>
            );
          })}
      </div>
      <div className=" w-[250px] h-[400px] tracking-tighter px-3">
        <h3 className=" py-5 text-2xl text-gray-600">
          Calibrate Forklift Movement
        </h3>
        <div className="flex flex-col w-full text-sm font-medium leading-none">
          <label htmlFor="videoName" className="flex gap-0.5 items-start">
            <span className="text-slate-800">Max Forklift Speed</span>
            <span className="text-red-500">*</span>
          </label>
          <input
            data-testid="add-video-name-input"
            type="text"
            id="maxspeed"
            required
            value={maxSpeed}
            onChange={(e) => setMaxSpeed(e.target.value)}
            className="py-4 mt-1 w-full bg-gray-50 px-3 rounded-xl border border-gray-200"
            aria-label="maxspeed"
          />
        </div>
      </div>
    </div>
  );
};

export default DimensionsDrawer;
