import React, { useState, useEffect } from "react";
import ClickActionButton from "./ClickActionButton";
import HeaderWithButton from "./HeaderWithButton";

const EmptyDataComponent = ({
  currentPage,
  title,
  buttonText,
  onButtonClick,
  ...props
}) => {
  const [showComponent, setShowComponent] = useState(false);

  const className = props.className
    ? props.className
    : "flex flex-col items-center justify-center w-full h-full overflow-y-auto";

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowComponent(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const leadingMessage =
    currentPage === "Dashboard"
      ? "No sites created yet!"
      : currentPage === "Scenario"
      ? "No scenario created yet!"
      : currentPage === "All Devices Modal"
      ? "No available devices to connect!"
      : "No device connected yet!";

  const subMessage =
    currentPage === "Dashboard"
      ? "Create a site to view and manage use cases"
      : currentPage === "Scenario"
      ? "Get started by adding your first scenario"
      : currentPage === "All Devices Modal"
      ? "Get started by adding your first device"
      : "Get started by connecting your first device";

  return (
    <div className=" overflow-y-auto">
      {showComponent && (
        <div className={className} data-testid="empty-data">
          <div className="flex flex-col items-center justify-center">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/8de934d1a8586f7b8d727d4ca64f989f58454423d9e68b4a8c0edc29b437dcfd?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde"
              alt="Empty state illustration"
              className="object-contain w-44 max-w-full aspect-[0.99]"
            />
          </div>
          <div className="flex flex-col items-center justify-center mt-5">
            <h2 className="text-lg font-semibold text-gray-900">
              {leadingMessage}
            </h2>
            <p className="text-sm font-medium text-gray-400">{subMessage}</p>
            <ClickActionButton
              title={title}
              buttonText={buttonText}
              onButtonClick={onButtonClick}
              testid="empty-header-other"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default EmptyDataComponent;
