import React, { useState, useRef, useEffect } from "react";
// import { createStream } from "../../../services/apiServiceDetails";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import CircularProgressWithLabel from "../../CircularProgressWithLabel";
import axiosInstance from "../../../services/axiosinstance";
import Button from "../../Button";
import useCustomToast from "../../../hooks/useToast";

export const AddVideo = ({
  isOpen,
  onClose,
  onSubmit,
  isUploading,
  setIsUploading,
  setProgress,
  progress,
}) => {
  const { projectid, project, siteid } = useParams();
  const showToast = useCustomToast();
  const fileInputRef = useRef(null);

  const options = ["Camera", "IoT", "Scada"];
  const [videoName, setVideoName] = useState("");
  const [videoType, setVideoType] = useState(options[0]);
  const [streamType, setStreamType] = useState("Recorded");
  const [file, setFile] = useState(null);
  const [deviceUrl, setDeviceUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [uploadInProgress, setUploadInProgress] = useState(false);

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const File = event.target.files[0];
    setFile(File);
  };

  const handleVideoTypeChange = (event) => {
    setVideoType(event.target.value);
    setStreamType("Recorded");
    setFile(null);
    setDeviceUrl("");
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

      if (!videoName) {
        showToast("Please enter a device name", "error");
        return;
      }

      if (
        (videoType === "Camera" && streamType === "Live") ||
        videoType === "IoT" ||
        videoType === "Scada"
      ) {
        if (!deviceUrl) {
          showToast("Please enter a device URL", "error");
          return;
        }
      }

      if (videoType === "Camera" && streamType === "Recorded") {
        if (!file) {
          showToast("Please select a video file", "error");
          return;
        }
      }

      setLoading(true);
      setIsUploading(true);

      const formData = new FormData();
      formData.append("video_name", videoName);
      formData.append("video_type", videoType);
      formData.append("stream_type", streamType);
      formData.append("site_id", siteid);

      if (file) {
        formData.append("video", file);
      } else if (deviceUrl) {
        formData.append("device_url", deviceUrl);
      }

      setUploadInProgress(true);
      console.log("uploading", formData);
      const data = await createStream(formData);

      if (data.success) {
        showToast("Device created successfully!", "success");
        onSubmit();
      } else if (data.duplicate) {
        showToast("Device already exists", "error");
      } else {
        showToast(data.message, "error");
      }
    } catch (error) {
      console.log(error);
      showToast("Failed to upload stream", "error");
    } finally {
      setLoading(false);
      setIsUploading(false);
    }
  };

  const createStream = async (formData) => {
    console.log(formData);
    try {
      const response = await axiosInstance.post(
        "/streams/stream/create",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const percentage = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setProgress(percentage);
          },
        }
      );
      return { success: true, data: response.data };
    } catch (error) {
      if (error.response) {
        if (
          error.response.data.error &&
          error.response.data.error.includes("already exists")
        ) {
          return {
            success: false,
            duplicate: true,
            message: `Device name already exists.`,
          };
        }
        return {
          success: false,
          message: error.response.data.error || "An error occurred",
        };
      } else {
        return { success: false, message: "Network Error" };
      }
    }
  };

  const handleStreamTypeChange = (event) => {
    setStreamType(event.target.value);
    setFile(null);
  };

  useEffect(() => {
    console.log("progress-upload", progress);
  }, [progress]);

  if (!isOpen) {
    return null;
  }

  return (
    <div
      data-testid="add-video-modal"
      className={` ${
        uploadInProgress ? "hidden" : "fixed"
      } inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50`}
    >
      <div
        data-testid="add-video-container"
        className="relative p-10 bg-white rounded-2xl w-[424px]"
      >
        <button
          data-testid="add-video-close-btn"
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 focus:outline-none"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        <header className="flex flex-col w-full">
          <h1
            data-testid="add-video-title"
            className="text-3xl font-semibold tracking-tighter leading-tight text-gray-900"
          >
            Add Device
          </h1>
          <p
            data-testid="add-video-subtitle"
            className="mt-2 text-sm font-medium leading-none text-gray-500"
          >
            Please enter the following details
          </p>
        </header>

        <form
          data-testid="add-video-form"
          onSubmit={handleSubmit}
          className="flex flex-col mt-12 w-full"
        >
          {/* Device Name */}
          <div className="flex flex-col w-full text-sm font-medium leading-none">
            <label htmlFor="videoName" className="flex gap-0.5 items-start">
              <span className="text-slate-800">Device Name</span>
              <span className="text-red-500">*</span>
            </label>
            <input
              data-testid="add-video-name-input"
              type="text"
              id="videoName"
              required
              onChange={(e) => setVideoName(e.target.value)}
              className="py-4 mt-1 w-full bg-gray-50 px-3 rounded-xl border border-gray-200"
              aria-label="Video name"
            />
          </div>

          {/* Device Type */}
          <div className="flex flex-col w-full mt-6">
            <label
              htmlFor="videoType"
              className="flex gap-0.5 items-start text-sm font-medium"
            >
              <span className="text-slate-800">Type of Device</span>
              <span className="text-red-500">*</span>
            </label>
            <select
              data-testid="add-video-type-select"
              id="videoType"
              value={videoType}
              onChange={handleVideoTypeChange}
              className="py-4 mt-1 w-full bg-gray-50 px-3 rounded-xl border border-gray-200"
              aria-label="Type of Video"
            >
              {options.map((option, index) => (
                <option
                  key={index}
                  value={option}
                  disabled={option === "IoT" || option === "Scada"}
                >
                  {option}
                </option>
              ))}
            </select>
          </div>

          {/* Conditional rendering based on Device Type */}
          {videoType === "Camera" && (
            <div className="flex flex-col w-full mt-6">
              <label
                htmlFor="streamType"
                className="flex gap-0.5 items-start text-sm font-medium"
              >
                <span className="text-slate-800">Stream Type</span>
                <span className="text-red-500">*</span>
              </label>
              <select
                data-testid="add-stream-type-select"
                id="streamType"
                value={streamType}
                onChange={handleStreamTypeChange}
                className="py-4 mt-1 w-full bg-gray-50 px-3 rounded-xl border border-gray-200"
                aria-label="Type of Stream"
              >
                <option value="Recorded">Recorded</option>
                <option value="Live">Live</option>
              </select>
            </div>
          )}

          {(videoType === "Camera" && streamType === "Live") ||
          videoType === "IoT" ||
          videoType === "Scada" ? (
            <div className="flex flex-col w-full mt-6 mb-6">
              <label
                htmlFor="deviceUrl"
                className="flex gap-0.5 items-start text-sm font-medium"
              >
                <span className="text-slate-800">
                  {videoType === "Camera" && streamType === "Live"
                    ? "Device URL"
                    : "Device IP Address"}
                </span>
                <span className="text-red-500">*</span>
              </label>
              <input
                type="url"
                id="deviceUrl"
                value={deviceUrl}
                required
                onChange={(e) => setDeviceUrl(e.target.value)}
                className="py-4 mt-1 w-full bg-gray-50 px-3 rounded-xl border border-gray-200"
                placeholder="Enter device URL/IP"
              />
            </div>
          ) : null}

          {videoType === "Camera" && streamType === "Recorded" && (
            <div className="flex flex-col w-full mt-6 mb-6">
              <label
                data-testid="add-video-upload-label"
                htmlFor="videoFile"
                className="flex gap-0.5 items-start text-sm font-medium"
              >
                <span className="text-slate-800">Upload File (MP4)</span>
                <span className="text-red-500">*</span>
              </label>
              <button
                type="button"
                data-testid="add-video-upload-btn"
                onClick={handleUploadClick}
                className="flex overflow-hidden gap-1 justify-center items-center px-3 py-3.5 mt-1 w-full text-[#052D61] rounded-lg border border-[#052D61] border-solid cursor-pointer"
              >
                {file ? file.name : "Upload"}
              </button>
              <input
                data-testid="add-video-file-input"
                type="file"
                accept="video/*"
                ref={fileInputRef}
                onChange={handleFileChange}
                className="hidden"
              />
            </div>
          )}

          <Button
            text="Submit"
            onClick={handleSubmit}
            loading={loading}
            testId="add-video-submit-btn"
          />
        </form>
      </div>
    </div>
  );
};
