export const CustomMarker = ({
  useCases,
  alertsInTimeFrame,
  growth,
  siteName,
}) => {
  console.log("alertFrame custome", alertsInTimeFrame);

  const colorClass = growth ? "bg-red-700" : "bg-green-700";
  const borderColorClass = "border-gray-300"; // Tailwind class for border
  const blinkingClass = growth ? "animate-blink" : "";

  return (
    <div
      className={`w-9 h-9 rounded-full ${colorClass} ${borderColorClass} border-4 flex items-center justify-center text-white font-bold ${blinkingClass}`}
    >
      {/* {alertsInTimeFrame} */}
    </div>
  );
};
