import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import BarChartIcon from "@mui/icons-material/BarChart";
import Sidebar from "../../components/Sidebar";
import SeverityChart from "../../components/SeverityChart";
import ParetoChart from "../../components/ParetoChart";
import ForkliftViolationChart from "../../components/ControlBarChart";
import ControlCharts from "../../components/Charts/DowtimeMaintenance/ControlChartLoadingUnloading";
import InventoryControlCharts from "../../components/Charts/InventoryManagement/ControlCharts";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import {
  GetAlertTypeCount,
  GetDowntimeD,
  GetSiteEvents,
} from "../../services/apiServiceDetails";
import Select from "react-select";
import { Report } from "@mui/icons-material";

const timeRangeOptions = [
  { value: "24hrs", label: "24 Hours" },
  { value: "1week", label: "1 Week" },
  { value: "1month", label: "1 Month" },
  { value: "1year", label: "1 Year" },
];

export const SixSigmaDashboard = () => {
  const sites = useSelector(
    (state) =>
      state.siteDetails?.sites || JSON.parse(localStorage.getItem("sites"))
  );
  const [selectedDateRange, setSelectedDateRange] = useState(
    timeRangeOptions[0]
  );
  const [selectWarehouse, setSelectWarehouse] = useState({
    value: sites[0]?.id,
    label: sites[0]?.name,
  });
  const [siteSpecificData, setSiteSpecificData] = useState([]);
  const [downtimeData, setDowntimeData] = useState([]);
  const [inventoryData, setInventoryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const tabs = [
    "Safety and Compliance",
    "Downtime and Maintenance",
    "Inventory Management",
  ];
  const [datecounters, setDatecounters] = useState(1);
  const [selectedTab, setSelectedTab] = useState("Safety and Compliance");

  const fetchData = async () => {
    if (!selectWarehouse.value) return;
    setLoading(true);
    try {
      const [siteData, events] = await Promise.all([
        GetAlertTypeCount(selectWarehouse.value, selectedDateRange.value),
        GetSiteEvents(selectWarehouse.value, selectedDateRange.value),
      ]);
      console.log("siteData", siteData, "events", events);
      setSiteSpecificData(siteData);
      setDowntimeData(events);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectWarehouse, selectedDateRange]);

  useEffect(() => {
    if (selectedDateRange.value === "Hours") {
      setDatecounters(24);
    } else {
      setDatecounters(1);
    }
  }, [selectedDateRange]);

  const navItems = [
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/de3b8b5271d8bc1e88c6056582e69f592845a7ad580a06c9846c637a3015e288?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde",
      text: "Active Sites",
      active: false,
      navigate: () => navigate("/home"),
    },
    {
      icon: <BarChartIcon />,
      text: "Reporting Dashboard",
      active: false,
      navigate: () => navigate("/dashboard"),
    },
    {
      icon: <AutoGraphIcon />,
      text: "Operational Dashboard",
      active: true,
      linkTo: "/six-sigma-chart",
      navigate: () => navigate("/six-sigma-chart"),
    },
    {
      icon: <Report size={24} />,
      text: "Incident Report",
      active: false,
      linkTo: "/reports",
      navigate: () => navigate("/reports"),
    },
  ];

  const handleBackClick = () => {
    navigate("/home");
  };

  return (
    <div className="h-screen w-screen bg-gray-100 flex">
      <Sidebar
        navItems={navItems}
        userName={user.name}
        usermail={user.email}
        currentPage="Dashboard"
        selectData={localStorage.getItem("projectname")}
        handleBackClick={handleBackClick}
      />

      <div className="flex flex-col h-[calc(100vh-2rem)] bg-white ml-5 mr-4 my-4 w-full rounded-xl">
        <div className="h-full flex rounded-xl flex-col m-4">
          <div className="bg-white shadow-sm p-6">
            <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center gap-4">
              <div className="flex flex-col">
                <h1
                  className="flex-1 shrink self-stretch my-auto text-3xl font-semibold tracking-tighter leading-tight text-gray-900 basis-10 max-md:max-w-full"
                  data-testid="dashboard-title"
                >
                  Operational Excellence Dashboard for {selectedDateRange.label}{" "}
                  {selectWarehouse ? `- ${selectWarehouse.label}` : ""}
                </h1>
              </div>

              <div className="flex items-center justify-end space-x-4 min-w-[500px]">
                {/* Time Range Filter */}
                <div className="w-40">
                  <Select
                    value={selectedDateRange}
                    onChange={(option) => setSelectedDateRange(option)}
                    options={timeRangeOptions}
                    className="text-sm"
                    classNamePrefix="select"
                    isSearchable={false}
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        borderColor: state.isFocused ? "#052D61" : "#E2E8F0",
                        boxShadow: state.isFocused
                          ? "0 0 0 1px #052D61"
                          : "none",
                        "&:hover": {
                          borderColor: "#052D61",
                        },
                      }),
                      option: (base, state) => ({
                        ...base,
                        backgroundColor: state.isSelected
                          ? "#052D61"
                          : state.isFocused
                          ? "#E8F0F9"
                          : "white",
                        color: state.isSelected ? "white" : "#1F2937",
                        "&:active": {
                          backgroundColor: "#052D61",
                          color: "white",
                        },
                      }),
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "#052D61",
                        primary25: "#E8F0F9",
                        primary50: "#C5D9F2",
                      },
                      borderRadius: 6,
                    })}
                  />
                </div>

                {/* Warehouse Filter */}
                <div className="w-40">
                  <Select
                    value={selectWarehouse}
                    onChange={(option) => setSelectWarehouse(option)}
                    options={
                      Array.isArray(sites)
                        ? sites.map((site) => ({
                            value: site.id,
                            label: site.name,
                          }))
                        : []
                    }
                    className="text-sm"
                    classNamePrefix="select"
                    // placeholder="Select Warehouse"
                    // isClearable
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        borderColor: state.isFocused ? "#052D61" : "#E2E8F0",
                        boxShadow: state.isFocused
                          ? "0 0 0 1px #052D61"
                          : "none",
                        "&:hover": {
                          borderColor: "#052D61",
                        },
                      }),
                      option: (base, state) => ({
                        ...base,
                        backgroundColor: state.isSelected
                          ? "#052D61"
                          : state.isFocused
                          ? "#E8F0F9"
                          : "white",
                        color: state.isSelected ? "white" : "#1F2937",
                        "&:active": {
                          backgroundColor: "#052D61",
                          color: "white",
                        },
                      }),
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "#052D61",
                      },
                      borderRadius: 6,
                    })}
                  />
                </div>
              </div>
            </div>

            <div className="mt-5">
              <div className="inline-flex gap-1 items-center text-sm my-auto text-gray-500 bg-gray-50 rounded-lg border border-gray-200 border-solid">
                <div className="flex p-2" data-testid="tabs-container">
                  {tabs.map((tab) => (
                    <div
                      className={`overflow-hidden gap-2 px-3.5 py-2 my-auto whitespace-nowrap rounded-md cursor-pointer transition-shadow duration-300
                                            ${
                                              selectedTab === tab
                                                ? "font-semibold bg-white border border-slate-300 shadow-[0px_4px_8px_rgba(0,0,0,0.2)] text-slate-700"
                                                : "border border-transparent shadow-none"
                                            }
                                            `}
                      onClick={() => setSelectedTab(tab)}
                      data-testid={`tab-${tab.toLowerCase().replace(" ", "-")}`}
                    >
                      {tab}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white shadow-sm flex-1 overflow-y-auto">
            <div className="p-6 overflow-y-auto h-[calc(100%-56px)]">
              {selectedTab === "Safety and Compliance" ? (
                <div className="grid gap-6">
                  <div className="bg-white rounded-lg border border-gray-200 p-6 hover:shadow-md transition-shadow">
                    <SeverityChart
                      data={siteSpecificData}
                      timeframe={selectedDateRange.value}
                      chartType="safety"
                      loading={loading}
                      selectWarehouse={selectWarehouse}
                    />
                  </div>
                  <div className="bg-white rounded-lg border border-gray-200 p-6 hover:shadow-md transition-shadow">
                    <ParetoChart
                      data={siteSpecificData}
                      timeFrame={selectedDateRange.value}
                      chartType="safety"
                      loading={loading}
                      selectWarehouse={selectWarehouse}
                    />
                  </div>

                  <div className="bg-white rounded-lg border border-gray-200 p-6 hover:shadow-md transition-shadow">
                    <ForkliftViolationChart
                      siteid={selectWarehouse ? selectWarehouse.value : null}
                      timeFrame={selectedDateRange.value}
                      chartType="compliance"
                    />
                  </div>
                </div>
              ) : selectedTab === "Downtime and Maintenance" ? (
                <div className="grid gap-6">
                  <div className="bg-white rounded-lg border border-gray-200 p-6 hover:shadow-md transition-shadow">
                    <ControlCharts
                      data={downtimeData}
                      timeFrame={selectedDateRange.value}
                      siteId={selectWarehouse ? selectWarehouse.value : null}
                    />
                  </div>
                </div>
              ) : (
                <div className="grid gap-6">
                  <div className="bg-white rounded-lg border border-gray-200 p-6 hover:shadow-md transition-shadow">
                    <InventoryControlCharts
                      data={downtimeData}
                      timeFrame={`${selectedDateRange.value}`}
                      siteId={selectWarehouse ? selectWarehouse.value : null}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
