import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Select from 'react-select';
import { rootCauseLabels } from '../api/dropdownData';

const CascadingDropdown = React.memo(({ data, value, onChange }) => {
  const [selections, setSelections] = useState([]);
  const [questions, setQuestions] = useState([rootCauseLabels.level1]);
  const [levels, setLevels] = useState([]);

  // Reset state when data prop changes
  useEffect(() => {
    if (data) {
      const firstLevelOptions = Object.keys(data).map(key => ({
        value: key,
        label: key
      }));
      setLevels([firstLevelOptions]);
      setSelections([]);
      setQuestions([rootCauseLabels.level1]);
    }
  }, [data]);

  const getNextLevelOptions = useCallback((currentData) => {
    if (!currentData || typeof currentData !== 'object') return null;
    
    if (Array.isArray(currentData)) {
      return currentData.map(item => ({ value: item, label: item }));
    }

    const optionsData = currentData.options || currentData;
    return Object.keys(optionsData).map(key => ({
      value: key,
      label: key
    }));
  }, []);

  const handleSelect = useCallback((selected, levelIndex) => {
    console.log('selected', selected, 'levelIndex', levelIndex);
    if (!selected) {
      const newSelections = selections.slice(0, levelIndex);
      const newLevels = levels.slice(0, levelIndex + 1);
      const newQuestions = questions.slice(0, levelIndex + 1);
      
      setSelections(newSelections);
      setLevels(newLevels);
      setQuestions(newQuestions);
      console.log('newSelections', newSelections);
      onChange(newSelections.map(s => s.value));
      return;
    }

    const newSelections = [...selections.slice(0, levelIndex), selected];
    let currentData = data;
    let nextQuestion = null;

    // Navigate to current selection's data
    for (let i = 0; i <= levelIndex; i++) {
      const selection = newSelections[i];
      if (i === levelIndex) {
        nextQuestion = currentData[selection.value]?.nextQuestion;
        currentData = currentData[selection.value]?.options;
      } else {
        currentData = currentData[selection.value]?.options;
      }
    }

    const newQuestions = [...questions.slice(0, levelIndex + 1)];
    if (nextQuestion) {
      newQuestions.push(nextQuestion);
    }

    const nextLevelOptions = getNextLevelOptions(currentData);
    const newLevels = [...levels.slice(0, levelIndex + 1)];
    if (nextLevelOptions) {
      newLevels.push(nextLevelOptions);
    }

    setSelections(newSelections);
    setQuestions(newQuestions);
    setLevels(newLevels);
    onChange(newSelections.map(s => s.value));
  }, [data, levels, selections, questions, onChange, getNextLevelOptions]);

  const customStyles = useMemo(() => ({
    control: (base, state) => ({
      ...base,
      minHeight: '42px',
      borderRadius: '0.375rem',
      borderColor: state.isFocused ? '#2563eb' : '#d1d5db',
      boxShadow: state.isFocused ? '0 0 0 1px #2563eb' : 'none',
      '&:hover': {
        borderColor: state.isFocused ? '#2563eb' : '#9ca3af'
      }
    }),
    menu: (base) => ({
      ...base,
      zIndex: 9999
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? '#2563eb' : state.isFocused ? '#f3f4f6' : 'white',
      color: state.isSelected ? 'white' : '#111827',
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      '&:active': {
        backgroundColor: '#2563eb',
        color: 'white'
      }
    }),
    input: (base) => ({
      ...base,
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      color: '#111827'
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      color: '#6b7280'
    }),
    singleValue: (base) => ({
      ...base,
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      color: '#111827'
    }),
    valueContainer: (base) => ({
      ...base,
      padding: '6px 12px'
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: '6px'
    })
  }), []);

  // Create pairs of dropdowns
  const dropdownPairs = useMemo(() => {
    const pairs = [];
    for (let i = 0; i < levels.length; i += 2) {
      pairs.push(levels.slice(i, i + 2));
    }
    return pairs;
  }, [levels]);

  return (
    <div className="space-y-4">
      {dropdownPairs.map((pair, pairIndex) => (
        <div key={pairIndex} className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {pair.map((options, index) => {
            const levelIndex = pairIndex * 2 + index;
            return (
              <div key={levelIndex} className="flex flex-col space-y-2">
                <label className="text-sm font-medium text-gray-700">
                  {questions[levelIndex] || `Level ${levelIndex + 1}`}
                </label>
                <Select
                  value={selections[levelIndex] || null}
                  onChange={(selected) => handleSelect(selected, levelIndex)}
                  options={options}
                  isClearable
                  isSearchable
                  styles={customStyles}
                  className="flex-grow"
                  placeholder="Select an option..."
                />
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
});

CascadingDropdown.displayName = 'CascadingDropdown';

export default CascadingDropdown;
