import React, { useRef, useEffect } from "react";

const OptionsMenu = ({
  showOptions,
  closeOptions,
  options,
  anchorPosition,
}) => {
  const optionsRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (optionsRef.current && !optionsRef.current.contains(event.target)) {
        closeOptions();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [closeOptions]);

  if (!showOptions) {
    return null;
  }

  return (
    <div
      ref={optionsRef}
      style={{
        top: anchorPosition?.top + 40 || "0.5rem",
        right: anchorPosition?.right || "0.5rem",
        minWidth: "100px",
      }}
      className="absolute right-0 mt-8 bg-white rounded-md shadow-lg"
    >
      <ul className="py-2 text-gray-900">
        {options.map((option, index) => (
          <li
            key={index}
            data-testid={option.testid}
            className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              option.onClick();
            }}
          >
            {option.label}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default OptionsMenu;
