import React, { useRef, useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { generateThumbnail } from "../utils/generateThumbnail";

const VideoThumbnail = ({ videoUrl }) => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const captureThumbnail = () => {
      const video = videoRef.current;
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");

      // Set crossOrigin for CORS handling before setting the src
      if (videoUrl.startsWith("http")) {
        video.crossOrigin = "anonymous";
      }

      const handleSeeked = () => {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

        try {
          // Attempt to create a data URL from the canvas
          generateThumbnail(videoUrl).then((thumbnail) => {
            setThumbnail(thumbnail);
          });
        } catch (e) {
          setThumbnail("https://picsum.photos/200");
          // console.log(
          //"Canvas is tainted and cannot be converted to data URL:",
          //e
          //);
        }
      };

      if (video && canvas && ctx) {
        video.currentTime = 1; // Capture a frame at 1 second
        video.addEventListener("seeked", handleSeeked, { once: true });
      }
    };

    if (videoUrl) {
      captureThumbnail();
    }
  }, [videoUrl]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div
      className="w-full overflow-hidden h-full bg-black"
      data-testid="video-thumbnail"
    >
      <video
        ref={videoRef}
        src={videoUrl}
        style={{ display: "none" }}
        data-testid="video-element"
      />
      <canvas
        ref={canvasRef}
        style={{ display: "none" }}
        data-testid="canvas-element"
      />
      <div
        className="w-full h-full overflow-hidden rounded-md bg-black z-50"
        style={{
          position: "relative",
          cursor: "pointer",
        }}
        onClick={handleOpen}
        data-testid="thumbnail-container"
      >
        {thumbnail ? (
          <img
            crossOrigin="anonymous"
            loading="lazy"
            src={thumbnail ? thumbnail : "https://picsum.photos/200"}
            alt={"stream"}
            className="w-full h-full object-cover"
            data-testid="thumbnail-image"
          />
        ) : (
          <div
            className="w-full h-48 bg-gray-200 flex items-center justify-center"
            data-testid="loading-placeholder"
          >
            Loading...
          </div>
        )}
        <IconButton
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          data-testid="play-button"
        >
          <PlayCircleOutlineIcon
            fontSize="large"
            style={{
              color: "white",
            }}
          />
        </IconButton>
        <div
          style={{
            position: "absolute",
            top: "5px",
            right: "5px",
            width: "53px",
            height: "53px",
            background: "rgba(255, 255, 255, 0.2)", // semi-transparent white
            backdropFilter: "blur(10px)", // blur for the glass effect
            borderRadius: "15px", // rounded corners
            boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)", // subtle shadow for depth
            border: "1px solid rgba(255, 255, 255, 0.3)",
            padding: "3px",
          }}
        >
          <img
            height={50}
            width={50}
            className=" mx-auto my-auto"
            src="/recording-camera-icon-or-video-vector-45246520-removebg-preview.png"
          />
        </div>
      </div>
      <Modal open={open} onClose={handleClose} data-testid="video-modal">
        <div style={{ position: "relative", paddingTop: "56.25%", height: 0 }}>
          <IconButton
            onClick={handleClose}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              zIndex: 1,
            }}
          >
            <CloseIcon />
          </IconButton>
          <video
            controls
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
            data-testid="modal-video"
          >
            <source src={videoUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </Modal>
    </div>
  );
};

export default VideoThumbnail;
