import React, { useCallback, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import { Login } from "./pages/Login";
import { Dashboard } from "./pages/Dashboard";
import { SiteDetails } from "./pages/SiteDetails";
import { GraphVisualize } from "./pages/Visualize/GraphVisualize";
import { Visualize } from "./pages/Visualize";
import GroupedChartDetails from "./pages/Visualize/GroupedChartDetails";
import { Projects, ProjectDetails } from "./pages/Projects";
import { Videos } from "./pages/Videos";
import PrivateRoute from "./utils/ProtectedRoute";
import { ModelDashboard } from "./pages/Projects/ModelDashboard";
import {
  BUTTON_TEXT,
  MODAL_MESSAGES,
  STATUS_TYPES,
  WEB_SOCKET_URL,
} from "./utils/constants";
import { WebSocketProvider } from "./context/WebSocketContext";
import { useDispatch } from "react-redux";
import { setTrainingStatus } from "./redux/actions/trainingStatusActions";
import useSession from "./hooks/useSession";
import SafetyDashboard from "./pages/Visualize/SafetyDashboard";
import { SixSigmaDashboard } from "./pages/Visualize/SixSigma";
import FFmpeg from "./pages/ffmpeg";
import ReportsDashboard from "./pages/Reports/ReportsDashboard";
import { getAllSites } from "./services/apiServiceDetails";
import { setSiteNames } from "./redux/actions/siteActions";

export default function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchSites(){
      let accountID = localStorage.getItem("account_id");
      const data = await getAllSites(accountID);
      dispatch(setSiteNames(data));
      localStorage.setItem("sites", JSON.stringify(data));
    }
    fetchSites();
  }, []);

  const onMessageCallback = useCallback(
    (data) => {
      console.log("Received message:", data);
      const projectId = data.project_id;

      if (data.status === "completed") {
        dispatch(
          setTrainingStatus(
            projectId,
            STATUS_TYPES.SUCCESS.TRAINING_COMPLETE,
            MODAL_MESSAGES[STATUS_TYPES.SUCCESS.TRAINING_COMPLETE],
            BUTTON_TEXT.TEST_MODEL,
            () => console.log("Start Detection clicked")
          )
        );
      } else if (data.status === "in_progress") {
        dispatch(
          setTrainingStatus(
            projectId,
            STATUS_TYPES.PROGRESS.GOAL_DETECTION,
            MODAL_MESSAGES[STATUS_TYPES.PROGRESS.GOAL_DETECTION]
          )
        );
      } else if (data.status === "Error") {
        dispatch(
          setTrainingStatus(
            projectId,
            STATUS_TYPES.ERROR.GENERAL_ERROR,
            MODAL_MESSAGES[STATUS_TYPES.ERROR.GENERAL_ERROR],
            () => console.log("Error message closed")
          )
        );
      }
    },
    [dispatch]
  );

  return (
    <WebSocketProvider
      url={WEB_SOCKET_URL}
      onMessageCallback={onMessageCallback}
    >
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/home" />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/home"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/reports"
            element={
              <PrivateRoute>
                <ReportsDashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/site-details/:siteid"
            element={
              <PrivateRoute>
                <Outlet />
              </PrivateRoute>
            }
          >
            <Route path=":project">
              <Route index element={<Projects />} />
              <Route path=":projectid" element={<ProjectDetails />} />
              <Route
                path=":projectid/detections"
                element={<Visualize />} // temporarily added project details here to confirm that visualization odesnot appera on ui
              />
              <Route
                path=":projectid/model-dashboard"
                element={<ModelDashboard />}
              />
            </Route>
            <Route path="videos" element={<Videos />} />
          </Route>
          <Route
            path="/visualization"
            element={
              <PrivateRoute>
                <Visualize />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <GraphVisualize />
              </PrivateRoute>
            }
          />
          <Route
            path="/six-sigma-chart"
            element={
              <PrivateRoute>
                <SixSigmaDashboard />
              </PrivateRoute>
            }
          />
          {/* <Route
            path="/dash"
            element={
              <SafetyDashboard />
            }
          />
          <Route
            path="/barChart"
            element={
              <PrivateRoute>
                <GroupedChartDetails />
              </PrivateRoute>
            }
          /> */}
          <Route
            path="*"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
        </Routes>
      </Router>
    </WebSocketProvider>
  );
}
