import { X } from "lucide-react";
import React from "react";

const Stepper = ({ steps, currentStep, heading, onClose }) => {
  return (
    <>
      {heading && (
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-bold">{heading}</h2>
          <button
            className="text-gray-600 hover:text-gray-800 float-right"
            onClick={onClose}
          >
            <X size={24} />
          </button>
        </div>
      )}
      <div className="flex items-center m-2">
        {steps.map((step, index) => (
          <div key={index} className="flex-1">
            <div
              className={`h-2 rounded-full mb-2 ${
                index <= currentStep ? "bg-primary" : "bg-gray-200"
              }`}
            ></div>
            <div
              className={`text-center text-sm ${
                index === currentStep
                  ? "text-primary font-bold"
                  : "text-gray-500"
              }`}
            >
              {step.title}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Stepper;
