import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";
import { STATUS_TYPES } from "../utils/constants";
import { StopCircle } from "@mui/icons-material";

const Tooltip = ({ message, isVisible }) => {
  return (
    isVisible && (
      <div className="absolute bg-gray-700 text-white text-sm rounded-md p-2 z-10">
        {message}
      </div>
    )
  );
};

const HeaderWithButton = ({
  title,
  buttonText,
  onButtonClick,
  detectionStatus,
  maxDetection,
  activeDetection,
  isExist,
  testid,
  type,
  detectionLoading,
  setDetectionStatus,
  streammodal,
  assosUpdated,
  currentPage,
  detectionStopinging,
  ...props
}) => {
  console.log("Props", type);
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    if (currentPage === "Project-Details" && activeDetection >= maxDetection) {
      setShowTooltip(true);
    }
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };
  // const trainingStatus = useSelector((state) => state.trainingStatus);
  // useEffect(() => {
  //   console.log("Training", trainingStatus);
  // }, [trainingStatus]);

  return (
    <div
      data-testid={testid}
      className="flex flex-wrap gap-4 items-center w-full max-md:max-w-full"
    >
      <h1
        data-testid="header-title"
        className="flex-1 shrink self-stretch my-auto text-3xl font-semibold tracking-tighter leading-tight text-gray-900 basis-10 max-md:max-w-full font-suse"
      >
        {title}
      </h1>
      {/* {isExist ? (       */}
      {streammodal?.text === "project-details-stream-modal" && (
        <button
          data-testid="openStream-button"
          onClick={streammodal.openStream.openStreamModal}
          className={`flex overflow-hidden ${
            !assosUpdated ? "hidden" : ""
          } gap-2 justify-center items-center self-stretch px-5 py-4 my-auto text-sm font-semibold leading-none text-center text-gray-50 rounded-xl border border-[#052D61] border-solid`}
        >
          <span className="self-stretch my-auto text-[#052D61]">
            Connect Device
          </span>
        </button>
      )}
      <>
        <button
          data-testid="main-button"
          onClick={
            currentPage === "Project-Details" &&
            activeDetection >= maxDetection &&
            !detectionStatus
              ? null
              : onButtonClick
          }
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          disabled={currentPage === "Project-Details" && !assosUpdated}
          title={
            !assosUpdated && currentPage === "project-details"
              ? "Please connect your streams before you can start detections or training the model"
              : ""
          }
          className={`flex gap-2 justify-center ${
            currentPage === "Project-Details" && !assosUpdated
              ? "bg-gray-400 text-gray-600 "
              : currentPage === "Project-Details" && detectionStatus
              ? "bg-orange-500 "
              : "bg-[#052D61]"
          } items-center self-stretch px-5 py-4 my-auto text-sm font-semibold leading-none text-center text-gray-50 rounded-xl relative group`}
        >
          {currentPage === "Project-Details" && detectionStatus && (
            <StopCircle className=" text-white" />
          )}
          {currentPage === "Project-Details" &&
            activeDetection >= maxDetection &&
            showTooltip &&
            !detectionStatus && (
              <span className=" w-[200px] z-50 absolute bottom-[-50px] left-0-2 px-3 py-1 text-xs text-gray-800 bg-gray-200 border border-gray-300 rounded-md shadow-lg">
                Sorry, you have reached your detections limit
              </span>
            )}

          <span className="relative">
            {currentPage === "Project-Details" && detectionLoading ? (
              <span className="flex items-center justify-center">
                <svg
                  className="animate-spin h-5 w-5 mr-2 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                  ></path>
                </svg>
                Starting Detections
              </span>
            ) : currentPage === "Project-Details" && detectionStopinging ? (
              <span className="flex items-center justify-center">
                <svg
                  className="animate-spin h-5 w-5 mr-2 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                  ></path>
                </svg>
                Stopping Detections
              </span>
            ) : (
              buttonText
            )}
          </span>
        </button>
        {type?.text === "project-details" && (
          <>
            <button
              data-testid="retrain-button"
              onClick={type.retrain.openModal}
              disabled={
                !assosUpdated ||
                detectionStatus ||
                detectionStopinging ||
                detectionLoading
              }
              className={`flex overflow-hidden gap-2 justify-center ${
                currentPage === "Project-Details" &&
                (!assosUpdated ||
                  detectionStatus ||
                  detectionStopinging ||
                  detectionLoading)
                  ? "bg-gray-400 text-gray-600"
                  : "text-[#052D61]"
              } items-center self-stretch px-5 py-4 my-auto text-sm font-semibold leading-none text-center rounded-xl border border-[#052D61] border-solid`}
              title={
                !assosUpdated && currentPage === "project-details"
                  ? "Please connect your streams before you can start detections or training the model"
                  : ""
              }
            >
              <span className="self-stretch my-auto font-golos">
                Train Model
              </span>
            </button>
          </>
        )}
      </>
      {/* // ) : (null)} */}
    </div>
  );
};

export default HeaderWithButton;
