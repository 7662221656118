import React, { useState, useEffect } from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

function TrendsSection({
  alertData,
  selectedDateRange,
  selectWarehouse,
  selectCategories,
  recentAlertData,
}) {
  const [recentAlert, setRecentAlert] = useState([]);
  const [trendData, setTrendData] = useState([
    { title: "Safety Incidents", value: "", change: 0, positive: false },
  ]);

  useEffect(() => {
    let incident = {};
    // console.log(
    //   "Trends section alert data",
    //   alertData,
    //   "\n alertData[selectedDateRange], ",
    //   alertData[selectedDateRange],
    //   "\n selectWarehouse",
    //   selectWarehouse,
    //   "\n selectCategories",
    //   selectCategories
    // );
    if (
      alertData[selectedDateRange] &&
      selectCategories.some(
        (useCase) =>
          useCase.includes("PPE") ||
          useCase.includes("Safety") ||
          useCase.includes("Incident") ||
          useCase.includes("Forklift") ||
          useCase.includes("Person Intrusion")
      )
    ) {
      incident = alertData[selectedDateRange].filter(
        (item) => item.siteName === selectWarehouse
      );
      // console.log("Trends section alert data Incident", incident);
    }

    if (incident.length > 0) {
      const currentAlerts = incident[0].alertsInTimeFrame;
      const totalAlerts = incident[0].previousAlerts;
      const previous24HoursAlerts = totalAlerts - currentAlerts;
      const denominator = previous24HoursAlerts || 1;
      const change = Math.ceil(
        ((currentAlerts - previous24HoursAlerts) / denominator) * 100
      );

      // console.log(
      //   "Trends section alert data Incident",
      //   currentAlerts,
      //   previous24HoursAlerts,
      //   change
      // );

      setTrendData((prevData) =>
        prevData.map((item) =>
          item.title === "Safety Incidents"
            ? {
                ...item,
                value: Math.abs(currentAlerts),
                change: Math.abs(change),
                positive: change > 0,
              }
            : item
        )
      );
    } else {
      setTrendData((prevData) =>
        prevData.map((item) =>
          item.title === "Safety Incidents"
            ? { ...item, value: 0, change: 0, positive: false }
            : item
        )
      );
    }
  }, [alertData, selectedDateRange, selectWarehouse, selectCategories]);

  const filterAlerts = (data, siteName, useCaseNames) => {
    return data.filter(
      (site) =>
        site.site_name === siteName &&
        useCaseNames.some((useCase) => site.use_case_name.includes(useCase))
    );
  };

  useEffect(() => {
    let recent_Alerts;
    if (Array.isArray(recentAlertData[selectedDateRange])) {
      recent_Alerts = filterAlerts(
        recentAlertData[selectedDateRange],
        selectWarehouse,
        selectCategories
      );

      if (recent_Alerts && recent_Alerts.length > 0) {
        const processedAlerts = recent_Alerts
          .filter((item) => item.alerts !== null)
          .flatMap((item) =>
            item.alerts.map((alert) => ({
              type: alert.alert_type,
              date: new Date(alert.created_at).toISOString(),
            }))
          )
          .sort((a, b) => new Date(b.date) - new Date(a.date));

        setRecentAlert(processedAlerts);
      } else {
        setRecentAlert([]);
      }
    }
  }, [recentAlertData, selectedDateRange, selectWarehouse, selectCategories]);

  return (
    <div className="flex flex-col h-full w-[25%] font-golos">
      <div className="flex flex-col h-full w-full divide-y gap-6">
        {/* Recent Alerts Section */}
        <div className="flex-grow w-full overflow-hidden text-xs px-4 py-4 rounded-lg border border-gray-200">
          <div className="flex flex-col w-full">
            <h3 className="text-sm font-medium text-gray-900 font-suse">
              Trends
            </h3>
            <div className="flex flex-col mt-4">
              {trendData.map((trend, index) =>
                trend.value ? (
                  <div
                    key={index}
                    className="p-4 w-full bg-white rounded-lg border-2 mt-2"
                  >
                    <div className="text-sm text-slate-800 font-suse">
                      {trend.title}
                    </div>
                    <div className="flex gap-2 mt-3">
                      <div className="text-3xl text-gray-900">
                        {trend.value}
                      </div>
                      <div
                        className={`flex gap-0.5 p-1 text-xs font-bold rounded-md ${
                          trend.positive
                            ? "text-red-600 bg-red-50"
                            : "text-emerald-600 bg-emerald-50"
                        }`}
                      >
                        {trend.positive ? (
                          <ArrowUpwardIcon className="text-red-600" />
                        ) : (
                          <ArrowDownwardIcon className="text-emerald-600" />
                        )}
                        <div>{trend.change}%</div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div key={index} className="text-center text-gray-500 p-4">
                    No data available
                  </div>
                )
              )}
            </div>
          </div>
        </div>
        <aside className="flex-grow w-full h-[40%] overflow-hidden relative px-4 py-4 rounded-lg border border-gray-200">
          <h2 className="gap-2 px-3 pb-1 text-sm font-semibold text-gray-900 font-suse">
            Recent Alerts
          </h2>
          <div className="overflow-y-auto max-h-full">
            {recentAlert?.length === 0 ? (
              <div className="text-center text-gray-500 p-4">
                No data available
              </div>
            ) : (
              recentAlert.map((alert, index) => {
                const alertDate = new Date(alert.date);
                const formattedDate = alertDate.toLocaleString(undefined, {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "numeric",
                  minute: "2-digit",
                  second: "2-digit",
                  hour12: true,
                });
                return (
                  <div key={index} className="flex flex-col px-3 py-4 text-xs">
                    <h3 className="font-medium text-gray-900">{alert.type}</h3>
                    <div className="flex justify-between mt-2 text-gray-500">
                      <span>{formattedDate}</span>
                      {alert.hasActions && (
                        <div className="flex gap-2">
                          <img
                            loading="lazy"
                            src="http://b.io/ext_24-"
                            className="w-4"
                            alt=""
                          />
                          <img
                            loading="lazy"
                            src="http://b.io/ext_25-"
                            className="w-4"
                            alt=""
                          />
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </aside>

        {/* Trends Section */}
      </div>
    </div>
  );
}

export default TrendsSection;
