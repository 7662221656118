import { Logout } from "@mui/icons-material";
import React from "react";
import LogoutIcon from '@mui/icons-material/Logout';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from "react-router-dom";

function UserProfile({ userName, usermail, isCollapsed }) {
  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem("session");
    window.location.reload();
  };
  return (
    <div
      className="flex flex-col mt-10 w-full rounded-xl max-w-[256px]"
      data-testid="user-profile"
    >
      <div
        className="flex gap-2 items-center justify-center w-full"
        data-testid="profile-header"
      >

      <Tooltip title={userName}  >
        <div
          className="flex items-center justify-center w-8 h-8 bg-teal-500 text-gray-50 text-xl font-semibold rounded-full shadow-sm"
          style={{ backgroundColor: "#009688" }}
          data-testid="user-initial"
        >
          {userName.charAt(0).toUpperCase()}
        </div>
      </Tooltip>

        {
          !isCollapsed && (        
          <div
            className="flex flex-col flex-1 shrink justify-center self-stretch my-auto font-medium basis-0"
            data-testid="user-info"
          >
            <div
              className="text-sm leading-none text-gray-900 text-ellipsis"
              data-testid="user-name"
            >
              {userName}
            </div>
            <div
              className="text-xs leading-none text-gray-400 text-ellipsis"
              data-testid="user-email"
            >
              {usermail}
            </div>
          </div>)
        }

      </div>
      {
        !isCollapsed ? (      
        <button
          onClick={() => navigate("/login")}
          className="overflow-hidden gap-2 self-stretch p-4 mt-4 w-full text-sm font-semibold tracking-normal leading-none text-center text-gray-900 bg-gray-200 rounded-lg"
          data-testid="logout-button"
        >
          Log out
        </button>) : (
                  <Tooltip 
                    title="Log out" 
                    arrow
                    sx={{
                      backgroundColor: 'black', // Change the background color
                      color: 'white',           // Change the text color
                      fontSize: '0.875rem',     // Adjust font size
                      padding: '0.5rem',        // Add padding
                      '& .MuiTooltip-arrow': {
                        color: 'black',         // Arrow color
                      },
                    }}
                  >
                    <div className="flex items-center p-4 cursor-pointer" onClick={void(0)}>
                      <LogoutIcon className="text-gray-500" />
                    </div>
                  </Tooltip>
               
        )
      }

    </div>
  );
}

export default UserProfile;
