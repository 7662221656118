import { MODAL_MESSAGES, BUTTON_TEXT } from "../../utils/constants";
import {
  SET_TRAINING_STATUS,
  RESET_TRAINING_STATUS,
  SET_MODAL_STATUS,
} from "../actions/trainingStatusActions";

const initialState =
  JSON.parse(localStorage.getItem("processStatusArray")) || {};

// const initialStateProgressGoalDetection = {
//   status: MODAL_STATUS.PROGRESS,
//   message: MODAL_MESSAGES.PROGRESS.GOAL_DETECTION,
//   buttonText: null,
//   onButtonClick: null,
// };
// const initialStateProgressModelTraining = {
//   status: MODAL_STATUS.PROGRESS,
//   message: MODAL_MESSAGES.PROGRESS.MODEL_TRAINING,
//   buttonText: null,
//   onButtonClick: null,
// };
// const initialStateSuccessTrainingComplete = {
//   status: MODAL_STATUS.SUCCESS,
//   message: MODAL_MESSAGES.SUCCESS.TRAINING_COMPLETE,
//   buttonText: BUTTON_TEXT.TEST_MODEL,
//   onButtonClick: () => console.log("Test Model clicked"),
// };
// const initialStateSuccessDetectionComplete = {
//   status: MODAL_STATUS.SUCCESS,
//   message: MODAL_MESSAGES.INFO.DETECTION_COMPLETE,
//   buttonText: BUTTON_TEXT.REMOVE_INCORRECT_LABELS,
//   onButtonClick: () => console.log("Remove Incorrect Labels clicked"),
// };
// const initialStateErrorGeneralError = {
//   status: MODAL_STATUS.ERROR,
//   message: MODAL_MESSAGES.ERROR.GENERAL_ERROR,
//   buttonText: null,
//   onButtonClick: null,
// };

const trainingStatusReducer = (state = initialState, action) => {
  const { projectId } = action.payload || {};

  switch (action.type) {
    case SET_TRAINING_STATUS:
      console.log("ts", action.payload);
      return {
        ...state,
        [projectId]: {
          status: action.payload.status,
          message: action.payload.message,
          buttonText: action.payload.buttonText || null,
          onButtonClick: action.payload.onButtonClick || null,
          modalStatus: action.payload.modalStatus,
        },
      };

    case SET_MODAL_STATUS:
      console.log("payload:", action.payload);
      return {
        ...state,
        [projectId]: {
          ...state[projectId],
          modalStatus: action.payload.status,
        },
      };

    case RESET_TRAINING_STATUS:
      return {
        ...state,
        [projectId]: {
          status: null,
          message: null,
          buttonText: null,
          onButtonClick: null,
        },
      };

    default:
      return state;
  }
};
export default trainingStatusReducer;
