import React, { useState } from "react";
import ImageDrawer from "./ImageDrawModal";
import DimensionsDrawer from "./RoiDetails";
export const InterestAreaModal = ({
  isOpen,
  onClose,
  onSave,
  points,
  setPoints,
  imageSrc,
  assosID,
  required_roi,
  showRoiDetails,
  roiDimensions,
  setRoiDimensions,
  setShowRoiDetails,
  onClick,
  onBack,
  usecaseType,
  flowType,
  checkboxStates,
  setCheckboxStates,
}) => {
  if (!isOpen) return null;
  console.log(points);
  console.log("required_roi", required_roi, roiDimensions);

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-100"
      data-testid="interest-area-modal"
    >
      <div className="bg-white rounded-lg shadow-xl w-[55vw]">
        <div
          className="flex justify-between items-center p-6 border-b"
          data-testid="modal-header"
        >
          {!showRoiDetails &&
          usecaseType ===
            "Forklift Safety & Compliance, Downtime & Maintenance" ? (
            <h2 className="text-xl font-bold" data-testid="modal-title">
              Draw Interest Area And Calibrate Forklift Movement
            </h2>
          ) : (
            <h2 className="text-xl font-bold" data-testid="modal-title">
              Draw Interest Area
            </h2>
          )}
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
            data-testid="modal-close-button"
          >
            <svg
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className="p-6">
          {!showRoiDetails &&
          usecaseType ===
            "Forklift Safety & Compliance, Downtime & Maintenance" ? (
            <p className="text-gray-600 mb-4" data-testid="modal-description">
              Mark discrete points and provide distance measurements to
              calibrate the system to determine forklift's movement
            </p>
          ) : usecaseType === "Safety & Compliance (Intrusion)" ? (
            <p className="text-gray-600 mb-4" data-testid="modal-description">
              Start drawing arross the video to mark the restricted area. -
              where you think you'd be able to capture the detections for the
              stream/video.
            </p>
          ) : usecaseType === "Inventory Management Efficiency" ? (
            <p className="text-gray-600 mb-4" data-testid="modal-description">
              please draw area of interest for all of the below Interest areas
              (picking, packing, shipping)
            </p>
          ) : (
            <p className="text-gray-600 mb-4" data-testid="modal-description">
              Start drawing across the video to mark an area of interest - where
              you think you'd be able to capture the detections for the
              stream/video.
            </p>
          )}

          <div
            className="h-100 mb-4 relative"
            data-testid="image-drawer-container"
          >
            {usecaseType ===
              "Forklift Safety & Compliance, Downtime & Maintenance" &&
            showRoiDetails ? (
              <DimensionsDrawer
                imageSrc={imageSrc}
                roiDimensions={roiDimensions}
                setRoiDimensions={setRoiDimensions}
                assosID={assosID}
                allstream={required_roi}
              />
            ) : (
              <ImageDrawer
                imageSrc={imageSrc}
                boundingBoxes={points}
                setBoundingBoxes={setPoints}
                assosID={assosID}
                allstream={required_roi}
                checkboxStates={checkboxStates}
                setCheckboxStates={setCheckboxStates}
                usecaseType={usecaseType}
              />
            )}
          </div>
          <div className="flex justify-start space-x-2">
            {usecaseType ===
              "Forklift Safety & Compliance, Downtime & Maintenance" &&
              showRoiDetails && (
                <button
                  onClick={onBack}
                  className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
                  data-testid="cancel-button"
                >
                  Back
                </button>
              )}
            <button
              className="px-4 py-2 bg-[#052D61] text-white rounded-md hover:bg-blue-700"
              onClick={
                usecaseType ===
                  "Forklift Safety & Compliance, Downtime & Maintenance" &&
                !showRoiDetails
                  ? onClick
                  : onSave
              }
              data-testid="save-button"
            >
              {usecaseType ===
                "Forklift Safety & Compliance, Downtime & Maintenance" &&
              !showRoiDetails
                ? "Next"
                : "Save"}
            </button>
            <button
              onClick={onClose}
              className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
              data-testid="cancel-button"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
