import React, { useMemo } from 'react';
import RadioGroup from './RadioGroup';
import Select from "react-select";
import CascadingDropdown from './Forms/CascadingDropdown';
import { incidentLevels } from './api/dropdownData';

const FormInput = ({ label, type, name, value, onChange, options, optionsMap, placeholder, isReadOnly, showConditional }) => {
  const baseClasses =
    "w-full border border-gray-300 rounded-md px-4 py-2 transition duration-200 focus:ring-2 focus:ring-blue-500 focus:outline-none disabled:bg-gray-100";
  
    const customStyles = useMemo(() => ({
      control: (base, state) => ({
        ...base,
        minHeight: '42px',
        borderRadius: '0.375rem',
        borderColor: state.isFocused ? '#052D61' : 'grey',
        boxShadow: state.isFocused ? '0 0 0 1px #052D61' : 'none',
        '&:hover': {
          borderColor: state.isFocused ? '#052D61' : 'grey'
        }
      }),
      menu: (base) => ({
        ...base,
        zIndex: 9999
      }),
      option: (base, state) => ({
        ...base,
        backgroundColor: state.isSelected
          ? '#052D61'
          : state.isFocused
          ? '#DBEAFE'
          : 'white',
        color: state.isSelected ? 'white' : 'black',
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
        '&:active': {
          backgroundColor: '#052D61',
          color: 'white'
        }
      }),
      input: (base) => ({
        ...base,
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
        color: '#052D61'
      }),
      placeholder: (base) => ({
        ...base,
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
        color: 'black'
      }),
      singleValue: (base) => ({
        ...base,
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
        color: '#052D61'
      }),
      valueContainer: (base) => ({
        ...base,
        padding: '6px 12px'
      }),
      dropdownIndicator: (base) => ({
        ...base,
        padding: '6px'
      })
    }), []);
    

  const selectOptions = options?.map((option) => ({ value: option, label: option }));

  switch (type) {
    case "cascading":
      return (
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-2">{label}</label>
          <CascadingDropdown
            data={incidentLevels}
            value={value}
            onChange={(selections) => onChange({ target: { name, value: selections } })}
          />
        </div>
      );
    case "radio":
      return (
        <div className="flex flex-wrap mb-4 gap-10">
          <label className="block text-sm font-medium text-gray-700">{label}</label>
          <RadioGroup
            name={name}
            value={value}
            onChange={onChange}
            disabled={isReadOnly}
          />
        </div>
      );
    case "rca":
      return (
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">{label}</label>
          <select
            name={name}
            value={value}
            onChange={onChange}
            className={baseClasses}
            disabled={isReadOnly}
          >
            <option value="">Select...</option>
            {options?.map((option, index) => (
              <option 
                key={index} 
                value={option}
                data-id={optionsMap?.[option]}
              >
                {option}
              </option>
            ))}
          </select>
        </div>
      )
    case "select":
      return (
        <>
        <label className="block text-sm font-medium text-gray-700 mb-1.5">{label}</label>
          <Select
            name={label}
            options={selectOptions}
            styles={customStyles}
            value={selectOptions?.find((option) => option.value === value)}
            onChange={(selectedOption) => onChange({ target: { name, value: selectedOption.value } })}
            placeholder={placeholder}
            isDisabled={isReadOnly}
          />
        </>
      );
    case "textarea":
      return (
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-2">{label}</label>
          <textarea
            className={baseClasses}
            value={value}
            name={name}
            onChange={onChange}
            placeholder={placeholder}
            disabled={isReadOnly}
            rows="3"
          ></textarea>
        </div>
      );
    default:
      return (
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-2">{label}</label>
          <input
            type={type}
            name={name}
            className={baseClasses}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            disabled={isReadOnly}
          />
        </div>
      );
  }
};

export default FormInput;