import React from 'react';
import { motion } from 'framer-motion';

const TableLoader = () => {
  const shimmerRows = Array(8).fill(null);

  const ShimmerRow = ({ delay }) => (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay }}
      className="flex items-center py-4 relative overflow-hidden bg-gray-50/50"
    >
      <div
        className="absolute inset-0 -translate-x-full animate-[shimmer_2s_infinite]"
        style={{
          background: 'linear-gradient(90deg, transparent 0%, rgba(255,255,255,0.8) 50%, transparent 100%)',
        }}
      />
      
      {/* ID Column */}
      <div className="px-6 w-24">
        <div className="h-5 bg-gray-200 rounded"></div>
      </div>
      
      {/* Title Column */}
      <div className="px-6 w-24 flex-1">
        <div className="h-5 bg-gray-200 rounded"></div>
      </div>
      
      {/* Created Column */}
      <div className="px-6 w-48 flex-1">
        <div className="h-5 bg-gray-200 rounded"></div>
      </div>
      
      {/* Status Column */}
      <div className="px-6 w-32 flex-1">
        <div className="h-6 w-20 bg-gray-200 rounded-full"></div>
      </div>
      
      {/* Location Column */}
      <div className="px-6 w-48 flex-1">
        <div className="h-5 bg-gray-200 rounded"></div>
      </div>
      
      {/* Actions Column */}
      <div className="px-6 w-32 flex-1">
        <div className="flex space-x-2">
          <div className="w-7 h-7 bg-gray-200 rounded-lg"></div>
          <div className="w-7 h-7 bg-gray-200 rounded-lg"></div>
          <div className="w-7 h-7 bg-gray-200 rounded-lg"></div>
        </div>
      </div>
    </motion.div>
  );

  return (
    <div className="relative min-h-[400px] w-full">
      {/* Shimmer Rows */}
      <div className="w-full divide-y divide-gray-200">
        {shimmerRows.map((_, index) => (
          <ShimmerRow key={index} delay={index * 0.1} />
        ))}
      </div>
      
      {/* Loading Overlay - only shows text and spinner */}
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col items-center">
        <div className="relative">
          <div className="w-16 h-16">
            <div className="absolute w-full h-full rounded-full border-4 border-gray-100"></div>
            <motion.div
              className="absolute w-full h-full rounded-full border-4 border-primary border-t-transparent"
              animate={{ rotate: 360 }}
              transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
            ></motion.div>
          </div>
        </div>
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="mt-4 text-center"
        >
          <h3 className="text-lg font-medium text-gray-900">Loading Reports</h3>
          <p className="text-sm text-gray-500 mt-1">Please wait while we fetch the data</p>
        </motion.div>
      </div>
    </div>
  );
};

export default TableLoader;
