import React from "react";
import AddIcon from "@mui/icons-material/Add";

const ClickActionButton = ({
  title,
  buttonText,
  onButtonClick,
  testid,
  type,
  ...props
}) => {
  return (
    <div
      data-testid={testid}
      className="flex flex-wrap gap-4 items-center justify-center w-full max-md:max-w-full"
    >
      <button
        data-testid="main-button"
        onClick={onButtonClick}
        className="flex overflow-hidden gap-2 justify-center items-center px-5 py-4 mt-6 text-sm font-semibold leading-none text-center text-gray-50 bg-[#052D61] rounded-xl"
      >
        <span className="self-stretch my-auto">{buttonText}</span>
      </button>
      {type?.text === "project-details" && (
        <button
          data-testid="retrain-button"
          onClick={type.retrain.openModal}
          className="flex overflow-hidden gap-2 justify-center items-center self-stretch px-5 py-4 my-auto text-sm font-semibold leading-none text-center text-gray-50 rounded-xl border border-blue-300 border-solid"
        >
          <span className="self-stretch my-auto text-[#052D61]">
            Train Model
          </span>
        </button>
      )}
    </div>
  );
};

export default ClickActionButton;
