import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import CustomModal from "./Modal/CustomModal";
import StatCard from "./StatCard";
import ClipLoader from "react-spinners/ClipLoader";

import {
  checkHourlyAlertOccurrences,
  checkMonthlyAlertOccurrences,
  checkWeeklyAlertOccurrences,
  checkYearlyAlertOccurrences,
} from "../utils/alertTypeUtils";
import { LineChartData } from "../mockData/mockData";
import TimeAnalysisCard from "./LineChart";
import { Category } from "@mui/icons-material";
import LineCharts from "./Charts/LineCharts/LineCharts";

function CategorySection({
  category,
  selectWarehouse,
  siteSpecificData,
  selectedDateRange,
  specificAlertData,
  LineChartData,
  fetchEvents,
}) {
  const [categoryCards, setCategoryCards] = useState([]);
  const [specificUseCaseData, setSpecificUseCaseData] = useState([]);
  const [specificUseCaseAllData, setSpecificUseCaseAllData] = useState([]);
  const [specificAlert, setSpecificAlert] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [alertName, setAlertName] = useState("");
  const [selectedTab, setSelectedTab] = useState("Alerts");
  const [lineChartData, setLineChartData] = useState(LineChartData);
  const [showLineChart, setShowLineChart] = useState(false);
  const [isNumber, setIsNumber] = useState(false);
  const [cummilativeData, setCummilativeData] = useState(0);

  function getUseCaseData(specificData, useCaseName) {
    // Filter the useCases array for the specified scenario names
    const filteredUseCases = specificData.useCases.find(
      (useCase) => useCase.name == useCaseName
    );

    return filteredUseCases;
  }

  const getSiteUseCaseAlerts = (siteName, useCaseName) => {
    // Find the site by site_id
    const site = specificAlertData.sites.find(
      (site) => site.site_name == siteName
    );

    if (!site) {
      return `Site with ID  not found.`;
    }

    // Find the scenario within the site by use_case id
    const useCase = site.use_cases?.find(
      (useCase) => useCase.name == useCaseName
    );

    if (!useCase) {
      return `Use case with ID not found in site ${site.site_name}.`;
    }

    // Return site name, scenario name, and all alerts with details
    return {
      siteName: site.site_name,
      useCaseName: useCase.name,
      alerts: useCase.alerts?.map((alert) => ({
        alertTypeId: alert.alert_id,
        alertName: alert.alert_type,
        projectId: alert.project_id,
        description: alert.description,
        createdAt: alert.created_at,
        violation_video_url: alert.violation_clip_URL
          ? alert.violation_clip_URL
          : alert.violation_video_url
          ? alert.violation_video_url
          : null,
        forklift_position: alert.forklift_position
          ? alert.forklift_position
          : null,
        streamId: alert.stream_id || null,
      })),
    };
  };

  useEffect(() => {
    const alertUseCase = getSiteUseCaseAlerts(selectWarehouse, category);
    setSpecificAlert(alertUseCase.alerts);
  }, [category, selectWarehouse]);

  const getAlertCounts = (data) => {
    const alertCounts = {};

    data.forEach((group) => {
      group.values.forEach((alert) => {
        if (!alertCounts[alert.name]) {
          alertCounts[alert.name] = 0;
        }
        alertCounts[alert.name] += alert.value;
      });
    });

    return alertCounts;
  };

  const getAlertData = (alerts, specificAlertName, timePeriod) => {
    let count;
    if (timePeriod === "Hours") {
      count = checkHourlyAlertOccurrences(alerts, specificAlertName);
    } else if (timePeriod === "Month") {
      count = checkMonthlyAlertOccurrences(alerts, specificAlertName);
    } else if (timePeriod === "Week") {
      count = checkWeeklyAlertOccurrences(alerts, specificAlertName);
    } else if (timePeriod === "Year") {
      count = checkYearlyAlertOccurrences(alerts, specificAlertName);
    } else {
      throw new Error(
        'Invalid time period specified. Use "hourly" or "monthly".'
      );
    }
    return count;
  };

  const getNewAlertCounts = (alertCounts) => {
    return Object.entries(alertCounts).map(([alertType, count]) => ({
      alertType,
      count,
      details: getAlertData(specificAlert, alertType, selectedDateRange),
    }));
  };

  function groupByHourWithDefaults(data, prevdata) {
    // Create a deep copy of prevdata so original data remains unchanged
    const result = JSON.parse(JSON.stringify(prevdata));

    data.forEach((item) => {
      // Convert hour to "HH:00" format
      const hour = new Date(item.created_at).getHours();
      const grouptime = hour.toString().padStart(2, "0") + ":00";

      // Find the corresponding group in the result copy
      const group = result.find((g) => g.group === grouptime);

      if (group) {
        // Update or add total_trips
        let totalTripsEntry = group.values.find(
          (v) => v.name === "Total Trips"
        );
        if (totalTripsEntry) {
          totalTripsEntry.value += item.total_trips;
        } else {
          group.values.push({ name: "Total Trips", value: item.total_trips });
        }

        // Update or add Occupied Time
        let occupiedTimeEntry = group.values.find(
          (v) => v.name === "Forklift Utilization Time"
        );
        if (occupiedTimeEntry) {
          occupiedTimeEntry.value += item.occupied_time;
        } else {
          group.values.push({
            name: "Forklift Utilization Time",
            value: item.occupied_time,
          });
        }
      }
      // console.log("group", group);
    });

    return result;
  }

  // Assume `dateRange` is globally available and can be "hour", "day", "week", "month", or "year".
  // Example: Set the range globally as needed.

  // function groupByDateRangeWithDefaults(data, prevdata, daterange) {
  //   let result = [];
  //   let tempres = [];
  //   if (prevdata.length > 0) {
  //     result = JSON.parse(JSON.stringify(prevdata));
  //   }

  //   console.log("groupByDateRangeWithDefaults chart data in st", data, prevdata, daterange);

  //   data.forEach((item) => {
  //     const date = new Date(item.created_at);
  //     let groupKey;

  //     // Determine the grouping key based on dateRange
  //     switch (daterange) {
  //       case "Hours":
  //         groupKey = date.getHours().toString().padStart(2, "0") + ":00";
  //         break;
  //       case "Week":
  //         groupKey = [
  //           "Sunday",
  //           "Monday",
  //           "Tuesday",
  //           "Wednesday",
  //           "Thursday",
  //           "Friday",
  //           "Saturday",
  //         ][date.getDay()];
  //         break;
  //       case "Month":
  //         groupKey = parseInt(date.getDate().toString(), 10);
  //         break;
  //       case "Year":
  //         groupKey = [
  //           "January",
  //           "February",
  //           "March",
  //           "April",
  //           "May",
  //           "June",
  //           "July",
  //           "August",
  //           "September",
  //           "October",
  //           "November",
  //           "December",
  //         ][date.getMonth()];
  //         break;
  //       default:
  //         throw new Error("Invalid date range specified");
  //     }

  //     // Find the corresponding group in the result copy
  //     let group = result.find((g) => g.group === groupKey);
  //     if (!group) {
  //       group = { group: groupKey, values: [] };
  //       tempres.push(group);
  //     }

  //     // Update or add Forklift Utilization Time
  //     let occupiedTimeEntry = group.values.find(
  //       (v) => v.name === "Forklift Utilization Time"
  //     );
  //     if (occupiedTimeEntry) {
  //       occupiedTimeEntry.value += item.occupied_time || 0;
  //     } else {
  //       group.values.push({
  //         name: "Forklift Utilization Time",
  //         value: item.occupied_time || 0,
  //       });
  //     }

  //     // Update or add Total Trips
  //     let totalTripsEntry = group.values.find((v) => v.name === "Total Trips");
  //     if (totalTripsEntry) {
  //       totalTripsEntry.value += item.total_trips || 0;
  //     } else {
  //       group.values.push({
  //         name: "Total Trips",
  //         value: item.total_trips || 0,
  //       });
  //     }

  //     // Update or add Employee Mobile Usage Time (specific to Employee Efficiency)
  //     let onMobileEntry = group.values.find((v) => v.name === "Employee Mobile Usage");
  //     if (onMobileEntry) {
  //       onMobileEntry.value += item.on_mobile || 0;
  //     } else {
  //       group.values.push({
  //         name: "Employee Mobile Usage",
  //         value: item.on_mobile || 0,
  //       });
  //     }

  //     // Update or add Time Spent In Work Area Time (specific to Employee Efficiency)
  //     let workAreaEntry = group.values.find((v) => v.name === "Time Spent In Work Area");
  //     if (workAreaEntry) {
  //       workAreaEntry.value += item.work_area || 0;
  //     } else {
  //       group.values.push({
  //         name: "Time Spent In Work Area",
  //         value: item.work_area || 0,
  //       });
  //     }
  //   });

  //   console.log("tempres chart data in st !!!", tempres);

  //   return tempres.length > 0 ? tempres : result;
  // }
  let daysInMonth = new Date(
    new Date().getFullYear(),
    new Date().getMonth() + 1,
    0
  ).getDate();

  const DATE_RANGE_MAPPINGS = {
    Hours: {
      getKey: (date) => date.getHours().toString().padStart(2, "0") + ":00",
      getAllGroups: () =>
        Array.from(
          { length: 24 },
          (_, i) => i.toString().padStart(2, "0") + ":00"
        ),
    },
    Week: {
      getKey: (date) =>
        [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ][date.getDay()],
      getAllGroups: () => [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
    },
    Month: {
      getKey: (date) => date.getDate().toString(),
      getAllGroups: () =>
        Array.from({ length: daysInMonth }, (_, i) => (i + 1).toString()),
    },
    Year: {
      getKey: (date) =>
        [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ][date.getMonth()],
      getAllGroups: () => [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
    },
  };

  const VALUE_MAPPINGS = [
    { key: "occupied_time", name: "Forklift Utilization Time" },
    { key: "total_trips", name: "Total Trips" },
    { key: "on_mobile", name: "Employee Mobile Usage" },
    { key: "work_area", name: "Time Spent In Work Area" },
  ];

  function groupByDateRangeWithDefaults(data, prevdata, daterange) {
    if (!DATE_RANGE_MAPPINGS[daterange]) {
      throw new Error("Invalid date range specified");
    }
    let tempres =
      prevdata.length > 0 ? JSON.parse(JSON.stringify(prevdata)) : [];
    const allGroups = DATE_RANGE_MAPPINGS[daterange].getAllGroups();
    allGroups.forEach((groupKey) => {
      if (!tempres.find((item) => item.group === groupKey)) {
        tempres.push({
          group: groupKey,
          values: VALUE_MAPPINGS.map(({ name }) => ({
            name,
            value: 0,
          })),
        });
      }
    });
    // console.log(
    //   "groupByDateRangeWithDefaults chart data in st",
    //   data,
    //   prevdata,
    //   daterange
    // );

    data.forEach((item) => {
      const date = new Date(item.created_at);
      const groupKey = DATE_RANGE_MAPPINGS[daterange].getKey(date);
      const group = tempres.find((g) => g.group === groupKey);

      if (group) {
        VALUE_MAPPINGS.forEach(({ key, name }) => {
          const existingEntry = group.values.find((v) => v.name === name);
          if (existingEntry) {
            existingEntry.value += item[key] || 0;
          } else {
            group.values.push({
              name,
              value: item[key] || 0,
            });
          }
        });
      }
    });

    // console.log("tempres chart data in st !!!", tempres);

    return tempres;
  }

  const filterData = () => {
    setTimeout(() => {
      const specificUseCaseData = getUseCaseData(siteSpecificData, category);
      const alertCounts = getAlertCounts(
        specificUseCaseData?.alerts[selectedDateRange]
      );
      const getAlertCount = getNewAlertCounts(alertCounts);

      setSpecificUseCaseAllData(specificUseCaseData.alerts);
      setSpecificUseCaseData(specificUseCaseData.alerts[selectedDateRange]);

      let eventBarchartdata = LineChartData?.use_cases?.find(
        (uc) => uc.name === category
      );

      // console.log("finaldata eventBarchartdata", eventBarchartdata);

      if (eventBarchartdata?.Events) {
        setLineChartData(eventBarchartdata?.Events);
        let finaldata = groupByDateRangeWithDefaults(
          eventBarchartdata?.Events,
          specificUseCaseData.alerts[selectedDateRange],
          selectedDateRange
        );
        // console.log("finaldata", finaldata);
        setSpecificUseCaseData(finaldata);
      }

      if (specificUseCaseData && specificUseCaseData.alertCount) {
        const newCards = getAlertCount.map((item) => {
          const previousCount = item.details.previousCount || 1;
          return {
            title: item.alertType,
            count: item.count,
            heading: "Violation",
            isHour: false,
            change:
              ((item.details.recentCount - item.details.previousCount) /
                previousCount) *
              100,
            isPositive: item.details.hasIncreased,
          };
        });

        if (eventBarchartdata?.Events) {
          // console.log("eventBarchartdata", eventBarchartdata);
          if (eventBarchartdata.name === "Employee Efficiency") {
            let totalOnMobileTime, totalWorkAreaTime;
            // Calculate based on on_mobile and work_area for Employee Efficiency
            totalOnMobileTime = eventBarchartdata?.Events.reduce(
              (sum, item) => sum + item.on_mobile,
              0
            );

            totalWorkAreaTime = eventBarchartdata?.Events.reduce(
              (sum, item) => sum + item.work_area,
              0
            );

            newCards.push(
              {
                title: "Employee Mobile Usage",
                count: totalOnMobileTime,
                heading: "Employee Mobile Usage",
                isHour: true,
                change: eventBarchartdata.percentage_change?.on_mobile,
                isPositive: false,
              },
              {
                title: "Time Spent In Work Area",
                count: totalWorkAreaTime,
                heading: "Time Spent In Work Area",
                isHour: true,
                change: eventBarchartdata.percentage_change?.work_area,
                isPositive: false,
              }
            );
          } else if (
            category ===
            "Forklift Safety, Compliance, Downtime, and Maintenance"
          ) {
            let totalOccupiedTime, totaltrips;
            totalOccupiedTime = eventBarchartdata?.Events.reduce(
              (sum, item) => sum + item.occupied_time,
              0
            );
            totaltrips = eventBarchartdata?.Events.reduce(
              (sum, item) => sum + item.total_trips,
              0
            );
            if (totaltrips > 0) {
              setIsNumber(true);
            }

            newCards.push(
              {
                title: "Forklift Utilization Time",
                count: totalOccupiedTime,
                heading: "Forklift Utilization Time",
                isHour: true,
                change: eventBarchartdata?.percentage_change?.occupied_time,
                isPositive: false,
              },
              {
                title: "Total Trips",
                count: totaltrips,
                heading: "Total Trips",
                isHour: false,
                change: eventBarchartdata?.percentage_change?.total_trips,
                isPositive: false,
              }
            );
          }
        }
        // console.log("newCards", newCards);
        setCategoryCards(newCards);
      }

      setIsLoading(false);
    }, 1000);
  };

  useEffect(() => {
    setIsLoading(true);
    if (category !== "") {
      filterData();
    }
  }, [
    siteSpecificData,
    selectedDateRange,
    category,
    specificAlert,
    LineChartData,
  ]);

  const filterAlertData = (alertType) => {
    const filteredData = {};

    for (const period in specificUseCaseAllData) {
      filteredData[period] = specificUseCaseAllData[period]
        .map((entry) => ({
          group: entry.group,
          values: entry.values?.filter((value) => value.name === alertType),
        }))
        ?.filter((entry) => entry.values.length > 0); // Remove entries with no values
    }

    return filteredData;
  };

  const handleOpenModal = (alertType, total) => {
    let filteredD = {};
    console.log("alertType", alertType, total);
    if (
      alertType === "Forklift Utilization Time" ||
      alertType === "Total Trips" ||
      alertType === "Employee Mobile Usage" ||
      alertType === "Time Spent In Work Area"
    ) {
      if (alertType === "Total Trips") {
        setIsNumber(true);
      }
      // console.log("specificUseCaseData", specificUseCaseData);
      filteredD[selectedDateRange] = specificUseCaseData
        .map((entry) => ({
          group: entry.group,
          values: entry.values.filter((value) => value.name === alertType),
        }))
        ?.filter((entry) => entry.values.length > 0); // Remove entries with no values

      setCummilativeData(total);
      // console.log("filteredD", filteredD);
    } else {
      filteredD = filterAlertData(alertType);
      setCummilativeData(total);
      // console.log("filteredD", filteredD);
    }

    setModalData(filteredD);
    setAlertName(alertType);
    setOpenModal(true);
  };

  const handleOpenModalLineChart = (data, title) => {
    // console.log("LineChartData", data);
    setModalData(data);
    setShowLineChart(true);
    setAlertName(title);
    setOpenModal(true);
  };

  const shouldShowTimeAnalysis = () => {
    // console.log(
    //   "LineChartData.use_cases",
    //   LineChartData?.use_cases?.filter((usecase) => usecase.name === category),
    //   LineChartData?.use_cases?.filter((usecase) => usecase.name === category).length > 0,
    //   categoryCards,
    //   specificAlert,
    //   category
    // );
    return (
      !isLoading &&
      LineChartData?.use_cases?.filter((usecase) => usecase.name === category)
        .length > 0
    );
  };

  const handleCloseModal = () => {
    console.log("Close the modal ||||||||");
    setOpenModal(false);
    setShowLineChart(false);
    setModalData(null);
  };

  // console.log("categoryCards", categoryCards);

  return (
    <section className="flex flex-col px-4 mt-8 w-full max-md:max-w-full font-golos">
      <h2 className="text-sm font-semibold leading-none text-gray-900 max-md:max-w-full font-suse">
        {category}
      </h2>
      <div className="flex flex-wrap gap-2 items-start mt-2 w-full max-md:max-w-full">
        {isLoading ? (
          <div className="w-full flex justify-center items-center">
            <ClipLoader color="#052D61" loading={isLoading} size={50} />
          </div>
        ) : (specificAlert && categoryCards.length > 0) ||
          LineChartData?.use_cases?.filter(
            (usecase) => usecase.name === category && usecase?.Events !== null
          ).length > 0 ? (
          <>
            {categoryCards.map((card, index) => (
              <StatCard
                key={index}
                {...card}
                value={card.count}
                selectedDateRange={selectedDateRange}
                filteredData={specificUseCaseData}
                onClick={handleOpenModal}
                className="flex-1 basis-[240px] min-w-[240px] max-w-[240px]"
                category={category}
                isNumber={
                  isNumber && alertName?.toLowerCase()?.includes("violations")
                }
              />
            ))}

            {shouldShowTimeAnalysis() &&
              LineChartData?.use_cases
                ?.filter((usecase) => usecase.name === category)
                .map((usecase, index) => (
                  <LineCharts
                    key={index}
                    data={usecase}
                    timeframe={selectedDateRange}
                    onClick={handleOpenModalLineChart}
                  />
                ))}
          </>
        ) : (
          <div className="flex overflow-hidden flex-col shrink flex-1 px-0.5 rounded-lg border-2 border-gray-200 border-solid basis-[240px] min-w-[240px] max-w-[240px] h-[220px] font-golos">
            <div className="flex-1 flex items-center justify-center">
              <span className="text-sm text-gray-500 font-medium">
                {category.toLowerCase().includes("safety") ||
                category.toLowerCase().includes("compliance") ||
                category.toLowerCase().includes("forklift")
                  ? "No safety incidents to report. Keep up the great work!"
                  : "No data to display"}
              </span>
            </div>
          </div>
        )}
      </div>

      {/* Modal Popup */}
      <CustomModal
        open={openModal}
        alertName={alertName}
        handleClose={handleCloseModal}
        modalData={modalData}
        category={category}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        selectedDateRange={selectedDateRange}
        specificAlert={specificAlert}
        selectedWarehouse={selectWarehouse}
        utilsfunctions={groupByDateRangeWithDefaults}
        fetchEvents={fetchEvents}
        setModalData={setModalData}
        showLineChart={showLineChart}
        cummilativeData={cummilativeData}
        isNumber={isNumber && alertName?.toLowerCase()?.includes("violations")}
      />
    </section>
  );
}

export default CategorySection;
