import React, { useState, useMemo, useCallback, useEffect } from "react";
import {
  createProject,
  UpdateProject,
  createProjectStreams,
  getallstreams,
  getProjectStreams,
  getProject,
  fetchUsecase,
  fetchApplicationflows,
} from "../../../services/apiServiceDetails";
import { useParams, useNavigate } from "react-router-dom";
import useCustomToast from "../../../hooks/useToast";
import { useCaseToLogics } from "../../../utils/constants";

// Constants
// let USE_CASES = [
//   { id: 1, name: "PPE violation detection" },
//   { id: 2, name: "Forklift detection" },

const USE_CASES = [
  { id: 1, name: "Safety & Compliance" },
  { id: 2, name: "Inventory Management & Efficiency" },
  { id: 3, name: "Downtime & Maintenance" },
];
const SUB_USE_CASES = [
  { id: 1, name: "PPE" },
  { id: 2, name: "Forklift" },
];

const APPLICATION_LOGICS = {
  "Safety & Compliance": [
    { id: 1, name: "Safety vest not worn", subUsecase: "PPE" },
    { id: 2, name: "Safety helmet not worn", subUsecase: "PPE" },
    { id: 3, name: "Safety goggles not worn", subUsecase: "PPE" },
    { id: 4, name: "Safety gloves not worn", subUsecase: "PPE" },
    { id: 5, name: "Forklift overspeeding", subUsecase: "Forklift" },
    { id: 6, name: "Forklift too close to employees", subUsecase: "Forklift" },
    {
      id: 7,
      name: "Forklift did not stop at designated stoppage",
      subUsecase: "Forklift",
    },
  ],
  "Inventory Management & Efficiency": [
    { id: 1, name: "Number of packages handled per employee" },
    { id: 2, name: "Total time employees spent on the mobile phone" },
    { id: 3, name: "Total time employees spent in designated work areas" },
    {
      id: 4,
      name: " Total idle time of packages between picking, packing and shipping",
    },
  ],
  "Downtime & Maintenance": [
    { id: 1, name: "Percentage utilization of forklift" },
    { id: 2, name: "Average time required for forklift load/unload" },
  ],
};

const STREAMS = ["Stream 1", "Stream 2", "Stream 3"];

// Components
const CloseButton = ({ onClose }) => (
  <button
    onClick={onClose}
    className="text-gray-400 hover:text-gray-900 p-2 rounded-full transition-colors"
    aria-label="Close"
    data-testid="close-button"
  >
    <svg
      className="w-6 h-6"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  </button>
);

const SelectField = ({ label, options, value, onChange, required, action }) => (
  <div data-testid="select-field">
    <label
      className="block text-sm font-medium text-gray-700"
      data-testid="select-label"
    >
      {label} {required && <span className="text-red-600">*</span>}
    </label>
    <select
      value={value}
      onChange={onChange}
      className="mt-1 w-full px-4 py-3 border rounded-lg border-gray-300 focus:ring-blue-500 focus:border-blue-500 transition-shadow focus:shadow-lg"
      required={required}
      disabled={action === "edit"}
      data-testid="select-input"
    >
      <option value="" disabled data-testid="select-default-option">
        Select option
      </option>
      {options.map((option, index) => {
        console.log("option", option);
        if (option.id === 4) {
          return null; // Skip the option
        }
        return (
          <option
            key={index}
            value={typeof option === "string" ? option : option.name}
            data-testid={`select-option-${index}`}
          >
            {typeof option === "string" ? option : option.name}
          </option>
        );
      })}
    </select>
  </div>
);

const CheckboxGroup = ({
  label,
  options,
  selectedOptions,
  onChange,
  selectedUseCase,
  selectedSubUseCase,
}) => {
  console.log("Selected Scenario", selectedUseCase?.toLowerCase());

  return (
    <div data-testid="checkbox-group">
      <label
        className="block text-sm font-medium text-gray-700"
        data-testid="checkbox-label"
      >
        {useCaseToLogics(selectedUseCase)}
      </label>
      <div className="mt-2 space-y-2">
        {options.map((option, index) => {
          return (
            <div key={option.id} className="flex flex-col items-start">
              <div className=" flex items-center flex-row">
                <input
                  type="checkbox"
                  id={`logic-${option.id}`}
                  checked={selectedOptions.includes(option.name)}
                  onChange={() => onChange(option.name)}
                  className="w-4 h-4 text-[#052D61] border-gray-300 rounded focus:ring-[#052D61]"
                  data-testid={`checkbox-input-${option.id}`}
                />
                <label
                  htmlFor={`logic-${option.id}`}
                  className="ml-2 text-sm text-gray-700"
                  data-testid={`checkbox-label-${option.id}`}
                >
                  {option.name}
                </label>
              </div>
              {selectedUseCase?.toLowerCase() ===
                "forklift safety & compliance, downtime & maintenance" &&
                index === 1 && (
                  <label
                    className="block text-sm font-medium text-gray-700 mt-5"
                    data-testid="checkbox-label"
                  >
                    Select Incident Type
                  </label>
                )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const MultiSelectDropdown = ({
  label,
  options,
  selectedOptions,
  onChange,
  required,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (option) => {
    let str = option.toString();
    onChange(str);
  };

  const removeOption = (option, e) => {
    e.stopPropagation();
    onChange(option);
  };

  return (
    <div className="relative" data-testid="multi-select-dropdown">
      <label
        className="block text-sm font-medium text-gray-700"
        data-testid="dropdown-label"
      >
        {label} {required && <span className="text-red-600">*</span>}
      </label>
      <div
        className="mt-1 w-full px-4 py-2 border rounded-lg border-gray-300 focus:ring-blue-500 focus:border-blue-500 cursor-pointer flex flex-wrap items-center"
        onClick={toggleDropdown}
        data-testid="dropdown-input"
      >
        {selectedOptions.length === 0 ? (
          <span className="text-gray-500" data-testid="dropdown-placeholder">
            Select streams
          </span>
        ) : (
          selectedOptions.map((option) => {
            const opt = options.find((opt) => opt.id == option);
            return (
              <span
                key={option}
                className="bg-blue-100 text-[#052D61] text-xs font-semibold mr-2 px-2.5 py-0.5 rounded flex items-center"
                data-testid={`selected-option-${option}`}
              >
                {opt.video_name}
                <button
                  type="button"
                  className="ml-1 text-[#052D61] hover:text-blue-800"
                  onClick={(e) => removeOption(option, e)}
                  data-testid={`remove-option-${option}`}
                >
                  ×
                </button>
              </span>
            );
          })
        )}
      </div>
      {isOpen && (
        <div className="absolute top-[8%] -right-[60%] z-10 h-[180px] overflow-y-auto w-[180px] mt-1 bg-white rounded-md shadow-lg">
          {options.map((option) => (
            <div
              key={option}
              className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer"
              onClick={() => handleOptionClick(option.id)}
              data-testid={`dropdown-option-${option.id}`}
            >
              <input
                type="checkbox"
                checked={selectedOptions.includes(option.id.toString())}
                className="mr-2"
                data-testid={`checkbox-${option.id}`}
              />
              <span data-testid={`option-name-${option.id}`}>
                {option.video_name}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export const CreateProject = ({
  isOpen,
  onClose,
  onSubmit,
  disablecreateproject,
  action,
  seteditData,
  setaction,
  data,
}) => {
  const [selectedUseCase, setSelectedUseCase] = useState("");
  const [selectedSubUseCase, setSelectedSubbUseCase] = useState("");
  const [projectName, setprojectName] = useState("");
  const [selectedLogics, setSelectedLogics] = useState([]);
  const [selectedStreams, setSelectedStreams] = useState([]);
  const [allstreams, setallstreams] = useState([]);
  const [usecaseID, setusecaseID] = useState("");
  const [applicationLogics, setapplicationLogics] = useState([]);
  const { name, siteid } = useParams();
  const showToast = useCustomToast();
  const [useCases, setUseCases] = useState([]);
  const navigate = useNavigate();

  const getAllStreams = async () => {
    const resp = await getallstreams(siteid);
    setallstreams(resp);
  };

  console.log(data);

  const getprojectstreams = async () => {
    const resp = await getProjectStreams(data.id);
    console.log(resp);
    resp.forEach((streamData) => {
      console.log(streamData);
      if (streamData.stream && streamData.stream.id) {
        setSelectedStreams((prevStreams) => [
          ...prevStreams,
          String(streamData.stream.id),
        ]);
      } else {
        console.error("stream or stream.id is undefined", streamData);
      }
    });
  };

  const getUsecase = async () => {
    const resp = await fetchUsecase();
    console.log(resp);
    let useCases = resp.map((res, index) => {
      return { id: res.usecase_id, name: res.usecase_type };
    });
    setUseCases(useCases);
  };

  const getproject = async () => {
    const resp = await getProject(data.id);
    console.log(resp);
    setprojectName(resp.project.name);
    setSelectedUseCase(resp.usecase.usecase_type);
    resp.flows.forEach((flow) => {
      setSelectedLogics((prevStreams) => [...prevStreams, flow.flowtype]);
    });
    // console.log(resp);
  };
  useEffect(() => {
    getAllStreams();
    getUsecase();
  }, []);

  useEffect(() => {
    if (data) {
      getproject();
      getprojectstreams();
    }
  }, [data]);

  // let applicationLogics = useMemo(
  //   () => (selectedUseCase ? APPLICATION_LOGICS[selectedUseCase] : []),
  //   [selectedUseCase]
  // )

  const onCloseModal = () => {
    setSelectedUseCase("");
    setSelectedLogics([]);
    setSelectedStreams([]);
    seteditData(null);
    setaction("create");
    onClose();
  };

  const handleLogicChange = useCallback((logic) => {
    setSelectedLogics((prevLogics) =>
      prevLogics.includes(logic)
        ? prevLogics.filter((item) => item !== logic)
        : [...prevLogics, logic]
    );
  }, []);

  const handleStreamChange = useCallback((stream) => {
    setSelectedStreams((prevStreams) =>
      prevStreams.includes(stream)
        ? prevStreams.filter((item) => item !== stream)
        : [...prevStreams, stream]
    );
  }, []);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      const projectData = {
        projectName: projectName,
        useCase: selectedUseCase,
        logics: selectedLogics,
        streams: selectedStreams,
      };
      // console.log(projectData);
      var resp = "";
      try {
        if (action === "edit") {
          resp = await UpdateProject(projectData, data.id);
          showToast("Project Updated Successfully", "success");
          navigate(`/site-details/${siteid}/projects/${resp.id}`);
        } else {
          resp = await createProject(projectData, siteid);
          // if (resp.id) {
          //   const res = await createProjectStreams(resp.id, selectedStreams);
          // }
          showToast("Scenario Created Successfully", "success");
          navigate(`/site-details/${siteid}/projects/${resp.id}`);
        }
        // console.log(resp);
      } catch (error) {
        showToast("Failed to create scenario", "error");
        // console.log(error);
      }
    },
    [selectedUseCase, selectedLogics, selectedStreams, onSubmit]
  );

  const fetchflows = async () => {
    const res = await fetchApplicationflows(usecaseID);
    console.log(res);
    let d = [];
    res.map((r, index) => {
      d.push({ id: index, name: r.flow_type });
    });
    setapplicationLogics(d);
    // setflows(res);
  };

  useEffect(() => {
    const foundUseCase = useCases.find(
      (usecase) => usecase.name === selectedUseCase
    );
    console.log(foundUseCase);
    if (foundUseCase) setusecaseID(foundUseCase.id);
  }, [selectedUseCase]);

  console.log(usecaseID);

  useEffect(() => {
    fetchflows();
  }, [usecaseID]);

  useEffect(() => {
    console.log(selectedUseCase, selectedLogics, selectedStreams);
  }, [selectedLogics, selectedStreams]);

  if (!isOpen) return null;

  // if (!disablecreateproject) {
  //   showToast("Please Upload some streams to your site", "error");
  //   return null;
  // }

  console.log(usecaseID);
  console.log(applicationLogics);

  return (
    <div
      data-testid="create-project-modal"
      className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden"
    >
      <div
        className="absolute inset-0 bg-black opacity-50 backdrop-blur-sm"
        onClick={onCloseModal}
        data-testid="backdrop"
      ></div>

      <div className="relative w-full max-w-md px-4 py-10">
        <div
          className="bg-white rounded-2xl shadow-xl p-8"
          data-testid="modal-content"
        >
          <div
            className="flex items-start justify-between border-b border-gray-200 pb-6"
            data-testid="modal-header"
          >
            <div className="flex flex-col items-start">
              <h3
                className="text-3xl font-semibold tracking-tighter text-gray-900"
                data-testid="modal-title"
              >
                {action === "create" ? "Add Scenario" : "Edit Scenario"}
              </h3>
              <p
                className="text-sm text-gray-600 mt-2"
                data-testid="modal-description"
              >
                Please enter the following details
              </p>
            </div>
            <CloseButton onClose={onCloseModal} />
          </div>

          <form
            onSubmit={handleSubmit}
            className="mt-5 space-y-6"
            data-testid="project-form"
          >
            <div className="flex flex-col w-full text-sm font-medium leading-none">
              <label
                htmlFor={"projectname"}
                className="flex gap-0.5 items-start self-start"
                data-testid="project-name-label"
              >
                <span className="text-slate-800">Name</span>
                <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id={"projectname"}
                required={true}
                value={projectName}
                onChange={(e) => setprojectName(e.target.value)}
                className="flex gap-1 py-4 px-2 mt-1 w-full bg-gray-50 rounded-xl border border-gray-200 border-solid min-h-[48px]"
                aria-label="Project name"
                data-testid="project-name-input"
              />
            </div>
            <SelectField
              label="Type"
              options={useCases}
              value={selectedUseCase}
              onChange={(e) => {
                setSelectedUseCase(e.target.value);
                setSelectedLogics([]);
              }}
              required
              action={action}
              data-testid="use-case-select"
            />

            {/* Instead of app logics, if the use cases have safety and compliance, say "Select incident type". If scenario is efficiency or maintenance, say "Select metric type" */}

            {selectedUseCase && (
              <CheckboxGroup
                label="Select Application Logic"
                options={applicationLogics}
                selectedOptions={selectedLogics}
                onChange={handleLogicChange}
                data-testid="logic-checkbox-group"
                selectedUseCase={selectedUseCase}
                selectedSubUseCase={selectedSubUseCase}
              />
            )}

            {/* {selectedUseCase && selectedLogics.length > 0 && (
              <MultiSelectDropdown
                label="Devices"
                options={allstreams}
                selectedOptions={selectedStreams}
                onChange={handleStreamChange}
                required
                data-testid="streams-multi-select"
              />
            )} */}
            <div className="relative group">
              <button
                type="submit"
                className={`w-full px-5 py-3 text-sm font-semibold text-white ${
                  selectedLogics.length > 0
                    ? "bg-[#052D61] hover:bg-blue-700"
                    : "bg-gray-400 text-gray-600"
                } rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-300 transition-transform transform hover:scale-105`}
                data-testid="submit-button"
                disabled={!(selectedLogics.length > 0)}
              >
                {action === "create" ? "Create Scenario" : "Save Changes"}
              </button>
              {selectedLogics.length === 0 && (
                <div className="absolute bottom-[-150%] left-0 w-[300px] px-3 py-2 bg-white text-black rounded shadow-lg opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                  Please Select One/Multiple Metric Types
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
