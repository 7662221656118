import { SET_PROJECT_ID, SET_USER } from "../actions/userActions";

const initialState = null;

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      // console.log("action",action);
      return {
        ...state,
        email: action.payload.email,
        name: action.payload.name,
        socketID: action.payload.socketID,
      };
    case SET_PROJECT_ID:
      return {
        ...state,
        projectID: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
