import React, {
    useState,
    useEffect,
    useCallback,
    useMemo,
    useRef,
  } from "react";

function DateModalFilter({ setSelectedDate, selectedDateValue }) {
  const [selectedOption, setLocalSelectedOption] = useState(selectedDateValue);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setLocalSelectedOption(newValue); // Update local state for dropdown display
    setSelectedDate(newValue); // Call the parent callback to update parent state
  };


  return (
    <div className="flex gap-2 items-center self-stretch px-4 py-2.5 my-auto bg-white rounded-lg border border-gray-300 border-solid min-h-[40px] w-[168px]">
      <select
        value={selectedOption}
        onChange={handleChange}
        className="flex-1 shrink self-stretch my-auto basis-0 text-ellipsis bg-white border-none outline-none"
      >
        <option value="Hours">24 Hours</option>
        <option value="Week">1 Week</option>
        <option value="Month">1 Month</option>
        <option value="Year">1 Year</option>
      </select>

    </div>
  );
}

export default DateModalFilter;