import { useState, useEffect, useRef } from "react";
import { create } from "amazon-ivs-player";

const VisualizationActivity = ({ data }) => {
  // const playbackUrl =
  //   "https://514456358459.us-east-1.playback.live-video.net/api/video/v1/us-east-1.108782093821.channel.vofTxL10MXB1.m3u8";
  const playbackUrl =
    "https://514456358459.us-east-1.playback.live-video.net/api/video/v1/us-east-1.108782093821.channel.uO4dKaKsF9Ve.m3u8";
  const [selectedVideo, setSelectedVideo] = useState(data[0]);
  const [isReady, setIsReady] = useState(false);
  const videoEl = useRef(null);
  const POLLING_INTERVAL = 5000; // Poll every 5 seconds

  useEffect(() => {
    const pollForPlaybackUrl = async () => {
      try {
        const response = await fetch(playbackUrl, {
          method: "HEAD", // Use HEAD to avoid downloading content
        });

        if (response.status === 200) {
          setIsReady(true); // Video is available, stop polling
          clearInterval(pollingInterval);
        }
      } catch (error) {
        console.error("Error polling for playback URL:", error);
      }
    };

    const pollingInterval = setInterval(pollForPlaybackUrl, POLLING_INTERVAL);

    return () => {
      clearInterval(pollingInterval); // Cleanup interval on unmount
    };
  }, [playbackUrl]);

  useEffect(() => {
    if (isReady && videoEl.current) {
      const player = create({
        wasmWorker:
          "https://cdn.jsdelivr.net/npm/amazon-ivs-player@latest/dist/assets/amazon-ivs-wasmworker.min.js",
        wasmBinary:
          "https://cdn.jsdelivr.net/npm/amazon-ivs-player@latest/dist/assets/amazon-ivs-wasmworker.min.wasm",
      });

      console.log(player);

      player.attachHTMLVideoElement(videoEl.current);
      player.load(playbackUrl);
      player.play();

      return () => {
        player.pause();
        player.delete();
      };
    }
  }, [isReady, playbackUrl]);

  if (!data) {
    return null; // Return early if data is not available yet
  }

  return (
    <div className="flex flex-row p-4 w-full h-[80vh] rounded-xl border-2 border-gray-200 border-solid">
      {/* Video List with Scroll */}
      <div className="w-1/4 p-4 bg-gray-50 border-r border-gray-200 overflow-y-auto">
        {data.map((video) => (
          <div
            key={video.stream.id}
            className={`flex items-center p-2 mb-1 cursor-pointer rounded bg-blue-100`}
            onClick={() => setSelectedVideo(video)}
          >
            <div>
              <h3 className={`font-semibold text-[#052D61]`}>
                {video.stream.video_name}
              </h3>
              <p className="text-xs text-gray-500 truncate">
                {video.stream.url}
              </p>
            </div>
          </div>
        ))}
      </div>

      <div className="w-3/4 h-full p-4 bg-gray-100 flex flex-col">
        <div
          className="relative bg-black rounded-lg overflow-hidden"
          style={{ height: "calc(100%)" }}
        >
          {isReady ? (
            <video
              ref={videoEl}
              controls
              style={{ width: "100%", height: "auto" }}
            />
          ) : (
            <p className="text-white text-center">Loading video...</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default VisualizationActivity;
