import moment from "moment/moment";

const STATUS_TYPES = {
  PROGRESS: {
    GOAL_DETECTION: "progress_goal_detection",
    MODEL_TRAINING: "progress_model_training",
  },
  SUCCESS: {
    TRAINING_COMPLETE: "success_training_complete",
  },
  INFO: {
    DETECTION_COMPLETE: "success_detection_complete",
  },
  ERROR: "error",
};

export function getIdByName(name) {
  const sitesData = localStorage.getItem("sites");
  const parsedData = sitesData ? JSON.parse(sitesData) : [];
  // console.log("siteData", parsedData);
  const warehouse = parsedData.find((item) => item.name === name);
  // console.log("warehouse", warehouse);
  return warehouse ? warehouse.id : null;
}

const MODAL_MESSAGES = {
  [STATUS_TYPES.PROGRESS.GOAL_DETECTION]:
    "Target Detection is in progress. Please wait as this process may take a few minutes.",
  [STATUS_TYPES.PROGRESS.MODEL_TRAINING]:
    "Model training in progress. Please wait as this process may take a few minutes.",
  [STATUS_TYPES.SUCCESS.TRAINING_COMPLETE]:
    "Training complete! Your model is ready as per your requirements. Click 'Start Detection' to view results!",
  [STATUS_TYPES.INFO.DETECTION_COMPLETE]:
    "Target detection is complete! Review detections and fix any incorrectly detected entities by clicking on Fix Incorrect Detections",
  [STATUS_TYPES.ERROR]:
    "Something went wrong. You can retry by clicking on the 'Retrain Model' button above.",
};

const BUTTON_TEXT = {
  TEST_MODEL: "Test Model",
  REMOVE_INCORRECT_LABELS: "Fix Incorrect Detections",
};

// This function converts an email to a unique socket ID by generating a 32-bit hash from the email string
function emailToSocketID(email) {
  let hash = 0;
  let timestamp = new Date().getTime();
  hash = (hash + timestamp) & 0xffffffff;
  for (let i = 0; i < email.length; i++) {
    const char = email.charCodeAt(i);
    hash = (hash * 31 + char) & 0xffffffff;
  }
  return Math.abs(hash).toString(16);
}

const useCaseToLogics = (useCase) => {
  // console.log("useCase", useCase);
  // console.log("useCase in lower case", useCase?.toLowerCase());
  if (
    !useCase?.toLowerCase().includes("forklift") &&
    (useCase?.toLowerCase().includes("safety") ||
      useCase?.toLowerCase().includes("compliance"))
  ) {
    return "Select incident type";
  } else if (
    useCase?.toLowerCase().includes("efficiency") ||
    useCase?.toLowerCase().includes("management") ||
    useCase?.toLowerCase().includes("maintenance")
  ) {
    return "Select metric type";
  }
  return useCase;
};

const useCaseToLogicsF = (useCase) => {
  if (
    useCase?.toLowerCase().includes("safety") ||
    useCase?.toLowerCase().includes("compliance")
  ) {
    return "Incidents";
  } else if (
    useCase?.toLowerCase().includes("efficiency") ||
    useCase?.toLowerCase().includes("management") ||
    useCase?.toLowerCase().includes("maintenance")
  ) {
    return "Metrics";
  }
  return useCase;
};

export const formatDate = (dateString) => {
  const options = {
    day: "numeric",
    month: "long",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  };

  return new Date(dateString).toLocaleString('en-GB', options);
};

const getFromLocalStorage = (key) => {
  // console.log("key", key);
  const sites = localStorage.getItem("sites");

  if (sites) {
    let parsedSites = JSON.parse(sites);
    // console.log("sName", parsedSites);
    const site = parsedSites?.find((site) => site.id == key);
    // console.log("sName", site);
    return site?.name;
  }
  return null;
};

function memoize(fn) {
  const cache = new Map();
  return function(...args) {
    const key = JSON.stringify(args);
    if (cache.has(key)) {
      return cache.get(key);
    } else {
      const result = fn(...args);
      cache.set(key, result);
      return result;
    }
  };
}

export function convertToLocalTimeAndShift(timestamp) {
  console.log("timestamp", timestamp);

  // Try to parse the timestamp to a valid date
  let date;
  if (typeof timestamp === "string") {
    date = new Date(Date.parse(timestamp)); // Parse string to Date
    console.log("date", date);
  } else if (typeof timestamp === "number") {
    date = new Date(timestamp);
  } else {
    console.error("Invalid timestamp format");
    return null;
  }

  if (isNaN(date.getTime())) {
    console.error("Invalid Date object");
    return null;
  }

  const localTime = date.toLocaleString();
  const hour = date.getHours();
  console.log("hour", hour);

  let shift;
  if (hour >= 5 && hour < 12) {
    shift = "Morning";
  } else if (hour >= 12 && hour < 17) {
    shift = "Afternoon";
  } else if (hour >= 17 && hour < 21) {
    shift = "Evening";
  } else {
    shift = "Night";
  }

  return {
    localTime: localTime,
    shift: shift,
    hour: hour,
  };
}

export const formatTime = memoize(function formatTime(second) {
  // console.log("second", second);
  let seconds = parseInt(second);
  if (typeof seconds !== 'number' || seconds < 0 || !Number.isInteger(seconds)) {
      return 'Invalid input';
  }

  if (seconds < 60) {
      return `${seconds} ${seconds === 1 ? 'sec' : 'secs'}`;
  }

  let hours = Math.floor(seconds / 3600);
  let remainingSeconds = seconds % 3600;
  let minutes = Math.floor(remainingSeconds / 60);
  remainingSeconds = remainingSeconds % 60;

  let parts = [];
  if (hours > 0) {
      parts.push(`${hours} ${hours === 1 ? 'hr' : 'hrs'}`);
  }
  if (minutes > 0) {
      parts.push(`${minutes} ${minutes === 1 ? 'min' : 'mins'}`);
  }
  if (remainingSeconds > 0) {
      parts.push(`${remainingSeconds} ${remainingSeconds === 1 ? 'sec' : 'secs'}`);
  }

  return parts.join(' ');
});

export const processSiteData = memoize(function processSiteData(data) {
  const useCases = [];
  const newAlertMap = {};
  const newAlertDataMap = {};
  let site = data.sites[0];

  if (site) {
    site.use_cases.forEach((useCase) => {
      useCases.push({
        value: useCase.name,
        label: useCase.name
      });
      if (useCase.alerts) {
        newAlertMap[useCase.name] = [
          ...new Set(useCase.alerts.map((alert) => alert.alert_type))
        ].map(type => ({
          value: type,
          label: type
        }));
        useCase.alerts?.forEach((alert) => {
          if (!newAlertDataMap[alert.alert_type]) {
            newAlertDataMap[alert.alert_type] = [];
          }
          newAlertDataMap[alert.alert_type].push({
            ...alert,
            id: alert.alert_id,
            created_at: alert.created_at
          });
        });
      }
    });
  }

  return { useCases, newAlertMap, newAlertDataMap };
});

export const formaOccurrenceTime = memoize(function formatDate(occurrenceTime) {

  const expectedFormat = "D MMMM YYYY, HH:mm:ss";
  const dateFormats = [
    "MMMM D, YYYY [at] HH:mm:ss",
    "D MMMM YYYY [at] HH:mm:ss",
    "DD/MM/YYYY, HH:mm:ss",
  ];

  const isExpectedFormat = moment(occurrenceTime, expectedFormat, true).isValid();
  if (isExpectedFormat) {
    return occurrenceTime;
  }

  let parsedDate = null;
  for (const format of dateFormats) {
    const date = moment(occurrenceTime, format, true);
    if (date.isValid()) {
      parsedDate = date;
      break;
    }
  }
  return parsedDate
    ? parsedDate.format(expectedFormat)
    : occurrenceTime;
})

const TIME_CONSTANTS = {
  HOUR_IN_MS: 60 * 60 * 1000,
  DAY_IN_MS: 24 * 60 * 60 * 1000,
};

const TIME_RANGES = {
  Hours: {
    current: 23 * TIME_CONSTANTS.HOUR_IN_MS,
    previous: 48 * TIME_CONSTANTS.HOUR_IN_MS,
  },
  Week: {
    current: 6 * TIME_CONSTANTS.DAY_IN_MS,
    previous: 14 * TIME_CONSTANTS.DAY_IN_MS,
  },
  Month: {
    current: 29 * TIME_CONSTANTS.DAY_IN_MS,
    previous: 60 * TIME_CONSTANTS.DAY_IN_MS,
  },
  Year: {
    current: 364 * TIME_CONSTANTS.DAY_IN_MS,
    previous: 730 * TIME_CONSTANTS.DAY_IN_MS,
  },
};

const WEB_SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
export {
  STATUS_TYPES,
  MODAL_MESSAGES,
  BUTTON_TEXT,
  emailToSocketID,
  WEB_SOCKET_URL,
  useCaseToLogics,
  useCaseToLogicsF,
  getFromLocalStorage,
  TIME_RANGES,
};
