import React from "react";
import { Check } from "lucide-react";

const SelectionIndicator = ({ isSelected, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={`absolute top-2 left-2 w-6 h-6 rounded-full border-2 flex items-center justify-center transition-colors duration-200 ${
        isSelected
          ? "bg-[#052D61] border-blue-600"
          : "bg-gray-200/80 border-white hover:bg-gray-100"
      }`}
    >
      {isSelected && <Check size={16} className="text-white" />}
    </div>
  );
};

export default React.memo(SelectionIndicator);
