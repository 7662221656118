import React, { useState, useEffect, useRef } from "react";

import { Button } from "@mui/material";
import { image } from "d3";
import ImageGrid from "./ImageGrid";
import ImageModal from "./ImageModal";

const WrongLabelSelector = ({
  onBack,
  onDelete,
  onTrainModel,
  isOpen,
  onClose,
  images,
  currentPage,
  totalPages,
  onPageChange,
  selectedStream,
  setSelectedStream,
  updateFrame,
  setImages,
}) => {
  const [deleteStreams, setDeleteStreams] = useState([]);
  const [showRnd, setShowRnd] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [unDisable, setUndisable] = useState(false);

  const handleImageClick = (url) => {
    setSelectedImage(url);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedImage("");
  };

  const cameraStreams = [
    { id: 1, name: "Counter", url: "https://picsum.photos/200" },
    { id: 2, name: "Back alley", url: "https://picsum.photos/200" },
    { id: 3, name: "Aisle", url: "https://picsum.photos/200" },
    { id: 4, name: "Counter (View 2)", url: "https://picsum.photos/200" },
  ];

  const handleSelect = (stream) => {
    console.log("stream selected", stream);
    if (selectedStream.includes(stream.frame_id)) {
      // If the stream is already selected, remove it from the array
      setSelectedStream(selectedStream.filter((id) => id !== stream.frame_id));
    } else {
      // If not, add it to the array
      setSelectedStream([...selectedStream, stream.frame_id]);
    }
  };

  const handleDelete = () => {
    setDeleteStreams(selectedStream);
    console.log("selected stream", selectedStream);
    let imgs = images.filter(
      (item) => !selectedStream.includes(item.frames[0].frame_id)
    );
    // setImages(imgs);
    onDelete(imgs);
  };

  // useEffect(() => {
  //   console.log("unDisable state changed:", unDisable);
  // }, [unDisable]);

  console.log("unDisable", unDisable);

  if (!isOpen) return null;

  return (
    <div className="relative bg-white rounded-t-lg shadow-xl w-full h-full overflow-auto">
      {/* Header */}
      <div className="flex justify-between items-center px-6 py-4">
        <h1 className="flex-1 shrink self-stretch my-auto text-3xl font-semibold tracking-tighter leading-tight text-gray-900 basis-10 max-md:max-w-full">
          Fix Incorrect Detections
        </h1>
      </div>
      <button
        onClick={onClose}
        className="fixed top-4 right-4 text-gray-600 hover:text-gray-800"
        data-testid="close-modal-button"
      >
        <span className="block w-6 h-6 relative" aria-label="Close">
          <span className="block absolute w-full h-0.5 bg-current transform rotate-45 top-1/2"></span>
          <span className="block absolute w-full h-0.5 bg-current transform -rotate-45 top-1/2"></span>
        </span>
      </button>

      {/* Navigation and Button */}
      <div className="flex items-center justify-between px-6 py-4">
        {/* <div className="flex items-center space-x-2 text-sm font-medium text-[#052D61]">
          <span className="font-semibold">Select Camera Device</span>
          <span>{">"}</span>
          <span className="text-gray-500">Specify Detection Target</span>
          <span>{">"}</span>
          <span className="text-gray-500">Fix Incorrect Detections</span>
        </div> */}

        <div className="flex gap-2">
          <button
            onClick={handleDelete}
            className={`flex overflow-hidden gap-2 justify-center items-center self-stretch px-5 py-4 my-auto text-sm font-semibold leading-none text-center rounded-xl
            ${
              !selectedStream.length
                ? "text-gray-300  bg-gray-100"
                : "text-gray-800  bg-gray-200"
            }`}
            disabled={!selectedStream.length}
          >
            <span className="self-stretch my-auto">Delete</span>
          </button>
          {/* <button
            onClick={onBack}
            className="flex overflow-hidden gap-2 justify-center items-center self-stretch px-5 py-4 my-auto text-sm font-semibold leading-none text-center text-gray-800 bg-gray-200 rounded-xl"
          >
            <span className="self-stretch my-auto">Back</span>
          </button> */}
          <button
            onClick={onTrainModel}
            className={`flex overflow-hidden gap-2 justify-center items-center self-stretch px-5 py-4 my-auto text-sm font-semibold leading-none text-center
              rounded-xl ${
                !selectedStream.length && !unDisable
                  ? "text-gray-300  bg-blue-100"
                  : "text-gray-50 bg-blue-700"
              }`}
            disabled={!selectedStream.length && !unDisable}
          >
            <span className="self-stretch my-auto">Train Model</span>
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-6 px-6 py-6">
        {images?.length > 0 ? (
          images.map((stream) => (
            <ImageGrid
              //objects = { stream?.frames[0]?.objects != null ? stream?.frames[0]?.objects : null }
              initialStream={stream}
              frame_width={stream?.frames[0]?.frame_width}
              frame_height={stream?.frames[0]?.frame_height}
              //setImages={setimageDetails}
              selectedStream={selectedStream}
              handleSelect={handleSelect}
              updateFrame={updateFrame}
              deleteStreams={deleteStreams}
              unDisable={unDisable}
              setUndisable={setUndisable}
              showRnd={showRnd}
              setShowRnd={setShowRnd}
              handleImageClick={handleImageClick}
            />
          ))
        ) : (
          <div className="flex justify-center items-center h-full">
            <p className="text-gray-500">No images available.</p>
          </div>
        )}
        {/* Enlarged Image */}
        {isModalOpen && (
          <ImageModal imageUrl={selectedImage} onClose={handleCloseModal} />
        )}
      </div>

      {/* Pagination Controls */}
      <div className="flex justify-between items-center px-6 py-4">
        <Button
          variant="contained"
          color="primary"
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </Button>
        <span>{`Page ${currentPage} of ${totalPages}`}</span>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setShowRnd(false);
            onPageChange(currentPage + 1);
          }}
          disabled={currentPage === totalPages}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default WrongLabelSelector;
