import React, { useRef, useEffect, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Modal from "@mui/material/Modal";
import { Check } from "lucide-react";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { generateThumbnail } from "../utils/generateThumbnail";
import OptionsMenu from "./OptionsMenu";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { create } from "amazon-ivs-player"; // Import the IVS player
import VideoModal from "./VideoPlayers/VideoModal";
import { fetchPresignedUrl, stopStreams } from "../services/apiServiceDetails";

export const StreamCard = ({
  stream,
  index,
  handleMoreClick,
  showOptions,
  handleOptionClick,
  handleCardClick,
  testid,
  closeOptions,
  checkOption,
  handleSelect,
  selectedStream,
  project,
  fetchDetectionStatus,
}) => {
  const videoRef = useRef(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [showOption, setshowoption] = useState(false);
  const [open, setOpen] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [playbackUrl, setPlaybackUrl] = useState(stream?.stream?.video_url);
  const [type, setType] = useState("video/mp4");
  const [liveVideoUrl, setLiveVideoUrl] = useState(project?.playback_url);

  useEffect(() => {
    console.log(project);
    if (project?.playback_url) {
      setLiveVideoUrl(project.playback_url || localStorage.getItem("playback_url"));
    }
  }, [project?.playback_url]);

  // Function to check if the live video is active

  // Use useEffect to check live video when the modal opens
  useEffect(() => {
    console.log(project);

    // Ref to store interval ID

    const checkLiveVideo = async () => {
      try {
        const response = await fetch(liveVideoUrl);
        if (response.status === 200) {
          setIsReady(true);
          setPlaybackUrl(liveVideoUrl);
        } else {
          setIsReady(false);
          setPlaybackUrl(stream?.stream?.video_url);
        }
      } catch (error) {
        setIsReady(false);
        setPlaybackUrl(stream?.stream?.video_url);
      }
    };

    // Start polling only if fetchDetectionStatus is true
    // if (fetchDetectionStatus) {
    // Start polling if not already running

    let intervalId = setInterval(checkLiveVideo, 5000);

    // } else {
    // Stop polling if fetchDetectionStatus is false
    // }

    // Cleanup when component unmounts
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [
    project,
    liveVideoUrl,
    stream?.stream?.video_url,
    fetchDetectionStatus,
    open,
  ]);

  async function setThumbnailUrl(url) {
    // "thumbnail_url": ""
    if (stream.stream?.video_url && stream.stream.thumbnail_url === "") {
      console.log("Thumbnail URL is empty");
      generateThumbnail(stream.stream?.video_url).then((thumbnail) => {
        setThumbnail(thumbnail);
      });
    } else {
      url = await fetchPresignedUrl(stream.stream.thumbnail_url);
      setThumbnail(stream.stream.thumbnail_url);
    }
  }

  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      setThumbnail("https://picsum.photos/200/300");
    } else {
      setThumbnailUrl();
    }
  }, [stream.stream?.video_url]);

  useEffect(() => {
    if (showOptions === index) {
      setshowoption(true);
    } else if (showOptions === null) {
      setshowoption(false);
    }
  }, [showOptions, index]);

  const handleOpen = () => {
    setOpen(true);
    // setIsReady(true); // Set player ready when modal opens
  };

  const handleClose = async (streamId, isRTSPStream) => {
    setOpen(false);
    setIsReady(false); // Reset player ready state when modal closes
    if (isRTSPStream) {
      await stopStreams(streamId);
    }
  };

  // Amazon IVS player setup
  // useEffect(() => {
  //   let player;
  //   if (videoRef.current) {
  //     // Create the IVS player instance
  //     player = create({
  //       wasmWorker:
  //         "https://cdn.jsdelivr.net/npm/amazon-ivs-player@latest/dist/assets/amazon-ivs-wasmworker.min.js",
  //       wasmBinary:
  //         "https://cdn.jsdelivr.net/npm/amazon-ivs-player@latest/dist/assets/amazon-ivs-wasmworker.min.wasm",
  //     });

  //     // Attach the player to the video element
  //     player.attachHTMLVideoElement(videoRef.current);
  //     console.log(player);

  //     // Load live video URL if ready, otherwise load the fallback URL
  //     if (isReady) {
  //       player.load(
  //         "https://514456358459.us-east-1.playback.live-video.net/api/video/v1/us-east-1.108782093821.channel.uO4dKaKsF9Ve.m3u8"
  //       );
  //     } else {
  //       player.load(playbackUrl);
  //     }

  //     player.play();

  //     // Cleanup player when component unmounts
  //     return () => {
  //       player.pause();
  //       player.delete();
  //     };
  //   }
  // }, [isReady, liveVideoUrl, playbackUrl]);

  return (
    <div
      className="relative overflow-hidden rounded-xl shadow-md cursor-pointer"
      data-testid={testid}
    >
      <div className="relative w-full h-48">
        {thumbnail ? (
          <img
            crossOrigin="anonymous"
            loading="lazy"
            src={thumbnail}
            alt={stream.stream?.video_name}
            className="w-full h-48 object-cover"
            data-testid="stream-card-thumbnail"
          />
        ) : (
          <div
            className="w-full h-48 bg-gray-200 flex items-center justify-center"
            data-testid="thumbnail-loading"
          >
            Loading...
          </div>
        )}
        {!checkOption && (
          <IconButton
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            onClick={handleOpen}
            data-testid="play-button"
          >
            <PlayCircleOutlineIcon
              fontSize="large"
              style={{ color: "white" }}
            />
          </IconButton>
        )}
      </div>

      {stream.rois?.length > 0 && (
        <div
          className="absolute top-2 left-2 bg-[#052D61] text-white text-xs z-15 font-medium py-1 px-2 rounded"
          data-testid="interest-areas"
        >
          Interest areas added
        </div>
      )}

      <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent p-4">
        <h3
          className="text-white text-lg font-semibold"
          data-testid="stream-title"
        >
          {stream.stream?.video_name}
        </h3>
        <p className="text-gray-300 text-sm" data-testid="stream-id">
          ID: {stream.stream?.id}
        </p>
      </div>

      {checkOption ? (
        <div
          className={`absolute top-2 left-2 w-6 h-6 rounded-full border-2 flex items-center justify-center ${
            selectedStream === stream.stream?.id
              ? "bg-[#052D61] border-blue-600"
              : "bg-gray-200 border-white"
          }`}
        >
          {selectedStream === stream.stream?.id && (
            <Check size={16} className="text-white" />
          )}
        </div>
      ) : (
        <button
          onClick={(e) => {
            e.stopPropagation();
            handleMoreClick(index);
          }}
          className="absolute top-2 right-2 text-white"
          data-testid="more-options-button"
        >
          <MoreVertIcon />
        </button>
      )}

      {showOption && (
        <OptionsMenu
          showOptions={showOptions === index}
          closeOptions={closeOptions}
          options={[
            {
              label: "Add/Edit Interest Areas",
              onClick: () => handleCardClick(thumbnail),
              testid: "add-interest-areas",
            },
          ]}
          anchorPosition={null}
        />
      )}
      <VideoModal
        open={open}
        onClose={handleClose}
        playbackUrl={playbackUrl}
        liveVideoUrl={liveVideoUrl}
        isReady={isReady}
        streamId={stream?.stream?.id}
      />
      {/* <Modal open={open} onClose={handleClose} data-testid="video-modal">
        <div className="w-full fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] max-w-4xl mx-auto bg-white rounded-lg shadow-lg overflow-hidden">
          <IconButton
            onClick={handleClose}
            style={{
              color: "white",
              position: "absolute",
              top: "10px",
              right: "10px",
              zIndex: 1,
            }}
          >
            <CloseIcon />
          </IconButton>
          <div className="relative w-full pt-[56.25%] bg-gray-900">
            {!isReady ? (
              <video
                ref={videoRef}
                controls
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
                autoPlay
                preload="metadata"
                data-testid="modal-video"
              >
                <source src={playbackUrl} type={"video/mp4"} />
                Your browser does not support the video tag.
              </video>
            ) : (
              <IVSPlayer
                handleClose={handleClose}
                playbackUrl={liveVideoUrl}
              />
            )}
          </div>
        </div>
      </Modal> */}
    </div>
  );
};
