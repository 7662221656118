// Configuration for environment
const IS_DEVELOPMENT = process.env.NODE_ENV === 'development';
const API_BASE_URL = 'https://qa.lumeneo.ai/services/report/api';

// Mock data structure for Root Cause Analysis flow
const mockRcaData = {
  "PPE_HELMET": {
    root: {
      id: "PPE_HELMET_I000",
      type: "Question",
      content: "Initial Safety Helmet Incident"
    },
    nodes: {
      "PPE_HELMET_I000": [
        {
          id: "PPE_HELMET_Q101",
          type: "Question",
          content: "Why did the employee not wear the safety helmet?",
          children: [
            {
              id: "PPE_HELMET_A102",
              type: "Answer",
              content: "Employee was unaware of the requirement."
            },
            {
              id: "PPE_HELMET_A101",
              type: "Answer",
              content: "Safety helmet was not available with the employee"
            }
          ]
        }
      ],
      "PPE_HELMET_A101": [
        {
          id: "PPE_HELMET_Q201",
          type: "Question",
          content: "Why was the safety helmet not available?",
          children: [
            {
              id: "PPE_HELMET_A201",
              type: "Answer",
              content: "Insufficient safety helmets in inventory"
            },
            {
              id: "PPE_HELMET_A202",
              type: "Answer",
              content: "Safety helmet was damaged/lost"
            }
          ]
        }
      ],
      "PPE_HELMET_A102": [
        {
          id: "PPE_HELMET_Q301",
          type: "Question",
          content: "Why was the employee unaware of the requirement?",
          children: [
            {
              id: "PPE_HELMET_A301",
              type: "Answer",
              content: "No proper training provided"
            },
            {
              id: "PPE_HELMET_A302",
              type: "Answer",
              content: "Safety protocols not properly communicated"
            }
          ]
        }
      ],
      "PPE_HELMET_A201": [
        {
          id: "PPE_HELMET_Q401",
          type: "Question",
          content: "Why were there insufficient safety helmets in inventory?",
          children: [
            {
              id: "PPE_HELMET_A401",
              type: "Answer",
              content: "Ineffective system to track PPE inventory levels"
            },
            {
              id: "PPE_HELMET_A402",
              type: "Answer",
              content: "Unexpected demand surge for safety helmets"
            }
          ]
        }
      ]
    }
  }
};

// Helper function to handle API errors
const handleApiError = (error) => {
  console.error('API Error:', error);
  throw error;
};

// Map incident types to their RCA IDs
export const incidentTypeMap = {
  "Safety Helmet": "PPE_HELMET",
  "PPE - Fall Protection": "FALL_PROTECTION",
  "Forklift Speed Violation": "PPE_HELMET"
};

// Get root node for an incident type
export async function getIncidentRootNode(useCase) {
  console.log('Getting root node for useCase:', useCase);
  
  if (IS_DEVELOPMENT) {
    const mappedType = incidentTypeMap[useCase];
    console.log('Mapped incident type:', mappedType);
    
    if (!mappedType || !mockRcaData[mappedType]) {
      console.error('No mock data found for type:', useCase);
      throw new Error(`No mock data available for incident type: ${useCase}`);
    }
    
    // Get initial neighbors as first response
    const initialNeighbors = mockRcaData[mappedType].nodes["PPE_HELMET_I000"];
    console.log('Initial neighbors:', initialNeighbors);
    return initialNeighbors[0];
  }
  
  try {
    const response = await fetch(`${API_BASE_URL}/incidentNodeID`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ useCase }),
    });
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    return await response.json();
  } catch (error) {
    handleApiError(error);
  }
}

// Get neighbor nodes for a given node ID
export async function getNodeNeighbors(nodeId) {
  console.log('Getting neighbors for nodeId:', nodeId);
  
  if (IS_DEVELOPMENT) {
    // Extract the incident type from nodeId (e.g., PPE_HELMET_A101 -> PPE_HELMET)
    const incidentType = nodeId.split('_').slice(0, 2).join('_');
    console.log('Extracted incident type:', incidentType);
    
    const neighbors = mockRcaData[incidentType]?.nodes[nodeId];
    console.log('Found neighbors:', neighbors);
    
    if (!neighbors) {
      console.log('No neighbors found for nodeId:', nodeId);
      return [];
    }
    
    return neighbors;
  }
  
  try {
    const response = await fetch(`${API_BASE_URL}/nodes/${nodeId}/neighbors`);
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    return await response.json();
  } catch (error) {
    handleApiError(error);
  }
}
