import React from "react";
import { Navigate } from "react-router-dom";
import useSession from "../hooks/useSession";

const PrivateRoute = ({ children }) => {
  const { user, loading } = useSession(); // Destructure user and loading from useSession
  const session = localStorage.getItem("session");

  // Show loading state while fetching session
  if (loading) {
    return <div>Loading...</div>; // Show loading message until user session is checked
  }

  // Redirect to login if there is no session
  if (!session) {
    return <Navigate to="/login" />;
  }

  // If user is authenticated, render the children
  if (user) {
    return children;
  }

  // Redirect to login if user is not authenticated
  return <Navigate to="/login" />;
};

export default PrivateRoute;
