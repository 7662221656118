import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
  useContext,
} from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import config from "../../config";
import Sidebar from "../../components/Sidebar";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { Button } from "@mui/material";
import GroupedChartDetails from "./GroupedChartDetails";
import AlertTable from "./AlertTable";
import { processAlertData } from "./../../utils/chartData";
import {
  getAllAlerts,
  getVideos,
  getAllROIImages,
  getRequestStatus,
  startDetection,
} from "../../services/apiServiceDetails";
import { DotLoader } from "react-spinners";
import StreamTable from "../../components/StreamTable";
import VideoPlayer from "../../components/VideoPlayer";
import { AlertTableData } from "../../mockData/mockData";
import Tabs from "../../components/Tabs";
import Activity from "../../components/Activity";
import { useParams } from "react-router-dom/dist";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import ModalTrainingStatus from "../../components/ModelTrainingStatus";
import ModalLayout from "../../components/TrainModals/ModalLayout";
import {
  setModalStatus,
  setTrainingStatus,
} from "./../../redux/actions/trainingStatusActions";
import StreamSelectionComponent from "../../components/StreamSelectModal/StreamSelectionComponent";
import VisualizationActivity from "../../components/VisualizationActivity";
import useCustomToast from "../../hooks/useToast";
import { WebSocketContext } from "../../context/WebSocketContext";

export const Visualize = () => {
  const { siteid, project, projectid } = useParams();
  const showToast = useCustomToast();

  const navItems = [
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/6742242c9064deb66a33a690108c59f05063597fd9064c3653ef37c2eacb0e67?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde",
      text: "Use Case Details",
      active:
        window.location.pathname ===
        `/site-details/${siteid}/${project}/${projectid}`,
      navigate: () =>
        navigate(`/site-details/${siteid}/${project}/${projectid}`),
    },
    // {
    //   icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/389f597f3dbe7367231aff370cd6b6a526ff3bd25933c7a8ab653571d65eb103?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde",
    //   text: "Model Dashboard",
    //   active: window.location.pathname.includes(`/model-dashboard`),
    //   navigate: () =>
    //     navigate(`/site/${siteid}/${project}/${projectid}/model-dashboard`),
    // },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/5606b06eae36d23ab1603f3e1c8b04d8b7d174fe2a7cceaee2ecec9fa22a9b09?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde",
      text: "Detections",
      active: window.location.pathname.includes(
        `/site-details/${siteid}/${project}/${projectid}/detections`
      ),
      navigate: () =>
        navigate(`/site-details/${siteid}/${project}/${projectid}/detections`),
    },
    // {
    //   icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/ae7e6fac086adfa5edba6aaec57b23e81441178bb24bf679c3aae6b13cc45c7c?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde",
    //   text: "Business Workflow",
    //   active:
    //     window.location.pathname ===
    //     `/site-details/${siteid}/${project}/business-workflow`,
    //   navigate: () =>
    //     projectid ? navigate(`/site-details/${siteid}/${project}/${projectid}`) : null,
    // },
  ];

  const [chartData, setChartData] = useState({});
  const [alertCount, setAlertCount] = useState(0);
  const [alerts, setAlerts] = useState([]);
  const [alertTableData, setAlertTableData] = useState([]);
  const [selectedOption, setSelectedOption] = useState("All Videos");
  const [videoData, setVideoData] = useState([]);
  const [name, setName] = useState("");
  const pollingInterval = 5000; // Polling every 5 seconds
  const [open, setOpen] = useState(false);
  const [alertTypes, setAlertTypes] = useState(false);
  const [tabActive, setTabActive] = useState(12);
  const [loading, setLoading] = useState(true);
  const [requestID, setRequestID] = useState(null);
  const [hasDeleteAnnotation, setDeleteAnnotation] = useState(false);
  const [retrainModal, setRetrainModal] = useState(false);
  const [images, setImages] = useState([]);
  const [imageAOIDeleted, setImageAOIDeleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [totalImages, setTotalImages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [statusmessage, setStatusmessage] = useState(
    "Model Traning is in progress.Please wait as this process may take a few minutes"
  );
  const [statusType, setStatusType] = useState("progress");
  const [pageSize] = useState(12);
  const [hasData, setHasData] = useState(false); // Button appears only when data is fetched
  const imageCache = useRef({});
  const pollingIntervalRef = useRef(null);
  const [streamOpen, setStreamOpen] = useState(false);
  const { API_URL, API_KEY, USE_MOCK_DATA } = config;
  const videoUrl = "https://www.w3schools.com/html/mov_bbb.mp4"; // Sample video URL
  const [selectedTab, setSelectedTab] = useState("Overview");
  const playbackUrl =
    "https://514456358459.us-east-1.playback.live-video.net/api/video/v1/us-east-1.108782093821.channel.vofTxL10MXB1.m3u8";

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const trainingStatus = useSelector((state) => state.trainingStatus);
  const siteNames = useSelector((state) => state.siteDetails);
  const { connectWebSocket, triggerFetch, setTriggerFetch } =
    useContext(WebSocketContext);
  var StreamOptions = ["All Videos"];

  // disptach projectid in user state
  useEffect(() => {
    if (projectid) {
      dispatch({ type: "SET_PROJECT_ID", payload: projectid });
      connectWebSocket(projectid);
    }
  }, [projectid]);
  // console.log("trainingStatus", trainingStatus);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleTestModel = () => setStreamOpen(true);
  const handleCloseSelectStream = () => setStreamOpen(false);

  // console.log(requestID, hasDeleteAnnotation);

  // Polling logic to repeatedly fetch data every `pollingInterval`
  const startPolling = useCallback(() => {
    if (pollingIntervalRef.current) {
      clearInterval(pollingIntervalRef.current);
    }
    pollingIntervalRef.current = setInterval(() => { }, pollingInterval);
  }, [currentPage, pollingInterval]);

  // Stop polling
  const stopPolling = useCallback(() => {
    if (pollingIntervalRef.current) {
      clearInterval(pollingIntervalRef.current);
      pollingIntervalRef.current = null;
    }
  }, []);

  // // Fetch images for the current page when the page changes
  // useEffect(() => {
  //   fetchImages(currentPage);
  // }, [currentPage, fetchImages]);

  useEffect(() => {
    // console.log("imageAOIDeleted", imageAOIDeleted);
    // Only call fetchImages if the current page changes or imageAOIDeleted is true
    if (imageAOIDeleted || currentPage !== undefined) {
      // Reset the imageAOIDeleted flag after fetching
      if (imageAOIDeleted) {
        setImageAOIDeleted(false);
      }
    }
  }, [currentPage, imageAOIDeleted]);

  const totalPages = useMemo(
    () => Math.ceil(totalImages / pageSize),
    [totalImages, pageSize]
  );

  // Handle page change in the modal
  const handlePageChange = useCallback(
    (newPage) => {
      if (newPage > 0 && newPage <= totalPages) {
        setCurrentPage(newPage);
      }
    },
    [totalPages]
  );

  const startdetection = async () => {
    try {
      const resp = await startDetection(projectid);
      showToast("Detection started", "success");
      // handleTestModel();
    } catch (error) {
      console.error("Error starting detection:", error);
      showToast("An error occurred while starting detection.", "error");
    }
  };

  const handleRetrainModalOpen = () => {
    dispatch(setModalStatus("wrong-label-detection"));
    setRetrainModal(true);
    stopPolling(); // Stop polling when the modal opens
  };

  useEffect(() => {
    const siteName = siteNames.names.find((item) => item.id == siteid)?.name;
    setName(siteName);
  }, []);

  const onTabClick = (dataset) => {
    var number = parseInt(dataset.match(/\d+/)[0], 10);
    setTabActive(number);
    console.log("Tab clicked:", number);
  };

  useEffect(() => {
    const fetchData = async () => {
      var processedData;
      try {
        // if (USE_MOCK_DATA) {
        //   processedData = processAlertData(vt); // Process the data
        //   setAlertTableData(vt);
        // } else {
        const data = await getAllAlerts(projectid, 24); // Fetch the alert data
        const videoDetail = await getVideos(projectid); // Fetch the videos
        console.log(videoDetail);
        setVideoData(videoDetail);
        // StreamOptions.push(videoDetail);
        if (videoDetail.length > 0) {
          videoDetail.map((video) =>
            StreamOptions.push(video.stream.video_name)
          );
        }

        console.log("processedData", JSON.stringify(processedData));

        setAlertTableData(data);
        processedData = processAlertData(data); // Process the data
        //}
        // console.log("processedData", processedData);
        setChartData(processedData.barChartData); // Set the processed data
        setAlertCount(processedData.alertCount);
        setAlertTypes(processedData.alertTypes);
      } catch (error) {
        console.error("Error fetching or processing data:", error);
      } finally {
        setLoading(false); // Ensure loading is set to false regardless of success or error
      }
    };

    fetchData();
  }, []);

  const colorScheme = ["#FFC643", "#FF9393", "#9BD4FF", "#90DD94"]; // Example color scheme

  const selectDataOptions = {
    names: [
      {
        id: 1,
        name: "PPE Identification",
      },
      {
        id: 2,
        name: "Apron Indentifier",
      },
    ],
  };

  const combinedAlerts =
    alertTableData && alertTableData.alerts && alertTableData.alerts.length > 0
      ? alertTableData.alerts.flatMap((item) => item.all_alerts ?? [])
      : [];

  const handleChange = (event) => {
    //setLoading(true);
    console.log(event.target.value);
    setSelectedOption(event.target.value);
    var filteredData = [];
    if (event.target.value != "All Videos") {
      filteredData = combinedAlerts.filter(
        (d) => d.alert_type == event.target.value.toString()
      );
    } else {
      filteredData = combinedAlerts;
    }
    // console.log("filteredData", filteredData);
    setAlertTableData(filteredData);
    // setLoading(false);
  };

  const handleBackClick = () => {
    navigate("/home"); // Navigate to the dashboard page
  };

  // console.log("chartalertData", alertTableData);

  const fetchRequestStatus = async () => {
    try {
      const response = await getRequestStatus(requestID);
      // Stop polling after receiving data
      return response.status; // Indicate that data was received
    } catch (error) {
      console.error("Error fetching data:", error);
      // Indicate that data was not received
    }
  };

  useEffect(() => {
    if (requestID) {
      const intervalId = setInterval(async () => {
        const receivedData = await fetchRequestStatus();
        if (receivedData === "Completed") {
          setStatusType("success");
          setStatusmessage(
            "Training complete! Your model is ready as per your requirements. Click 'Start Detection' to view results!"
          );
          clearInterval(intervalId);
        } else {
          setStatusmessage(
            "Model Traning is in progress.Please wait as this process may take a few minutes"
          );
        }
      }, pollingInterval);
      return () => clearInterval(intervalId);
    }
  }, [requestID]);

  return (
    <div className="h-screen w-screen bg-gray-100 ">
      <div className="flex h-full">
        <Sidebar
          navItems={navItems}
          userName={user.name}
          usermail={user.email}
          currentPage="Visualization"
          selectData={localStorage.getItem("projectname")}
          handleBackClick={handleBackClick}
        // selectDataOptions={selectDataOptions}
        />
        <main className="flex-1 flex-col ml-5 h-screen w-full">
          <div className="flex flex-col grow justify-center py-4 pr-4 h-full  max-md:max-w-full">
            <div className="flex overflow-hidden flex-col w-full h-full shadow-sm max-md:max-w-full">
              <div className="flex gap-4 items-center px-6 py-4 w-full text-sm font-medium leading-none text-gray-400 bg-white rounded-t-xl border-b border-gray-100 max-md:px-5 max-md:max-w-full">
                <div className="flex gap-1 items-center self-stretch my-auto min-w-[240px]">
                  <Link to={`/home`} className={`self-stretch my-auto`}>
                    Dashboard
                  </Link>
                  <ChevronRightOutlinedIcon
                    style={{ fontSize: "1.25rem", color: "inherit" }}
                    className="shrink-0 self-stretch my-auto w-5 aspect-square"
                  />
                  <div className="flex gap-2.5 items-start self-stretch my-auto">
                    <div className="gap-2.5 self-stretch">{name}</div>
                  </div>
                  <ChevronRightOutlinedIcon
                    style={{ fontSize: "1.25rem", color: "inherit" }}
                    className="shrink-0 self-stretch my-auto w-5 aspect-square"
                  />
                  <div className="flex gap-2.5 items-start self-stretch my-auto whitespace-nowrap">
                    <Link
                      to={`/site-details/${siteid}/projects`}
                      className={`self-stretch my-auto`}
                    >
                      Projects
                    </Link>
                  </div>
                  <ChevronRightOutlinedIcon
                    style={{ fontSize: "1.25rem", color: "inherit" }}
                    className="shrink-0 self-stretch my-auto w-5 aspect-square"
                  />
                  <div className="self-stretch my-auto">
                    {localStorage.getItem("projectname")}
                  </div>
                </div>
              </div>
              <div className="flex flex-col px-8 pt-8 w-full bg-white rounded-b-xl max-md:px-5 max-md:max-w-full  overflow-y-scroll">
                <div className="flex flex-wrap gap-2.5 items-center w-full max-md:max-w-full">
                  <div className="flex gap-2 items-center self-stretch pb-1.5 my-auto w-7">
                    <div className="flex gap-2 justify-center items-center self-stretch my-auto w-7 h-7 bg-gray-100 rounded-full min-h-[28px]">
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/67dc089b297e35f19fdb59dd93dddc5a2cb859ed56a62e9c4a3e54795154b209?placeholderIfAbsent=true&apiKey=c3798bd8287e48f5a443b9c5de4a9bde"
                        className="object-contain self-stretch my-auto w-4 aspect-square"
                      />
                    </div>
                  </div>
                  <div className="flex-1 shrink self-stretch my-auto text-3xl font-medium leading-tight text-gray-900 basis-0 max-md:max-w-full">
                    {localStorage.getItem("projectname")}
                  </div>
                </div>
                <div className="w-full h-full text-sm font-medium leading-none">
                  <VisualizationActivity data={videoData} />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>

      <VideoPlayer videoUrl={videoUrl} open={open} onClose={handleClose} />
    </div>
  );
};
