import React from 'react';
import TableRow from './TableRow';

const EditableTable = ({ rows, onChange, onAddRow, onDeleteRow }) => {
  return (
    <div className="w-full bg-white shadow-sm rounded-lg overflow-hidden">
      <div className="overflow-x-auto">
        <table className="w-full table-auto border-2 border-blue-100">
          <thead className="bg-gradient-to-br from-primary-focus to-primary text-white">
            <tr>
              {[
                "Action", 
                "Description", 
                "Assignee", 
                "Due Date", 
                "Status",
                "Verification By", 
                "Actions"
              ].map((header, idx) => (
                <th 
                  key={idx}
                  className="px-4 py-3 text-left text-sm font-semibold text-gray-700 text-white"
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {rows?.map((row, index) => (
              <tr 
                key={index} 
                className={`
                  hover:bg-blue-50/50 
                  transition-colors 
                  duration-200 
                  ease-in-out
                  ${index % 2 === 0 ? 'bg-white' : 'bg-gray-50/50'}
                `}
              >
                <TableRow
                  row={row}
                  index={index}
                  onChange={onChange}
                  onDelete={onDeleteRow}
                  isEditable={true}
                />
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {rows?.length === 0 && (
        <div className="text-center py-8 text-gray-500">
          <p className="text-sm">No planned actions yet</p>
        </div>
      )}
    </div>
  );
};

export default EditableTable;
