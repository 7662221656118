import { X } from 'lucide-react';
import React from 'react';

const Modal = ({ isOpen, onClose, children, heading }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 overflow-auto z-50">
      <div className="flex h-screen items-center justify-center">
        <div className="bg-white rounded-lg shadow-md w-full h-full overflow-y-auto">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;