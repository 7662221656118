// Utility function to filter alerts based on time frame
function filterAlertsByTimeFrame(alerts, timeFrame) {
    const now = new Date();
    const pastDate = new Date();
    
    switch (timeFrame) {
        case '24hours':
            pastDate.setHours(now.getHours() - 24);
            break;
        case 'week':
            pastDate.setDate(now.getDate() - 7);
            break;
        case 'month':
            pastDate.setMonth(now.getMonth() - 1);
            break;
        case 'year':
            pastDate.setFullYear(now.getFullYear() - 1);
            break;
        default:
            return [];
    }
    return alerts.filter(alert => new Date(alert.created_at) >= pastDate);
}

// Function to create filtered data for each time frame
export const createRecentAlertData=(data)=> {
    const filteredData = {
        Hours: [],
        Week: [],
        Month: [],
        Year: []
    };

    data.sites.forEach(site => {
        site.use_cases?.forEach(use_case => {
            const alerts = use_case.alerts || [];
            
            // Filter alerts for each time frame
            filteredData.Hours.push({
                site_id: site.site_id,
                site_name: site.site_name,
                use_case_name: use_case.name,
                alerts: filterAlertsByTimeFrame(alerts, '24hours')
            });

            filteredData.Week.push({
                site_id: site.site_id,
                site_name: site.site_name,
                use_case_name: use_case.name,
                alerts: filterAlertsByTimeFrame(alerts, 'week')
            });

            filteredData.Month.push({
                site_id: site.site_id,
                site_name: site.site_name,
                use_case_name: use_case.name,
                alerts: filterAlertsByTimeFrame(alerts, 'month')
            });

            filteredData.Year.push({
                site_id: site.site_id,
                site_name: site.site_name,
                use_case_name: use_case.name,
                alerts: filterAlertsByTimeFrame(alerts, 'year')
            });
        });
    });

    return filteredData;
}

