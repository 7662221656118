import React from "react";
import Tooltip from "@mui/material/Tooltip";

const Tabs = ({ selectedTab = "Overview", setSelectedTab }) => {
  const tabs = ["Overview", "Heatmap", "Spaghetti Diagram"];
  // console.log(selectedTab)
  return (
    <div className="flex font-golos" data-testid="tabs-container">
      {tabs.map((tab) => (
        <Tooltip
          key={tab}
          title={tab !== "Overview" ? "Coming Soon" : ""}
          arrow // Adds an arrow to the tooltip
          disableHoverListener={tab === "Overview"} // Only show tooltip for "Spaghetti Diagram"
        >
          <div
            className={`overflow-hidden gap-2 self-stretch px-3.5 py-2 my-auto whitespace-nowrap rounded-md ${
              tab !== "Overview" ? "" : "cursor-pointer "
            }transition-shadow duration-300 
            ${tab !== "Overview" ? "text-gray-300" : ""}
            ${
              selectedTab === tab
                ? "font-semibold bg-white border border-slate-300 shadow-[0px_4px_8px_rgba(0,0,0,0.2)] text-slate-700"
                : "border border-transparent shadow-none"
            }
            `}
            onClick={() => (tab === "Overview" ? setSelectedTab(tab) : void 0)}
            data-testid={`tab-${tab.toLowerCase().replace(" ", "-")}`}
          >
            {tab}
          </div>
        </Tooltip>
      ))}
    </div>
  );
};

export default Tabs;
