import React from 'react'

const Loader = ({ heading = "Loading", subheading = "Please wait!!" }) => {
    return (
        <section className="flex overflow-hidden flex-row justify-center items-center mt-8 w-full bg-gray-50 rounded-2xl border-2 border-gray-200 border-solid min-h-[600px] max-md:max-w-full">
            <div className="flex flex-col items-center justify-center">
                <div className="animate-spin rounded-full h-20 w-20 border-t-4 border-b-4 border-primary"></div>
                <h2 className="mt-6 text-xl font-semibold text-gray-700">
                    {heading}...
                </h2>
                <p className="text-gray-500">
                    {subheading}
                </p>
            </div>
        </section>
    )
}

export default Loader