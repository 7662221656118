import React, { useMemo, useState, useEffect, useCallback } from 'react';
import {
    ResponsiveContainer,
    ComposedChart,
    Bar,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ReferenceLine,
    Cell,
} from 'recharts';
import { GetSiteEvents } from '../../../services/apiServiceDetails';

const TIME_CONSTANTS = {
    HOUR_IN_MS: 60 * 60 * 1000,
    DAY_IN_MS: 24 * 60 * 60 * 1000,
};

const CONFIG = {
    pickedBoxes: {
        limits: { upper: 100, lower: 10 },
        colors: {
            belowLower: '#FF4444',
            withinLimits: '#4477AA',
            aboveUpper: '#FF4444',
        },
        key: 'picked_boxes',
        label: 'Picked Boxes'
    },
    packedBoxes: {
        limits: { upper: 100, lower: 10 },
        colors: {
            belowLower: '#FF4444',
            withinLimits: '#4477AA',
            aboveUpper: '#FF4444',
        },
        key: 'packed_boxes',
        label: 'Packed Boxes'
    },
    shippedBoxes: {
        limits: { upper: 100, lower: 10 },
        colors: {
            belowLower: '#FF4444',
            withinLimits: '#4477AA',
            aboveUpper: '#FF4444',
        },
        key: 'shipped_boxes',
        label: 'Shipped Boxes'
    },
};

const calculateMovingAverage = (data, windowSize, key) => {
    if (!data || data.length === 0) return [];
    const values = data.map(item => item[key]);
    const result = [];

    for (let i = 0; i < values.length; i++) {
        if (i < windowSize - 1) {
            result.push(null);
            continue;
        }

        let sum = 0;
        for (let j = i - (windowSize - 1); j <= i; j++) {
            sum += values[j];
        }
        const avg = sum / windowSize;
        result.push(parseFloat(avg.toFixed(2)));
    }

    return data.map((item, index) => ({
        ...item,
        [`${key}MA`]: result[index],
    }));
};

const getBarColor = (value, limits, colors) => {
    if (value < limits.lower) return colors.belowLower;
    if (value > limits.upper) return colors.aboveUpper;
    return colors.withinLimits;
};

const formatDate = (dateStr, timeFrame) => {
    const date = new Date(dateStr);
    switch (timeFrame) {
        case "24hrs":
            return date.toLocaleString('en-US', { 
                hour: 'numeric',
                hour12: true
            }).replace(':00', '');
        case "1week":
            return date.toLocaleDateString('en-US', { weekday: 'short' });
        case "1month":
            return date.getDate().toString().padStart(2, '0');
        case "1year":
            return date.toLocaleDateString('en-US', { month: 'short' });
        default:
            return dateStr;
    }
};

const generateAllPossibleTicks = (timeFrame) => {
    const now = new Date();
    const ticks = [];
    
    switch (timeFrame) {
        case "Hours":
        case "24hrs":
            // Last 24 hours
            for (let i = 23; i >= 0; i--) {
                const date = new Date(now);
                date.setHours(date.getHours() - i);
                date.setMinutes(0, 0, 0);
                ticks.push(formatDate(date, timeFrame));
            }
            break;
        case "Week":
        case "1week":
            // Last 7 days
            for (let i = 6; i >= 0; i--) {
                const date = new Date(now);
                date.setDate(date.getDate() - i);
                ticks.push(formatDate(date, timeFrame));
            }
            break;
        case "Month":
        case "1month":
            // Days in current month
            const daysInMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
            for (let i = 1; i <= daysInMonth; i++) {
                const date = new Date(now.getFullYear(), now.getMonth(), i);
                ticks.push(formatDate(date, timeFrame));
            }
            break;
        case "Year":
        case "1year":
            // Last 12 months
            for (let i = 0; i < 12; i++) {
                const date = new Date(now.getFullYear(), i, 1);
                ticks.push(formatDate(date, timeFrame));
            }
            break;
    }
    return ticks;
};

const getXAxisKey = (timeFrame) => {
    return "formattedDate";
};

let chartedData = [
  {
      "site_id": 95,
      "use_cases": [
          {
              "name": "Inventory Management Efficiency",
              "percentage_change": {
                  "packed_boxes": 4800,
                  "picked_boxes": 4800,
                  "shipped_boxes": 4800
              },
              "Events": [
                  {
                      "created_at": "2024-12-05 14:00:00",
                      "picked_boxes": 0,
                      "packed_boxes": 0,
                      "shipped_boxes": 0
                  },
                  {
                      "created_at": "2024-12-05 15:00:00",
                      "picked_boxes": 0,
                      "packed_boxes": 0,
                      "shipped_boxes": 0
                  },
                  {
                      "created_at": "2024-12-05 16:00:00",
                      "picked_boxes": 0,
                      "packed_boxes": 0,
                      "shipped_boxes": 0
                  },
                  {
                      "created_at": "2024-12-05 17:00:00",
                      "picked_boxes": 0,
                      "packed_boxes": 0,
                      "shipped_boxes": 0
                  },
                  {
                      "created_at": "2024-12-05 18:00:00",
                      "picked_boxes": 0,
                      "packed_boxes": 0,
                      "shipped_boxes": 0
                  },
                  {
                      "created_at": "2024-12-05 19:00:00",
                      "picked_boxes": 0,
                      "packed_boxes": 0,
                      "shipped_boxes": 0
                  },
                  {
                      "created_at": "2024-12-05 20:00:00",
                      "picked_boxes": 0,
                      "packed_boxes": 0,
                      "shipped_boxes": 0
                  },
                  {
                      "created_at": "2024-12-05 21:00:00",
                      "picked_boxes": 0,
                      "packed_boxes": 0,
                      "shipped_boxes": 0
                  },
                  {
                      "created_at": "2024-12-05 22:00:00",
                      "picked_boxes": 0,
                      "packed_boxes": 0,
                      "shipped_boxes": 0
                  },
                  {
                      "created_at": "2024-12-05 23:00:00",
                      "picked_boxes": 0,
                      "packed_boxes": 0,
                      "shipped_boxes": 0
                  },
                  {
                      "created_at": "2024-12-06 00:00:00",
                      "picked_boxes": 0,
                      "packed_boxes": 0,
                      "shipped_boxes": 0
                  },
                  {
                      "created_at": "2024-12-06 01:00:00",
                      "picked_boxes": 0,
                      "packed_boxes": 0,
                      "shipped_boxes": 0
                  },
                  {
                      "created_at": "2024-12-06 02:00:00",
                      "picked_boxes": 0,
                      "packed_boxes": 0,
                      "shipped_boxes": 0
                  },
                  {
                      "created_at": "2024-12-06 03:00:00",
                      "picked_boxes": 0,
                      "packed_boxes": 0,
                      "shipped_boxes": 0
                  },
                  {
                      "created_at": "2024-12-06 04:00:00",
                      "picked_boxes": 0,
                      "packed_boxes": 0,
                      "shipped_boxes": 0
                  },
                  {
                      "created_at": "2024-12-06 05:00:00",
                      "picked_boxes": 0,
                      "packed_boxes": 0,
                      "shipped_boxes": 0
                  },
                  {
                      "created_at": "2024-12-06 06:00:00",
                      "picked_boxes": 0,
                      "packed_boxes": 0,
                      "shipped_boxes": 0
                  },
                  {
                      "created_at": "2024-12-06 07:00:00",
                      "picked_boxes": 0,
                      "packed_boxes": 0,
                      "shipped_boxes": 0
                  },
                  {
                      "created_at": "2024-12-06 08:00:00",
                      "picked_boxes": 0,
                      "packed_boxes": 0,
                      "shipped_boxes": 0
                  },
                  {
                      "created_at": "2024-12-06 09:00:00",
                      "picked_boxes": 28,
                      "packed_boxes": 28,
                      "shipped_boxes": 28
                  },
                  {
                      "created_at": "2024-12-06 10:00:00",
                      "picked_boxes": 8,
                      "packed_boxes": 8,
                      "shipped_boxes": 8
                  },
                  {
                      "created_at": "2024-12-06 11:00:00",
                      "picked_boxes": 8,
                      "packed_boxes": 8,
                      "shipped_boxes": 8
                  },
                  {
                      "created_at": "2024-12-06 12:00:00",
                      "picked_boxes": 0,
                      "packed_boxes": 0,
                      "shipped_boxes": 0
                  },
                  {
                      "created_at": "2024-12-06 13:00:00",
                      "picked_boxes": 4,
                      "packed_boxes": 4,
                      "shipped_boxes": 4
                  }
              ]
          }
      ]
  }
];

const useChartData = (data, timeFrame, config) => {
    const [processedData, setProcessedData] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const allPossibleTicks = useMemo(() => generateAllPossibleTicks(timeFrame), [timeFrame]);

    useEffect(() => {
        if (!data) {
            setError("No data available");
            setLoading(false);
            return;
        }

        try {
            setLoading(true);
            setError(null);
            
            if (!data?.sites?.[0]) {
                throw new Error(`No data available for the selected site`);
            }

            const inventoryData = data.sites[0].use_cases.find(
                uc => uc.name === "Inventory Management Efficiency"
            );

            if (!inventoryData || !inventoryData.Events) {
                throw new Error(`No ${config.label.toLowerCase()} data available for the selected time period`);
            }

            const processedEvents = inventoryData.Events.map(event => {
                // const date = new Date(event.created_at);
                return {
                    formattedDate: formatDate(event.created_at, timeFrame),
                    [config.key]: event[config.key] || 0,
                    created_at: event.created_at
                };
            });
            // if all config.keys or values are 0, remove then set error
            if (processedEvents.every(event => event[config.key] === 0)) {
                throw new Error(`No ${config.label.toLowerCase()} data available for the selected time period`);
            }
            // console.log("processedEvents", processedEvents);

            // Create a map of all possible ticks with default values
            const fullDataMap = allPossibleTicks.reduce((acc, tick) => {
                acc[tick] = {
                    formattedDate: tick,
                    [config.key]: 0,
                    created_at: null
                };
                return acc;
            }, {});

            // console.log("fullDataMap", fullDataMap);

            // Update the map with actual values
            processedEvents.forEach(event => {
                const key = event.formattedDate;
                if (fullDataMap[key]) {
                    fullDataMap[key] = event;
                }
            });

            // console.log("fullDataMap", fullDataMap);

            // Convert map back to array and sort
            let fullData = Object.values(fullDataMap);
            
            // Find current tick based on timeFrame
            const now = new Date();
            let currentTick = formatDate(now, timeFrame);

            // Reorder array to put current tick last
            fullData = [
                ...fullData.filter(item => item.formattedDate !== currentTick),
                ...fullData.filter(item => item.formattedDate === currentTick)
            ];

            // console.log("fullData", fullData);

            setProcessedData(fullData);
            setError(null);
        } catch (error) {
            console.error('Error processing data:', error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    }, [data, timeFrame, config, allPossibleTicks]);

    return { data: processedData, loading, error };
};

const ChartComponent = ({ timeFrame, movingAverageWindow, siteId, config, data, loading: parentLoading }) => {
    const { data: chartData, loading: chartLoading, error } = useChartData(data, timeFrame, config);

    if (parentLoading || chartLoading) {
        return (
            <div className="w-full h-[500px] bg-white rounded-lg shadow-sm">
                <div className="animate-pulse h-full">
                    <div className="h-6 bg-gray-200 rounded w-1/4 mb-4"></div>
                    <div className="h-[calc(100%-2rem)] bg-gray-100 rounded-lg">
                        <div className="h-full w-full flex items-center justify-center">
                            <div className="flex flex-col items-center space-y-2">
                                <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
                                <p className="text-sm text-gray-500">Loading chart data...</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (error) {
      return (
        <div className="w-full h-[500px] bg-white rounded-lg shadow-sm p-6 border border-gray-200">
          <div className="h-full flex flex-col items-center justify-center text-center">
            <div className="mb-4">
              <svg className="w-16 h-16 text-gray-400 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
            <h3 className="text-xl font-semibold text-gray-700 mb-2">
              {config.label} Data Unavailable
            </h3>
            <p className="text-gray-500 mb-4 max-w-md">
              {error === 'No forklift data available' 
                ? `No ${config.label.toLowerCase()} data is currently available for this site and time period.`
                : error}
            </p>
            <div className="text-sm text-gray-400">
              Try selecting a different time period.
            </div>
          </div>
        </div>
      );
    }

    const dataWithMA = calculateMovingAverage(chartData, movingAverageWindow, config.key);

    return (
        <div className="w-full h-[500px]">
            <h3 className="text-lg font-semibold mb-4">{config.label} Control Chart</h3>
            <ResponsiveContainer width="100%" height="100%">
                <ComposedChart
                    data={dataWithMA}
                    margin={{ top: 20, right: 120, left: 20, bottom: 60 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis 
                        dataKey="formattedDate"
                        interval={0}
                        angle={-45}
                        textAnchor="end"
                        height={80}
                        tick={{ fontSize: 12 }}
                    />
                    <YAxis 
                        label={{ 
                            value: 'Number of Boxes', 
                            angle: -90, 
                            position: 'insideLeft',
                            offset: -5
                        }}
                    />
                    <Tooltip
                        content={({ active, payload }) => {
                            if (active && payload && payload.length) {
                                return (
                                    <div className="bg-white p-2 border rounded shadow">
                                        <p className="text-sm">{`${config.label}: ${payload[0].value}`}</p>
                                        {payload[1]?.value && (
                                            <p className="text-sm">{`Moving Average: ${payload[1].value}`}</p>
                                        )}
                                        <p className="text-xs text-gray-500">
                                            {payload[0].payload.created_at ? 
                                                new Date(payload[0].payload.created_at).toLocaleString() :
                                                'No data'
                                            }
                                        </p>
                                    </div>
                                );
                            }
                            return null;
                        }}
                    />
                    <Legend 
                        content={({ payload }) => (
                            <div className="flex justify-center items-center space-x-6 mt-2">
                                <div className="flex items-center">
                                    <div 
                                        className="w-4 h-4 mr-2"
                                        style={{ backgroundColor: config.colors.withinLimits }}
                                    />
                                    <span>{config.label}</span>
                                </div>
                                <div className="flex items-center">
                                    <div 
                                        className="w-4 h-4 mr-2"
                                        style={{ 
                                            background: 'linear-gradient(45deg, transparent 0%, transparent 49%, #FF8800 49%, #FF8800 51%, transparent 51%, transparent 100%)',
                                            border: '1px solid #FF8800'
                                        }}
                                    />
                                    <span>Moving Average</span>
                                </div>
                            </div>
                        )}
                    />
                    <Bar 
                        dataKey={config.key}
                        name={config.label}
                        barSize={20}
                    >
                        {dataWithMA.map((entry, index) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={getBarColor(entry[config.key], config.limits, config.colors)}
                            />
                        ))}
                    </Bar>
                    <ReferenceLine 
                        y={config.limits.upper} 
                        stroke="#FF4444" 
                        strokeDasharray="3 3"
                        label={{
                            value: 'Upper Limit',
                            position: 'right',
                            fill: '#FF4444'
                        }}
                    />
                    <ReferenceLine 
                        y={config.limits.lower} 
                        stroke="#FF4444" 
                        strokeDasharray="3 3"
                        label={{
                            value: 'Lower Limit',
                            position: 'right',
                            fill: '#FF4444'
                        }}
                    />
                    <Line
                        type="monotone"
                        dataKey={`${config.key}MA`}
                        stroke="#FF8800"
                        name="Moving Average"
                        dot={false}
                    />
                </ComposedChart>
            </ResponsiveContainer>
        </div>
    );
};

const PickedBoxesChart = (props) => (
    <ChartComponent {...props} config={CONFIG.pickedBoxes} />
);

const PackedBoxesChart = (props) => (
    <ChartComponent {...props} config={CONFIG.packedBoxes} />
);

const ShippedBoxesChart = (props) => (
    <ChartComponent {...props} config={CONFIG.shippedBoxes} />
);

const InventoryControlCharts = ({ timeFrame = "Hours", movingAverageWindow = 3, siteId, data, loading: parentLoading }) => {
    return (
        <div className="space-y-8">
            <PickedBoxesChart timeFrame={timeFrame} movingAverageWindow={movingAverageWindow} siteId={siteId} data={data} loading={parentLoading} />
            <PackedBoxesChart timeFrame={timeFrame} movingAverageWindow={movingAverageWindow} siteId={siteId} data={data} loading={parentLoading} />
            <ShippedBoxesChart timeFrame={timeFrame} movingAverageWindow={movingAverageWindow} siteId={siteId} data={data} loading={parentLoading} />
        </div>
    );
};

export default InventoryControlCharts;